import styles from "./ResumeReviewDetailPrint.module.css";
import { useState, useEffect, useLayoutEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../common/hooks/useCustomAxios';
import MessageManager from "../common/ErrorManager";

import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const ResumeReviewDetailPrint = () => {

  const [loadComponent, setLoadComponent] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState(false)
  const [candidateInformation, setCandidateInformation] = useState()
  const [needsReview, setNeedsReview] = useState(false)
  const [resumeIdToProcess, setResumeIdToProcess] = useState()
  const [isRequestInProgress, setIsRequestInProgress] = useState(false)
  const [noInfoAvailable, setNoInfoAvailable] = useState(false)
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIcon)
  const [isRationaleVisible, setRationaleVisible] = useState('inline')

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();

  let processing = true
  let redirect = false
  let showMessageArea = false

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  let fitAssessment = ["-", "Not a Fit", "Possible Fit", "God Fit", "Great Fit"]
  // resume.fit_score > 6? fit_assessment[3] : resume.fit_score > 3 ?  resume.fit_score[2] : resume.fit_score > 0 ? resume.fit_score[1] : resume.fit_score[0]
  let fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus]

  useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });

  // Retrieve data first and populate any state
  useEffect( () => {

    if(urlParameters.id > 0 && urlParameters.rid > 0 && urlParameters.rsid > 0 && (loadComponent || state.newResume  === true)){
      setLoadComponent(false)
      fetchData(process.env.REACT_APP_RESUME_DETAIL_API )
    }else{
      //Nothing
    }

    return () => {
        processing = false
    }
    }, [state] 
  )

  const loadComponentData = () => {

  }

  const setInitialData = (responseData)  => {
    const {id, name, fit_assessment, fit_score, profesional_experience, resume_url_1, uploaded_date, fit_rationale, fit_summary} = responseData

    setIsRequestInProgress(false)
    setShowMessage(false)
    showMessageArea = false
    setNeedsReview(false)
    setNoInfoAvailable(false)

    if(name === undefined || name === null){
      //probably not a valid resume/candidate id
      if( (fit_score === undefined || fit_score === null) || fit_score < 0 && id > 0){
        setResumeIdToProcess(id)
        setNeedsReview(true) 
      }
    }else if((fit_score === undefined || fit_score === null) || fit_score < 0 && id > 0){
      setResumeIdToProcess(id)
      setNeedsReview(true)
    }else if(name !== undefined || name !== null && fit_score > 0){
      setCandidateInformation(responseData)
    }else{
      setNoInfoAvailable(true)
    }
    processing = false

    if(redirect){
      navigate("/CompanySetup/" + ctx.Company.id + "/" + id, {state: {newRole:true}});
    }
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    setIsRequestInProgress(false)
    let noRoleFoundMsg = "Candidate's resume detail could not be found. Please try again, or select a different candidate."
    let errorMsg = "An error was found. Please check the console for more information"
    displayMessage(error.response !== undefined ? noRoleFoundMsg : errorMsg)
  }

  const reviewThisResume = ()=>{
    setNeedsReview(false)
    showMessageArea = false
    setIsRequestInProgress(true)
    // postData(process.env.REACT_APP_RESUME_REVIEWS_API)
    fetchData(process.env.REACT_APP_RESUME_REVIEW_API)
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT

  // Get data using the axios functionality
  const fetchData = async(urlPath) => {
    setIsRequestInProgress(true)
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_RESUME_DETAIL_API
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid + "/" + urlParameters.rsid
    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
        setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeReviewDetail", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  }

  const postData = async(urlPath) => {
    COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid
    const postData = resumeIdToProcess
    // await axios.post(URL , postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }}
    //   )
    await customAxios.post(URL , postData)
    .then(res => {
      setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "AdditionalEvalCrit", ctx.Config.Error.getLevels.error)
    })
  }

  const displayMessage = (messageToDisplay) => {
    showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        showMessageArea = show
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  const setRationaleVisibleOnOff = () => {
    let isOn = isRationaleVisible === 'inline' ?  'none' : 'inline'
    setRationaleVisible(isOn)
    
    let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    setOpenCloseIconUp(iconStyle)    
  }

  return candidateInformation ? (
    
        <div className={styles.mainCandidateReviewArea}>
          {/* Summary Area */}
          <div className={styles.candidateContainerInfoArea}>
              {/* Candidate info area */}
              <div className={styles.candidateLabelArea}>
                <div className={styles.candidateLabel}> {candidateInformation.name} </div>
              </div>
              <div className={styles.assessmentArea}>
                <div className={fitAssessmentStyle[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null ? 0 : candidateInformation.fit_assessment + 1]} >{fitAssessment[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null  ? 0 : candidateInformation.fit_assessment + 1]}</div>
                <div className={styles.scoreContent} >Score: {candidateInformation.fit_score} </div>
              </div>
              
              <div className={styles.candidateSummaryArea} >
                  {candidateInformation.fit_summary}
              </div>
              
              {/* ^ Candidate info area ^ */}
          </div>
          {/* ^ Summary Area ^ */}

          {/* Rationale Area */}
          <div className={styles.rationaleArea}>
            <div className={styles.rationaleTitle} >
              <div className={styles.rationaleLabel}>
                Rationale
              </div>
              <div className={styles.rationaleOpenCloseArrow}>
                
              </div>
            </div>
            <div style={{display: isRationaleVisible}} className={styles.rationaleContent} >
              {candidateInformation.fit_rationale}
              <p/>  {/* <p/> To create a space at the bottom */}
            </div>
            <div className={styles.notesArea} >
              {/* NOTES HERE */}
            </div>
          </div>
          {/* ^ Rationale Area ^ */}

          {/* Resume Area */}
          <div className={styles.resumeComponent} > 
            <div className={styles.resumeLabel}>
                  Resume
            </div>          
            <div className={styles.resumeDetailView} > {candidateInformation.profesional_experience} </div>
          </div>
          {/* ^ Resume Area ^ */}
        { showMessageArea ? 
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        : <></>}
        </div>

  ) : needsReview ? (
        <div className= {styles.noInfoMessageArea}>
            This candidate's resume has not been analyzed or an issue was found.<br/>
            Please try again, or select a different candidate.
            <div className={styles.performReviewButton} onClick={()=> reviewThisResume()} >Perform the analysis</div>
        </div>
  ) : isRequestInProgress ? (
        <div className={styles.requestInProgressContainer} >
          <div className={styles.requestInProgressArea} >
            <img height='180' width='108' src='/TO_Page_Progress.gif' /><br/>
            Candidate's resume analysis is in-progress...
          </div>
        </div>
  ) : noInfoAvailable ? (
        <div className= {styles.noInfoMessageArea}>
          No information available, please select a different candidate.
        </div>
  ) : showMessage ? (
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                    {message}
                </div> 
            </div>
        </div>
  ) : ( 
        <div></div> 
  )
};

export default ResumeReviewDetailPrint;
