import styles from "./ResumeReviewDetail.module.css";
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import useCustomAxios from '../common/hooks/useCustomAxios';
import MessageManager from "../common/ErrorManager";
import ProcessStatusDisplayNames from "../common/Status/ProcessStatusDisplayNames";
import CalendarScheduler from '../components/calendar/CalendarScheduler';

import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const ResumeReviewDetail = () => {

  const [loadComponent, setLoadComponent] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState(false)
  const [candidateInformation, setCandidateInformation] = useState()
  const [needsReview, setNeedsReview] = useState(false)
  const [resumeIdToProcess, setResumeIdToProcess] = useState()
  const [isRequestInProgress, setIsRequestInProgress] = useState(false)
  const [noInfoAvailable, setNoInfoAvailable] = useState(false)
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIconUp)
  const [isRationaleVisible, setRationaleVisible] = useState('none')
  const [processStatus, setProcessStatus] = useState(-1);
  const [myList, setMyList] = useState("[]");
  const [onMyList, setOnMyList] = useState(false);
  const [isResumeVisible, setIsResumeVisible] = useState(false);
  const [personalNotes, setPersonalNotes] = useState('');
  const [areNotesVisible, setAreNotesVisible] = useState(false);
  const [saveNotes, setSaveNotes] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [transferEvent, setTransferEvent] = useState({});

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();
  let printComponentRef = useRef();

  let processing = true
  let redirect = false
  let showMessageArea = false

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  let fitAssessment = ["-", "Not a Fit", "Possible Fit", "Good Fit", "Great Fit"]
  // resume.fit_score > 6? fit_assessment[3] : resume.fit_score > 3 ?  resume.fit_score[2] : resume.fit_score > 0 ? resume.fit_score[1] : resume.fit_score[0]
  let fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus]

  useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });

  // Retrieve data first and populate any state
  useEffect( () => {

    loadMyListData();

    if(urlParameters.id > 0 && urlParameters.rid > 0 && urlParameters.rsid > 0 && (loadComponent || state.newResume  === true)){
      setLoadComponent(false)
      fetchData(process.env.REACT_APP_RESUME_DETAIL_API )
    }else{
      //Nothing
    }

    return () => {
        processing = false
    }
    }, [state] 
  )

  useEffect( () => {

    loadMyListData();

    return () => {
        processing = false
    }
    }, [] 
  )

  const loadMyListData = () => {
    const myNewList = [];
    const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]" ? ctx.User?.myList : 0;
    if(myListInCtx !== 0){
      const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
      parsedList.forEach((item)=> myNewList.push(item));
      setMyList(myNewList)
    }else{
      setMyList([]);
    }

    return myNewList;
  }

  const setInitialData = (responseData)  => {
    const {id, name, fit_assessment, fit_score, profesional_experience, resume_url_1, uploaded_date, fit_rationale, fit_summary} = responseData

    setIsRequestInProgress(false)
    setShowMessage(false)
    showMessageArea = false
    setNeedsReview(false)
    setNoInfoAvailable(false)
    setPersonalNotes("")

    if(id !== undefined && id !== null && id > 0){
      const myPreferedList = loadMyListData();
      if(myPreferedList.includes(String(id))){
        setOnMyList(true);
      }
    }

    if(name === undefined || name === null){
      //probably not a valid resume/candidate id
      if( (fit_score === undefined || fit_score === null) || fit_score < 0 && id > 0){
        setResumeIdToProcess(id)
        setNeedsReview(true) 
      }
    }else if((fit_score === undefined || fit_score === null) || fit_score < 0 && id > 0){
      setResumeIdToProcess(id)
      setNeedsReview(true)
    }else if(name !== undefined || name !== null && fit_score > 0){
      setProcessStatus(responseData.process_status)
      setCandidateInformation(responseData);
      setPersonalNotes(responseData.notes === null || responseData.notes === undefined ? "" :responseData.notes);
    }else{
      setNoInfoAvailable(true)
    }
    processing = false

    if(redirect){
      navigate("/CompanySetup/" + ctx.Company.id + "/" + id, {state: {newRole:true}});
    }
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    setIsRequestInProgress(false)
    let noRoleFoundMsg = "Candidate's resume detail could not be found. Please try again, or select a different candidate."
    let errorMsg = "An error was found. Please check the console for more information"
    displayMessage(error.response !== undefined ? noRoleFoundMsg : errorMsg)
  }

  const reviewThisResume = ()=>{
    setNeedsReview(false)
    showMessageArea = false
    setIsRequestInProgress(true)
    // postData(process.env.REACT_APP_RESUME_REVIEWS_API)
    fetchData(process.env.REACT_APP_RESUME_REVIEW_API)
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT

  // Get data using the axios functionality
  const fetchData = async(urlPath) => {
    setIsRequestInProgress(true)
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_RESUME_DETAIL_API
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid + "/" + urlParameters.rsid
    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
        setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeReviewDetail", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  }

  const postData = async(urlPath, dataToPost) => {
    COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid
    const postData = dataToPost
    // await axios.post(URL , postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }}
    //   )
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      // console.log(res.data);
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeReviewDetail", ctx.Config.Error.getLevels.error)
    })
  }

  const displayMessage = (messageToDisplay) => {
    showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        showMessageArea = show
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  const setRationaleVisibleOnOff = () => {
    let isOn = isRationaleVisible === 'inline' ?  'none' : 'inline'
    setRationaleVisible(isOn)
    
    let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    setOpenCloseIconUp(iconStyle)    
  }

  const setResumeVisibleOnOff = ()=>{
    setIsResumeVisible(isResumeVisible ? false : true);
  }

  const selectStatus = (selectedStatus)=>{
    // console.log("Status Selected: " + selectedStatus);
    const url = process.env.REACT_APP_RESUME_PROCESS_STATUS_API;
    setProcessStatus(selectedStatus);
    // id: Optional[int] = Field(default=None)
    // """Store resume id"""
    // status: Optional[int] = Field(default=None)
    // """Store the resume status to update"""

    const dataToPost = {
      id: urlParameters.rsid,
      status: selectedStatus,
    }

    postData(url, [dataToPost]);
  }

  const addToRemoveFromMyList = (id)=>{
    setOnMyList(onMyList === true ? false : true);
    //send the request to store the change
    const url = process.env.REACT_APP_USER_UPDATE_FOCUSLIST_API;
    const idToUpdate = [id];
    postDataUpdateMyList(url, idToUpdate);
  }

  const updateMyListLocally = (response)=>{
    const {user_focus_list, last_updated} = response;
    ctx.User.myList = JSON.stringify(user_focus_list);
    setContext(ctx);
  }

  const postDataUpdateMyList = async(urlPath, dataToPost) => {
    let URL = urlPath
    const postData = dataToPost
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      updateMyListLocally(res.data);
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeReviewDetail", ctx.Config.Error.getLevels.error)
    })
  }


  const postDataUpdateNotes = async(dataToPost) => {
    let URL = process.env.REACT_APP_RESUME_NOTES_UPDATE_API + "/" + urlParameters.id + "/" + urlParameters.rid + "/" + urlParameters.rsid
    const postData = dataToPost
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      updateSaveButton(res.data);
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeReviewDetail", ctx.Config.Error.getLevels.error)
    })
  }

  const updateSaveButton = ()=>{
    setSaveNotes(false);
  }

  const setPersonalNotesValue = (newNotes)=>{
    setPersonalNotes(newNotes);
    setSaveNotes(true);
  };

  const setAreNotesVisibleOnOff = ()=>{
    setAreNotesVisible(areNotesVisible ? false: true);
  }

  const saveNotesSubmit = ()=>{

    const dataToSave = {id: urlParameters.rsid,
                        role_id: urlParameters.rid,
                        notes: personalNotes
                       };
    postDataUpdateNotes(dataToSave);
  }

  const openScheduler = (arg)=>{
    const guestList = [];

    if(candidateInformation){
      const contactInfo = {id: candidateInformation.id, name: candidateInformation.name, 
        email: candidateInformation.email_1, role_id: candidateInformation.role_id, 
        resume_id: candidateInformation.id, role_name: null,
        company_name: null };
        
        guestList.push(contactInfo);
    }

    if(guestList.length > 0){
    const todayDate = new Date();
    const event = 
      { id:"-1", title: "", start: todayDate, end: todayDate, className: styles.eventClick,
        extendedProps: {
          meeting_id: 0,
          location: '',
          description: '',
          guests: guestList, //[{name: '', email: '', role_id: 0, resume_id: 0}],
          url: ''
        },
      };
    setTransferEvent(event);
    setShowScheduler(true);
    }else{
      displayMessage("No candidates have been selected to create an invite.")
    }
  };

  const closeScheduler = ()=>{
    const show = showScheduler === true ? false : true;
    setShowScheduler(show);
  }

  return candidateInformation ? (
    
        <div className={styles.mainCandidateReviewArea}>
          {/* Summary Area */}
          <div className={styles.candidateContainerInfoArea}>
              {/* Candidate info area */}
              <div className={styles.candidateLabelArea}>
                <div className={styles.candidateLabel}> {candidateInformation.name}</div>
                <div onClick={()=>addToRemoveFromMyList(candidateInformation.id)} >
                  {
                    onMyList === true ?
                  <img className={styles.starIconArea} alt="Add to My List" src="/Star_Icon.svg" />:
                  <img className={styles.starIconArea} alt="Add to My List" src="/Star_Empty_Icon.svg" />
                  }
                </div>
                <div className={styles.inviteArea} onClick={(e)=> openScheduler(e)} >
                  <img className={styles.inviteIcon} alt="Create an invitation" title="Create an invitation" src="/Calendar_Icon_green.svg" />
                </div>
              </div>
              <div className={styles.assessmentArea}>
                <div className={fitAssessmentStyle[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null ? 0 : candidateInformation.fit_assessment + 1]} >{fitAssessment[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null  ? 0 : candidateInformation.fit_assessment + 1]}</div>
                <div className={styles.scoreContent} >Score: {candidateInformation.fit_score} </div>
                <div className={styles.processStatus} >{ProcessStatusDisplayNames(processStatus)}
                {/* <div className={styles.processStatus} >{ProcessStatusDisplayNames(candidateInformation.process_status)} */}
                {/* <div className={styles.processStatus} ><span >{ProcessStatusDisplayNames(4)}</span>  */}
                  <div className={styles.processStatusMenu} >
                    <div className={processStatus === -1 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(-1)}>Ready for Next Step</div>
                    <div className={processStatus === 1 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(1)}>Reviewed</div>
                    <div className={processStatus === 2 ? styles.processStatusMenuSelected : ''}onClick={()=>selectStatus(2)}>Shared with Talent Team</div>
                    <div className={processStatus === 3 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(5)}>Candidate Follow up</div>
                    <div className={processStatus === 4 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(3)}>Shared with Hiring Lead/Team</div>
                    <div className={processStatus === 5 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(4)}>Internal Feedback In-Progress</div>
                    <div className={processStatus === 6 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(6)}>Initial Call(s) In-Progresss</div>
                    <div className={processStatus === 7 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(7)}>Interview(s) In-Progress</div>
                    <div className={processStatus === 8 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(8)}>Offer Extended</div>
                    <div className={processStatus === 9 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(9)}>Hired</div>
                    <div className={processStatus === 1 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(0)}>Rejected</div>
                  </div>
                </div>
                </div>
              {/* <div className={styles.processStatus} ><span >&nbsp;&nbsp;{ProcessStatusDisplayNames(4)}</span> </div> */}
              <div className={styles.candidateSummaryArea} >
                  {candidateInformation.fit_summary}
              </div>
              
              {/* ^ Candidate info area ^ */}
          </div>
          {/* ^ Summary Area ^ */}

          {/* Rationale Area */}
          <div className={styles.rationaleArea}>
            <div className={styles.rationaleTitle} >
              <div className={styles.rationaleLabel}>
                Rationale
                <img className={openCloseIconUp} onClick={setRationaleVisibleOnOff} alt="" src="/open-close-icon.svg" />
              </div>
            </div>
            <div style={{display: isRationaleVisible}} className={styles.rationaleContent} >
              {candidateInformation.fit_rationale}
              <p/>  {/* <p/> To create a space at the bottom */}
            </div>
          </div>
          {/* ^ Rationale Area ^ */}
          <div className={areNotesVisible ? styles.notesArea : styles.notesAreaClosed} >
              {/* NOTES HERE */}
            <div className={styles.notesTitle} >
              <div className={styles.notesLabel}>
                  Notes
                  <img className={areNotesVisible ? styles.openCloseIcon : styles.openCloseIconUp} onClick={setAreNotesVisibleOnOff} alt="" src="/open-close-icon.svg" />
                  {
                    saveNotes ? 
                  <div className={styles.submitButtonArea} onClick={saveNotesSubmit}>
                    <div >Save</div>
                    <div >
                      {/* <button className={styles.submitButton} type="submit" onClick={handleSubmit} >Save</button> */}
                      <img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
                    </div>
                  </div>
                  :<></>
                  }
              </div>
            </div>
              <textarea value={personalNotes} onChange={(e) => {setPersonalNotesValue(e.target.value);}}               
                        className={styles.notes} 
                        id="notes" name="notes" 
                        rows={14 }  placeholder="Add your personal notes here..."/>
          </div>
          {/* Resume Area */}
          <div className={isResumeVisible ? styles.resumeComponent : styles.resumeComponentClosed} >
            <div className={styles.resumeLabel}>
                  Resume
                  <img className={isResumeVisible ? styles.openCloseIcon : styles.openCloseIconUp} onClick={setResumeVisibleOnOff} alt="" src="/open-close-icon.svg" />
            </div>
            <div className={styles.resumeDetailView} > {candidateInformation.profesional_experience} </div>
          </div>
          {/* ^ Resume Area ^ */}
        { showMessageArea ? 
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        : <></>}
        {
          showScheduler ?
          <div className={ showScheduler ? styles.messageContainer : styles.hideModal } >
            <CalendarScheduler closeScheduler={closeScheduler} event={transferEvent} />
          </div>
          :<></>
        }

        </div>

  ) : needsReview ? (
        <div className= {styles.noInfoMessageArea}>
            This candidate's resume has not been analyzed or an issue was found.<br/>
            Please try again, or select a different candidate.
            <div className={styles.performReviewButton} onClick={()=> reviewThisResume()} >Perform the analysis</div>
        </div>
  ) : isRequestInProgress ? (
        <div className={styles.requestInProgressContainer} >
          <div className={styles.requestInProgressArea} >
            <img height='180' width='108' src='/TO_Page_Progress.gif' /><br/>
            Candidate's resume analysis is in-progress...
          </div>
        </div>
  ) : noInfoAvailable ? (
        <div className= {styles.noInfoMessageArea}>
          No information available, please select a different candidate.
        </div>
  ) : showMessage ? (
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                    {message}
                </div> 
            </div>
        </div>
  ) : ( 
        <div></div> 
  )
};

export default ResumeReviewDetail;
