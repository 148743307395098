import styles from "./TalentAvailability.module.css";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useRef, useState, useEffect  } from 'react';

import CalendarAvailabilityView from "../../components/calendar/CalendarAvailabilityView"

const TalentAvailability = () => {


  const [showSubHeader, setSubHeader] = useState("");

  return (
    
        <div className={styles.mainHomeArea}>
    <div >
      <img className={styles.logoIcon_2} alt="" src="/Talent_Logo_Txt_Color_Simple.svg" />
      <div className={styles.topGlobalNavArea}></div>
    </div>
          <div className={styles.mainContentArea} >
            <div className={styles.welcomeLabelArea} >
              {/* <img className={styles.toLogo} src="/Talent_Logo_Txt_Color_Simple.svg" /> */}
              <span className={styles.talentColorLogo}>Talent</span> <span className={styles.opinionColorLogo}>Opinion</span> <br/>Availability Calendar<br/>
              <span className={styles.welcomeAreaSubHeader} >{showSubHeader}</span>
            </div>
            <div className={styles.calendarArea} >
                <CalendarAvailabilityView setSubHeader={setSubHeader} />
            </div>
          </div>
        </div>
  )
};

export default TalentAvailability;
