import styles from "./Registration.module.css";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MessageManager from "../common/ErrorManager";


const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/
// const NAME_LASTNAME_REGEX = /^[A-Z][a-z-]{3,23}$/;
const NAME_LASTNAME_REGEX = /^[A-Za-z-]{3,70}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;

const VerifyEmail = () =>{

    const [emailField, setEmailField] = useState("");
    const [emailFocus, setEmailFocus] = useState(false);
    const [validEmail, setValidEmail] = useState(false);

    const [validationCode, setValidationCode] = useState();
    const [isValidCode, setIsValidCode] = useState(false);
    const [validationRequest, setValidationRequest] = useState(true);
    const [validationCodeFocus, setValidationCodeFocus] = useState(false);
    const [isValidationCodeFieldVisible, setIsValidationCodeFieldVisible] = useState(false);

    const [registrationErrorMsg, setRegistrationErrorMsg] = useState("");
    const [isRequestInProgress, setIsRequestInProgress] = useState(false)

    const navigate = useNavigate();
    const emailFieldRef = useRef();
    const errorMessageRef = useRef();    
    const validationCodeRef = useRef();
    
    
    // let {state} = useLocation();

    let today = new Date();


    //Initial state, focus on the name field
    useEffect( () => {
        if(validationRequest){
            emailFieldRef?.current.focus()
        }
            // emailFieldRef.current.focus();
            return () => {
            }
        }, []
    );
 
    useEffect(() => {        
        setValidEmail(EMAIL_REGEX.test(emailField));
    }, [emailField])

    useEffect(() => {
        setIsValidCode(CODE_REGEX.test(validationCode));
    }, [validationCode])

    const handleSubmit = (e) =>{
        e.preventDefault();
        setRegistrationErrorMsg("")
        
        if(validEmail && isValidCode){
            const validateCodeUrl = process.env.REACT_APP_OAUTH_EMAIL_VALIDATE_CODE_API
            setIsRequestInProgress(true)
            postData(validateCodeUrl, {validation_code: validationCode, email_1: emailField})
        }else if(validEmail){
            const validateEmailUrl = process.env.REACT_APP_OAUTH_EMAIL_VALIDATION_API
            setIsRequestInProgress(true)
            postData(validateEmailUrl, {email_1: emailField})
        }else{
            setIsRequestInProgress(false)
            displayMessage("One or more of the required fields is mssing.")
        }
    }

    const setValidateCodeResponse = (responseData) => {
        const {validation_message, updated_date} = responseData;
        
        setIsRequestInProgress(false)

        if(validation_message.includes("is valid")){
            displayMessage("Code is valid.");

            navigate("/register", {state: {validation_code: validationCode, email: emailField}, replace: true});
        }else{
            displayMessage(validation_message);
        }
    }

    const setValidateCodeErrorResponse = (responseData)  => {

        setIsRequestInProgress(false)

        let errorMsg = ""

        if(responseData.response?.status === 400){
            errorMsg = "Email and validation code is required. Missing email or code."
        }else if (responseData.response?.status === 424){
            errorMsg = "Code is not valid. Enter a valid code."
        }else{
            errorMsg = "Email validation failed. Try again in 1 minute."
        }

        displayMessage(errorMsg)
    }

    const setEmailValidationResponse = (responseData)  => {
        const {validation_message, updated_date} = responseData

        let errorMsg = ""
        setIsRequestInProgress(false)

        setValidationRequest(false);
        const message = validation_message
        if(message.includes("sent")){
            displayMessage("Email has been sent to your inbox.");
        }else{
            displayMessage(message);
        }
    }

    const setEmailValidationErrorResponse = (error)  => {

        setIsRequestInProgress(false)

        let errorMsg = ""

        if(error.response?.status === 400){
            errorMsg = "Email is required. Missing email."
        }else if (error.response?.status === 401){
            errorMsg = "Email is already registered. Try a new one."
        }else if (error.response?.status === 422){
            errorMsg = "Verification request issue. Try again."
        }else if (error.response?.status === 500){
            errorMsg = "Email validation code could not be sent. Try again."
        }else{
            errorMsg = "Email validation failed. Try again."
        }

        displayMessage(errorMsg)
    }

    const postData = async(apiUrl, dataToPost) => {        
        const COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_OAUTH_REGISTRATION_PORT

        // Change to support proxy
        // let URL_PATH = COMAIN_PORT +  apiUrl
        let URL_PATH = apiUrl
        let URL = URL_PATH
        const postData = dataToPost
        await axios.post(URL , postData)
        .then(res => {
            if(validationRequest){
                setEmailValidationResponse(res.data)
            }else{
                setValidateCodeResponse(res.data)
            }
        })
        .catch(error => {
        //   errorsMessages(error)
          MessageManager(error, "Login", 2)
            if(validationRequest){
                setEmailValidationErrorResponse(error)
            }else{
                setValidateCodeErrorResponse(error)
            }
        })
    }


    // Errors captured when sending API requests
    const errorsMessages = (error) => {
        setIsRequestInProgress(false)
        let errorMsg = ""
        
        if(error.response?.status === 400){
            errorMsg = "Missing Email or Password"
        }else if (error.response?.status === 401){
            errorMsg = "User or email has already been registered."
        }else if (error.response?.status === 424){
            errorMsg = "Invalid code or expired."
        }else{
            // Email has not been validated, or code is not loger valid.
            errorMsg = "Sign Up failed."
        }

        displayMessage(errorMsg)
        errorMessageRef.current.focus()

    }

    const displayMessage = (messageToDisplay) => {
        // showMessageArea = true
        // setShowMessage(true)
        // setMessage(messageToDisplay)

        setRegistrationErrorMsg(messageToDisplay)
      }


    return (
        <div className={styles.mainRegisterArea} >
            <div className={styles.logo}> <img className={styles.logo}  src="/Talent_Logo_Txt_Color_Simple.svg"  ></img> </div>
            <div className={styles.registerArea} >
                <div ref={errorMessageRef} className={styles.loginError} aria-live="assertive">
                    {registrationErrorMsg}
                </div>
                <div  className={styles.heading}>Verify</div>
                <div className={styles.formArea}>

                    <form onSubmit={handleSubmit}>
                        <div className={styles.validationMessage} >
                            To start your registration process, please verify and validate your email.
                        
                        </div>
                        <div className={styles.validationMessage} >
                            If your email is not yet registered, then you'll receive a validation code.
                        </div>
                        <div className={styles.validationMessage} >Enter the code below to continue.</div>
                        <div className={styles.emailArea} >
                            <label className={emailField && !validEmail ? styles.invalidFieldLabel : styles.emailFieldLabel}  htmlFor="email_1">Email</label>
                            <input className={styles.emailField}  type="text" id="email_1" 
                                    ref={emailFieldRef}
                                    placeholder="Enter your email here."
                                    autoComplete="off" 
                                    onChange={ (e)=> setEmailField(e.target.value) } 
                                    value={emailField}
                                    required
                                    aria-invalid={validEmail ? "false" : "true"}
                                    aria-describedby="uidnote3"
                                    onFocus={() => setEmailFocus(true)}
                                    onBlur={() => setEmailFocus(false)}
                            />
                        </div>
                        <div id="uidnote3" className={emailFocus && emailField && !validEmail ? styles.fieldMsg : styles.screenReaderHidden}>
                                Format example: <span aria-label="your name">yourname</span><span aria-label="at symbol">@</span><span aria-label="domain">domain</span><span aria-label="dot">.</span><span aria-label="com">com</span>
                        </div>
                    
                        <div className={styles.emailValidationCodeArea} >
                            <label className={validationCode && !isValidCode ? styles.invalidFieldLabel : styles.emailFieldLabel}  htmlFor="validation_code">Validation code</label>
                            <input className={styles.validationCodeField}  type="text" id="validation_code" 
                                    ref={validationCodeRef}
                                    placeholder="Enter validation code here."
                                    autoComplete="off" 
                                    onChange={ (e)=> setValidationCode(e.target.value) } 
                                    value={validationCode}
                                    aria-invalid={isValidCode ? "false" : "true"}
                                    aria-describedby="uidnote3"
                                    onFocus={() => setValidationCodeFocus(true)}
                                    onBlur={() => setValidationCodeFocus(false)}
                            />
                        </div>
                        <div id="uidnote3" className={validationCodeFocus && validationCode && !isValidCode ? styles.fieldMsg : styles.screenReaderHidden}>
                                Validation codes are 6 digits long.
                        </div>
                    
                        <button disabled={ !validEmail && !isRequestInProgress ? true : false} className={!validEmail || isRequestInProgress  ? styles.signInButtonDisabled : styles.signInButton} >{validEmail && isValidCode ? "Continue" : "Verify email" }</button>
                    </form>


                    <div className={styles.registeredAlready}>
                        Already registered? <br></br>
                        <span className={styles.registeredMsg} >
                        <Link className={styles.registeredMsg} to={'/signin'} state={{new: true}} >Sign In</Link>
                        </span>
                    </div>
                </div>
                { isRequestInProgress ?
                    <div className={styles.progressContainer} >
                        <div className={styles.progressArea} >
                            <div > <img className={styles.progressLogo}  src="/Profile_Icon_WhiteCircle_Thicker_Plain_Anim.svg"  /></div>
                            <div>Validation in-progress...</div>
                        </div>
                    </div>
                    :<></>
                }
            </div>
        </div>
    )
}

export default VerifyEmail