import styles from "./ResumeChat.module.css";
import MessageManager from "../common/ErrorManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const ResumeChatSelectMessage = () => {

  return (
    
        <div className={styles.mainCandidateReviewAndChatArea}>
          
          <div className={styles.selectCoAndResumeIndicatorGif} >
            <img alt="Indicator" width="10" height="310" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
          </div>
          <div className={styles.selectCoAndResumeIndicatorLine} ></div>
          <div className={styles.selectCoAndResumeIndicatorMsg} >
            Please click on the name of a candidate from the helper view<br/> 
            to start a chat session.
          </div>
        </div>
  )
};

export default ResumeChatSelectMessage;
