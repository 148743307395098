import styles from "./Legal.module.css";
import { Link } from "react-router-dom";

const Legal = () => {

    return(

        <div className={styles.legalContainer} >
            <div className={styles.legalPageTitle} >
                Legal information and resources
                <div className={styles.legalSubTitleInfo}>Find all the information you need regarding your legal rights to use Talent Opinion services.</div>
            </div>
            <div className={styles.legalMainSectionContainer} >
                <div className={styles.legalTermSection} >
                    <div className={styles.legalSectionTitle} >
                        Terms of Use
                    </div>
                    <div className={styles.legalSectionContent} >
                    <Link to={"/legal/terms"} className={styles.links} >Talent Opinion Terms of Use.</Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Legal;