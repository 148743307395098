import styles from "./Registration.module.css";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from "axios";
import useCustomAxios from '../common/hooks/useCustomAxios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import MessageManager from "../common/ErrorManager";
import ContextManager from "../common/Context/ContextManager";
import TermsofUse from "./Legal/TermsofUse";


const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/
// const NAME_LASTNAME_REGEX = /^[A-Z][a-z-]{3,23}$/;
const NAME_LASTNAME_REGEX = /^[A-Za-z-]{3,70}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;

const Registration = () =>{

    const [nameField, setNameField] = useState("");
    const [nameFocus, setNameFocus] = useState(false);
    const [lastNameField, setLastNameField] = useState("");
    const [lastNameFocus, setLastNameFocus] = useState(false);
    const [emailField, setEmailField] = useState("");
    const [emailFocus, setEmailFocus] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [passwordField, setPasswordField] = useState("");
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [validName, setValidName] = useState(false);
    const [validLastName, setValidLastName] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [matchPasswordField, setMatchPasswordField] = useState('');
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);
    const [validMatchPasswordField, setValidMatchPassword] = useState(false);
    const [validationCode, setValidationCode] = useState(0);
    const [isValidCode, setIsValidCode] = useState(false);
    const [validationRequest, setValidationRequest] = useState(true);
    const [validationCodeFocus, setValidationCodeFocus] = useState(false);
    const [isValidationCodeFieldVisible, setisValidationCodeFieldVisible] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [termsFieldChecked, setTermsFieldChecked] = useState(false);
    const [termsFocus, setTermsFocus] = useState(false);
    


    const customAxios = useCustomAxios()
    const [registrationErrorMsg, setRegistrationErrorMsg] = useState("");
    const [isRequestInProgress, setIsRequestInProgress] = useState(false)
    const { ctx, setContext } = ContextManager()

    const navigate = useNavigate();
    const location = useLocation()
    const from = location.state?.from?.pathname || "/";
    const verifiedValidationCode = location.state?.validation_code || null;
    const verifiedEmail = location.state?.email || null
    const nameFieldRef = useRef();
    const lastNameFieldRef = useRef();
    const emailFieldRef = useRef();
    const errorMessageRef = useRef();
    const validationCodeRef = useRef();
    const termsFieldRef = useRef();
    
    
    // let {state} = useLocation();

    let today = new Date();

    useEffect( () => {
            nameFieldRef?.current.focus();
            setValidationCode(verifiedValidationCode ? verifiedValidationCode : 0)
            setEmailField(verifiedEmail ? verifiedEmail : null)
            // emailFieldRef.current.focus();
            return () => {
            }
        }, []
    );
    
    useEffect(() => {
        setValidName(NAME_LASTNAME_REGEX.test(nameField));
    }, [nameField])

    useEffect(() => {        
            setValidLastName(NAME_LASTNAME_REGEX.test(lastNameField));
    }, [lastNameField])

    useEffect(() => {        
        setValidEmail(EMAIL_REGEX.test(emailField));
    }, [emailField])


    useEffect(() => {

        setValidPassword(PWD_REGEX.test(passwordField));
        
        setValidMatchPassword(passwordField === matchPasswordField)

    }, [passwordField, matchPasswordField])


    useEffect(() => {

        setIsValidCode(CODE_REGEX.test(validationCode));

    }, [validationCode])

    useEffect(() => {

        // setIsValidCode(CODE_REGEX.test(validationCode));
        // console.log("Terms checked: " + termsFieldChecked);

    }, [termsFieldChecked])

    const handleSubmit = (e) =>{
        e.preventDefault();
        setRegistrationErrorMsg("")
        
        if(validationCode === null || validationCode === undefined || validationCode === 0){
            setIsRequestInProgress(false)
            displayMessage("The email validation code is mssing. Please go back and validate your email.")
        }else if(validEmail && validPassword && validName && validLastName && validMatchPasswordField && termsFieldChecked){
            setIsRequestInProgress(true)
            postData(e);
        }else{
            setIsRequestInProgress(false)
            displayMessage("One or more of the required fields or the validation code is mssing.")
        }

    }

    const setValidationCodeResponse = (responseData) => {

        setValidationCode(responseData.validation_message ? responseData.validation_message : 0)
    }

    const setInitialData = (responseData)  => {
        const {access_token, token_type, user_id, features_limit, session_timeOut} = responseData

        setIsRequestInProgress(false);
        
        ctx.User.id = user_id;
        ctx.User.token = access_token;
        ctx.Session.sessionTimeOut = session_timeOut;
        const rightNow = new Date()
        ctx.Session.lastRequestTime = rightNow;

        ctx.Resume.uploads = Number(features_limit.uploads);
        ctx.Resume.reviews = Number(features_limit.reviews);
        ctx.Resume.compares = Number(features_limit.compares);
        ctx.Resume.chat = Number(features_limit.chat);
        setContext(ctx);

        setNameField("");
        setLastNameField("");
        setEmailField("");
        setPasswordField("");
        setMatchPasswordField("");

        navigate("/home", {state: {active:true, lastRequestTime: rightNow, sessionTimeOut: session_timeOut}, replace: true});
    }

    const postData = async(e) => {
        e.preventDefault();
        
        const COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_OAUTH_REGISTRATION_PORT

        // Change to support proxy
        // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_OAUTH_REGISTRATION_API
        let URL_PATH = process.env.REACT_APP_OAUTH_REGISTRATION_API
        let URL = URL_PATH
        const postData = {first_name: nameField, last_name: lastNameField, validation_code: validationCode, email_1: emailField, password: passwordField, terms_accepted: termsFieldChecked}
        // await axios.post(URL , postData, {
        await customAxios.post(URL , postData)
        .then(res => {
            setInitialData(res.data)
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "Login", ctx.Config.Error.getLevels.error)
        })
    }


    // Errors captured when sending API requests
    const errorsMessages = (error) => {
        setIsRequestInProgress(false)
        let errorMsg = ""
        
        if(error.response?.status === 400){
            errorMsg = "Missing Email, Password, or Terms of Use not accepted."
        }else if (error.response?.status === 401){
            errorMsg = "User or email has already been registered. Try a different email."
        }else if (error.response?.status === 424){
            errorMsg = "Invalid code or expired. Validate your email again."
        }else if (error.response?.status === 422){
            errorMsg = "Sign Up issue found. Please try again."
        }else{
            // Email has not been validated, or code is not loger valid.
            errorMsg = "Sign Up failed."
        }

        displayMessage(errorMsg)
        errorMessageRef.current.focus()

    }

    const displayMessage = (messageToDisplay) => {
        // showMessageArea = true
        // setShowMessage(true)
        // setMessage(messageToDisplay)

        setRegistrationErrorMsg(messageToDisplay)
      }

    const dispalyTermsofUse = () =>{
        setShowTerms(true);
    }

    const closeMessage = () =>{
        const show = showTerms === true ? false : true
        setShowTerms(show)
    }

    return (
        <div className={styles.mainRegisterArea} >
            <div className={styles.logo}> <img className={styles.logo}  src="/Talent_Logo_Txt_Color_Simple.svg"  ></img> </div>
            <div className={styles.registerArea} >
                <div ref={errorMessageRef} className={styles.loginError} aria-live="assertive">
                    {registrationErrorMsg}
                </div>
                <div  className={styles.heading}>Sign Up</div>
                <div className={styles.formArea}>

                    <form onSubmit={handleSubmit}>

                        <div className={styles.termsArea} >
                            <input className={styles.termsChecbox}  type="checkbox" id="Terms" 
                                ref={termsFieldRef}
                                autoComplete="off" 
                                onChange={ (e)=> setTermsFieldChecked(e.target.checked) } 
                                checked={termsFieldChecked}
                                required
                                aria-invalid={termsFieldChecked ? "true" : "false"}
                                aria-describedby="termsnote"
                                onFocus={() => setTermsFocus(true)}
                                onBlur={() => setTermsFocus(false)}
                            />
                            <div onClick={dispalyTermsofUse}>Read and accept<br/>Terms of Use.</div>
                        </div>
                        <div id="termsnote" className={styles.screenReaderHidden}>
                                Read and accept the Terms of Use.
                        </div>
                        <div className={styles.nameArea} >
                            <label className={nameField && !validName ? styles.invalidFieldLabel : styles.nameFieldLabel}  htmlFor="first_name">First Name</label>
                            <input className={styles.nameField}  type="text" id="first_name" 
                                    ref={nameFieldRef}
                                    autoComplete="off" 
                                    onChange={ (e)=> setNameField(e.target.value) } 
                                    value={nameField}
                                    required
                                    aria-invalid={validName ? "false" : "true"}
                                    aria-describedby="uidnote"
                                    onFocus={() => setNameFocus(true)}
                                    onBlur={() => setNameFocus(false)}
                            />
                        </div>
                        <div id="uidnote" className={nameFocus && nameField && !validName ? styles.fieldMsg : styles.screenReaderHidden}>
                                Valid name is 3 to 70 characters.  Must begin with a letter.
                                Numbers, spaces, and underscores are not allowed.
                        </div>
                        <div className={styles.lastNameArea} >
                            <label className={lastNameField && !validLastName ? styles.invalidFieldLabel : styles.lastNameFieldLabel}  htmlFor="last_name">Last Name</label>
                            <input className={styles.lastNameField}  type="text" id="last_name" 
                                    ref={lastNameFieldRef}
                                    autoComplete="off" 
                                    onChange={ (e)=> setLastNameField(e.target.value) } 
                                    value={lastNameField}
                                    required
                                    aria-invalid={validLastName ? "false" : "true"}
                                    aria-describedby="uidnote2"
                                    onFocus={() => setLastNameFocus(true)}
                                    onBlur={() => setLastNameFocus(false)}
                            />
                        </div>
                        <div id="uidnote2" className={lastNameFocus && lastNameField && !validLastName ? styles.fieldMsg : styles.screenReaderHidden}>
                                Valid Last Name is 3 to 70 characters.  Must begin with a letter.
                                Numbers and underscores are not allowed.
                        </div>
                        <div className={styles.emailArea} >
                            <label className={emailField && !validEmail ? styles.invalidFieldLabel : styles.emailFieldLabel}  htmlFor="email_1">Email</label>
                            <input className={styles.emailField}  type="text" id="email_1" 
                                    ref={emailFieldRef}
                                    autoComplete="off" 
                                    onChange={ (e)=> setEmailField(e.target.value) } 
                                    value={emailField}
                                    required
                                    aria-invalid={validEmail ? "false" : "true"}
                                    aria-describedby="uidnote3"
                                    onFocus={() => setEmailFocus(true)}
                                    onBlur={() => setEmailFocus(false)}
                            />
                        </div>
                        <div id="uidnote3" className={emailFocus && emailField && !validEmail ? styles.fieldMsg : styles.screenReaderHidden}>
                                Valid emails include an initial name or identifier, followed by an '@' symbol, a valid domain, and extension.
                                i.e., yourname@domain.com.
                        </div>
                        <div className={styles.passwordArea} >
                            <label className={passwordField && !validPassword ? styles.invalidFieldLabel : styles.passwordFieldLabel} htmlFor="password">Password</label>
                            <input className={styles.passwordField} type="password" id="password"
                                    onChange={ (e)=> setPasswordField(e.target.value) } 
                                    value={passwordField}
                                    required
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="passwordMsg"
                                    onFocus={() => setPasswordFocus(true)}
                                    onBlur={() => setPasswordFocus(false)}
                            />
                        </div>
                        <div id="passwordMsg" className={passwordFocus && passwordField && !validPassword ? styles.fieldMsg : styles.screenReaderHidden}>
                            Valid password is 8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.
                            i.e., <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </div>
                        <div className={styles.passwordArea} >
                            <label className={matchPasswordField && !validMatchPasswordField ? styles.invalidFieldLabel : styles.passwordFieldLabel} htmlFor="password">Confirm Password</label>
                            <input className={styles.passwordField} type="password" id="passwordMatch"
                                    onChange={ (e)=> setMatchPasswordField(e.target.value) } 
                                    value={matchPasswordField}
                                    required
                                    aria-invalid={validMatchPasswordField ? "false" : "true"}
                                    aria-describedby="passwordMsg"
                                    onFocus={() => setMatchPasswordFocus(true)}
                                    onBlur={() => setMatchPasswordFocus(false)}
                            />
                        </div>
                        <div id="passwordMsg" className={matchPasswordFocus && !validMatchPasswordField ? styles.fieldMsg : styles.screenReaderHidden}>
                            Password and Confirm Password must match.
                        </div>
                        <button disabled={ !validEmail || !validPassword || !validName || !validLastName || !validMatchPasswordField || !termsFieldChecked ? true : false} 
                        className={ !validEmail || !validPassword || !validName || !validLastName || !validMatchPasswordField || !termsFieldChecked || isRequestInProgress? styles.signInButtonDisabled : styles.signInButton} >Sign Up</button>

                    </form>
                    <div className={styles.registeredAlready}>
                        Already registered? <br></br>
                        <span className={styles.registeredMsg} >
                        <Link className={styles.registeredMsg} to={'/signin'} state={{new: true}} >Sign In</Link>
                        </span>
                    </div>
                </div>

                { isRequestInProgress ?
                    <div className={styles.progressContainer} >
                        <div className={styles.progressArea} >
                            <div > <img className={styles.progressLogo}  src="/Profile_Icon_WhiteCircle_Thicker_Plain_Anim.svg"  /></div>
                            <div>Validation in-progress...</div>
                        </div>
                    </div>
                    :<></>
                }
                { showTerms ?
                    <div className={styles.termsContainer} > <div onClick={() => {closeMessage()}} className={styles.closeTermsMessage}>&times;</div>  <TermsofUse registration={true} /> </div>
                    : <></>
                }
            </div>
        </div>
    )
}

export default Registration