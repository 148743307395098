import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from "@fullcalendar/interaction"
import CalendarAvailabilityViewWapper from './CalendarAvailabilityViewWrapper'
import styles from './CalendarAvailabilityView.module.css'
import useCustomAxios from '../../common/hooks/useCustomAxios';
import ContextManager from "../../common/Context/ContextManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import MessageManager from "../../common/ErrorManager";
import { useRef, useState, useEffect  } from 'react';


const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/
// const NAME_LASTNAME_REGEX = /^[A-Z][a-z-]{3,23}$/;
const NAME_LASTNAME_REGEX = /^[A-Za-z-]{3,70}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;

export default function CalendarAvailabilityView(props) {
  
  const [preferedView, setPreferedView] = useState("timeGridWeek");
  const [calendarApi, setCalendarApi] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [transferEvent, setTransferEvent] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dayAndTimeSelected, setDayAndTimeSelected] = useState(false);
  const [calendarTypeSelected, setCalendarTypeSelected] = useState(preferedView);
  const [availabilityToken, setAvailabilityToken] = useState("");
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [codeIsValid, setCodeIsValid] = useState(false);

  // validation form ----------------------------------------------------
  const [validationCode, setValidationCode] = useState("");
  const [isValidCode, setIsValidCode] = useState(false);
  const [validationRequest, setValidationRequest] = useState(true);
  const [validationCodeFocus, setValidationCodeFocus] = useState(false);
  const [validationCodeErrorMsg, setValidationCodeErrorMsg] = useState("");

  const errorMessageRef = useRef();
  const validationCodeRef = useRef();


  const calendarRef = useRef(null);
  const customAxios = useCustomAxios()
  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();
  const urlParameters = useParams();

  let tokenSent = false;

  useEffect( () => {

    if(!tokenIsValid && availabilityToken === "" && urlParameters.token !== "" && !tokenSent){
      tokenSent = true;
      validateTokenData(urlParameters.token);
    }

    return () => {

    }
  }, []
  );


  useEffect(() => {
    setIsValidCode(CODE_REGEX.test(validationCode));
}, [validationCode])


useEffect(() => {
  if(codeIsValid === true){
    props.setSubHeader("Click on calendar to select a date and time, and drag to select multiple hours. Mobile users hold date and time to select.");
  }
}, [codeIsValid])

  const setNewTransferEvent = (event)=>{
    // setTransferEvent
    if(event){
      let newEvents = [];
      if(calendarEvents.length > 0){
        newEvents = [...calendarEvents];
      }
      const id = newEvents.length;
      event.id = id;
      const day = event.start.toDateString();
      const from = event.start.toLocaleTimeString();
      const to = event.end.toLocaleTimeString()
      const sharedEvent = { id: id, title: 'Available', day: day, start: from, end:to, zone_name: event.zoneName };
      newEvents.push(sharedEvent);
      // Add to shared events
      setCalendarEvents(newEvents);
      // Add to the calendar as new event
      calendarApi.addEvent(event);
    };
  }

  const setInitialData = (responseData)  => {
    const {meeting_invite_list, last_updated} = responseData
    setIsRequestInProgress(false);
  };

  const validateTokenData = async(data) => {
    let URL_PATH = process.env.REACT_APP_AVAILABILITY_TOKEN_VALIDATE_API;
    URL_PATH = URL_PATH.replace("{TKN}", data);

    if(!isRequestInProgress){
      setIsRequestInProgress(true);
      await customAxios.get(URL_PATH)
      .then(res => {
        setTokenValidationFromRequest(res.data);
      })
      .catch(error => {
        // errorsMessages(error);
        setTokenValidationFromRequest(false);
        MessageManager(error, "CalendarAvailabilityView", ctx.Config.Error.getLevels.error)
      })
    }
  };

  const validateCodeData = async(data) => {
    let URL_PATH = process.env.REACT_APP_AVAILABILITY_CODE_VALIDATE_API;
    URL_PATH = URL_PATH.replace("{CDE}", data).replace("{TKN}", availabilityToken);

      setIsRequestInProgress(true);
      await customAxios.get(URL_PATH)
      .then(res => {
        setCodeValidationFromRequest(res.data);
      })
      .catch(error => {
        // errorsMessages(error);
        setCodeValidationFromRequest(false);
        MessageManager(error, "CalendarAvailabilityView", ctx.Config.Error.getLevels.error)
      })
  };


  const postAvailabilityData = async(url, data) => {

    if(!isRequestInProgress){
      setIsRequestInProgress(true);
      await customAxios.post(url, data)
      .then(res => {
        setInitialData(res.data);
      })
      .catch(error => {
          errorsMessages(error)
          MessageManager(error, "CalendarAvailabilityView", ctx.Config.Error.getLevels.error)
      })
    }
  };

  const setTokenValidationFromRequest = (response)=>{
    setIsRequestInProgress(false);
    if(response){
      setTokenIsValid(true);
      setAvailabilityToken(urlParameters.token);
    }else{
      setTokenIsValid(false);
      setAvailabilityToken("");
    }
  };

  const setCodeValidationFromRequest = (response)=>{
    if(response){
      setCodeIsValid(true);
      setValidationCodeErrorMsg("");
    }else{
      setCodeIsValid(false);
      setValidationCodeErrorMsg("Invalid code or has expired.");
    }
  };

  const handleSubmit = (e) =>{
    e.preventDefault();
    setValidationCodeErrorMsg("");
    validateCodeData(validationCode);
  }

  const errorsMessages = (error) => {
    // let msg = "An error was found. Please check the console for more information"
    let msg = "If calendar invites are not listed, then please use the refresh button."
    // displayMessage(error.response !== undefined ? error.response.data.detail : msg);
    displayMessage(msg);
  }

  const displayMessage = (messageToDisplay) => {
    setIsRequestInProgress(false);
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const handleDateClick = (arg) => {
    alert("@handleDateClick");
    if(!dayAndTimeSelected){
      calendarApi.changeView('timeGridWeek', arg.dateStr);
      alert("@handleDateClick - set new view");
    };
      
    setDayAndTimeSelected(false);
  }


  const handleSelect = (arg)=>{
    if(arg.allDay){
      //Do nothing
    }else{
      options.timeZoneName = "long";
      const zone_name = arg.start.toLocaleDateString(Intl.DateTimeFormat().resolvedOptions().locale, {day:'2-digit',timeZoneName: 'long' }).substring(4);
      const event = { title: 'Available', start: arg.start, end: arg.end, zoneName: zone_name };
      setNewTransferEvent(event);
      setDayAndTimeSelected(true);
    };
  };

  const eventClickHandler = (arg)=>{
    setNewTransferEvent(arg.event);
  }

  const closeMessage = () =>{
    const show = showMessage === true ? false : true;
    setShowMessage(show)
    setMessage("")
    setSuccessMessage(false);
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function getWeekdayMonthDayName(dateStr, locale)
  {
    var date = new Date(dateStr);
    options.timeZone = "UTC";
    options.timeZoneName = "short";

    return date.toLocaleDateString(locale, { weekday: 'long'}) + ", " + 
           date.toLocaleDateString(locale, { month: 'long'}) + " " + 
           date.toLocaleDateString(locale, { day: 'numeric'});
  }

  const deleteAvailability = (id)=>{
    const availabilityList = calendarEvents.filter((e, index)=> e.id !== id);
    setCalendarEvents(availabilityList);
    deleteScheduledEvent(id)
  };

  const deleteScheduledEvent = (id)=>{
    const existingEvent = calendarApi.getEventById(id);
    if(existingEvent !== undefined && existingEvent !== null){
      existingEvent.remove();
    }
  };


  return (
    <div className={styles.mainContainer}>
{      
      codeIsValid ?
      <>
        <CalendarAvailabilityViewWapper  token={availabilityToken} code={validationCode} />
      </>
      :
      tokenIsValid ?
        <div className={styles.codeAreaContainer} >
            <div className={styles.codeArea} >
                <div ref={errorMessageRef} className={styles.loginError} aria-live="assertive">
                    {validationCodeErrorMsg}
                </div>
                <div  className={styles.heading}>Verify</div>
                <div className={styles.formArea}>

                    <form onSubmit={handleSubmit}>
                        <div className={styles.validationMessage} >
                            A validation code has been sent to the email provided in your resume.
                        </div>
                        <div className={styles.validationMessage2} >
                            Make sure to review the junk folder<br/>and add the sender's address<br/>to the approved list.
                        </div>
                        <div className={styles.validationMessage2} >Enter the code to continue.</div>
                    
                        <div className={styles.emailValidationCodeArea} >
                            <label className={validationCode && !isValidCode ? styles.invalidFieldLabel : styles.emailFieldLabel}  htmlFor="validation_code">Validation code</label>
                            <input className={styles.validationCodeField}  type="text" id="validation_code" 
                                    ref={validationCodeRef}
                                    placeholder="Enter validation code here."
                                    autoComplete="off" 
                                    onChange={ (e)=> setValidationCode(e.target.value) } 
                                    value={validationCode}
                                    aria-invalid={isValidCode ? "false" : "true"}
                                    aria-describedby="uidnote3"
                                    onFocus={() => setValidationCodeFocus(true)}
                                    onBlur={() => setValidationCodeFocus(false)}
                            />
                        </div>
                        <div id="uidnote3" className={validationCodeFocus && validationCode && !isValidCode ? styles.fieldMsg : styles.screenReaderHidden}>
                                Validation codes are 6 digits long.
                        </div>                    
                        <button  disabled={!isValidCode ? true : false} className={!isValidCode ? styles.validationButtonDisabled : styles.validationButton} >{"Verify code"}</button>
                    </form>

                </div>
            </div>
        </div>
      :
      isRequestInProgress ?
      <div className={styles.verifyingLink} >Verifying calendar link...</div>
      :
      <div className={styles.noValidLink} >Calendar link is not valid or has expired.</div>
}
      {
        showMessage ?
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                  {message}
                </div> 
            </div>
        </div>
        : <></>
        }
    </div>
  )
}