import styles from "./CompanySetup_guide_1.module.css";
import CompanyInfoArea from "../components/companysetup/CompanyInfoArea";
import Role from '../components/companysetup/RoleArea'
import RoleCriteria from '../components/companysetup/AdditionalEvaluationCriteria'
import ResumeList from '../components/companysetup/ResumesList'
import MessageManager from "../common/ErrorManager";


import { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const CompanySetup_guide_1 = () => {

  const [newRole, setNewRole] = useState(false);
  const [newCompany, setNewCompany] = useState(false);
  const [companySelectedId, setCompanySelectedId] = useState(0);
  const [roleSelectedId, setRoleSelectedId] = useState(0);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIconUp);
  const [openCloseRoleIconUp, setOpenCloseRoleIconUp] = useState(styles.openCloseIconUp);
  const [openCloseRoleCriteriaIconUp, setOpenCloseRoleCriteriaIconUp] = useState(styles.openCloseIconUp);
  const [companyInfoInterIsVisible, setCompanyInterVisible] = useState(styles.companyInfoAreaGuide);
  const [companyRoleIsVisible, setCompanyRoleIsVisible] = useState(styles.roleArea);
  const [companyRoleCriteriaIsVisible, setCompanyRoleCriteriaIsVisible] = useState(styles.additionalEvaluationCriteria);
  const [saveCompanyChanges, setSaveCompanyChanges] = useState(false);
  const [saveRoleChanges, setSaveRoleChanges] = useState(false);
  const [saveRoleCriteriaChanges, setSaveRoleCriteriaChanges] = useState(false);
  const [newStateToBeSaved, setNewStateToBeSaved] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({type: 'message', message: []});
  const [waitingCompanySave, setWaitingCompanySave] = useState(false);
  const [waitingRoleSave, setWaitingRoleSave] = useState(false);
  const [waitingRoleCriteriaSave, setWaitingRoleCriteriaSave] = useState(false);
  const [showNextStep, setShowNextStep] = useState(false);
  
  const saveCompanyInfo = {
    companyInfoArea : false,
    role : false,
    roleCriteria : false
  };

  const [saveStatus, setSaveStatus] = useState(saveCompanyInfo);

  const parameters = useParams();
  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();
  let {state} = useLocation();

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

  // Retrieve data first and populate any state
  useEffect( () => {
    
      if(state !== null && state !== undefined){
        //Possible states: newRole, roleSel, newCo
        if(state.roleSel === true){
          //TODO: REVIEW THIS CODE
          refreshToRoleSelection()
        }
      }
      
      return () => {

      }
    }, [state]
  );

  const refreshToRoleSelection = () => {
    setNewRole(newRole === false ? true : false)
    setNewCompany(newCompany === false ? true : false)
    navigate("/companysetupguide2/" + parameters.id + "/" + parameters.rid)
  }

  const addNewCompany = () => {
    setNewRole(newRole === false ? true : false)
    setNewCompany(newCompany === false ? true : false)
    ctx.Company.id = 0
    ctx.Role.id = 0
    setContext(ctx)
    navigate("/companysetupguide1/", {state: {newRole:false}, replace: true})
  }

  const addNewRole = () =>{
    setNewRole(newRole === false ? true : false)
    ctx.Role.id = 0
    setContext(ctx)
    navigate("/companysetupguide2/" + ctx.Company.id, {state: {newRole:false}, replace: true})
  }
 
  const openModal = () =>{
      if(isModalVisible === false){
        setModalVisibility(true)
        setIsModalVisible(true)
      }
  }

  const uploadModalOpen = (status) => {
    setModalVisibility(false)
    setIsModalVisible(false)
  }

  const setCompanyInterVisibleOnOff = () => {
    let isOn = companyInfoInterIsVisible === styles.companyInfoAreaGuide ?  styles.companyInfoAreaCompanyNameOnlyGuide : styles.companyInfoAreaGuide
    setCompanyInterVisible(isOn)
    
    let iconStyle = isOn === styles.companyInfoAreaGuide ? styles.openCloseIconUp : styles.openCloseIcon
    setOpenCloseIconUp(iconStyle)
  }

  const setCompanyRoleVisibleOnOff = () => {
    let isOn = companyRoleIsVisible === styles.roleArea ?  styles.roleAreaNameOnly : styles.roleArea
    setCompanyRoleIsVisible(isOn)
    
    let iconStyle = isOn === styles.roleArea ? styles.openCloseIconUp : styles.openCloseIcon
    setOpenCloseRoleIconUp(iconStyle)
  }

  const setCompanyRoleCriteriaVisibleOnOff = () => {
    let isOn = companyRoleCriteriaIsVisible === styles.additionalEvaluationCriteria ?  styles.additionalEvaluationCriteriaNameOnly : styles.additionalEvaluationCriteria
    setCompanyRoleCriteriaIsVisible(isOn)
    
    let iconStyle = isOn === styles.additionalEvaluationCriteria ? styles.openCloseIconUp : styles.openCloseIcon
    setOpenCloseRoleCriteriaIconUp(iconStyle)
  }

  useEffect( () => {
    
    if(saveStatus.companyInfoArea === true){
      setNewStateToBeSaved(true);
    }else if(saveStatus.role === true){
      setNewStateToBeSaved(true);
    }else if(saveStatus.roleCriteria === true){
      setNewStateToBeSaved(true);
    }else{
      setNewStateToBeSaved(false);
    }

    return () => {

    }
  }, [saveStatus]
);

  const setNewStateToBeSavedMessage = (saveMessageCompanyInfo)=>{
    const newCompanyInfoState = {...saveStatus};
    newCompanyInfoState[saveMessageCompanyInfo.name] = saveMessageCompanyInfo.save;
    setSaveStatus( newCompanyInfoState );
    setShowNextStep(true);
    console.log("@Activating Next Steps.....");
  }

  const sendSaveMessage = ()=>{

    if(ctx.Company.id > 0){
      if(saveStatus.companyInfoArea === true){
        setSaveCompanyChanges(true);
      }

      if(saveStatus.role === true){
        setSaveRoleChanges(true);
      }

      if(saveStatus.roleCriteria === true){
        setSaveRoleCriteriaChanges(true);
      }

      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.roleCriteria = false;
      newCompanyInfoState.role = false;
      newCompanyInfoState.companyInfoArea = false;
      setSaveStatus( newCompanyInfoState );
    }else{
      saveCompanyFirst();
    }
  }

  const saveCompanyFirst = ()=> {
    if(saveStatus.companyInfoArea === true){
      setSaveCompanyChanges(true);
      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.companyInfoArea = false;
      setSaveStatus( newCompanyInfoState );
      setWaitingRoleSave(true);
    }
  }

  const saveRoleSecond = ()=> {
    if(saveStatus.role === true){
      setSaveRoleChanges(true);
      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.role = false;
      setSaveStatus( newCompanyInfoState );
      setWaitingRoleCriteriaSave(true);
    }
  }

  const saveRoleCriteriaThird = ()=> {
    if(saveStatus.roleCriteria === true){
      setSaveRoleCriteriaChanges(true);
      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.roleCriteria = false;
      setSaveStatus( newCompanyInfoState );
    }
  }

  const showChildrenMessage = (newMessageToInclude)=>{
    const newMessage = {...message};
    const newMessageList = [...newMessage.message];
    
    if(newMessageList.length > 0){
      newMessageList.push(' | ');
    }
  
    newMessageList.push(newMessageToInclude.message);
    if(newMessageToInclude.type === "error"){
      newMessage.type = "error";
    }else{
      newMessage.type = "message";
    }

    newMessage.message = newMessageList;
    setMessage(newMessage);
    setShowMessage(true);

    //Check if new company was saved, if so trigger saving role
    if(waitingRoleSave === true){
      setWaitingRoleSave(false)
      saveRoleSecond();
    }

    //Check if new role was saved, if so trigger saving role options
    if(waitingRoleCriteriaSave === true){
      setWaitingRoleCriteriaSave(false);
      saveRoleCriteriaThird();
    }
  }


  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show)
        setMessage({type: 'message', message: []})
    }
  }

  return (
        <div className={styles.mainCompanySetupArea}>
         
          <div className={styles.gridGuideInstructions}>
              <div className={styles.item1}>
                <div className={styles.actionIndex} >1</div>
                Enter (or edit) the company name for wich all potential roles or job postings will be ceated. This field is required.
              </div>
              <div className={styles.item5}>
                {/* <img className={styles.arrowIndicator} width={"50px"} src="/Arrow_Indicator_Icon_64x64.svg" /> */}
                <div className={styles.svgStep1} >
                  <svg width="13.856mm" height="150.495mm" version="1.1" viewBox="0 0 13.856 15.495" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow" transform="translate(-5.3983 7.8017)" fill="none" stroke="#895bf2" 
                        stroke-linecap="round" stroke-linejoin="round" stroke-width=".75009">
                      <path d="m14.393 4.1484 1.9658-1.7e-5 -4.0323 3.1699-4.0327-3.1699"/>
                      <path d="m12.33 3.4761-2.6e-5 -53.5"/>
                    </g>
                  </svg>
                </div>
              </div>
              <div className={styles.item2}>
              <div className={styles.actionIndex} >2</div>
                Optionally enter a quick or high level description for the company. You can leave this field empty.
              </div>
              <div className={styles.item6}>
                {/* <img className={styles.arrowIndicator} width={"50px"} src="/Arrow_Indicator_Icon_64x64.svg" /> */}
                <div className={styles.svgStep2} >
                  <svg width="13.856mm" height="150.495mm" version="1.1" viewBox="0 0 13.856 15.495" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow" transform="translate(-5.3983 7.8017)" fill="none" stroke="#895bf2" 
                        stroke-linecap="round" stroke-linejoin="round" stroke-width=".75009">
                      <path d="m14.393 4.1484 1.9658-1.7e-5 -4.0323 3.1699-4.0327-3.1699"/>
                      <path d="m12.33 3.4761-2.6e-5 -54.869"/>
                    </g>
                  </svg>
                </div>
              </div>
              <div className={styles.item3}>
              <div className={styles.actionIndex} >3</div> Save. This is required as no roles can be created without a company.
              </div>
              <div className={styles.item7}>
                {/* <img className={styles.arrowIndicator} width={"50px"} src="/Arrow_Indicator_Icon_64x64.svg" /> */}
                <div className={styles.svgStep3} >
                  {
                  newStateToBeSaved ?
                  <svg width="13.856mm" height="43.495mm" version="1.1" viewBox="0 0 13.856 15.495" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow" transform="translate(-5.3983 7.8017)" fill="none" stroke="#895bf2" 
                        stroke-linecap="round" stroke-linejoin="round" stroke-width=".75009">
                      <path d="m14.393 4.1484 1.9658-1.7e-5 -4.0323 3.1699-4.0327-3.1699"/>
                      <path d="m12.33 3.4761-2.6e-5 -54.869"/>
                    </g>
                  </svg>
                  :<></>
                  }
                </div>
              </div>
              <div className={styles.item4}>
                Creates a new company <br/> '^' Opens/Closes section.
              </div>
              <div className={styles.svgActionIndicator} >
                  <svg width="13.856mm" height="11.495mm" version="1.1" viewBox="0 0 13.856 15.495" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow" transform="translate(-5.3983 7.8017)" fill="none" stroke="#0ea5e9" 
                        stroke-linecap="round" stroke-linejoin="round" stroke-width=".75009">
                      <path d="m14.393 4.1484 1.9658-1.7e-5 -4.0323 3.1699-4.0327-3.1699"/>
                      <path d="m12.33 3.4761-2.6e-5 -54.869"/>
                    </g>
                  </svg>
                </div>
            </div>
          
          <div className={styles.guideSection} >
            <div className={styles.addNewCompanyButton}>
              <div className={styles.addNewRoleLabel} onClick={(e) => {addNewCompany()}} >Add new company</div>
              <img className={openCloseIconUp} onClick={setCompanyInterVisibleOnOff} alt="" src="/open-close-icon.svg" />
            </div>
            <div className={companyInfoInterIsVisible}>
              <CompanyInfoArea createnewcompany={newCompany} setPageCompanyId={setCompanySelectedId} setNewStateToSave={setNewStateToBeSavedMessage} saveAllChanges={saveCompanyChanges} setSaveAllChanges={setSaveCompanyChanges}  showMessage={showChildrenMessage} />
            </div>
            {/* <div className={styles.addNewRoleButton}>
              <div className={styles.addNewRoleLabel} onClick={(e) => {addNewRole()}}> Add new role </div>
              <img className={openCloseRoleIconUp} onClick={setCompanyRoleVisibleOnOff} alt="" src="/open-close-icon.svg" />
            </div>
            <div className={companyRoleIsVisible}>
              <Role createnewrole={newRole} pagecompanyid={companySelectedId} setPageroleId={setRoleSelectedId} setNewStateToSave={setNewStateToBeSavedMessage} saveAllChanges={saveRoleChanges} setSaveAllChanges={setSaveRoleChanges} showMessage={showChildrenMessage} />
            </div>
            <div className={styles.roleCriteriaButton} >
              <img className={openCloseRoleCriteriaIconUp} onClick={setCompanyRoleCriteriaVisibleOnOff} alt="" src="/open-close-icon.svg" />
            </div>
            <div className={companyRoleCriteriaIsVisible} >
              <RoleCriteria createnewrole={newRole} pagecompanyid={companySelectedId} pageroleid={roleSelectedId} setNewStateToSave={setNewStateToBeSavedMessage} saveAllChanges={saveRoleCriteriaChanges} setSaveAllChanges={setSaveRoleCriteriaChanges}  showMessage={showChildrenMessage} />
            </div>
            <div className={styles.addNewResumeButton}>
              {parameters.rid > 0 ? <div className={styles.addNewResumeLabel} onClick={() => {openModal()}} >Upload new candidate(s)</div> : <></> }
            </div>
            <div className={styles.resumelistcomponent}> 
              <ResumeList openUploadModal={modalVisibility} uploadModalOpen={uploadModalOpen} showMessage={showChildrenMessage} />
            </div> */}

            {
              newStateToBeSaved ? 
              <div className={message.message.length > 0 ? styles.saveAllWitMessage : styles.saveAll} onClick={sendSaveMessage} >
                <div className={styles.saveAllArea}>
                  <div><img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2_white.svg" /></div>
                  <div className={styles.saveAllText}>Save all changes</div>
                </div>
              </div>
              : <></>
            }

            {
              showMessage ?
              <div className={message.type === "message" ? styles.childrenMessagesArea : styles.childrenErrorMessagesArea} >  
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div className={styles.childrenMessages} > {message.message} </div>
              </div>
              : <></>
            }
          </div>
          <div className={styles.gridPageIndex} >
            <div className={styles.pageIndexTop1} >
              <div className={styles.circle} >
                <span className={styles.pageIndexTopSpan} >1</span>
                <span className={styles.pageIndexTopSpanV} >|</span>
                <span className={styles.pageIndexTopSpanDivisor} >3</span>
              </div>
            </div>
            <div className={styles.pageIndexTop2} >
              {
              showNextStep ?
                <span onClick={addNewRole} className={styles.pageIndexTopSpanNext} >Next step</span>
              :<></>
              }
            </div>
          </div>
        </div>
        
  );
};

export default CompanySetup_guide_1;
