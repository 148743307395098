import styles from "./GlobalStatsView.module.css";
import MessageManager from "../common/ErrorManager.js";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager.js";

import HomeStats from "../components/home/HomeStats.js";

const GlobalStatsView = () => {

  return (
    
        <div className={styles.mainHomeArea}>
          <div className={styles.mainContentArea} >
            <div className={styles.welcomeLabelArea} >
              Welcome to<br/>
              <img className={styles.toLogo} src="/Talent_Logo_Txt_Color_Simple.svg" />
            </div>
            <div className={styles.statsArea} >
                <HomeStats />
            </div>
            <div className={styles.newsArea} >
                <div className={styles.newsContainer} >
                  TBD                
                  {/* <HomeNews /> */}
                </div>
            </div>
          </div>
        </div>
  )
};

export default GlobalStatsView;
