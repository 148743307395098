import styles from "./FeaturesStats.module.css";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useRef, useState, useEffect, ChangeEvent, useLayoutEffect } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";


const FeaturesStats = (props) => {

    const [featureUsage, setFeatureUsage] = useState({});
    const [isUploadsVisible, setIsUploadsVisible] = useState(false);
    const [isReviewsVisible, setIsReviewsVisible] = useState(false);
    const [isComparesVisible, setIsComparesVisible] = useState(false);
    const [isChatsVisible, setIsChatsVisible] = useState(false);

    const urlParameters = useParams();
    let {state} = useLocation();
    const { ctx, setContext } = ContextManager()
    const customAxios = useCustomAxios()

    const setInitialData = (responseData)  => {
        setFeatureUsage(responseData);
    }

    useEffect( () => {
    
        if(props?.displayUploads === true){
            setIsUploadsVisible(true);
        }

        if(props?.displayReviews === true){
            setIsReviewsVisible(true);
        }

        if(props?.displayCompares === true){
            setIsComparesVisible(true);
        }

        if(props?.displayChats === true){
            setIsChatsVisible(true);
        }

        if(props?.displayAll){
            setIsUploadsVisible(true);
            setIsReviewsVisible(true);
            setIsComparesVisible(true);
            setIsChatsVisible(true);
        }

        fetchData()

        return () => {
        }
        }, [props]
    );

    const fetchData = async() => {
        // Change to support proxy
        // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
        let URL_PATH = process.env.REACT_APP_RESUME_FEATURES_USAGE_API
        let URL = URL_PATH
        await customAxios.get(URL)
        .then(res => {
            setInitialData(res.data)
        })
        .catch(error => {
            errorsMessages(error)
            MessageManager(error, "FeaturesStats", ctx.Config.Error.getLevels.error)
        }) //Make sure to use proper funcion to capture error
    };

    // Errors captured when sending API requests
    const errorsMessages = (error, source) => {
        
    }


    const planOptionSelected = (option) => {
        if(option === "chats"){
          const chats = ctx?.Resume?.chat === -1 ? "Unlimited": Number(ctx?.Resume?.chat);
          return chats;
        }else if(option === "reviews"){
          const reviews = ctx?.Resume?.reviews === -1 ? "Unlimited": Number(ctx?.Resume?.reviews);
          return reviews;
        }else if(option === "uploads"){
          const uploads = ctx?.Resume?.uploads === -1 ? "Unlimited": Number(ctx?.Resume?.uploads);
          return uploads;
        }else if(option === "compares"){
          const compares = ctx?.Resume?.compares === -1 ? "Unlimited": Number(ctx?.Resume?.compares);
          return compares;
        }else if(option === "plan"){
          const plan = ctx?.Resume?.plan === -1 ? "Unlimited": ctx?.Resume?.plan;
          return plan;
        }else if(option === "companies"){
          const companies = ctx?.Resume?.companies === -1 ? "Unlimited": ctx?.Resume?.companies;
          return companies;
        }else if(option === "roles"){
          const roles = ctx?.Resume?.roles === -1 ? "Unlimited": ctx?.Resume?.roles;
          return roles;
        }
    }


    return(
        <div className={styles.featuresStatsContainer} >
            <div className={styles.featuresLabel0_1}><b>Feature</b></div>
            <div className={styles.featuresLabel0_2}><b>Plan</b></div>
            <div className={styles.featuresLabel0_3}><b>Usage</b></div>
            {/* { isChatsVisible ?
                <div className={styles.featuresStatsArea} > */}
                    <div className={styles.featuresLabel1}>Analysis</div>
                    <div className={styles.featuresValue1}>{planOptionSelected("reviews")}</div>
                    <div className={styles.featuresValue1_2}>{featureUsage?.reviews === -1 ? "Unlimited" : featureUsage.reviews}</div>
                {/* </div>
                : <></>
            }
            { isReviewsVisible ?
                <div className={styles.featuresStatsArea} > */}
                    <div className={styles.featuresLabel2}>Compares</div>
                    <div className={styles.featuresValue2}>{planOptionSelected("compares")}</div>
                    <div className={styles.featuresValue2_2}>{featureUsage?.compares === -1 ? "Unlimited" : featureUsage.compares}</div>
                {/* </div>
                : <></>
            }
            { isUploadsVisible ?
                <div className={styles.featuresStatsArea}> */}
                    <div className={styles.featuresLabel3}>Chats</div>
                    <div className={styles.featuresValue3}>{planOptionSelected("chats")}</div>
                    <div className={styles.featuresValue3_2}>{featureUsage?.chats === -1 ? "Unlimited" : featureUsage.chats}</div>
                {/* </div>
                : <></>
            }
            { isComparesVisible ?
                <div className={styles.featuresStatsArea} > */}
                    <div className={styles.featuresLabel4} >Uploads</div>
                    <div className={styles.featuresValue4}>{planOptionSelected("uploads")}</div>
                    <div className={styles.featuresValue4_2}>{featureUsage?.uploads === -1 ? "Unlimited" : featureUsage.uploads}</div>
                {/* </div>
                : <></>
            }
            { isComparesVisible ?
                <div className={styles.featuresStatsArea} > */}
                    <div className={styles.featuresLabel5}>Companies</div>
                    <div className={styles.featuresValue5}>{planOptionSelected("companies")}</div>
                    <div className={styles.featuresValue5_2}>{featureUsage?.companies === -1 ? "Unlimited" : featureUsage.companies}</div>
                {/* </div>
                : <></>
            }
            { isComparesVisible ?
                <div className={styles.featuresStatsArea} > */}
                    <div className={styles.featuresLabel6}>Roles</div>
                    <div className={styles.featuresValue6}>{planOptionSelected("roles")}</div>
                    <div className={styles.featuresValue6_2}>{featureUsage?.roles === -1 ? "Unlimited" : featureUsage.roles}</div>
                {/* </div>
                : <></>
            } */}

        </div>
    );
}

export default FeaturesStats;