import styles from "./TopGlobalViewsPopupMenu.module.css";
import { useNavigate} from "react-router-dom";

const TopGlobalViewsPopupMenu = (props) => {


    const navigate = useNavigate();

    const gotToProfile = () =>{

        navigate("/userprofile", {state:{from: "nav"}});

    }

    const gotToSignout = () =>{

        navigate("/signout", {state:{from: "nav"}});

    }

    const goToGlobalStatsView = () =>{
      props.closePopUp();
      navigate("/globalstatsview", {state:{from: "nav"}});

    }

    const gotToGeneralView = () =>{
      props.closePopUp();
      navigate("/generalview", {state:{from: "nav"}});

    }

    const goToGretGoodFitView = ()=>{
      props.closePopUp();
      navigate("/topcandidates", {state:{from: "nav"}});
    }

    const gotToMyListView = ()=>{
      props.closePopUp();
      navigate("/mylist", {state:{from: "nav"}});
    }

    
    const gotToReadyForNextStepView = ()=>{
      props.closePopUp();
      navigate("/readynextstep", {state:{from: "nav"}});
    }

    return (
    <div className={styles.topcontainer}>
      <div className={styles.linkArea} onClick={goToGretGoodFitView}>
        <div className={styles.iconItem}><img height={"28px"} className={styles.topGlobalIcon} src="/GTFGDF_Icon_64x64.svg"/></div>
        <div className={styles.linkItem}>Top View</div>
      </div>
      <div className={styles.linkArea} onClick={gotToMyListView} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/Star_Icon.svg"/></div>
        <div className={styles.linkItem}>My List View</div>
      </div>
      <div className={styles.linkArea} onClick={gotToReadyForNextStepView} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/workflow_decision_icon.svg"/></div>
        <div className={styles.linkItem}>Next Step View</div>
      </div>
      <div className={styles.linkArea} onClick={gotToGeneralView} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/GenView_Icon_64x64.svg"/></div>
        <div className={styles.linkItem}>General View</div>
      </div>
      {/* <div className={styles.linkArea} onClick={goToGlobalStatsView} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/StatsView_Icon_64x64.svg"/></div>
        <div className={styles.linkItem}>Stats</div>
      </div> */}
    </div>
  );
};

export default TopGlobalViewsPopupMenu;