
// Container object for application context
// Remember to add any new created object in the ContextManager
const ContextContainer = {
    User: { id: 0, name: "", token: 0, myList: "[]", isHelperOpen: true, isAddGuideOpen: true, isSideNavBarOpen: true },
    Session: { id: 0, sessionTimeOut: 30, lastRequestTime: "" },
    Company: { id: 0, name: "" },
    Role: { id: 0, name: "", optionList: [] },
    Resume: { plan: "Free", companies: 1, roles: 1, uploads: 3, reviews: 3, compares: 1, chat: 1 },
    Config: {
      Error: {
        Level: 3,
        getLevels:{
          info: 1,
          trace: 2,
          error: 3
        }
      }
    }

  }

  export default ContextContainer;