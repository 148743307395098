import styles from "./CompanyViewReport.module.css";
import MessageManager from "../common/ErrorManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";
import CompanyViewReportList from "../components/companysetup/CompanyViewReportList";

const CompanyViewMyListReport = () => {

  return (
    
        <div className={styles.mainReportViewArea}>
            <div className={styles.headerViewArea} >View of My Prefered List of Candidates</div>
            <CompanyViewReportList myList={true} />
        </div>
  )
};

export default CompanyViewMyListReport;
