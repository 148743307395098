import styles from "./AdditionalEvaluationCriteria.module.css";
import { useState, useEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import ErrorManager from "../../common/ErrorManager";


const AdditionalEvaluationCriteria = (props) => {

  const [customContent, setCustomContent] = useState('');
  const [customCheckState, setCustomCheckState] = useState(false);
  const [option5CheckState, setOption5CheckState] = useState(false);
  const [option4CheckState, setOption4CheckState] = useState(false);
  const [option3CheckState, setOption3CheckState] = useState(false);
  const [option2CheckState, setOption2CheckState] = useState(false);
  const [option1CheckState, setOption1CheckState] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [loadComponent, setLoadComponent] = useState(true);
  const [companyId, setCompanyId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [isVisible, setIsVisible] = useState('none');
  const [companyInfoInterIsVisible, setCompanyInterVisible] = useState('inline');
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIcon);
  const [roleId, setRoleId] = useState(0);
  const [isNewRole, setNewRole] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  let {state} = useLocation();
  let firstLoad = true;

  let isDataNeeded = true;

  const emptyList = () =>{
    const newEmptyList = []
    for(let i=1; i<7; i++){
      const option = {
        id: i,
        name: "",
        selected: false,
        custom_option: "",
        company_role_option_id: i
      }

      newEmptyList.push(option);
    }

    return newEmptyList
  }

  if(props.createnewrole !== isNewRole){
    setNewRole(props.createnewrole);
  }

  useEffect( () => {
    if(props.saveAllChanges === true){
      if(isRequestInProgress === false){
        props.setSaveAllChanges(false);
        props.setNewStateToSave({name: "roleCriteria", save: false});
        processPostingData();
      }
    }

    return () => {

    }
    }, [props]        
  );

  // Retrieve data first and populate any state
  useEffect( () => {
    setUserId(ctx.User.id)

    if(urlParameters.id > 0 && urlParameters.rid > 0){
      ErrorManager("-----------Getting options info>", "AdditionalEvalCrit", ctx.Config.Error.getLevels.trace)
      firstLoad = false;
      setLoadComponent(false);

      let cid = 0;
      let rid = 0;
  
      if(urlParameters.id > 0 && urlParameters.rid > 0){
        cid = urlParameters.id;
        rid = urlParameters.rid
      }else if(ctx.Company.id > 0 && ctx.Role.id > 0){
        cid = ctx.Company.id;
        rid = ctx.Role.id
      }

      fetchData(isDataNeeded, cid, rid);
    }else{
      // if(isNewRole === true){
        ErrorManager("-----------Empty list", "AdditionalEvalCrit", ctx.Config.Error.getLevels.trace)
        setOptionList(emptyList())
        setOptionsState(emptyList())
      // }
    }

    return () => {
        isDataNeeded = false;
    }
    }, [isNewRole, state]
  )

  const setInitialData = (responseData)  => {
    const {id, company_role_option_list, last_updated} = responseData
    setRoleId(urlParameters.rid)
    setCompanyId(urlParameters.id)
    setOptionList(company_role_option_list)
    setSubmitButtonState(false)
    setOptionsState(company_role_option_list)
    isDataNeeded = false
    // Let the CompanySetup Page know data has been saved
    props.setNewStateToSave({name: "roleCriteria", save: false});
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let noRoleFoundMsg = "Please select, create, or save role information before updating/saving the Evaluation Options."
    let errorMsg = "An error was found. Make sure a company and a role have been created or selected."
    setSuccessMessage(false);
    displayMessage(error.response !== undefined ? noRoleFoundMsg : errorMsg, "error");
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_HOST_DOMAIN_PORT

  // Get data using the axios functionality
  const fetchData = async(processing, cid, rid) => {
    let PATH = process.env.REACT_APP_COMPANY_ROLE_OPTIONS_API;
    // Change to support Proxy
    // let URL_PATH = COMAIN_PORT +  PATH
    let URL_PATH = PATH;

    // let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid
    let URL = URL_PATH + "/" + cid + "/" + rid;
    if(processing === true){  
      // await axios.get(URL, {
      //   headers: {
      //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
      //   }})
      await customAxios.get(URL)
      .then(res => {
        if(processing === true){
          setInitialData(res.data)
        }
      })
      .catch(error => {
        processing = false
        errorsMessages(error)
        ErrorManager(error, "AdditionalEvalCrit", ctx.Config.Error.getLevels.error)
      })
    }
  }

  // Post data using the axios functionality
  const postData = async(processing, cid, rid) => {
    let PATH= process.env.REACT_APP_COMPANY_ROLE_OPTIONS_UPDATE_API;
    // Change to support Proxy
    // let URL_PATH = COMAIN_PORT +  PATH
    let URL_PATH = PATH;
    // let URL = URL_PATH + "/" + ctx.Company.id + "/" + ctx.Role.id
    let URL = URL_PATH + "/" + cid + "/" + rid;
    const postData = {
        "id": roleId,
        "company_role_option_list": optionList,
        "last_updated": "20231011"
      }
    // await axios.post(URL , postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }}
    //   )
    await customAxios.post(URL , postData)
    .then(res => {
      if(processing === true){
        setSuccessMessage(true);
        displayMessage("Evaluation Options have been saved.", "message");
        setInitialData(res.data)
      }
    })
    .catch(error => {
      processing = false
      errorsMessages(error)
      ErrorManager(error, "AdditionalEvalCrit", ctx.Config.Error.getLevels.error)
    })
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    processPostingData();
  }

  const processPostingData = ()=>{
    setMessage('');

    let cid = 0;
    let rid = 0;

    // if(urlParameters.id > 0 && urlParameters.rid > 0){
    //   cid = urlParameters.id;
    //   rid = urlParameters.rid
    // }else if(ctx.Company.id > 0 && ctx.Role.id > 0){
      cid = ctx.Company.id;
      rid = ctx.Role.id
    // }

    postData(true, cid, rid);
  }

  const setSubmitButtonState = (state) => {
    if(state === true){
      setIsVisible('inline');
      props.setNewStateToSave({name: "roleCriteria", save: true});
    }else{
      setIsVisible('none');
      props.setNewStateToSave({name: "roleCriteria", save: false});
    }
  }
  
  const setCompanyInterVisibleOnOff = () => {
    let isOn = companyInfoInterIsVisible === 'inline' ?  'none' : 'inline'
    setCompanyInterVisible(isOn)
    
    let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    setOpenCloseIconUp(iconStyle)
  }

  const setCriteriaOption = (optionChanging) => {
    const listToUpdate = [...optionList]
    listToUpdate.find((s) => {
      const found = (Number(s.company_role_option_id) === Number(optionChanging.target.id))
      typeof(optionChanging.target.checked) === "boolean" ?
        s.selected = (found === true) ? optionChanging.target.checked : s.selected
      : s.custom_option = (found === true) ? optionChanging.target.value : s.custom_option
      ErrorManager("Saved custom option:" + s.company_role_option_id + " | Options is Selected: " + s.selected + " and Custom:" + s.custom_option, "AdditionalEvalCrit", ctx.Config.Error.getLevels.trace)
      //For this to work do not include a return      
    })

    setOptionList(listToUpdate);
    setOptionsState(listToUpdate)
    setSubmitButtonState(true);
  }

  const setOptionsState = (options) => {
    options.forEach( (optionObject) => {

      switch(optionObject.company_role_option_id) {
        case 1:
          return setOption1CheckState(optionObject.selected)
        case 2:
          return setOption2CheckState(optionObject.selected)
        case 3:
          return setOption3CheckState(optionObject.selected)
        case 4:
          return setOption4CheckState(optionObject.selected)
        case 5:
          return setOption5CheckState(optionObject.selected)
        case 6:
          {
            setCustomCheckState(optionObject.selected)
            setCustomContent(optionObject.custom_option === null ? "": optionObject.custom_option)
          }
        default:
          return "default"
      }

    } )
  }
  
  const displayMessage = (messageToDisplay, type) => {
    // ## Temporary disabled to enable global message on page
    // setShowMessage(true)
    // setMessage(messageToDisplay)
    if(type === "error"){
      // Remove this if enabled messages in this component
      setShowMessage(true);
      setSuccessMessage(false);
      setMessage(messageToDisplay);
      props.showMessage({type: "error", message: "*** Error found at the Role Evaluation Options section. ***"});
    }else{
      props.showMessage({type: "message", message: messageToDisplay});
    }
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  return (
    <div className={styles.container} >
      <div style={{display: companyInfoInterIsVisible}}  >
        <form>
          <div className={styles.additionalEvaluationCriteria}>
            <div className={styles.evaluationCriteriaArea}>
              <div className={styles.customOptionArea}>
                <div><input type="checkbox" id='6' name="id" className={styles.checkboxCustom} checked={customCheckState} onChange={(e)=> {setCriteriaOption(e);}}/></div>
                <div className={styles.customOptionLabel}>
                  <span>
                    <span className={styles.custom}>Custom</span>
                  </span>
                  <span className={styles.separateEachWithAComa}>
                    <span>{` `}</span>
                    <span>(separate each consideration with a coma)</span>
                  </span>
                </div>
                <div className={styles.customOptionLabelDisclaimer}>
                  <span>
                  Provide your own additional considerations here. Please keep in mind that new considerations may influence the evaluation, either by making it more stringent or more flexible.
                  </span>
                </div>
                <div className={styles.customOptionsArea}>
                  <textarea value={customContent} onChange={(e) => {setCriteriaOption(e);}} 
                      className={styles.aboutInputField} 
                      id="6" name="custom_option" 
                      rows={4} cols={40} placeholder="Enter your own consideraions here, i.e., consider cover letter info, etc."/>
                </div>
              </div>
              <div className={styles.cultureOptionArea}>
                <div><input type="checkbox" id='5' name="id" className={styles.checkbox} disabled /></div>
                <div className={styles.educationOptionLabel3}>
                Not enabled option
                </div>
              </div>
              <div className={styles.redFlagsOptionsArea}>
                <div className={styles.redflagOptionParentArea}>
                  <div className={styles.jobChangesArea}>
                    <div><input type="checkbox" id='2' name="id" className={styles.checkboxInside}  checked={option2CheckState} onChange={(e)=> {setCriteriaOption(e);}}/></div>
                    <div className={styles.jobChangesOptionsLabel}>Work/Job/Employment gaps</div>
                  </div>
                  <div className={styles.lackOfStabilityArea}>
                    <div><input type="checkbox" id='3' name="id" className={styles.checkboxInside}  checked={option3CheckState} onChange={(e)=> {setCriteriaOption(e);}}/></div>
                    <div className={styles.jobChangesOptionsLabel}>
                      Lack of stability
                    </div>
                  </div>
                  <div className={styles.inconsistentProgressionArea}>
                    <div><input type="checkbox" id='4' name="id" className={styles.checkboxInside}  checked={option4CheckState} onChange={(e)=> {setCriteriaOption(e);}}/></div>
                    <div className={styles.jobChangesOptionsLabel}>
                      Inconsistent progression | Stagnant/Static career
                    </div>
                  </div>
                  <div className={styles.redflagOptionArea}>
                    <div className={styles.checkbox} />
                    <div className={styles.redFlagOptionsLabel}>
                      Consider red flags from resume
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.educationOptionArea}>
                <div><input type="checkbox" id='1' name="id" className={styles.checkbox} disabled  /></div>
                <div className={styles.educationOptionLabel3}>
                  Not enabled option
                </div>
              </div>
              <div className={styles.evaluationOptionsInfoLabel}>
                In some circumstances a job position may or may not require some
                specific options. These allow the company to find more candidates or
                to narrow down the pool by being more strict on the evaluation.
              </div>
            </div>
            <div className={styles.evaluationOptionsLabelArea}>
              <b className={styles.evaluationOptionsLabel}>Evaluation Options</b>
            </div>
          </div>
          <div className={styles.submitButtonArea} style={{display: isVisible}} onClick={handleSubmit} >
            <div >Save</div>
            <div >
              {/* <button className={styles.submitButton} type="submit" onClick={(e)=> {handleSubmit(e)}} >Save</button> */}
              <img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
            </div>
          </div>
          { showMessage ?
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
          : <></>
          }
        </form>
      </div>
      {/* <img className={openCloseIconUp} onClick={setCompanyInterVisibleOnOff} alt="" src="/open-close-icon.svg" /> */}
    </div>
  );
};

export default AdditionalEvaluationCriteria;
