import styles from "./TopGlobalNavArea.module.css";
import TopGlobalPopupMenu from "./TopGlobalPopupMenu";
import TopGlobalDotPopupMenu from "./TopGlobalDotPopupMenu";
import TopGlobalViewsPopupMenu from "./TopGlobalViewsPopupMenu";
import { useState, useEffect } from 'react';
import { Link, useParams, Navigate, useNavigate, useLocation } from "react-router-dom";

import UserFeedback from "../user/UserFeedback";

const TopGlobalNavArea1 = (props) => {

  const {state} = useLocation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [showDotMenu, setShowDotMenu] = useState(false);
  const [showViewsMenu, setShowViewsMenu] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);

  const showMenuClick = () => {
    const show = showMenu === false ? true : false;
    setShowDotMenu(false);
    setShowViewsMenu(false);
    setShowMenu(show);
  }

  const showDotMenuClick = () => {
    const show = showDotMenu === false ? true : false;
    setShowMenu(false);
    setShowViewsMenu(false);
    setShowDotMenu(show);
  }

  const showViewsMenuClick = () => {
    const show = showViewsMenu === false ? true : false;
    setShowMenu(false);
    setShowDotMenu(false);
    setShowViewsMenu(show);
  }

  // const gotoLegal = () => {
  //   navigate("/legal");
  // }

  const showFeedbackForm = ()=>{
    setShowFeedback(showFeedback ? false : true);
  }

  const closeMessage = () =>{
    setShowFeedback(false);
}

  useEffect(() => {
    if(state?.from === "nav"){
      setShowMenu(false)
    }
  }, [state])

  useEffect(() => {
    if(props.closedNavBar){
      const isClosed = Number(props.closedNavBar) < 550 ? false : true;
      setIsNavBarOpen(isClosed);
    }
  }, [props])

  const goToFocusedView = () =>{
    navigate("/topcandidates", {state:{from: "nav"}});
  }

  const closeOrOpenNavBar = ()=>{
    if(props?.setNavBarOpenOrClosed){
      props.setNavBarOpenOrClosed();
    }
  }

  return (
    <div >
      <div className={isNavBarOpen ? styles.logoIcon1Background : showFeedback ? styles.logoIcon1NoBackgroundFeedback : styles.logoIcon1NoBackground}><img className={styles.logoIcon_1} alt="" src="/Talent_Logo_Txt_Color_Simple_1.svg" onClick={()=> closeOrOpenNavBar()} /></div>
      <img className={styles.logoIcon_2} alt="" src="/Talent_Logo_Txt_Color_Simple_2.svg" />
      <div className={styles.topGlobalNavArea}>
        <div className={styles.topGlovalNavLinksArea}>
          {/* <div className={styles.topLegalArea} >
            <div className={styles.topLinks} onClick={gotoLegal} >Legal</div>
          </div> */}
          <div className={styles.topFeedbackArea} >
            <div className={styles.topLinks} onClick={showViewsMenuClick} >Views</div>
          </div>
          { showViewsMenu ? 
          <div className={styles.topGlobalViewMenu} > <TopGlobalViewsPopupMenu closePopUp={showViewsMenuClick} /> </div>
            : <></>
          }
          <div className={styles.topGlobalDotMenuArea} onClick={showDotMenuClick} >
            <img className={styles.topGlobalDotIcon} src="/Dots_Menu_Icon_Plain_2.svg"/>
          </div>
          { showDotMenu ? 
          <div className={styles.topGlobalDotMenu} > <TopGlobalDotPopupMenu showDotMenu={showFeedbackForm} closePopUp={showDotMenuClick} /> </div>
            : <></>
          }
          <div className={styles.topGlobalProfileArea} onClick={showMenuClick} >
            <img className={styles.topGlobalIcon} src="/Profile_Icon_TopNavBar_Plain_4.svg"/>
          </div>
          { showMenu ? 
              <div className={styles.topGlobalMenu} > <TopGlobalPopupMenu /> </div>
            : <></>
          }
          <div className={styles.topGlovalHomeArea}></div>
        </div>
        { showFeedback ? 
          <div className={ showFeedback ? styles.feedbackContainer : styles.hideModal } >
              <div className={ showFeedback ? styles.feedbackArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      <UserFeedback />
                  </div> 
              </div>
          </div>
          : <></>
        }
      </div>
    </div>
  );
};

export default TopGlobalNavArea1;
