import styles from "./News.module.css";
import HomeNews from "../components/home/HomeNews.js";

const news = () => {

  return (
    
        <div className={styles.mainNewsArea}>
          <div className={styles.mainContentArea} >
            <div className={styles.welcomeLabelArea} >
            Welcome to the Latest News<br/>
              {/* <img className={styles.toLogo} src="/Talent_Logo_Txt_Color_Simple.svg" /> */}
            </div>
            <div className={styles.newsArea} >
                <HomeNews />
            </div>
          </div>
        </div>
  )
};

export default news;
