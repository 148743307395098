

// Resume received (1), Shared with Talent Team (2), Shared with Hiring Lead/Team (3), 
//           Internal Feedback In-Progress (4), Candidate Msgs. Follow up (5), Initial Call(s) (6), 
//           Interview(s) In-progress (7), Offer (8), Hired (9), Rejected (0)

const ProcessStatusDisplayNames = (status)=>{
    switch(Number(status)){
      case -1: return "Ready for Next Step";
      case 0: return "Rejected";
      case 1: return "Reviewed";
      case 2: return "Shared with Talent Team";
      case 3: return "Candidate Follow up";
      case 4: return "Shared with Hiring Lead/Team";
      case 5: return "Internal Feedback In-Progress";
      case 6: return "Initial Call(s) In-Progresss";
      case 7: return "Interview(s) In-Progress";
      case 8: return "Offer Extended";
      case 9: return "Hired";
      default: return "Received";
    }
  };

  export default ProcessStatusDisplayNames;