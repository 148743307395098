import styles from "./TopGlobalPopupMenu.module.css";
import { useNavigate} from "react-router-dom";

const TopGlobalPopupMenu = () => {


    const navigate = useNavigate();

    const gotToProfile = () =>{

        navigate("/userprofile", {state:{from: "nav"}});

    }

    const gotToSignout = () =>{

        navigate("/signout", {state:{from: "nav"}});

    }

    const goToLegal = () =>{

      navigate("/legal", {state:{from: "nav"}});

    }

    const gotToFaqs = () =>{

      navigate("/faqs", {state:{from: "nav"}});

    }

    return (
    <div className={styles.topcontainer}>
      <div className={styles.linkArea} onClick={gotToProfile}>
        <div className={styles.iconItem}><img height={"28px"} className={styles.topGlobalIcon} src="/Profile_Icon_TopNavBar_Plain_4.svg"/></div>
        <div className={styles.linkItem}>Profile</div>
      </div>
      <div className={styles.linkArea} onClick={gotToSignout} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/Signout_Icon_64x64_Path_Simple.svg"/></div>
        <div className={styles.linkItem}>Sign out</div>
      </div>
    </div>
  );
};

export default TopGlobalPopupMenu;