import styles from "./Login.module.css";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MessageManager from "../common/ErrorManager";
import ContextManager from "../common/Context/ContextManager";

import useCustomAxios from '../common/hooks/useCustomAxios';
import useAuth from '../common/hooks/useAuth';

const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/

const Login = () =>{

    const [usernameField, setUsernameField] = useState("");
    const [passwordField, setPwdField] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const [isRequestInProgress, setIsRequestInProgress] = useState(false)
    const [validUsername, setValidUsername] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [emailFocus, setUsernameFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const { ctx, setContext } = ContextManager()
    const { setAuth } = useAuth();

    const customAxios = useCustomAxios()
    const navigate = useNavigate();
    const location = useLocation()
    const from = location.state?.from?.pathname || "/home";
    const usernameFieldRef = useRef();
    const errorMessageRef = useRef();

    let today = new Date();


    //Initial state, focus on username field
    useEffect( () => {
            usernameFieldRef.current.focus();
            // const from = state?.from?.pathname || "/";
            setContext(ctx);
            return () => {
            }
        }, []
    );


    //Retrieve data first and populate any state
    useEffect( () => {
            setLoginErrorMsg("")

                setValidUsername(EMAIL_REGEX.test(usernameField));
                setValidPassword(passwordField)
            return () => {

            }
        }, [usernameField, passwordField]
    );


    const handleSubmit = (e) =>{

        e.preventDefault();
        setLoginErrorMsg("")
        setIsRequestInProgress(true)
        postData(e);

    }

    const setInitialData = (responseData)  => {
        const {access_token, token_type, user_id, features_limit, focus_list, session_timeOut} = responseData
    
        setAuth({user_id, access_token, session_timeOut});
        ctx.User.id = user_id;
        ctx.User.token = access_token;
        ctx.User.myList = focus_list;
        ctx.Session.sessionTimeOut = session_timeOut;
        const rightNow = new Date();
        ctx.Session.lastRequestTime = rightNow;

        ctx.Resume.plan = features_limit.plan
        ctx.Resume.companies = Number(features_limit.companies);
        ctx.Resume.roles = Number(features_limit.roles);
        ctx.Resume.uploads = Number(features_limit.uploads);
        ctx.Resume.reviews = Number(features_limit.reviews);
        ctx.Resume.compares = Number(features_limit.compares);
        ctx.Resume.chat = Number(features_limit.chat);
        setContext(ctx);

        setUsernameField("");
        setPwdField("");

        setIsRequestInProgress(false);
        const fromTo = from === '' || from === '/' ? "/home": from;
        navigate(fromTo, {state: {active:true, lastRequestTime: rightNow, sessionTimeOut: session_timeOut}, replace: true});
    }

    const postData = async(e) => {
        e.preventDefault();
        
        const COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_OAUTH_REGISTRATION_PORT

        // Change to support proxy
        // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_OAUTH_API
        let URL_PATH = process.env.REACT_APP_OAUTH_API
        let URL = URL_PATH
        const postData = {username: usernameField, password: passwordField}
        await customAxios.post(URL , postData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "type": "formData"
          }}
          )
        .then(res => {
          setInitialData(res.data)
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "Login", ctx.Config.Error.getLevels.error)
        })
    }


    // Errors captured when sending API requests
    const errorsMessages = (error) => {
        setIsRequestInProgress(false)
        let errorMsg = ""
        
        if(error?.response?.status === 400){
            errorMsg = "Missing Username or Password"
        }else if (error?.response?.status === 401){
            errorMsg = "Incorrect username or password."
        }else{
            errorMsg = "Login failed."
        }

        displayMessage(errorMsg)
        errorMessageRef.current.focus()

    }

    const displayMessage = (messageToDisplay) => {
        // showMessageArea = true
        // setShowMessage(true)
        // setMessage(messageToDisplay)

        setLoginErrorMsg(messageToDisplay)
      }


    return (
        <div className={styles.mainLoginArea} >
            <div className={styles.logo}> <img className={styles.logo}  src="/Talent_Logo_Txt_Color_Simple.svg"  ></img> </div>
            <div className={styles.loginArea} >
                <div ref={errorMessageRef} className={styles.loginError} aria-live="assertive">
                    {loginErrorMsg}
                </div>
                <div  className={styles.heading}>Sign In</div>
                <div className={styles.formArea}>
                    <form onSubmit={handleSubmit}>

                        <div className={styles.usernameArea} >
                            <label className={styles.usernameFieldLabel}  htmlFor="username">Username</label>
                            <input className={styles.usernameField}  type="text" id="username" 
                                    ref={usernameFieldRef}
                                    autoComplete="on" 
                                    onChange={ (e)=> setUsernameField(e.target.value) } 
                                    value={usernameField}
                                    placeholder="name@domain.com"
                                    required
                                    aria-invalid={validUsername ? "false" : "true"}
                                    aria-describedby="uidnote3"
                                    onFocus={() => setUsernameFocus(true)}
                                    onBlur={() => setUsernameFocus(false)}
                            />
                        </div>
                        <div className={styles.passwordArea} >
                            <label className={styles.passwordFieldLabel} htmlFor="password">Password</label>
                            <input className={styles.passwordField} type="password" id="password" 
                                    onChange={ (e)=> setPwdField(e.target.value) } 
                                    value={passwordField}
                                    required
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="passwordMsg"
                                    onFocus={() => setPasswordFocus(true)}
                                    onBlur={() => setPasswordFocus(false)}
                            />
                        </div>

                        <button disabled={isRequestInProgress || !validUsername || !validPassword ? true : false} className={isRequestInProgress || !validUsername || !validPassword ? styles.signInButtonDisabled : styles.signInButton} >Sign In</button>

                    </form>
                </div>
                <p>
                        Need an account? <br></br>
                        <span className={styles.needAccountMsg} >
                        <Link className={styles.needAccountMsg} to={'/signup'} state={{new: true}} > Sign Up</Link>
                        </span>
                </p>

                { isRequestInProgress ?
                    <div className={styles.progressContainer} >
                        <div className={styles.progressArea} >
                            <div > <img className={styles.progressLogo}  src="/Profile_Icon_WhiteCircle_Thicker_Plain_Anim.svg"  /></div>
                            <div>Login in-progress...</div>
                        </div>
                    </div>
                    :<></>
                }

            </div>
        </div>
    )
}

export default Login