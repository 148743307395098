import styles from "./TermsofUse.module.css";
import { useRef, useState, useEffect, useContext } from 'react';

const TermsofUse = (props) => {

    const [registrationDisplay, setRegistrationDisplay] = useState(false);

    useEffect( () => {
        if(props?.registration === true){
            setRegistrationDisplay(true);
        }

        return () => {
        }
    }, [props]
);

    return(

        <div className={registrationDisplay ? styles.touContainerRegistration : styles.touContainer} >
            <div className={styles.touPageTitle} >
                Talent Opinion Terms of Use
                <div className={styles.touPublishInfo}>Published January 3, 2024. Effective as of January 3, 2024.</div>
            </div>
            <div className={styles.touMainSectionContainer} >
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        1. Acceptance of Terms
                    </div>
                    <div className={styles.touSectionContent} >
                        These Terms of Use ("<b>Terms</b>") constitute a legally binding agreement between Talent Opinion ("<b>Company</b>", "<b>we</b>", "<b>us</b>") and you ("<b>User</b>", "<b>you</b>"). By accessing or using our Talent Opinion Software as a Service (SaaS) platform and related services, websites, web-based applications and products, customer support, discussion forums or other interactive areas or services (collectively, the "<b>Services</b>"), you acknowledge that you have read, understood, and agree to be bound by these Terms. If you disagree with any of these Terms, you are not authorized to use the Services. Please do not use our Services.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        2. Description of Service
                    </div>
                    <div className={styles.touSectionContent} >
                        The Services is a SaaS platform and Artificial Intelligence driven (AI-driven)  job requirements (job postings) and resume fit analysis software. We may modify the Services at any time without prior notice.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        3. Eligibility and Account Creation
                    </div>
                    <div className={styles.touSectionContent} >
                        You must be of legal age or have the legal capacity to enter into the terms, or, if you are not, that you have obtained parental or guardian consent to enter into this Agreement to use the Services. To access the Services, you may be required to create an account by providing certain information about yourself. You are solely responsible for the accuracy and completeness of the information you provide.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        4. Access and Use of the Service
                    </div>
                    <div className={styles.touSectionContent} >
                    •	You are granted a non-exclusive, non-transferable, revocable license to access and use the Services for your internal business purposes.<br/>
                    •	You agree not to access the Services for any purpose that is illegal or unauthorized by these Terms.<br/>
                    •	You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        5. Prohibited Uses
                    </div>
                    <div className={styles.touSectionContent} >
                    You are prohibited from using the Services for any illegal or unauthorized purpose, including, but not limited to:<br/>
                        •	Infringing on the intellectual property rights of others;<br/>
                        •	Transmitting or uploading any User Content that violates any third-party rights or applicable laws;<br/>
                        •	Transmitting or uploading any harmful code or User Content;<br/>
                        •	Engaging in harassment, threats, or other abusive behavior;<br/>
                        •	Interfering with the operation of the Services or any other user's use of the Services;<br/>
                        •	Collecting or storing personal information from other users without their express consent;<br/>
                        •	Impersonating any person or entity.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        6. User Content
                    </div>
                    <div className={styles.touSectionContent} >
                        You may submit or upload content or documents such as resumes and job postings or text-based Input content such as chat conversations to the Services ("User Content"). You retain all ownership rights to your User Content. However, by submitting User Content, you grant Company a non-exclusive, worldwide, royalty-free, irrevocable license to use, reproduce, modify, adapt, publish, translate, display, and distribute your User Content in connection with the Services. You are solely responsible for the legality, reliability, and appropriateness of your User Content.<br/>
			When you submit or upload content, it helps enhance the accuracy and effectiveness of our Services, tailored specifically to your use case. We do not use your content for marketing purposes or to create profiles of you. Instead, your content is utilized solely to improve our models. For example, by further training on the analysis of resumes (candidates) and various job requirements (job postings), we can make our Services more helpful for everyone.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        7. Intellectual Property
                    </div>
                    <div className={styles.touSectionContent} >
                        The Services and all content contained therein, including but not limited to text, graphics, logos, images, software, and data, are protected by copyright and other intellectual property laws. You may not use any of the Company's intellectual property without our express written permission.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        8. Payment and Subscription
                    </div>
                    <div className={styles.touSectionContent} >
                        The Services may offer various subscription plans with different features and pricing. You agree to pay all applicable fees for the Services as described on the Services. All fees are non-refundable unless otherwise stated. You may cancel your subscription at any time, but you will not be eligible for a refund of any unused portion of your subscription term. If you cancel your subscription then you can continue using the Services at the same subscription level until A)any unused portion of your subscription or limits of the Services have been reached or B)The subscription term is reached (subscription period expired).
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        9. Disclaimers and Warranties
                    </div>
                    <div className={styles.touSectionContent} >
                        THE SERVICES IS PROVIDED "AS IS" AND WITH ALL FAULTS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THE RESULTS OBTAINED FROM THE USE OF THE SERVICES OR THAT WILL BE EFFECTIVE, ACCURATE, OR RELIABLE. COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE. COMPANY DOES NOT WARRANT THAT THE QUALITY OF THE SERVICES AND SOFTWARE WILL MEET YOUR EXPECTATIONS OR ANY ERRORS OR DEFECTS IN THE SERVICES WILL BE CORRECTED.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        10. Limitation of Liability
                    </div>
                    <div className={styles.touSectionContent} >
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES.<br /><br />
                    COMPANY TOTAL LIABILITY IN ANY MATTER ARISING OUT OF OR RELATED TO THE TERMS IS LIMITED TO THE GREATER OF US $10 OR THE AGGREGATE AMOUNT THAT YOU PAID FOR ACCESS TO THE SERVICES DURING THE THREE-MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE LIABILITY.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        11. Termination
                    </div>
                    <div className={styles.touSectionContent} >
                        Company may terminate your access to the Services for any reason at any time without notice, including a breach of these Terms. You may terminate your account at any time.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        12. Governing Law and Dispute Resolution
                    </div>
                    <div className={styles.touSectionContent} >
                        These Terms will be governed by and construed in accordance with the laws of the State of Illinois, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these Terms will be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration will be held in Chicago, Illinois, and the arbitrator's award will be final and binding on the parties.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        13. Entire Agreement
                    </div>
                    <div className={styles.touSectionContent} >
                        These Terms constitute the entire agreement between you and Company with respect to the Services. Any prior or contemporaneous communications, representations, or agreements, whether oral or written, are superseded by these Terms.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        14. Changes to Terms
                    </div>
                    <div className={styles.touSectionContent} >
                        Company may revise these Terms at any time by posting the revised Terms on the Services. The revised Terms will be effective immediately upon posting. You agree to review the Terms periodically and to be bound by the most current version. If you are using the Services on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
                    </div>
                </div>
                <div className={styles.touTermSection} >
                    <div className={styles.touSectionTitle} >
                        15. Contact Us
                    </div>
                    <div className={styles.touSectionContent} >
                        If you have any questions about these Terms, please contact us at <b>contactto@talentopinion.com.</b>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default TermsofUse;