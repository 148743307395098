import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ContextManager from "../../common/Context/ContextManager";
import IsSessionRequired from "../TimeUtil/IsSessionRequired";

const RequireAuth = () => {
// Replace the code above with the one below once role based is active
// const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const { ctx, setContext } = ContextManager()

    // console.log(">>>>>>>>>> auth object: " + auth.access_token)

    return (
        // auth?.user_id
        auth?.user_id || IsSessionRequired() === false
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />

        // Activate the code below when role based pages is active
        // auth?.roles?.find(role => allowedRoles?.includes(role))
        //     ? <Outlet />
        //     : auth?.user_id
        //         ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        //         : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;