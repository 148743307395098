import styles from "./UploadResume.module.css";
import { useRef, useState, useEffect, ChangeEvent, useLayoutEffect } from 'react';
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";

const UploadResume = (props) => {

    // const [message, setMessage] = useState('')
    const [userId, setUserId] = useState(0)
    const [loadComponent, setLoadComponent] = useState(true)
    const [message, setMessage] = useState('')
    const [fileList, setFileList] = useState([]);
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);
    const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(styles.hide)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showFileType, setShowFileType] = useState(true);
    const [isInNavContainer, setIsInNavContainer] = useState(false);
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [featureUsage, setFeatureUsage] = useState({});
    const [resumeListUploadToDelete, setResumeListUploadToDelete] = useState([]);
   
    const inputRef = useRef(null);
    const containerRef = useRef()
    const urlParameters = useParams(props);
    const { ctx, setContext } = ContextManager()
    const customAxios = useCustomAxios()
    const navigate = useNavigate();
    let {state} = useLocation();
    let firstLoad = true  
    let today = new Date()


    useEffect( () => {
        setUserId(ctx.User.id)

        fetchData();

        return () => {
        }
        }, []
    );

    useEffect( () => {
        if(fileList.length < 1){
            setIsUploadEnabled(false);
        }

        return () => {
        }
        }, [fileList]
    );

    useEffect( () => {
            setUserId(ctx.User.id)

            let refresh = false
            if(state !== null && state !== undefined){
            refresh = state.newRole === true || state.newCo === true ? true : false
            }

            if((loadComponent && firstLoad) || refresh){
                firstLoad = false //Prevents another initial request and reload within the same request
                setLoadComponent(false)
            }
            return () => {
            }
        }, [state]
    );

    useEffect( () => {
    
        setIsInNavContainer(props.inContainer? true: false)

        // if(props.openUploadModal === true && isModalVisible === false){
        if(props.openUploadModal === true){
            setIsModalVisible(true)
            setModalVisibility(styles.resumeListContainer)

            setShowFileType(Number(window.innerWidth) > 830 && !isInNavContainer ? true : false);
        }else{
            setIsModalVisible(false)
            setModalVisibility(styles.hide)
        }
            
        return () => {
        }
        }, [props]
    );

    const setUploadData = (responseData)  => {
        setFileList([])
        setIsRequestInProgress(false)
        setIsUploadEnabled(false)
        setSuccessMessage(true)
        displayMessage("All resumes were succesfully uploaded.")
    }
    
    const setInitialData = (responseData)  => {
        setFeatureUsage(responseData);
    }

    const fetchData = async() => {
        // Change to support proxy
        // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
        let URL_PATH = process.env.REACT_APP_RESUME_FEATURES_USAGE_API
        let URL = URL_PATH
        await customAxios.get(URL)
        .then(res => {
            setInitialData(res.data)
        })
        .catch(error => {
            // errorsMessages(error)
            MessageManager(error, "FeaturesStats", ctx.Config.Error.getLevels.error)
        }) //Make sure to use proper funcion to capture error
    };

    const selectResumesToUpload = (e) => {
        //Use the the hidden input element's click event
        e.preventDefault()
        inputRef.current?.click();
    };

    const setSelectedResumes = (e) => {
        e.preventDefault()
        if (!e.target.files) {
            displayMessage("No resumes have been selected.")
            return;
        }
        
        // const files = fileList ? [...fileList] : [];
        const files = e.target.files ? [...e.target.files] : [];
        const existingFiles = fileList.length > 0 ? [...fileList] : []

        const aggregatedFiles = fileList.length > 0 ? existingFiles.concat( files) : files;

        setFileList(aggregatedFiles);
        if(aggregatedFiles.length > 0) { 
            setIsUploadEnabled(true)
        }else{ setIsUploadEnabled(false)}
    };

    const setResumeSelection = (e) =>{
        let updatedResumeListToDelete = []
        
        if(resumeListUploadToDelete.includes(e.target.id)){ 
          updatedResumeListToDelete = resumeListUploadToDelete.filter(s => s !==  e.target.id);
        }else{
          updatedResumeListToDelete = [...resumeListUploadToDelete, e.target.id];
        }
        
        setResumeListUploadToDelete(updatedResumeListToDelete)
        setIsDeleteEnabled(updatedResumeListToDelete.length > 0 ? true : false);
    }

    const removeFileFromUploadList = ()=>{
        let updatedResumeListToUpload = [...fileList];

        resumeListUploadToDelete.map( fileName => {
                console.log("Found in list: " + fileName)
                updatedResumeListToUpload = updatedResumeListToUpload.filter(s => s.name !==  fileName);
            }
        );
        
        if((fileList.length - resumeListUploadToDelete.length) === updatedResumeListToUpload.length){
            console.log("Files removed");
            setIsDeleteEnabled(false);
            setResumeListUploadToDelete([]);
        }
        
        setFileList(updatedResumeListToUpload);
    }

    const preProcessUpload = (e) =>{
        // e.preventDefault()
        if(fileList.length === 0){
            displayMessage("No resumes have been selected.")
            return;
        }

        let continueUpload = false;
        const uploads = featureUsage?.uploads !== -1 ? Number(featureUsage?.uploads): -1;

        if(uploads !== -1 && ctx.Resume.reviews !== -1){
            // if(fileList.length > 10){
            //     displayMessage("Please upload upto 10 resume(s) on each time.");
            //     continueUpload = false;
            // }else if(uploads >= ctx.Resume.uploads){
            //     displayMessage("You can only upload upto " + ctx.Resume.uploads + " resume(s).");
            //     continueUpload = false;
            // }else{
            //     continueUpload = true;
            // }
            if(uploads >= Number(ctx.Resume.uploads)){
                displayMessage("Plan limit reached. The plan allows upto " + ctx.Resume.uploads + " resume uploads. You have " + (Number(ctx.Resume.uploads) - uploads) + " uploads left.");
                continueUpload = false;
            }else if(fileList.length > Number(ctx.Resume.uploads) || fileList.length > (Number(ctx.Resume.uploads) - uploads)){
                displayMessage("Upload list is above plan limit or above uploads left. You have " + (Number(ctx.Resume.uploads) - uploads) + " uploads left. Remove from list.");
                continueUpload = false;
            }else if(fileList.length > process.env.REACT_APP_UPLOAD_QTY_MAX){
                // displayMessage("You can only upload upto " + ctx.Resume.uploads + " resume(s).");
                displayMessage("Please upload upto " + process.env.REACT_APP_UPLOAD_QTY_MAX + " resume(s) on each time.");
                continueUpload = false;
            }else{
                continueUpload = true;
            }
        }else if(fileList.length > process.env.REACT_APP_UPLOAD_QTY_MAX){
            displayMessage("Please upload upto " + process.env.REACT_APP_UPLOAD_QTY_MAX + " resume(s) on each time.")
            continueUpload = false;
        }
        else{
            continueUpload = true;
        }

        if(continueUpload === true){
            uploadResumes();
        }
    }

    const uploadResumes = () => {
        const resumesFormData = new FormData();
    
        fileList.forEach((file, i) => {
          // data.append(`file-${i}`, file, file.name);
          resumesFormData.append(`files`, file, file.name);
        });
        setIsRequestInProgress(true);
        postData(resumesFormData);
    };

    //Set the base domain and port for create/update, and requests urls.
    let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_UPLOAD_PORT
    // Post data using axios
    const postData = async(resumeList) => {
        let PATH= process.env.REACT_APP_UPLOAD_API
        // Change to support proxy
        // let URL_PATH = COMAIN_PORT +  PATH
        let URL_PATH = PATH
        let URL = URL_PATH + "/" + ctx.Company.id + "/" + ctx.Role.id
        const postFilesData = resumeList
        
        await customAxios.post(URL, postFilesData)
        .then(res => {
            setUploadData(res.data)
        })
        .catch(error => {
        errorsMessages(error)
        MessageManager(error, "UploadResume", ctx.Config.Error.getLevels.error)
        })
    }

    // Errors displayed when sending API requests
    const errorsMessages = (error) => {
        let msg = "An error was found. Please try again."
        // setShowMessage(true)
        // displayMessage(error.response !== undefined ? error.response.data.detail : msg)
        displayMessage(msg)
    }

    const displayMessage = (messageToDisplay) => {
        setIsRequestInProgress(false);
        setShowMessage(true);
        setMessage(messageToDisplay);
    }

    const openModal = () =>{
        setModalVisibility(styles.resumeListContainer)
    }

    const closeModal = () =>{
        setModalVisibility(styles.hide)
        if(props.uploadModalOpen){
            props.uploadModalOpen(false)
        }
        setIsModalVisible(false);

        // if(window.location.pathname.includes("CompanySetup")){
        //     console.log("@ Pathname: " + window.location.pathname);
        //     navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume: true}});
        //     // window.location.reload(true);
        // }
    }

    const closeMessage = () =>{
        if(message){
            const show = showMessage === true ? false : true
            setShowMessage(show);
            setMessage("");
            setSuccessMessage(false);

            // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
            // console.log("Pathname: " + window.location.pathname);
            // if(window.location.pathname.includes("CompanySetup")){
            //     navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
            // }
        }
    }

    return ( isModalVisible ?
        <div className={modalVisibility} >
            <div className={styles.resumeListArea} ref={containerRef} >
                <div className={styles.resumeFileNameArea} >
                    <div className={styles.resumeFileNameLabel} >File Name</div>{showFileType ? <div className={styles.resumeTypeLabel} >FileType</div> : <></>} <div onClick={() => {closeModal()}} className={styles.close}>&times;</div>
                </div>
                <div className={styles.resumeList}>
                    { fileList.length < 1 ?
                    <div className={styles.arrowArea} >
                        <div className={styles.arrow1Text} >Use the "Browse" button to look for resumes in this device.</div>
                        <svg className={styles.arrow1}  width="13.856mm" height="30mm" version="1.1" viewBox="0 0 13.856 15.495" xmlns="http://www.w3.org/2000/svg">
                            <g id="arrow" transform="translate(-5.3983 7.8017)" fill="none" stroke="#895bf2" 
                                stroke-linecap="round" stroke-linejoin="round" stroke-width=".75009">
                            <path d="m14.393 4.1484 1.9658-1.7e-5 -4.0323 3.1699-4.0327-3.1699"/>
                            <path d="m12.33 3.4761-2.6e-5 -54.869"/>
                            </g>
                        </svg>
                    </div>
                    : <></>
                    }
                    <div>
                        {
                            fileList.map((file, index) => (
                                <div key={index} className={styles.resumeListItems}>
                                    <div key={index} className={styles.resumeListItemName}>{file.name}</div>
                                    {showFileType ? <div className={styles.resumeListItemType}>{file.type}</div> : <></>}
                                    <input type="checkbox" id={file.name} name={file.name} className={styles.checkbox} onChange={(e)=> {setResumeSelection(e);}}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={styles.buttonsArea}>
                    {/* <button className={styles.selectResumesButton} onClick={(e)=> selectResumesToUpload(e)}>Select resumes</button> */}
                    {/* <button className={isUploadEnabled ? styles.uploadResumesButton : styles.uploadResumesButtonDisabled} onClick={preProcessUpload}  >Upload resumes</button> */}
                    <div className={styles.buttonsGroup}>
                        <div className={styles.selectResumesButton } onClick={(e)=> selectResumesToUpload(e)}>
                            <img  alt="Browse" width="48" height="28.8" title="Browse" src="/select-svgrepo-com_optimized.svg" />
                            <div className={styles.uploadLabel}  >Browse</div>
                        </div>
                       
                        <div className={isDeleteEnabled ? styles.deleteResumesButton : styles.deleteResumesButtonDisabled} onClick={removeFileFromUploadList}>
                        <img alt="Delete" width="48" height="28.8" title="Delete" src="/Trash_Logo_button_3.svg" />
                            <div className={styles.uploadLabel}  >Remove</div>
                        </div>

                        <div className={isUploadEnabled ? styles.uploadResumesButton : styles.uploadResumesButtonDisabled} onClick={preProcessUpload}>
                            <img  alt="Upload" width="48" height="28.8" title="Upload" src="/Upoad_Icon_64x64_Path_Simple.svg" />
                            <div className={styles.uploadLabel}  >Upload</div>
                        </div>
                        {/* <div className={styles.uploadIcon}> <img className={styles.hoverColor} alt="Review" width="48" height="28.8" title="Upload" src="/Upoad_Icon_64x64_Path_Simple.svg" /></div> */}
                    </div>
                    <input 
                        type="file" 
                        ref={inputRef} 
                        onChange={(e) => setSelectedResumes(e)} 
                        style={{ display: 'none' }} 
                        accept='.doc,.docx,.pdf,application/msword' 
                        multiple 
                    />                    
                </div>
                { showMessage ?
                <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
                    <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                        <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                        <div>
                            {message}
                        </div> 
                    </div>
                </div>
                : <></>
                }
                { isRequestInProgress ?
                    <div className={styles.progressContainer} >
                        <div className={styles.progressArea} >
                            <div > <img className={styles.progressLogo}  src="/Upoad_Icon_64x64_Path_Simple_Anim.svg"  /></div>
                            <div>Upload in-progress...</div>
                        </div>
                    </div>
                    :<></>
                }
            </div>
        </div>
        : <></>
    )

};

export default UploadResume;