import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from "@fullcalendar/interaction"
import styles from './CalendarAvailabilityView.module.css'
import useCustomAxios from '../../common/hooks/useCustomAxios';
import ContextManager from "../../common/Context/ContextManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import MessageManager from "../../common/ErrorManager";
import { useRef, useState, useEffect  } from 'react';


const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/
// const NAME_LASTNAME_REGEX = /^[A-Z][a-z-]{3,23}$/;
const NAME_LASTNAME_REGEX = /^[A-Za-z-]{3,70}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;

export default function CalendarAvailabilityViewWapper(props) {
  
  const [preferedView, setPreferedView] = useState("timeGridWeek");
  const [calendarApi, setCalendarApi] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [transferEvent, setTransferEvent] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dayAndTimeSelected, setDayAndTimeSelected] = useState(false);
  const [calendarTypeSelected, setCalendarTypeSelected] = useState(preferedView);
  const [availabilityToken, setAvailabilityToken] = useState("");
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [codeIsValid, setCodeIsValid] = useState(false);

  // validation form ----------------------------------------------------
  const [validationCode, setValidationCode] = useState(0);
  const [isValidCode, setIsValidCode] = useState(false);
  const [validationRequest, setValidationRequest] = useState(true);
  const [validationCodeFocus, setValidationCodeFocus] = useState(false);
  const [validationCodeErrorMsg, setValidationCodeErrorMsg] = useState("");

  const [currentWidth, setCurrentWidth] = useState(0);

  const errorMessageRef = useRef();
  const validationCodeRef = useRef();


  const calendarRef = useRef(null);
  const customAxios = useCustomAxios()
  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();
  const urlParameters = useParams();

  const htmlContainerElement = useRef();

  useEffect( () => {

      const calendarApiRef = calendarRef.current.getApi();
      setCalendarApi(calendarApiRef);
      calendarApiRef.setOption('height', "100%");
      calendarApiRef.updateSize();
      const calendarViewObject = calendarApiRef.view;
      setCalendarTypeSelected(calendarViewObject.type);
      const start = new Date(calendarViewObject.activeStart).toISOString()
      const end  = new Date(calendarViewObject.activeEnd).toISOString()

    return () => {

    }
  }, []
  );


  useEffect( () => {

    if(calendarApi?.view.type !== undefined){
      setCalendarTypeSelected(calendarApi.view.type);
    }
    return () => {

    }
  }, [calendarApi?.view?.type]
  );


  useEffect( () => {

    if(props?.token){
      setAvailabilityToken(props.token);
    }

    if(props?.code){
      setValidationCode(props.code)
    }

    return () => {

    }
  }, [props]
  );


  const setNewTransferEvent = (event)=>{
    // setTransferEvent
    if(event){
      let id = 0;
      let newEvents = [];
      if(calendarEvents.length > 0){
        newEvents = [...calendarEvents];
        const lastId = newEvents.length - 1;
        id = Number(newEvents[lastId].id) + 1;
      }
      
      event.id = id;
      const day = event.start.toDateString();
      const from = event.start.toLocaleTimeString();
      const to = event.end.toLocaleTimeString()
      const sharedEvent = { id: id, title: 'Available', day: day, start: from, end:to, zone_name: event.zoneName };
      newEvents.push(sharedEvent);
      // Add to shared events
      setCalendarEvents(newEvents);
      // Add to the calendar as new event
      calendarApi.addEvent(event);
    };
  }

  const setInitialData = (responseData)  => {
    setIsRequestInProgress(false);
    availabilitySent = false;
    
    if(responseData){
      const msg = "Your availability was successfully shared.\nA copy of the message has been sent to you.\nFeel free to close this message and the 'Availability Calendar'.";
      displayMessage(msg);
    }else{
      const msgEr = "Your availability could not be shared at this time.\nPlease try again, or refresh the page to start a new session if your second try is not successful.";
      displayMessage(msgEr, "error");
    }
  };

  let availabilitySent = false;
  const postAvailabilityData = async(url, data) => {

    if(!isRequestInProgress){
      availabilitySent = true;
      setIsRequestInProgress(true);
      await customAxios.post(url, data)
      .then(res => {
        setInitialData(res.data);
      })
      .catch(error => {
          errorsMessages(error)
          MessageManager(error, "CalendarAvailabilityView", ctx.Config.Error.getLevels.error)
      })
    }
  };


  const errorsMessages = (error) => {
    // let msg = "An error was found. Please check the console for more information"
    let msg = "Your availability could not be shared at this time.\nPlease try again, or refresh the page to start a new session if your second try is not successful."
    // displayMessage(error.response !== undefined ? error.response.data.detail : msg);
    displayMessage(msg);
  }

  const displayMessage = (messageToDisplay, type) => {
    setIsRequestInProgress(false);
    setShowMessage(true)

    if(type === "error"){
      // Remove this if enabled messages in this component
      // setShowMessage(true);
      setSuccessMessage(false);
      // setMessage(messageToDisplay);
    }else{
      // setShowMessage(true);
      setSuccessMessage(true);
    }
    setMessage(messageToDisplay);
  }

  const handleDateClick = (arg) => {
    if(!dayAndTimeSelected){
      calendarApi.changeView('timeGridWeek', arg.dateStr);
    };
      
    setDayAndTimeSelected(false);
  }


  const handleSelect = (arg)=>{
    if(arg.allDay){
      //Do nothing
    }else{
      options.timeZoneName = "long";
      const zone_name = arg.start.toLocaleDateString(Intl.DateTimeFormat().resolvedOptions().locale, {day:'2-digit',timeZoneName: 'long' }).substring(4);
      const event = { title: 'Available', start: arg.start, end: arg.end, zoneName: zone_name };
      setNewTransferEvent(event);
      setDayAndTimeSelected(true);
    };
  };

  const eventClickHandler = (arg)=>{
    setNewTransferEvent(arg.event);
  }

  const closeMessage = () =>{
    const show = showMessage === true ? false : true;
    setShowMessage(show)
    setMessage("")
    setSuccessMessage(false);
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };


  const deleteAvailability = (id)=>{
    const availabilityList = calendarEvents.filter((e, index)=> e.id !== id);
    setCalendarEvents(availabilityList);
    deleteScheduledEvent(id)
  };

  const deleteScheduledEvent = (id)=>{
    const existingEvent = calendarApi.getEventById(id);
    if(existingEvent !== undefined && existingEvent !== null){
      existingEvent.remove();
    }
  };


  const shareAvailability = ()=>{
    if(calendarEvents.length > 0){
      
      setIsRequestInProgress(true);

      const sharedAvailability = {
        token: availabilityToken,
        code: validationCode,
        availability_list: calendarEvents
      };

      const url = process.env.REACT_APP_SHARED_AVAILABILITY_API;

      postAvailabilityData(url, sharedAvailability);
    };
  }

  let movement_timer = 0
  const windowWrapSize = 895
  const SET_EXPERIENCE_RESIZE_TIMEOUT = 100;
  window.addEventListener('resize', ()=>{
    clearInterval(movement_timer);
    movement_timer = setTimeout(captureHtmlContainerSize, SET_EXPERIENCE_RESIZE_TIMEOUT);
  });

  const captureHtmlContainerSize = () =>{
    if(htmlContainerElement.current){
      setCurrentWidth(htmlContainerElement.current.offsetWidth)
    }
  }

  return (
    <div className={styles.calendarContainerWrap}  onLoad={captureHtmlContainerSize} ref={htmlContainerElement}  >
        <div className={currentWidth < windowWrapSize ? styles.calendarContainerFull : styles.calendarContainer} >
            <FullCalendar ref={calendarRef} 
            plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
            initialView= {preferedView} //"custom" //"dayGridMonth" //"dayGridWeek" // "timeGridWeek" // "timeGridDay"
            selectable = {true}
            editable = {true}
            // dragScroll = {true}
            nowIndicator = {true}
            // weekends={false}
            // headerToolbar= { {left: 'prev,next today', center: 'title', right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay'}}
            headerToolbar= { {left: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay today prev,next'}}
            dayMaxEvents = {true}
            dateClick={handleDateClick}
            select={handleSelect}
            // Eventclick is not needed for Availability view
            // ----> eventClick={eventClickHandler}
            // eventContent={renderEventContent}
            // eventsSet={()=> alert("Something changed")}
            // eventChange={(e)=> alert(e)}

          />
        </div>
        <div className={currentWidth < windowWrapSize ? styles.selectionContainerFull : styles.selectionContainer}>
          <div className={styles.selectionContainerHeader} >Selected dates & times</div>

          <div className={styles.sendButtonContainer}>
            <div className={calendarEvents.length > 0 ? styles.sendButtonArea : styles.sendButtonAreaInactive} onClick={calendarEvents.length > 0 ? shareAvailability : ()=>{}} >
              <div className={styles.sendLabel} >Share your availability</div>
              <img className={styles.sendButtonIcon} alt="Send" title="Send" src="/Send_Invite_Icon_White.svg" />
            </div>
          </div>
          <ul className={styles.ul} >
            {
              calendarEvents.length > 0 ?
              calendarEvents.map( (event, index) =>
                  <li key={"li" + index} className={styles.dropArea} >
                    <div onClick={() => {deleteAvailability(event.id)}} className={styles.closeMessage}>&times;</div>
                    <span key={"spa"+index} className={styles.availabilityDayLabel}>{event.day}</span><br/>
                    <div className={styles.availabilityTimeArea}>
                      <div key={"from"+index} className={styles.availabilityFromLabel}><b>From:</b> {event.start} ({event.zone_name})</div>
                      <div key={"to"+index} className={styles.availabilityToLabel}><b>To:</b> {event.end} ({event.zone_name})</div><br/>
                    </div>
                  </li>
              )
              :
              <div className={styles.noSelectionMessage} >No date or time has been selected.</div>
            }
          </ul>
        </div>
      {
        showMessage ?
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                  {message}
                </div> 
            </div>
        </div>
        : <></>
        }
        { isRequestInProgress ?
            <div className={styles.progressContainer} >
                <div className={styles.progressArea} >
                    <div > <img className={styles.progressLogo}  src="/Send_Invite_Icon_Anim.svg"  /></div>
                    <div>Sharing availability...</div>
                </div>
            </div>
            :<></>
        }
    </div>
  )
}