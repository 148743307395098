
export default function GetInviteTemplateName(templateId, templateList){
  
  const templateNameFiltered = templateList.filter(template => Number(template.id) === Number(templateId));

  let name = '';

  if(templateNameFiltered.length > 0){
    name = templateNameFiltered[0].name;
  };

  return name;
  
  // switch(Number(templateId)){
    //     case 1: return 'Initial Call 1';
    //     case 2: return 'Initial Call 2';
    //     case 3: return 'Event 1';
    //     case 4: return 'Event 2';
    //     case 5: return 'Event 3';
    //     case 6: return 'Event 4';
    //     case 7: return 'Best time to call 1';
    //     case 8: return 'Best time to call 2';
    //     case 9: return 'Best time to call 3';
    //     case 10: return 'In-person interview 1';
    //     case 11: return 'In-person interview 2';
    //     default: return 'Select a template*';
    //   }

};