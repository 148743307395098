import styles from "./CompanySetup.module.css";
import CompanyInfoArea from "../components/companysetup/CompanyInfoArea";
import Role from '../components/companysetup/RoleArea'
import RoleCriteria from '../components/companysetup/AdditionalEvaluationCriteria'
import ResumeList from '../components/companysetup/ResumesList'
import MessageManager from "../common/ErrorManager";
import Switch from "../common/Buttons/Switch";


import { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const CompanySetup = () => {

  const [newRole, setNewRole] = useState(false);
  const [newCompany, setNewCompany] = useState(false);
  const [companySelectedId, setCompanySelectedId] = useState(0);
  const [roleSelectedId, setRoleSelectedId] = useState(0);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIconUp);
  const [openCloseRoleIconUp, setOpenCloseRoleIconUp] = useState(styles.openCloseIconUp);
  const [openCloseRoleCriteriaIconUp, setOpenCloseRoleCriteriaIconUp] = useState(styles.openCloseIconUp);
  const [companyInfoInterIsVisible, setCompanyInterVisible] = useState(styles.companyInfoArea);
  const [companyRoleIsVisible, setCompanyRoleIsVisible] = useState(styles.roleArea);
  const [companyRoleCriteriaIsVisible, setCompanyRoleCriteriaIsVisible] = useState(styles.additionalEvaluationCriteriaNameOnly);
  const [saveCompanyChanges, setSaveCompanyChanges] = useState(false);
  const [saveRoleChanges, setSaveRoleChanges] = useState(false);
  const [saveRoleCriteriaChanges, setSaveRoleCriteriaChanges] = useState(false);
  const [newStateToBeSaved, setNewStateToBeSaved] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({type: 'message', message: []});
  const [waitingCompanySave, setWaitingCompanySave] = useState(false);
  const [waitingRoleSave, setWaitingRoleSave] = useState(false);
  const [waitingRoleCriteriaSave, setWaitingRoleCriteriaSave] = useState(false);
  const [guidedOption, setGuidedOption] = useState(true);
  
  const saveCompanyInfo = {
    companyInfoArea : false,
    role : false,
    roleCriteria : false
  };

  const [saveStatus, setSaveStatus] = useState(saveCompanyInfo);

  const parameters = useParams();
  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();
  let {state} = useLocation();

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

  useEffect( () => {
    if(ctx.User.isAddGuideOpen === true){
      setGuidedOption(true);
    }else{
      setGuidedOption(false);
    }

    return () => {

    }
  }, [state]
  );

  // Retrieve data first and populate any state
  useEffect( () => {
    
      if(state !== null && state !== undefined){
        //Possible states: newRole, roleSel, newCo
        if(state.roleSel === true){
          //TODO: REVIEW THIS CODE
          refreshToRoleSelection()
        }
      }
      
      return () => {

      }
    }, [state]
  );

  const refreshToRoleSelection = () => {
    setNewRole(newRole === false ? true : false)
    setNewCompany(newCompany === false ? true : false)
    navigate("/companysetup/" + parameters.id + "/" + parameters.rid)
  }

  const addNewCompany = () => {
    setNewRole(newRole === false ? true : false)
    setNewCompany(newCompany === false ? true : false)
    ctx.Company.id = 0
    ctx.Role.id = 0
    setContext(ctx)
    if(guidedOption){
      navigate("/companysetupguide1/", {state: {newRole:false}, replace: true});
    }else{
      navigate("/companysetup/", {state: {newRole:false}, replace: true});
    }
  }

  const addNewRole = () =>{
    setNewRole(newRole === false ? true : false)
    ctx.Role.id = 0
    setContext(ctx)
    if(guidedOption){
      navigate("/companysetupguide2/" + ctx.Company.id, {state: {newRole:false}, replace: true});
    }else{
      navigate("/companysetup/" + ctx.Company.id, {state: {newRole:false}, replace: true});
    }
  }
 
  const openModal = () =>{
      if(isModalVisible === false){
        setModalVisibility(true)
        setIsModalVisible(true)
      }
  }

  const uploadModalOpen = (status) => {
    setModalVisibility(false)
    setIsModalVisible(false)
  }

  const setCompanyInterVisibleOnOff = () => {
    let isOn = companyInfoInterIsVisible === styles.companyInfoArea ?  styles.companyInfoAreaCompanyNameOnly : styles.companyInfoArea
    setCompanyInterVisible(isOn)
    
    let iconStyle = isOn === styles.companyInfoArea ? styles.openCloseIconUp : styles.openCloseIcon
    setOpenCloseIconUp(iconStyle)
  }

  const setCompanyRoleVisibleOnOff = () => {
    let isOn = companyRoleIsVisible === styles.roleArea ?  styles.roleAreaNameOnly : styles.roleArea
    setCompanyRoleIsVisible(isOn)
    
    let iconStyle = isOn === styles.roleArea ? styles.openCloseIconUp : styles.openCloseIcon
    setOpenCloseRoleIconUp(iconStyle)
  }

  const setCompanyRoleCriteriaVisibleOnOff = () => {
    let isOn = companyRoleCriteriaIsVisible === styles.additionalEvaluationCriteria ?  styles.additionalEvaluationCriteriaNameOnly : styles.additionalEvaluationCriteria
    setCompanyRoleCriteriaIsVisible(isOn)
    
    let iconStyle = isOn === styles.additionalEvaluationCriteria ? styles.openCloseIconUp : styles.openCloseIcon
    setOpenCloseRoleCriteriaIconUp(iconStyle)
  }

  useEffect( () => {
    
    if(saveStatus.companyInfoArea === true){
      setNewStateToBeSaved(true);
    }else if(saveStatus.role === true){
      setNewStateToBeSaved(true);
    }else if(saveStatus.roleCriteria === true){
      setNewStateToBeSaved(true);
    }else{
      setNewStateToBeSaved(false);
    }

    return () => {

    }
  }, [saveStatus]
);

  const setNewStateToBeSavedMessage = (saveMessageCompanyInfo)=>{
    const newCompanyInfoState = {...saveStatus};
    newCompanyInfoState[saveMessageCompanyInfo.name] = saveMessageCompanyInfo.save;
    setSaveStatus( newCompanyInfoState );

  }

  const sendSaveMessage = ()=>{

    // const seedRnd = Math.floor(Math.random() * 10) + 1;

    if(ctx.Company.id > 0){
      if(saveStatus.companyInfoArea === true){
        setSaveCompanyChanges(true);
        // const newCompanyInfoState = {...saveStatus};
        // newCompanyInfoState.companyInfoArea = false;
        // setSaveStatus( newCompanyInfoState );
      }

      if(saveStatus.role === true){
        setSaveRoleChanges(true);
        // const newCompanyInfoState = {...saveStatus};
        // newCompanyInfoState.role = false;
        // setSaveStatus( newCompanyInfoState );
      }

      if(saveStatus.roleCriteria === true){
        setSaveRoleCriteriaChanges(true);
        // const newCompanyInfoState = {...saveStatus};
        // newCompanyInfoState.roleCriteria = false;
        // setSaveStatus( newCompanyInfoState );
      }

      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.roleCriteria = false;
      newCompanyInfoState.role = false;
      newCompanyInfoState.companyInfoArea = false;
      setSaveStatus( newCompanyInfoState );
    }else{
      saveCompanyFirst();
    }
  }

  const saveCompanyFirst = ()=> {
    if(saveStatus.companyInfoArea === true){
      setSaveCompanyChanges(true);
      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.companyInfoArea = false;
      setSaveStatus( newCompanyInfoState );
      setWaitingRoleSave(true);
    }
  }

  const saveRoleSecond = ()=> {
    if(saveStatus.role === true){
      setSaveRoleChanges(true);
      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.role = false;
      setSaveStatus( newCompanyInfoState );
      setWaitingRoleCriteriaSave(true);
    }
  }

  const saveRoleCriteriaThird = ()=> {
    if(saveStatus.roleCriteria === true){
      setSaveRoleCriteriaChanges(true);
      const newCompanyInfoState = {...saveStatus};
      newCompanyInfoState.roleCriteria = false;
      setSaveStatus( newCompanyInfoState );
    }
  }

  const showChildrenMessage = (newMessageToInclude)=>{
    const newMessage = {...message};
    const newMessageList = [...newMessage.message];
    
    if(newMessageList.length > 0){
      newMessageList.push(' | ');
    }
  
    newMessageList.push(newMessageToInclude.message);
    if(newMessageToInclude.type === "error"){
      newMessage.type = "error";
    }else{
      newMessage.type = "message";
    }

    newMessage.message = newMessageList;
    setMessage(newMessage);
    setShowMessage(true);

    //Check if new company was saved, if so trigger saving role
    if(waitingRoleSave === true){
      setWaitingRoleSave(false)
      saveRoleSecond();
    }

    //Check if new role was saved, if so trigger saving role options
    if(waitingRoleCriteriaSave === true){
      setWaitingRoleCriteriaSave(false);
      saveRoleCriteriaThird();
    }
  }


  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show)
        setMessage({type: 'message', message: []})
        // setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  const setGuidedOptionOnOff = (state)=>{
    setGuidedOption(state);
    ctx.User.isAddGuideOpen = state;
    setContext(ctx);
  }

  return (
        <div className={styles.mainCompanySetupArea}>
          {/* <div className={styles.pageIndex} ><span className={styles.pageIndexSpan} >1</span><span className={styles.pageIndexSpanV} >|</span>3</div> */}
          <div className={styles.switchButtonArea}>
            <span >Add new company and role guidance is {guidedOption ? "On" : "Off"}</span>
            <div className={styles.switchButton} >
              <Switch isSwitchOn={guidedOption} switchToggleHandler={() => setGuidedOptionOnOff(!guidedOption)} switchOnColor={"#895bf240"} />
            </div>
          </div>
          <div className={styles.addNewCompanyButton}>            
            <div className={styles.addNewRoleLabel} onClick={(e) => {addNewCompany()}} >Add new company</div>
            <img className={openCloseIconUp} onClick={setCompanyInterVisibleOnOff} alt="" src="/open-close-icon.svg" />
          </div>
          <div className={companyInfoInterIsVisible}>
            <CompanyInfoArea createnewcompany={newCompany} setPageCompanyId={setCompanySelectedId} setNewStateToSave={setNewStateToBeSavedMessage} saveAllChanges={saveCompanyChanges} setSaveAllChanges={setSaveCompanyChanges}  showMessage={showChildrenMessage} />
          </div>
          <div className={styles.addNewRoleButton}>
            <div className={styles.addNewRoleLabel} onClick={(e) => {addNewRole()}}> Add new role </div>
            <img className={openCloseRoleIconUp} onClick={setCompanyRoleVisibleOnOff} alt="" src="/open-close-icon.svg" />
          </div>
          <div className={companyRoleIsVisible}>
            <Role createnewrole={newRole} pagecompanyid={companySelectedId} setPageroleId={setRoleSelectedId} setNewStateToSave={setNewStateToBeSavedMessage} saveAllChanges={saveRoleChanges} setSaveAllChanges={setSaveRoleChanges} showMessage={showChildrenMessage} />
          </div>
          <div className={styles.roleCriteriaButton} >
            <img className={openCloseRoleCriteriaIconUp} onClick={setCompanyRoleCriteriaVisibleOnOff} alt="" src="/open-close-icon.svg" />
          </div>
          <div className={companyRoleCriteriaIsVisible} >
            <RoleCriteria createnewrole={newRole} pagecompanyid={companySelectedId} pageroleid={roleSelectedId} setNewStateToSave={setNewStateToBeSavedMessage} saveAllChanges={saveRoleCriteriaChanges} setSaveAllChanges={setSaveRoleCriteriaChanges}  showMessage={showChildrenMessage} />
          </div>
          <div className={styles.addNewResumeButton}>
            {parameters.rid > 0 ? <div className={styles.addNewResumeLabel} onClick={() => {openModal()}} >Upload</div> : <></> }
          </div>
          <div className={styles.resumelistcomponent}> 
            <ResumeList openUploadModal={modalVisibility} uploadModalOpen={uploadModalOpen} showMessage={showChildrenMessage} />
          </div>

          {
            newStateToBeSaved ? 
            <div className={message.message.length > 0 ? styles.saveAllWitMessage : styles.saveAll} onClick={sendSaveMessage} >
              <div className={styles.saveAllArea}>
                <div><img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2_white.svg" /></div>
                <div className={styles.saveAllText}>Save all changes</div>
              </div>
            </div>
            : <></>
          }

          {
            showMessage ?
            <div className={message.type === "message" ? styles.childrenMessagesArea : styles.childrenErrorMessagesArea} >  
              <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
              <div className={styles.childrenMessages} > {message.message} </div>
            </div>
            : <></>
          }

        </div>
        
  );
};

export default CompanySetup;
