import axios from "axios";
import ContextManager from "./Context/ContextManager";

// Errors captured when sending API requests
const ErrorManager = (errorMessage, fromLocation, errorLevel) => {
    //TODO: Send the error to the log server

    const logToConsole = false

    fromLocation = fromLocation === "" ? "Not Reported": fromLocation
    
    const logToServer = async(errorToPost) => {
        // await customAxios.post(URL , postData)
        await axios.post("/log-client-errors", errorToPost)
        .then(res => {
            return true;
        })
        .catch(error => {
          console.error("Error log found: " + error)
          return false
        })
    }

    let errorToLog = ""

    if(errorLevel >= 3 ){
        if(errorMessage.response){
            const err_data = 'API_Rep_Err_Data - From: '  + fromLocation + "\f\n " + errorMessage.response.data;
            const err_status = 'API_Rep_Err_Status - From: '  + fromLocation + "\\n " + errorMessage.response.status
            const err_headers = 'API_Rep_Err_Headers - From: '  + fromLocation + "\\n " + errorMessage.response.headers
            
            errorToLog = err_data + "\\n " + err_status + "\\n " + err_headers

        }else if(errorMessage.request){
            // No response by API
            const api_error_message = 'Comm_Err_Message - From: '  + fromLocation + "\\n " + errorMessage.message
            const api_error_code = 'Comm_Err_Code - From: '  + fromLocation + "\\n " + errorMessage.code
            const api_error_request = 'Comm_Err_Request - From: '  + fromLocation + "\\n " + errorMessage.request
            
            errorToLog = api_error_message + "\\n " + api_error_code + "\\n " + api_error_request;

        }else{
            // Something else triggered the error
            errorToLog = 'General_Error - From: ' + fromLocation + "\\n " + errorMessage.message
        }

        errorToLog += "\n " + 'Err_Config - From: '  + fromLocation + "\\n " + errorMessage.config

        const errorIsLogged = logToServer({message: errorToLog}) ? false : true;

        if(logToConsole || errorIsLogged){
            console.log(errorToLog)
        }

    }else if(errorLevel > 1){
        errorToLog = 'General_Info_Trace - From: ' + fromLocation + "-" + errorMessage;
    }
}

export default ErrorManager