import styles from "./TopGlobalDotPopupMenu.module.css";
import { useNavigate} from "react-router-dom";

const TopGlobalDotPopupMenu = (props) => {


    const navigate = useNavigate();

    const gotToProfile = () =>{

        navigate("/userprofile", {state:{from: "nav"}});

    }

    const gotToSignout = () =>{

        navigate("/signout", {state:{from: "nav"}});

    }

    const goToLegal = () =>{
      props.closePopUp();
      navigate("/legal", {state:{from: "nav"}});

    }

    const gotToFaqs = () =>{
      props.closePopUp();
      navigate("/faqs", {state:{from: "nav"}});

    }

    const showFeedbackForm = ()=>{
      props.closePopUp();
      props.showDotMenu();
    }

    const goToPlans = ()=>{
      props.closePopUp();
      navigate("/plans", {state:{from: "nav"}});
    }

    return (
    <div className={styles.topcontainer}>
      <div className={styles.linkArea} onClick={showFeedbackForm}>
        <div className={styles.iconItem}><img height={"28px"} className={styles.topGlobalIcon} src="/Feedback_Icon_Simple.svg"/></div>
        <div className={styles.linkItem}>Feedback</div>
      </div>
      <div className={styles.linkArea} onClick={gotToFaqs} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/FAQs_2_64x64_Path_Simple.svg"/></div>
        <div className={styles.linkItem}>FAQs</div>
      </div>
      <div className={styles.linkArea} onClick={goToLegal} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/Legal_Icon_64x64.svg"/></div>
        <div className={styles.linkItem}>Legal</div>
      </div>
      <div className={styles.linkArea} onClick={goToPlans} >
        <div className={styles.iconItem}><img height={"25px"} className={styles.topGlobalIcon} src="/Upgrade_Logo.svg"/></div>
        <div className={styles.linkItem}>Update Plan</div>
      </div>
    </div>
  );
};

export default TopGlobalDotPopupMenu;