import styles from "./__BaseTemplate.module.css";
import LeftNavBar from '../components/navbars/SideGlobalNavArea'
import TopGlobalNavArea from "../components/navbars/TopGlobalNavArea";
import JobsWidget from '../components/navbars/JobsWidget'
import ContextManager from "../common/Context/ContextManager";
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useRef, useState, useEffect} from 'react';

const __BaseTemplate = () => {

  const {state} = useLocation();
  const htmlContainerElement = useRef();

  const pageTitles = [
    {PageName: "companysetup", Title: "Company & Role Setup"},
    {PageName: "reviewdetail", Title: "Review | Candidate Detail"}, 
    {PageName: "resumecompare", Title: "Compare Candidates"}, 
    {PageName: "resumechat", Title: "Candidate & Role Chat"},
    {PageName: "userprofile", Title: "User Profile"}
  ];


  const [pageTitle, setPageTitle] = useState('')
  const [currentWidth, setCurrentWidth] = useState(0);
  const navigate = useNavigate();
  const { ctx, setContext } = ContextManager()

  const [isSideNavBarOpen, setIsSideNavBarOpen] = useState(true);

  useEffect( () => {

    // window.location.pathname.includes("resumechat")
    pageTitles.map( (key) => {
        if(window.location.pathname.toLocaleLowerCase().includes(key.PageName)){
          setPageTitle(key.Title)
        }
      } 
    );

    return () => {
    }
    }, [state] 
  )


  let movement_timer = null;
  const SET_EXPERIENCE_RESIZE_TIMEOUT = 100;
  window.addEventListener('resize', ()=>{
    clearInterval(movement_timer);
    movement_timer = setTimeout(captureHtmlContainerSize, SET_EXPERIENCE_RESIZE_TIMEOUT);
  });

  const MIN_SCREEN_LEFT_NAV_BAR_OPEN = 600
  const captureHtmlContainerSize = () =>{
    if(htmlContainerElement.current){
      // setWidth(htmlContainerElement.current.offsetWidth);
      const newWidth = htmlContainerElement.current.offsetWidth
      if(ctx.User.isSideNavBarOpen){
        // setIsSideNavBarOpen(true);
        setCurrentWidth(newWidth);
      }
    }
  }

  const setLeftNavBarOpen = ()=>{
    const newWidth = currentWidth < 550 ? 570 : 549;
    // if(isSideNavBarOpen){
      setCurrentWidth(newWidth);
    // }
  }

  const setNavBarOpenClose = () => {
    const isOpen = isSideNavBarOpen === true ? false : true
    ctx.User.isSideNavBarOpen = isOpen;
    setContext(ctx);
    setIsSideNavBarOpen(isOpen);
    setLeftNavBarOpen();
  }

  const goToFocusedView = () =>{
    navigate("/topcandidates", {state:{from: "nav"}});
  }

  return (
    <div className={currentWidth > 550 ? styles.templateContainer : styles.templateContainerClosed} onLoad={captureHtmlContainerSize} ref={htmlContainerElement}>
      <TopGlobalNavArea closedNavBar={currentWidth} setNavBarOpenOrClosed={setNavBarOpenClose} />
      <div className={styles.LeftNavBarOpener} onClick={()=> setNavBarOpenClose()} ><img alt="Open/Close" src="/accordion_menu.svg" /></div>
      {/* <div className={styles.LeftNavBar} > */}
        <LeftNavBar closedNavBar={currentWidth} isSideNavBarOpen={isSideNavBarOpen} />
      {/* </div> */}
      <div className={styles.pageTitle}> {pageTitle} </div>
      <div className={styles.contentArea}>
        <div className={styles.companiesWidgetArea}>
          <JobsWidget />
        </div>
        {/* Main Content */}
        <Outlet />
      </div>
    </div>
  );
};

export default __BaseTemplate;
