import styles from "./JobsWidget.module.css";
import { useState, useEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import ResumeList from "../companysetup/ResumesList"



const JobsWidget = (props) => {

  // const [message, setMessage] = useState('')
  const [userId, setUserId] = useState(0)
  const [loadComponent, setLoadComponent] = useState(true)
  const [companyList, setCompanyList] = useState([])
  const [showRoleList, setShowRoleList] = useState([])
  const [showPanelStyle, setShowPanelStyle] = useState(styles.jobsWidget)
  const [isPanelOpen, setIsPanelOpen] = useState(true)
  const [getNewResumeList, setGetNewResumeList] = useState({})
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const urlParameters = useParams(props);
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();
  let firstLoad = true

  let today = new Date()
  let c = 0

  useEffect( () => {
    if(ctx.User.isHelperOpen === true){
      setIsPanelOpen(true);
    }else{
      setIsPanelOpen(false);
    }
    
    return () => {

    }
  }, [state]
  );

  // Retrieve data first and populate any state
  useEffect( () => {
      setUserId(ctx.User.id)
      let refresh = false
      if(state !== null && state !== undefined){
        refresh = state.newRole === true || state.newCo === true ? true : false
      }

      if((loadComponent && firstLoad) || refresh){
        firstLoad = false //Prevents another initial request and reload within the same request
        setLoadComponent(false)
        // if(companyList.length === 0){
          fetchData(true)
        // }
      }
      return () => {

      }
    }, [state]
  );

  const refreshSelectionClick = (newSelectedcid, newSelectedrid) => {
    ctx.Company.id = newSelectedcid
    ctx.Role.id = newSelectedrid
    setContext(ctx)
    // {'/companysetup/' + company.id + '/' + role.id} state={{roleSel: true}}

    if(window.location.pathname.includes("companysetup")){
      navigate('/companysetup/' + newSelectedcid + '/' + newSelectedrid, {state:{roleSel: true}, replace: true});
    }else{
      setGetNewResumeList({roleSel: true, cid: newSelectedcid, rid: newSelectedrid})
    }
  }

  const setInitialData = (responseData)  => {
    const {company_list, last_updated} = responseData

    if(urlParameters.id > 0){
      checkIfRolesWereRetrieved(urlParameters.id, company_list)
    }else
    {
      setCompanyList(company_list);
    }
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_HOST_DOMAIN_PORT

  // Get data using axios
  const fetchData = async(processing) => {
    // Change to support proxy
    // let URL = COMAIN_PORT +  process.env.REACT_APP_COMPANY_LIST_API
    let URL = process.env.REACT_APP_COMPANY_LIST_API
    if(processing === true){
      // await axios.get(URL, {
      // headers: {
      //   'Authorization': 'Bearer '  + process.env.REACT_APP_TO_API_TOKEN
      // }})
      // .then(res => {
      //     if(processing === true){
      //       setInitialData(res.data)
      //     }            
      // })
      await customAxios.get(URL)
        .then(res => {
            if(processing === true){
              setInitialData(res.data)
            }            
        })
      .catch(error => {
        processing = false
        // errorsMessages(error)
        MessageManager(error, "JobsWidget", ctx.Config.Error.getLevels.error)
      })
    }
  }

   // Get data using axios
   const fetchRoles = async(companyId, companyListToUpdate) => {
    // Change to support proxy
    // let URL = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_LIST_API
    let URL = process.env.REACT_APP_COMPANY_ROLE_LIST_API
    URL += "/" + companyId

    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer '  + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
          processRoleListData(companyId, res.data, companyListToUpdate)
    })
    .catch(error => {
      MessageManager(error, "JobsWidget", ctx.Config.Error.getLevels.error)
    })
  }

  // Used for new page rendering, new company or role created, and open/close list
  const checkIfRolesWereRetrieved = (companyId, companyListToUpdate) =>{
    let rolesFound = false
    companyListToUpdate.find((s) => {
      const found = (Number(s.id) === Number(companyId))
        if(found === true){
          rolesFound = s.company_role_list !== undefined && s.company_role_list.length > 0 ? true : false
        }
      //For this to work do not include a return
    })

    if(!rolesFound){
      getRolesForCompany(companyId, companyListToUpdate)    
    }else{
      setIdsForRoleDisplay(companyId)
    }

    if(window.location.pathname.includes("companysetup") && !rolesFound){
      ctx.Company.id = companyId
      // ctx.Role.id = 0
      setContext(ctx)
      // navigate('/companysetup/', {state:{roleSel: false, newRole: false}, replace: true});
      // navigate('/companysetup/' + companyId, {state:{roleSel: false}});
      // navigate('/companysetup/' + companyId + '/' + ctx.Role.id , {state:{roleSel: true}, replace: true});

    }
  }

  const setIdsForRoleDisplay = (companyId) => {
    
    let roleListToDisplay = []

    if(showRoleList.includes(companyId)){ 
      roleListToDisplay = showRoleList.filter(s => s !==  companyId)
    }else{
      roleListToDisplay = [...showRoleList, companyId]
    }

    setShowRoleList(roleListToDisplay)
  }

  const getRolesForCompany = (id, companyListToUpdate) =>{
    fetchRoles(id, companyListToUpdate)
  }

  const processRoleListData = (companyId, roleListData, companyListToUpdate) => {
    const {company_role_list, last_updated} = roleListData
    setRoleData(companyId, company_role_list, companyListToUpdate)
  }

  const setRoleData = (companyId, roleList, companyListToUpdate) =>{
    try{
      const listToUpdate = [...companyListToUpdate]
      listToUpdate.find((s) => {
        if(Number(s.id) === Number(companyId)){
          s.company_role_list = roleList
        }
        //For this to work do not include a return      
      })

      setCompanyList(listToUpdate)
      setIdsForRoleDisplay(companyId)
      // setCompanyList(listToUpdate)
    }catch (error){
      MessageManager(error, "JobsWidget", ctx.Config.Error.getLevels.error)
    }
  }

  const openClosePanel = () => {

    const isOpen = isPanelOpen === true ? false : true
    setIsPanelOpen(isOpen);
    ctx.User.isHelperOpen = isOpen;
    setContext(ctx);

  }

  const openModal = () =>{
      if(isModalVisible === false){
        setModalVisibility(true)
        setIsModalVisible(true)
      }
  }

  const uploadModalOpen = (status) => {
    setModalVisibility(false)
    setIsModalVisible(false)  
  }

  const newCompanySetup = ()=> {
    ctx.Company.id = 0
    ctx.Role.id = 0
    setContext(ctx)
    navigate('/companysetup/', {state:{roleSel: false, newRole: false}, replace: true});
  }

  const newRoleSetup = ()=> {
    ctx.Role.id = 0
    setContext(ctx)
    navigate('/companysetup/' + ctx.Company.id, {state:{roleSel: false, newRole: false}, replace: true});
  }

  const openCloseIconButton = () =>{
    let iconStyle = "isOn" === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    // setOpenCloseIconUp(iconStyle)   
  }

  if(companyList.length > 0){
    return (
    <div id="panel" className={showPanelStyle}>
      <div className={ isPanelOpen ? styles.companyJobsWidget : styles.companyJobsWidgetClosed}>
        <div className={styles.companiesLabel} >Companies <span onClick={() => newCompanySetup()} className={styles.addSymbol} >&#43;</span> | Roles {urlParameters.id > 0 ? <span onClick={() => newRoleSetup()} className={styles.addSymbol} >&#43;</span>:<></>}</div>
        <div className={styles.companyWidget}>
 { companyList.length > 0 ? companyList.map( company => 
          <div key={company.id} className={styles.companyListPosition}> {/*<--- companyListPosition */}
            {/* Company display */}
            <div className={ctx.Company.id === company.id ? styles.companyBubbleSelected :styles.companyBubble} onClick={ () => checkIfRolesWereRetrieved(company.id, companyList) }>
              <div className={styles.companyName}  >{company.name}</div>
              {/* <div className={styles.companyName} >{company.name}</div> */}
              <div className={styles.openCloseRoleIcon} >
                <img
                  className={showRoleList.includes(company.id) ? styles.openCloseIconUp : styles.openCloseIcon}
                  alt=""
                  src="/open-close-icon.svg"
                />
              </div>  
            </div>
            {/* Company display */}
    { company.company_role_list!== undefined & showRoleList.includes(company.id) ? company.company_role_list.map( role => 
            <div key={role.id} className={styles.jobPositionList}>
              {/* First role display */}
              <div className={ctx.Role.id === role.id ? styles.rolePositionSelected : styles.rolePosition}>
                <div>
                  <div onClick={()=> {refreshSelectionClick(company.id,role.id)} } className={styles.roleNamePosition}>{role.name}</div>
                </div>
                <div className={ctx.Role.id === role.id ? styles.selectIndicator :styles.nonSelected} />
              </div>
              {/* First role display */}
            </div>
      ):<></>
    }
            <div className={styles.bottomPading} ></div>
          </div>
    ) :   <div className={styles.rolePositionSelected} ><span className={styles.companyName}>No company setups found</span></div>
  } 
        </div>

        <div className={styles.resumeList}>
          <div className={styles.candidatesLabel} >Candidates {urlParameters.rid > 0 ? <span onClick={() => openModal()} className={styles.addSymbol} >&#43;</span>:<></>}</div>
          <ResumeList list={getNewResumeList}  openUploadModal={modalVisibility} uploadModalOpen={uploadModalOpen} inContainer={true} />
        </div>
        <div className={styles.openCloseJobPositionsArea} onClick={openClosePanel} >
          <img className={isPanelOpen ? styles.openCloseJobPositionsIcon : styles.openCloseJobPositionsIconClosed} src="/open-close-job-positions4.svg"  />
        </div>
      </div>

    </div>
  );}
};

export default JobsWidget;
