import styles from "./ResumesList.module.css";
import { useRef, useState, useEffect } from 'react';
// import axios from "axios";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import UploadResume from "../resume/UploadResume";
import ProcessStatusDisplayNames from "../../common/Status/ProcessStatusDisplayNames"

const ResumesList = (props) => {

  const [userId, setUserId] = useState(0);
  const [loadComponent, setLoadComponent] = useState(true);
  const [resumeList, setResumeList] = useState([]);
  const [tempResumeList, setTempResumeList] = useState([]);
  const [resumeListToProcess, setResumeListToProcess] = useState([]);
  const [message, setMessage] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [message, setMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [fromDeleteAction, setFromDeleteAction] = useState(false);
  const [width, setWidth] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [processedResumeColumnList, setProcessedResumeColumnList] = useState([]);
  const [isReviewButtonActive, setIsReviewButtonActive] = useState(false);
  const [isCompareButtonActive, setIsCompareButtonActive] = useState(false);
  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [resumeListSizeLeft, setResumeListSizeLeft] = useState(0);
  const [featureUsage, setFeatureUsage] = useState({});
  const [isAnalysisInProgress, setIsAnalysisInProgress] = useState(false);
  const [myPreferedList, setMyPreferedList] = useState("[]");
  const [processStatus, setProcessStatus] = useState(-1);
  const [queueResumeList, setQueueResumeList] = useState([]);
  const [isItemOnQueue, setIsItemOnQueue] = useState(false);
  const [isCheck, setIsCheck] = useState([])
  const [refreshOnSchedule, setRefreshOnSchedule] = useState([]);

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();
  const htmlContainerElement = useRef();
  let firstLoad = true
  let today = new Date()

  let fitAssessment = ["-", "NaF", "PoF", "GdF", "GtF"]
  // resume.fit_score > 6? fit_assessment[3] : resume.fit_score > 3 ?  resume.fit_score[2] : resume.fit_score > 0 ? resume.fit_score[1] : resume.fit_score[0]
  let fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus, styles.inProgresstatus]
  const resumeListColumns = { name: 0,
                              fit_assessment: 0,
                              uploaded_date: 0,
                              fit_score: 0,
                              process_status: 0
                            };

const [sortArrow, setSortArrow] = useState(resumeListColumns);

  let movement_timer = null;
  const SET_EXPERIENCE_RESIZE_TIMEOUT = 100;
  let initialSize = 800
  const dateColValueMinSize = 390
  const allColTitlesMinSize = 460
  const mar = currentWidth < dateColValueMinSize ? 25 : 0
  const refreshTime = 6500 //in ms

  window.addEventListener('resize', ()=>{
    clearInterval(movement_timer);
    movement_timer = setTimeout(captureHtmlContainerSize, SET_EXPERIENCE_RESIZE_TIMEOUT);
  });

  const captureHtmlContainerSize = () =>{
    if(htmlContainerElement.current){
      setWidth(htmlContainerElement.current.offsetWidth);
      setHeight(htmlContainerElement.current.offsetHeight);
      initialSize = (htmlContainerElement.current.offsetWidth / processedResumeColumnList.length) - 10
      setWidth(initialSize)
      setCurrentWidth(htmlContainerElement.current.offsetWidth)
    }
  }

  let refreshTimeoutId = 0;
  const TriggerInprocessResumesCheck = (timeoutId, testTimes)=>{

    clearTimeout(timeoutId);

    if(Number(testTimes) > Number(process.env.REACT_APP_INPROGRESS_REFRESHS)){
      //Times is greater than 10. Finishing
      //Path left in place for furture functionality.
      setIsAnalysisInProgress(false);
    }else{
      if(isModalVisible === false){
        setIsAnalysisInProgress(false);
        fetchInProcessData(0,0, testTimes);
      }
    }
  }

  const InprocessResumesCheck = (responseData, testTimes)  => {
    const {id, company_role_resume_list, queue_resume_list, last_updated} = responseData

    setIsItemOnQueue(false);
    setQueueResumeList(queue_resume_list === undefined || queue_resume_list === null ? [] : queue_resume_list);

    if(company_role_resume_list !== undefined && company_role_resume_list !== null){
      
      let foundInProgress = false;

      if(isModalVisible === false){
        {
          let found = queue_resume_list.length > 0 ? true : false;
          if(found){
            refreshTimeoutId = setTimeout(updateListAfterStatusUpdate, refreshTime);
          }

          setResumeList(company_role_resume_list ? company_role_resume_list : []);
          setTempResumeList(company_role_resume_list ? company_role_resume_list : []);
          setIsAnalysisInProgress(false);
        }
      }
    }
    return true;
  }

  useEffect( () => {
    window.scrollTo(0, 0);
    if((urlParameters.id === 0 || urlParameters.rid === 0) || (ctx.Company.id === 0 || ctx.Role.id === 0)){
      setResumeList([]);
    }

    fetchFeatureUsageData();
    return () => {
    }
    }, []
  );

  // Retrieve data first and populate any state
  let refreshList = 0;
  let interval = 0;
  useEffect( () => {
      setUserId(ctx.User.id)
      let refresh = false

      if(state !== null && state !== undefined){
        if(state.roleSel === true || state.newResume === true) {
          refresh = true
        };
      }

      if((loadComponent && firstLoad) || refresh){
        firstLoad = false //Prevents another initial request and reload within the same request
        setLoadComponent(false)

        if(urlParameters.id > 0 && urlParameters.rid > 0){
          fetchData(0, 0);
        }else if(ctx.Company.id > 0 && ctx.Role.id > 0){
          fetchData(ctx.Company.id, ctx.Role.id);
        }else{
          setResumeList([]);
          setTempResumeList([]);
        }
      }else if((urlParameters.id === 0 || urlParameters.rid === 0) || (ctx.Company.id === 0 || ctx.Role.id === 0)){
        setResumeList([]);
      }

      return () => {
        clearInterval(interval);
      }
    }, [state]
  );

  useEffect( () => {
    
    if(props.openUploadModal === true && isModalVisible === false){
        
        setIsModalVisible(true)
        setModalVisibility(true)
    }else{
      setIsModalVisible(false)
      setModalVisibility(false)
    }

    if(props.list?.roleSel === true){
      fetchData(props.list.cid, props.list.rid);
    }

    return () => {
    }
    }, [props]
  );

  let refreshTimeoutIdAnalysis = 0;
  let testTimes = 0;

  const setFeatureUsageResponse = (responseData)  => {
    setFeatureUsage(responseData);
  }

const fetchFeatureUsageData = async() => {

    let URL_PATH = process.env.REACT_APP_RESUME_FEATURES_USAGE_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setFeatureUsageResponse(res.data)
    })
    .catch(error => {
        errorsMessages(error)
        MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
};

  const setInitialData = (responseData)  => {
    const {id, company_role_resume_list, queue_resume_list, last_updated} = responseData
    setIsRequestInProgress(false);

    setShowMessage(false);

    setIsItemOnQueue(false);

    if(fromDeleteAction === true){
      setShowDeleteMessage(false);
      setIsCheck([]);
      setResumeListToProcess([]);
      setFromDeleteAction(false);
      setButtonsSelectionState([]);
    }

    // setResumeListToProcess([])

    loadMyListData();

    // Process and check for In-progress analysis
    InprocessResumesCheck(responseData, 0);

  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_COMPANY_PORT

  const fetchInProcessData = async(cid, rid, testTimes) => {
    let URL = process.env.REACT_APP_COMPANY_ROLE_RESUME_LIST_API
    if(cid > 0 && rid > 0){
      URL += "/" + cid + "/" + rid
    }else if(urlParameters.id > 0 && urlParameters.rid > 0){
      URL += "/" + urlParameters.id + "/" + urlParameters.rid
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0){
      URL += "/" + ctx.Company.id + "/" + ctx.Role.id
    }

    await customAxios.get(URL)
    .then(res => {
      InprocessResumesCheck(res.data, testTimes);
    })
    .catch(error => {
      MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error);
    })
  }

  const fetchData = async(cid, rid) => {
    setIsItemOnQueue(false);
    let URL = process.env.REACT_APP_COMPANY_ROLE_RESUME_LIST_API
    // URL += "/" + ctx.Company.id + "/" + ctx.Role.id
    if(cid > 0 && rid > 0){
      URL += "/" + cid + "/" + rid
    }else{
      URL += "/" + urlParameters.id + "/" + urlParameters.rid
    }

    await customAxios.get(URL)
    .then(res => {
        setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
    })
  }

  const postData = async(urlPath) => {

    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + ctx.Company.id + "/" + ctx.Role.id
    const postData = resumeListToProcess

    await customAxios.post(URL , postData)
    .then(res => {
      setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
    })
  };

  const setResumeSelection = (e) =>{
    let updatedResumeListToProcess = [];
    let newUnCheck = [];

    if(resumeListToProcess.includes(e.target.id)){ 
      updatedResumeListToProcess = resumeListToProcess.filter(s => s !==  e.target.id);
      newUnCheck = [...isCheck];
      newUnCheck[e.target.id] = false;
    }else{
      updatedResumeListToProcess = [...resumeListToProcess, e.target.id];
      newUnCheck = [...isCheck];
      newUnCheck[e.target.id] = true;
    }
    
    setIsCheck(newUnCheck);
    setResumeListToProcess(updatedResumeListToProcess)
    setButtonsSelectionState(updatedResumeListToProcess)

  }

  const setButtonsSelectionState = (updatedResumeListToProcess) =>{
    setIsReviewButtonActive(updatedResumeListToProcess.length > 0? true: false)
    setIsCompareButtonActive(updatedResumeListToProcess.length > 1? true: false)
    setIsDeleteButtonActive(updatedResumeListToProcess.length > 0? true: false)
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    // let msg = "An error was found. Please check the console for more information"
    let msg = "Candidate information cannot be processed or retrieved."
    // displayMessage(error.response !== undefined ? error.response.data.detail : msg);
    displayMessage(msg);
  }

  const goToReviewDetail = (rsid)=>{
    if(window.location.pathname.includes("resumechat")){
      navigate("/resumechat/" + ctx.Company.id + "/" + ctx.Role.id + "/" + rsid +"?#", {state: {newResume:true, newRole:true}});
    }else{
      navigate("/reviewdetail/" + ctx.Company.id + "/" + ctx.Role.id + "/" + rsid +"?#", {state: {newResume:true}});
    }
  }

  const analyzeSelectedResumes = (e) => {
    
    let proceed = false;

    // fetchFeatureUsageData();

    if(resumeListToProcess.length === 0){
      displayMessage("No candidate's resumes have been selected to review.")
    }
    else if(Number(ctx.Resume.reviews) === -1){
      proceed = true;
    }
    else if(Number(ctx.Resume.reviews) !== -1){
      if(Number(ctx.Resume.reviews) <= Number(featureUsage.reviews)){
        displayMessage("Limit reached. Your plan only allows " + ctx.Resume.reviews + " analysis.")
      }else if(resumeListToProcess.length > Number(ctx.Resume.reviews) ){
        displayMessage("Analysis selection(s) is above plan Limit or above analysis left. Your plan limit is " + ctx.Resume.reviews + ". You have " + Number(Number(ctx.Resume.reviews) - Number(featureUsage.reviews)) + " analysis left. Remove from list.")
      }else if((resumeListToProcess.length + Number(featureUsage.reviews)) > Number(ctx.Resume.reviews) ){
        displayMessage("Plan limit will be reached. Your plan limit is " + ctx.Resume.reviews + ". You have " + Number(Number(ctx.Resume.reviews) - Number(featureUsage.reviews))  + " analysis left. Remove from list.")
      }else{
        proceed = true;
      }
    }else{
      //Nothing
    }

    if(proceed){
      setIsRequestInProgress(true);
      postData(process.env.REACT_APP_RESUME_REVIEWS_API)
    }

    return true;
  }

  const compareSelectedResumes = (e) => {

    let proceed = false;

    fetchFeatureUsageData();

    if(resumeListToProcess.length === 0){
      displayMessage("No candidates have been selected to compare.")
    }
    else if(resumeListToProcess.length < 2){
      displayMessage("At least 2 candidates need to be selected")
    }
    else if(resumeListToProcess.length > 3){
      displayMessage("Compare upto 3 candidates at the same time. Remove from list." )
    }else if(resumeListToProcess.length <= 3){
      if(Number(ctx.Resume.compares) === -1){
        proceed = true;
      }
      else if(Number(ctx.Resume.compares) !== -1){
        if(Number(ctx.Resume.compares) <= Number(featureUsage.compares)){
          displayMessage("Limit reached. Your plan limit is " + ctx.Resume.compares + " compares. You have " + Number(Number(ctx.Resume.compares) - Number(featureUsage.compares))  + " analysis left");
        // }else if(resumeListToProcess.length > Number(ctx.Resume.compares)){
        }else if(resumeListToProcess.length > 3){
          displayMessage("Limit reached. Your plan limit is " + ctx.Resume.compares + " compares. You have " + Number(Number(ctx.Resume.compares) - Number(featureUsage.compares))  + " analysis left")
        }
        else{
          proceed = true;
        }
      }
    }
    {
      let isNotProcessed = false;
      // const localResumeList = [...resumeList];
      const localResumeListToProcess = [...resumeListToProcess];
      localResumeListToProcess.map((item, index, array)=>{
        // const itemToCheck = tempResumeList.find((itemTofind)=>{ return itemTofind.id === item;});
        const itemToCheck = tempResumeList.filter((itemTofind) => { return Number(itemTofind.id) === Number(item);});

        if(itemToCheck !== undefined && itemToCheck !== null){
          if(itemToCheck[0].name === process.env.REACT_APP_INPROGRESS_RESUME || itemToCheck[0].fit_assessment === -1 || itemToCheck[0].fit_score === -1){
            proceed = false;
            isNotProcessed = true;
          }
        }
      });

      if(isNotProcessed === true){
        displayMessage("In-Process candidates cannot be compared." );
      }
    }

    if(proceed){
      const company = urlParameters.id > 0 ? urlParameters.id : ctx.Company.id;
      const role = urlParameters.rid > 0 ? urlParameters.rid : ctx.Role.id;
      navigate("/resumecompare/" + company + "/" + role, {state: {compare: true, compareIds: resumeListToProcess}, replace: true});
    }

  }

  const deleteSelectedResumes = (e) => {
    
    if(resumeListToProcess.length < 1){
      displayMessage("No candidate's resumes have been selected to delete.")
    }
    else{
      setFromDeleteAction(true)
      setShowDeleteMessage(false)
      postData(process.env.REACT_APP_RESUME_DELETE_API)
    }
  }

  const askDeleteQuestion = () =>{
    
    if(resumeListToProcess.length > 0){
      setShowDeleteMessage(true)
    }else{
      displayMessage("No candidate's resumes have been selected to delete.")
    }
  }

  const openModal = () =>{
    if(urlParameters.rid > 0 || ctx.Role.id > 0){
      clearTimeout(refreshTimeoutId);
      setIsRequestInProgress(false);
      setIsModalVisible(true);
      setModalVisibility(true);
    }else{
      displayMessage("No role has been selected to upload candidate's resumes.")
    }
  }

  // This is called from the UploadResume child
  const uploadModalOpenAction = (status) => {
    if(props.uploadModalOpen){
      props.uploadModalOpen(false);
    }
    setIsModalVisible(false);
    setModalVisibility(false);
    setIsRequestInProgress(false);

    if(window.location.pathname.toLowerCase().includes("companysetup") === false){
      TriggerInprocessResumesCheck(0, 0);
    }else{
      TriggerInprocessResumesCheck(0, 0);
      // navigate("/companysetup/" + urlParameters.id + "/" + urlParameters.rid, {state: {newResume: true}});
      navigate("/companysetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume: true, roleSel: true}, replace: true});
      
    }
  }

    const displayMessage = (messageToDisplay) => {
      setIsRequestInProgress(false);
      setShowMessage(true)
      setMessage(messageToDisplay)
    }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
    setShowDeleteMessage(false)
  }

  const searchItems = (searchValue) => {
    // setSearchInput(searchValue)

    if (searchValue !== '' && searchValue.length > 2) {

        let filteredData = []

        const index = fitAssessment.findIndex((item) => {return item.toLowerCase() === searchValue.toLowerCase()});

        if(index > -1){
            const index2 = index - 1;
            filteredData = tempResumeList.filter((item) => {
                return item.fit_assessment === index2;
            });
        }else if(searchValue.indexOf('-') > -1){
            searchValue = searchValue.replaceAll('-', '');
            searchValue =  searchValue.length === 0 || searchValue === '' ? -1 : searchValue;
            filteredData = tempResumeList.filter((item) => {
                return item.fit_score === Number(searchValue);
            });
        }else{
            filteredData = tempResumeList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase());
            })
        }

        setResumeList(filteredData);
    }
    else{
        setResumeList(tempResumeList);
    }
  }


  const sortItems = (fieldToSort) => {
    
    let sorteddData = []

    if(sortArrow[fieldToSort] === -1 || sortArrow[fieldToSort] === 0){
        sorteddData = [...tempResumeList].sort((itemA, itemB) => {
            setFieldSortArrowDirection(fieldToSort, 1);
            return itemA[fieldToSort] > itemB[fieldToSort] ? 1 : -1 ;
        });
    }else{
        sorteddData = [...tempResumeList].sort((itemA, itemB) => {
            setFieldSortArrowDirection(fieldToSort, -1);
            return itemA[fieldToSort] < itemB[fieldToSort] ? 1 : -1 ;
        });
    }

    setResumeList(sorteddData);
  }

  const setFieldSortArrowDirection = (fieldName, direction) =>{
    const newResumeListColumns = resumeListColumns;
    newResumeListColumns[fieldName] = Number(direction);
    setSortArrow(newResumeListColumns);
  }

  const displayFieldArrow = (fieldName)=>{
    return Number(sortArrow[fieldName]) === 1 ? <img alt="" className={styles.arrowIcon} src="/Arrow_Up.svg" />:<img alt="" className={styles.arrowIcon} src="/Arrow_Down.svg" />
  }

  const getDateAsString = (dateToConvert)=>{
    return String((new Date(dateToConvert)).getMonth() + 1 +"/"+(new Date(dateToConvert)).getDate()+"/"+(new Date(dateToConvert)).getFullYear());
  }

  const isIdOnMyPreferedList = (id)=>{
    // const thePreferedList = loadMyListData();
    const found = myPreferedList.includes(String(id));
    return found;
  }
  
  const loadMyListData = () => {
        
    let myNewList = [];
    if(myPreferedList[0] !== undefined && myPreferedList[0] !== null && myPreferedList[0] !== ''){
      const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]" ? ctx.User?.myList : 0;
      if(myListInCtx !== 0){
        const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
        parsedList.forEach((item)=> myNewList.push(item));
        setMyPreferedList(myNewList)
      }else{
        setMyPreferedList([]);
      }
    }else{
      myNewList = [...myPreferedList];
    }
  
    return myNewList;
  }

  const processStatusMenu2 = (cid, rid, rsid)=>{
    return(
        <>
            <div onClick={()=>selectStatus(-1, cid, rid, rsid)}>Ready for Next Step</div>
            <div onClick={()=>selectStatus(1, cid, rid, rsid)}>Reviewed</div>
            <div onClick={()=>selectStatus(2, cid, rid, rsid)}>Shared with Talent Team</div>
            <div onClick={()=>selectStatus(3, cid, rid, rsid)}>Candidate Follow up</div>
            <div onClick={()=>selectStatus(4, cid, rid, rsid)}>Shared with Hiring Lead/Team</div>
            <div onClick={()=>selectStatus(5, cid, rid, rsid)}>Internal Feedback In-Progress</div>            
            <div onClick={()=>selectStatus(6, cid, rid, rsid)}>Initial Call(s) In-Progresss</div>
            <div onClick={()=>selectStatus(7, cid, rid, rsid)}>Interview(s) In-Progress</div>
            <div onClick={()=>selectStatus(8, cid, rid, rsid)}>Offer Extended</div>
            <div onClick={()=>selectStatus(9, cid, rid, rsid)}>Hired</div>
            <div onClick={()=>selectStatus(0, cid, rid, rsid)}>Rejected</div>
        </>
    );
  };

  const processStatusMenu = (cid, rid, rsid, selectedStatus)=>{
    return(
      <select className={styles.processStatusMenu2} id="selectedStatus" name="selectedStatus" value={selectedStatus} onChange={(e)=> {selectStatus(e.target.value, cid, rid, rsid);}}>
        <option value={-1}>Ready for Next Step</option>
        <option value={1}>Reviewed</option>
        <option value={2}>Shared with Talent Team</option>
        <option value={3}>Candidate Follow up</option>
        <option value={4}>Shared with Hiring Lead/Team</option>
        <option value={5}>Internal Feedback In-Progress</option>
        <option value={6}>Initial Call(s) In-Progresss</option>
        <option value={7}>Interview(s) In-Progress</option>
        <option value={8}>Offer Extended</option>
        <option value={9}>Hired</option>
        <option value={0}>Declined</option>
      </select>
    );
  };

  const selectStatus = (selectedStatus, cid, rid, rsid)=>{
    // console.log("Status Selected: " + selectedStatus);
    const url = process.env.REACT_APP_RESUME_PROCESS_STATUS_API  + "/" + cid + "/" + rid;
    setProcessStatus(selectedStatus);

    const dataToPost = {
      id: rsid,
      status: selectedStatus,
    }

    // console.log(dataToPost);
    postDataStatusUpdate(url, [dataToPost]);
  }

  const postDataStatusUpdate = async(urlPath, dataToPost) => {
    let URL = urlPath
    const postData = dataToPost
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      updateListAfterStatusUpdate();
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
    })
  }

  const updateListAfterStatusUpdate = ()=>{
    if(urlParameters.id > 0 && urlParameters.rid > 0){
      fetchData(urlParameters.id, urlParameters.rid);
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0){
      fetchData(ctx.Company.id, ctx.Role.id);
    }
  }

  let analysisCalls = 0;
  const checkIfInQueue = (id) =>{
    id = Number(id);
    let isOnQueue = false;
    const found = queueResumeList.find( ql => Number(ql) === id);

    if(found){
      isOnQueue = true;
    };

    return isOnQueue;
  };

  return (
    <div className={styles.resumesListArea} onLoad={captureHtmlContainerSize} ref={htmlContainerElement}>
        <div >
            <input className={styles.filterInput} icon='search' placeholder='Filter here...' onChange={(e) => searchItems(e.target.value)} />
        </div>
        {
           isAnalysisInProgress ? 
            <div className={styles.analysisInProgres}>
              Analysis In-progress <img className={styles.analysisInProgresImg} alt="" src="/Bi_Color_Progress_Bar.svg" /> 
            </div>
           : <div className={styles.analysisInProgres}><img/></div>
        }
        <div className={styles.titlesArea} >
          { 
            currentWidth > allColTitlesMinSize ?
            <div className={styles.titlesArea} >
              {/* <div className={styles.nameTitle}> */}
                <div className={styles.name} onClick={()=> sortItems("name")} >Name {Number(sortArrow.name) === 0 ? "" : displayFieldArrow("name")}</div>
              {/* </div> */}
              {/* <div className={styles.fitAssessmentTitle}> */}
                <div className={styles.fitAssessment} onClick={()=> sortItems("fit_assessment")} >Fit Assessment {Number(sortArrow.fit_assessment) === 0 ? "" : displayFieldArrow("fit_assessment")}</div>
              {/* </div> */}

              {/* <div className={styles.fitScoreTitle}> */}
              <div className={styles.fitScore} onClick={()=> sortItems("fit_score")} >Fit Score {Number(sortArrow.fit_score) === 0 ? "" : displayFieldArrow("fit_score")}</div>
              {/* </div> */}

              {/* <div className={styles.uploadDateTitle}> */}
                <div className={styles.uploadDate} onClick={()=> sortItems("uploaded_date")} >Upload Date {Number(sortArrow.uploaded_date) === 0 ? "" : displayFieldArrow("uploaded_date")}</div>
              {/* </div> */}

              {/* <div className={styles.processAreaLabel}> */}
                <div className={styles.processArea} onClick={()=> sortItems("process_status")} >Status {Number(sortArrow.process_status) === 0 ? "" : displayFieldArrow("process_status")}</div>
              {/* </div> */}
              {/* <div className={styles.selectTitle}> */}
                <div className={styles.select}>Select</div>
              {/* </div> */}
            </div>
             : <></> 
          }
        </div>

      <div className={styles.resumeList}>

        {/* FIRST ROW */}
        {resumeList?.map( resume =>
          <div key={resume?.id} className={Number(urlParameters?.rsid) === Number(resume?.id) ?  styles.resumesInfoSelected : styles.resumesInfo} >
            <div className={styles.selectIndicator} />
            <div onClick={()=> goToReviewDetail(resume.id)} className={isIdOnMyPreferedList(resume.id) ? styles.candidateNameStar : styles.candidateName}>{ resume.name }</div>

            <div className={fitAssessmentStyle[checkIfInQueue(resume.id) ? 5 : resume.fit_assessment === undefined || resume.fit_assessment === null ? 0 : resume.fit_assessment + 1]}>
              <div className={styles.assessmentLabel}>
                {
                  resume.fit_assessment === undefined || resume.fit_assessment === null || resume.fit_assessment === -1 || checkIfInQueue(resume.id)  ?
                  <img className={styles.analysisInProgresListItemImg} alt="" src="/Bi_Color_Progress_ListItem_Bar.svg" />
                  :
                  fitAssessment[resume.fit_assessment === undefined || resume.fit_assessment === null  ? 0 : resume.fit_assessment + 1]
                }
              </div>
            </div>
            <div className={styles.scoreArea}>
              {resume.fit_score === undefined || resume.fit_score === null || resume.fit_score === -1 ? "-" : resume.fit_score}
            </div>
            {
              currentWidth > dateColValueMinSize ?
            <div className={styles.dateArea}> {getDateAsString(resume.uploaded_date)}</div>
              : <></>
            }
            {
              currentWidth > dateColValueMinSize ?
              <div className={styles.processNameArea} >{processStatusMenu(ctx.Company.id, resume.role_id, resume.id, resume.process_status)}
              </div>
            :<></>
            }
            <div className={styles.selectStatus}>
              <input type="checkbox" id={resume.id} 
                     name={resume.id} 
                     className={styles.checkbox} 
                     value={isCheck[resume.id]}
                     onChange={(e)=> {setResumeSelection(e);}}/>
            </div>
            <div className={styles.bottomPading} ></div>
          </div>
         )
        }{resumeList.length < 1 ? <div className={styles.noResumesInfo} >Select or create a role and upload a resume to display a candidate here.</div>:<><p/> </>}
        {/* FIRST ROW */}
      </div>

      <div className={styles.buttonsAndTitlesArea}>
        <div className={styles.buttonsArea}>

          <div className={isDeleteButtonActive ? styles.deleteButton : styles.deleteButtonInactive} onClick={(e)=> askDeleteQuestion(e)}>
            <div className={styles.deleteIcon}> <img className={styles.hoverColor} alt="Delete" title="Delete" src="/Trash_Logo_button_3.svg" /></div>
            <div className={styles.deleteLabel} >Delete</div>
          </div>

          <div className={isCompareButtonActive ? styles.compareButton : styles.compareButtonInactive} onClick={(e)=> compareSelectedResumes(e)}>
            <div className={styles.compareIcon}> <img className={styles.hoverColor} alt="Compare" title="Compare" src="/compare-icon-button.svg" /></div>
            <div className={styles.compareLabel}  >Compare</div>
          </div>

          <div className={isReviewButtonActive ? styles.reviewButton : styles.reviewButtonInactive} onClick={(e)=> analyzeSelectedResumes(e)}>
            <div className={styles.reviewIcon}> <img className={styles.hoverColor} alt="Analyze" title="Analyze" src="/review-icon-button.svg" /></div>
            <div className={styles.reviewLabel}  >Analyze</div>
          </div>

          <div className={urlParameters.rid > 0 ? styles.uploadButton : styles.uploadButtonInactive} onClick={(e)=> openModal(e)}>
            <div className={styles.uploadIcon}> <img className={styles.hoverColor} alt="Upload" width="48" height="28.8" title="Upload" src="/Upoad_Icon_64x64_Path_Simple.svg" /></div>
            <div className={styles.uploadLabel}  >Upload</div>
          </div>

        </div>

      </div>
      <div><UploadResume openUploadModal={modalVisibility} uploadModalOpen={uploadModalOpenAction} inContainer={props.inContainer} /></div>
      <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
          <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
              <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
              <div>
                {message}
              </div> 
          </div>
      </div>
      {
        showDeleteMessage ?
        <div className={ showDeleteMessage ? styles.messageContainer : styles.hideModal } >
          <div className= {styles.deleteMessageArea}>
            <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
            Are you sure you would like to delete the selected resume(s)<br/>
            Resumes will be permanently deleted.
            <div className={styles.performDeleteButton} onClick={()=> deleteSelectedResumes()} >Delete</div>
          </div>
        </div>
        :<></>
      }
      { isRequestInProgress ?
          <div className={styles.progressContainer} >
              <div className={styles.progressArea} >
                  <div > <img className={styles.progressLogo}  src="/TO_Page_Progress.gif"  /></div>
                  <div>Sending candidate(s) for analysis...</div>
              </div>
          </div>
          :<></>
      }
    </div>
  );
};

export default ResumesList;
