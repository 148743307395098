import axios from '../../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/token', {
            withCredentials: true
        });

        setRefreshTokenData(response);
        return response.data.access_token;
    }

    const setRefreshTokenData = (responseData) =>{
        // Set the token into the Auth hook
        setAuth(prev => {
            // console.log(">>>>>>>>>> Token Refreshed <<<<<<<<<<");
            return { ...prev, access_token: responseData.data.access_token } 
        });
    }

    return refresh;
};

export default useRefreshToken;