import { useContext, useDebugValue } from "react";
import AuthContext from "../Context/AuthProvider";

const useAuth = () => {
    const { auth } = useContext(AuthContext);
    // useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out")
    // console.log(">>>>>>>>>> @useAuth | auth object: " + auth.access_token)
    return useContext(AuthContext);
}

export default useAuth;