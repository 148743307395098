import styles from "./JobsWidgetPage.module.css";
import { useState, useEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../common/hooks/useCustomAxios';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";
import MessageManager from "../common/ErrorManager";



const JobsWidget = (props) => {

  // const [message, setMessage] = useState('')
  const [userId, setUserId] = useState(0)
  const [loadComponent, setLoadComponent] = useState(true)
  const [loadList, setLoadList] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [showRoleList, setShowRoleList] = useState([])
  const [companyListPrev, setCompanyListPrev] = useState([])


  const urlParameters = useParams(props);
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  let {state} = useLocation();
  let firstLoad = true
  let firstLoadist = false

  let today = new Date()
  let c = 0

  // Retrieve data first and populate any state
  useEffect( () => {
      setUserId(ctx.User.id)

      let refresh = false
      if(state !== null && state !== undefined){
        refresh = state.newRole === true || state.newCo === true ? true : false
        // firstLoad = false
      }

      if((loadComponent && firstLoad) || refresh){
        // firstLoad = false //Prevents another initial request and reload within the same request
        setLoadComponent(false)
        firstLoad = false
        c += 1
        if(companyList.length === 0){
          fetchData(true)
        }
        c += 1
      }

      c += 1

      return () => {

      }
    }, [state]
  );

  const refreshSelectionClick = (newSelectedcid, newSelectedrid) => {
    ctx.Company.id = newSelectedcid
    ctx.Role.id = newSelectedrid
    setContext(ctx)
  }

  const setInitialData = (responseData)  => {
    const {company_list, last_updated} = responseData
    
    // setCompanyList(company_list);

    c += 1

    if(urlParameters.id > 0){
      c += 1

      checkIfRolesWereRetrieved(urlParameters.id, company_list)
    }else
    {
      c += 1
      setCompanyList(company_list);
    }

    // setCompanyList(company_list);
    // setCompanyListPrev(company_list)
    // setLoadList(true)
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_HOST_DOMAIN_PORT

  // Get data using axios
  const fetchData = async(processing) => {
    // Change to support proxy
    // let URL = COMAIN_PORT +  process.env.REACT_APP_COMPANY_LIST_API
    let URL = process.env.REACT_APP_COMPANY_LIST_API
    if(processing === true){
      // await axios.get(URL, {
      // headers: {
      //   'Authorization': 'Bearer '  + process.env.REACT_APP_TO_API_TOKEN
      // }})
      await customAxios.get(URL)
      .then(res => {
          if(processing === true){
            c += 1

            setInitialData(res.data)
            c += 1

          }            
      })
      .catch(error => {
        processing = false
        // errorsMessages(error)
        MessageManager(error, "JobsWidget", ctx.Config.Error.getLevels.error)
      })
    }
  }

   // Get data using axios
   const fetchRoles = async(companyId, companyListToUpdate) => {
    // Change to support proxy
    // let URL = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_LIST_API
    let URL = process.env.REACT_APP_COMPANY_ROLE_LIST_API
    URL += "/" + companyId

    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer '  + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
        // if(isDataNeeded === true){
          c +=1

          processRoleListData(companyId, res.data, companyListToUpdate)
          // return res.data
        // }
    })
    .catch(error => {
      // errorsMessages(error)
      MessageManager(error, "JobsWidget", ctx.Config.Error.getLevels.error)
    })
  }


  const checkIfRolesWereRetrieved = (companyId, companyListToUpdate) =>{
    let rolesFound = false
    companyListToUpdate.find((s) => {
      const found = (Number(s.id) === Number(companyId))
        if(found === true){
          rolesFound = s.company_role_list !== undefined && s.company_role_list.length > 0 ? true : false
        }
      //For this to work do not include a return
    })

    if(!rolesFound){
      c +=1

      getRolesForCompany(companyId, companyListToUpdate)    
    }else{
      c +=1

      setIdsForRoleDisplay(companyId)
    }
  }

  const setIdsForRoleDisplay = (companyId) => {
    
    let roleListToDisplay = []

    if(showRoleList.includes(companyId)){ 
      roleListToDisplay = showRoleList.filter(s => s !==  companyId)
    }else{
      roleListToDisplay = [...showRoleList, companyId]
    }

    setShowRoleList(roleListToDisplay)
  }

  // Used for new page rendering, new company or role created, and open/close list
  const getRolesForCompany = (id, companyListToUpdate) =>{
    fetchRoles(id, companyListToUpdate)
  }

  const processRoleListData = (companyId, roleListData, companyListToUpdate) => {
    const {company_role_list, last_updated} = roleListData
    setRoleData(companyId, company_role_list, companyListToUpdate)
  }

    const setRoleData = (companyId, roleList, companyListToUpdate) =>{
    try{c += 1

    const listToUpdate = [...companyListToUpdate]
    listToUpdate.find((s) => {

      c += 1

      s.company_role_list = (Number(s.id) === Number(companyId)) ? roleList : []
        c += 1

      //For this to work do not include a return      
    })

    setIdsForRoleDisplay(companyId)
    setCompanyList(listToUpdate)
    // setCompanyListPrev(listToUpdate)
    }catch (error){
      c += 1
      MessageManager("!!!!!!!!!!!! Eror found @ updating role in list" + c + " | " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + "." + today.getMilliseconds(), "JobsWidget", 3)
      MessageManager(error, "JobsWidget", 3)
    }
  }

  return (
    <div className={styles.jobsWidget}>
      <div className={styles.companyJobsWidget}>
        <div className={styles.candidateList}>
        Resume List here
        </div>

        <div className={styles.companyWidget}>
 { companyList !== undefined ? companyList.map( company => 
          <div className={styles.companyListPosition}> {/*<--- companyListPosition */}
            {/* Company display */}
            <div className={ctx.Company.id === company.id ? styles.companyBubbleSelected :styles.companyBubble} onClick={ () => checkIfRolesWereRetrieved(company.id, companyList) }>
              <div className={styles.companyName}  >{company.name}</div>
              {/* <div className={styles.companyName} >{company.name}</div> */}
              <div className={styles.openCloseRoleIcon} >
                <img
                  className={styles.openCloseIcon}
                  alt=""
                  src="/open-close-icon.svg"
                />
              </div>  
            </div>
            {/* Company display */}
    { company.company_role_list!== undefined & showRoleList.includes(company.id) ? company.company_role_list.map( role => 
            <div className={styles.jobPositionList}>
              {/* First role display */}
              <div className={ctx.Role.id === role.id ? styles.rolePositionSelected : styles.rolePosition}>
                <div>
                  <Link to={'/companysetup/' + company.id + '/' + role.id} state={{roleSel: true}} onClick={()=> {refreshSelectionClick(company.id,role.id)} } className={styles.roleNamePosition}>{role.name}</Link>
                </div>
                <div className={ctx.Role.id === role.id ? styles.selectIndicator :styles.nonSelected} />
              </div>
              {/* First role display */}
            </div>
      ):<></>
    }
            <div className={styles.bottomPading} ></div>
          </div>
    ) : <div className={styles.rolePositionSelected} ><span className={styles.companyName}>No company setups found</span></div>
  } 
        </div>
        <img
          className={styles.openCloseIcon2}
          alt=""
          src="/open-close-icon1.svg"
        />
        <img
          className={styles.addCandidateSignIcon}
          alt=""
          src="/add-candidate-sign.svg"
        />
        <img
          className={styles.addCompanyPositionsSignIcon}
          alt=""
          src="/add-candidate-sign.svg"
        />
        <b className={styles.candidates}>Candidates</b>
        <b className={styles.companyPositions}>Company | Positions</b>
        <div className={styles.companyJobsWidgetChild} />
        <div className={styles.companyJobsWidgetItem} />
        <div className={styles.companyJobsWidgetInner} />
        {/* <img
          className={styles.openCloseIcon3}
          alt=""
          src="/open-close-icon1.svg"
        /> */}
        <div className={styles.evaluateButton}>
          <div className={styles.evaluateLabel}>Review</div>
        </div>
        <div className={styles.compareButton}>
          <div className={styles.evaluateLabel}>Compare</div>
        </div>
        <div className={styles.deleteButton}>
          <div className={styles.evaluateLabel}>Delete</div>
        </div>
      </div>
      <img
        className={styles.openCloseJobPositionsIcon}
        alt=""
        src="/open-close-job-positions1.svg"
      />
    </div>
  );
};

export default JobsWidget;
