import styles from "./ResumeChat.module.css";
import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../common/hooks/useCustomAxios';
import MessageManager from "../common/ErrorManager";

import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const ResumeChat = () => {

  const [loadComponent, setLoadComponent] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [candidateInformation, setCandidateInformation] = useState()
  const [needsReview, setNeedsReview] = useState(false)
  const [resumeIdToProcess, setResumeIdToProcess] = useState()
  const [chatHistory, setChatHistory] = useState([])
  const [isRequestInProgress, setIsRequestInProgress] = useState(false)
  const [noInfoAvailable, setNoInfoAvailable] = useState(false)
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIcon)
  const [isChatViewVisible, setIsChatViewVisible] = useState('inline')
  const [chatContent, setChatContent] = useState('');
  const [isVisible, setIsVisible] = useState('none');
  const [isChatVisible, setIsChatVisible] = useState(true);

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();
  const bottomViewRef = useRef()

  let processing = true
  let redirect = false
  let showMessageArea = false

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  };

  const chatRequest = {
    id: urlParameters.rsid,
    message: chatContent,
    include_job_description: true,
    date: "2023-11-09T05:48:43.073Z"
  };

  const chatResponse = {
    role: process.env.REACT_APP_TALENTO_ROLE,
    message: ""
  };

  let fitAssessment = ["-", "Not a Fit", "Possible Fit", "God Fit", "Great Fit"]
  // resume.fit_score > 6? fit_assessment[3] : resume.fit_score > 3 ?  resume.fit_score[2] : resume.fit_score > 0 ? resume.fit_score[1] : resume.fit_score[0]
  let fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus]

  useLayoutEffect(() => {
      // window.scrollTo(0, 0)
  });

  // Retrieve data first and populate any state
  useEffect( () => {
    if(urlParameters.id > 0 && urlParameters.rid > 0 && urlParameters.rsid > 0 && (loadComponent || state.roleSel  === true || state.newRole === true)){
      setLoadComponent(false)
      fetchData(process.env.REACT_APP_RESUME_DETAIL_API, false)
    }else{
      //Nothing
    }

    return () => {
        processing = false
    }
    }, [state] 
  )

  useEffect( () => {
    // bottomViewRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [chatHistory]
  )

  const setInitialData = (responseData)  => {
    const {id, name, fit_assessment, fit_score, uploaded_date, fit_rationale, fit_summary} = responseData
    setIsRequestInProgress(false)
    setShowMessage(false)
    showMessageArea = false
    setNeedsReview(false)
    setNoInfoAvailable(false)
    

    if(name === undefined || name === null){
      //probably not a valid resume/candidate id
      if( (fit_score === undefined || fit_score === null) || fit_score < 0 && id > 0){

        setResumeIdToProcess(id)
        setNeedsReview(true) 
      }
    }else if((fit_score === undefined || fit_score === null) || fit_score < 0 && id > 0){

      setResumeIdToProcess(id)
      setNeedsReview(true)
    }else if(name !== undefined || name !== null && fit_score > 0){

      setCandidateInformation(responseData)
      // Get the initial chat history, if any
      fetchData(process.env.REACT_APP_RESUME_CHAT_HISTORY_API, true)
    }else{

      setNoInfoAvailable(true)
    }
    processing = false

    // if(redirect){
    //   navigate("/CompanySetup/" + ctx.Company.id + "/" + id, {state: {newRole:true}});
    // }
  }

  const extractChatInformation = (responseData) => {
    const {id, chat_history, updated_date} = responseData

    if(chat_history !== null || chat_history !== undefined && chat_history.lenght > 0){
      setChatHistory(chat_history)
    }
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {

    let errorMsg = "";

    if(error?.response?.status === 400){
      errorMsg = "Please avoid profane, obscene, inappropriate, or otherwise derrogatory words. \n"
      errorMsg += "Profanity found: " + error?.response?.data?.detail + " - \n\n"
      errorMsg += "This request will not be stored in your history. \n\n Please try again."
    }else{
        errorMsg = "Unexpected error found. Please try again."
    }
    setIsRequestInProgress(false)
    // let noRoleFoundMsg = "Candidate's resume detail could not be found. Please try again, or select a different candidate."
    // let errorMsg = "An error was found. Please check the console for more information"
    let chatResponseError = chatResponse;
    chatResponseError.message = errorMsg;
    setChatHistory([chatResponseError]);
    // displayMessage(errorMsg)
  }

  const reviewThisResume = ()=>{

    setNeedsReview(false)
    showMessageArea = false
    setIsRequestInProgress(true)
    // postData(process.env.REACT_APP_RESUME_REVIEWS_API)
    fetchData(process.env.REACT_APP_RESUME_REVIEW_API, false)
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT

  // Get data using the axios functionality
  const fetchData = async(urlPath, isChatRequest) => {
    setIsRequestInProgress(true)
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_RESUME_DETAIL_API
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid + "/" + urlParameters.rsid
    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
        if(isChatRequest === true){
          extractChatInformation(res.data)
        }else{
          setInitialData(res.data)
        }
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeChat", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  }

  const postData = async(urlPath) => {
    COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid + "/" + urlParameters.rsid
    const postData = chatRequest
    // await axios.post(URL , postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }}
    //   )
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data)
      extractChatInformation(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "AdditionalEvalCrit", ctx.Config.Error.getLevels.error)
    })
  }

  const displayMessage = (messageToDisplay) => {
    showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        showMessageArea = show
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  const setChatViewVisibleOnOff = () => {
    // let isOn = isChatViewVisible === 'inline' ?  'none' : 'inline'
    // setIsChatViewVisible(isOn)

    setIsChatVisible(isChatVisible ? false : true);
    
    // let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    // let iconStyle = isChatVisible ? styles.openCloseIcon : styles.openCloseIconUp
    // setOpenCloseIconUp(iconStyle)    
  }

  const setSubmitButtonState = (state) => {
    if(state === true){
      setIsVisible('inline');
    }else{
      setIsVisible('none')
    }
  }

  const setChatMessageValue = (content) => {
    setChatContent(content);
    // setMessage('');
    if(content === ""){
      setSubmitButtonState(false);
    }else{
      setSubmitButtonState(true);
    }
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    const newChatPersonInteraction = {role: process.env.REACT_APP_PERSON_ROLE, message: chatContent};
    const newPersonMessageChat = [...chatHistory, newChatPersonInteraction]

    const requestProgress = (<img height='64' width='38.4' src='/TO_Page_Progress.gif' />)
    const newChatTalentInteraction = {role: process.env.REACT_APP_TALENTO_ROLE, message: requestProgress};
    const newTalentMessageChat = [...newPersonMessageChat, newChatTalentInteraction]

    setChatHistory(newTalentMessageChat)
    // setChatContent("")

    postData(process.env.REACT_APP_RESUME_CHAT_API);
    setChatContent("")
  }

  const goToReviewDetail = (rsid)=>{
    navigate("/reviewdetail/" + ctx.Company.id + "/" + ctx.Role.id + "/" + rsid +"?#", {state: {newResume:true}});
  }

  return candidateInformation ? (
    
        <div className={styles.mainCandidateReviewAndChatArea}>
          {/* Summary Area */}
          <div className={styles.candidateContainerInfoArea}>
              {/* Candidate info area */}
              <div className={styles.candidateLabelArea}>
                <div className={styles.candidateLabel} onClick={()=> goToReviewDetail(candidateInformation.id)} > {candidateInformation.name} </div>
              </div>
              <div className={styles.assessmentArea}>
                <div className={fitAssessmentStyle[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null ? 0 : candidateInformation.fit_assessment + 1]} >{fitAssessment[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null  ? 0 : candidateInformation.fit_assessment + 1]}</div>
                <div className={styles.scoreContent} >Score: {candidateInformation.fit_score} </div>
              </div>
              
              <div className={styles.candidateSummaryArea} >
                  {candidateInformation.fit_summary}
              </div>
              
              {/* ^ Candidate info area ^ */}
          </div>
          {/* ^ Summary Area ^ */}

          {/* Chat History Area */}
          {/* <div className={styles.chatArea}> */}
          <div className={isChatVisible ? styles.chatArea : styles.chatAreaHide}>
            <div className={styles.chatTitle} >
              <div className={styles.chatViewLabel}>
                Chat view
              </div>
              <div className={styles.chatViewOpenCloseArrow}>
                <div><img className={isChatVisible ? styles.openCloseIcon : styles.openCloseIconUp} onClick={setChatViewVisibleOnOff} alt="" src="/open-close-icon.svg" /> </div>
              </div>
            </div>
            {/* <div className={styles.chatViewArea} style={{display: isChatViewVisible}} > */}
            <div className={styles.chatViewArea}>
              { 
                chatHistory.map( (historyItem, index) =>
                  <div key={index} className={ historyItem.role === process.env.REACT_APP_PERSON_ROLE ? styles.chatPersonEntry : styles.chatOpinionEntry}>                    
                    <div className={styles.chatItem}>{historyItem.message}</div>
                  </div>
                )  
              }
              <div ref={bottomViewRef} />
            </div>
            <div className={styles.notesArea} >
              {/* NOTES HERE */}
            </div>
          </div>
          {/* ^ Chat History Area ^ */}

          {/* Chat Input Area */}
          <div className={styles.chatBoxArea} >
            <textarea value={chatContent} onChange={(e) => {setChatMessageValue(e.target.value);}}
                          className={styles.chatInputBox} 
                          id="message" name="message" 
                          rows={3} cols={108} placeholder="Enter your questions here..."
            />
            <div className={styles.submitButtonArea} style={{display: isVisible}}>
              {/* <button className={styles.submitButton} type="submit" onClick={handleSubmit} >SAve</button> */}
              <div className={styles.svgSubmitButton} onClick={handleSubmit}> <img height='48' width='28.8' src='/Submit_Button_Chat.svg' /> </div>
            </div>
          </div>
          <div className={styles.chatSugestionsSymbol} >&#9432;
              <div className={styles.chatSugestionsText} >
                <b>Chat question examples:</b>
                <ul >
                  <li>Which of the candidate's experience is the most relevant for the job?</li>
                  <li >List of all of the candidate's skills</li>
                  <li >Do a job description analysis and list areas for improvement</li>
                  <li >Does the job description description contains too much detail or not enough?</li>
                </ul>
              </div>
            </div>
          {/* ^ Chat Input Area ^ */}
        { showMessageArea ? 
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        : <></>}
          {/* {bottomViewRef.current?.scrollIntoView({behavior: 'smooth'}) } */}
        </div>

  ) : needsReview ? (
        <div className= {styles.noInfoMessageArea}>
            This candidate's resume has not been reviewed by Talent Opinion or an issue was found.<br/>
            Please try again, or select a different candidate.
            <div className={styles.performReviewButton} onClick={()=> reviewThisResume()} >Perform the analysis</div>
        </div>
  ) : isRequestInProgress ? (
        <div className={styles.requestInProgressContainer} >
          <div className={styles.requestInProgressArea} >
            <img height='180' width='108' src='/TO_Page_Progress.gif' /><br/>
            Candidate's resume analysis is in-progress...
          </div>
        </div>
  ) : noInfoAvailable ? (
        <div className= {styles.noInfoMessageArea}>
          No information available, please select a different candidate
        </div>
  ) : showMessage ? (
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                    {message}
                </div> 
            </div>
        </div>
  ) : ( 
        <div></div> 
  )
};

export default ResumeChat;
