
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import styles from "./SubscriptionCancel.module.css";
// import { useRouter } from 'next/navigation';
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useHistory } from "react"

export default function SubscriptionCancel() {

  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();
  const customAxios = useCustomAxios();

  const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);


  useEffect(() => {
    // These two may not be needed
    // const newContext = getContext();
    // setContext(newContext);
  }, [])

  const displayMessage = (messageToDisplay) => {
    // showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  };

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let msg = "An error was found trying to cancelling subscription. Wait a few seconds and try again."
    // setMessage(error.response !== undefined ? error.response.data.detail : msg)
    displayMessage(error.response !== undefined ? error.response.data.detail : msg)
  }

  const submitCancellation = async(planToPost) => {
    let URL = process.env.REACT_APP_USER_PLAN_CANCEL_API;
    const postData = planToPost;
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      // console.log(res.data);
      cancellationSucess(res.data)
    })
    .catch(error => {
      errorsMessages(error);
      MessageManager(error, "Payment", ctx.Config.Error.getLevels.error);
    })
  }

  const cancellationSucess = (response)=>{
    setSubscriptionIsCancelled(true);
  }

  const cancelSubscription = ()=>{
    const cancel = {
      subscription_id: 0
    };

    submitCancellation(cancel);
  }

  const closeMessage = () =>{
    const show = showMessage === true ? false : true;
    setShowMessage(show)
    setMessage("")
    setSuccessMessage(false);
  }

  return (
    <div className={styles.pageContainer} >
      <div className={styles.pageHeaderContainer} >
        <div className={styles.pageContainerHeader} >Subscriptions at<span className={styles.pageContainerHeaderTalent} > Talent</span><span className={styles.pageContainerHeaderOpinion}> Opinion</span></div>
        <div className={styles.pageContainerSubHeader}></div>
      </div>
      <div className={styles.planContainer} >
        { subscriptionIsCancelled === false ?
        <div className={styles.planArea} >
          <img className={styles.cancelPlanSubHeader} alt="Talent Opinion" src="/Talent_Logo_Txt_Color_Simple_1.svg" />
          <div className={styles.plan} >
            <div className={styles.planHeader} ></div>
            <div className={styles.planSubHeader} ></div>
            <div className={styles.planPriceArea} >
              <div className={styles.featureHeadline} >
                Canceling a subscription plan doesn't immediately move your account to a lower tier or to the free plan<br/> 
                it only stops the monthly subscription payment.
              </div> 
            </div>
            <div className={styles.planSelectButton} onClick={()=> cancelSubscription() } >Cancel subscription plan</div>
            <div className={styles.featureSubHeadlineCenter} >
              After canceling, your current plan features wil continue through the end of the plan period or when feature limits are reached.<br/>
              If no other subscription plan is selected the account will reset to the free subscription plan at the next period.
            </div>
          </div>
        </div>
        :
        <div className={styles.cancelPlanArea} >
          <img className={styles.cancelPlanSubHeader} alt="Talent Opinion" src="/Talent_Logo_Txt_Color_Simple_1.svg" />
          <div className={styles.pageContainerSubHeader} >
            Your {ctx.Resume.plan} subscription plan was cancelled.
            <div className={styles.featureSubHeadlineCenter} >
              Your current plan features wil continue through the end of the plan period or when feature limits are reached.<br/>
              If no other subscription plan is selected the account will reset to the free subscription plan at the next period.
            </div>
          </div>
        </div>
        }
      </div>
      {
        showMessage ?
      <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
          <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
              <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
              <div>
                {message}
              </div> 
          </div>
      </div>
      : <></>
      }
    </div>
  );
}
