
import { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import styles from "./ProcessWorkflowView.module.css";
import MessageManager from "../../common/ErrorManager";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import ContextManager from "../../common/Context/ContextManager";
import ProcessStatusDisplayNames from "../../common/Status/ProcessStatusDisplayNames"


  const DragNDropState = {
    id: null,
    listFrom: null,
    draggedFromIndex: null,
    draggedToIndex: null,
    isDragging: false,
    originalListOrder: [],
    updatedListOrder: []
  }

  const stateOpenClose = [ true, true,true,true,true,true,true,true,true,true ];
  const stateOpenCloseStageGroup = [ true, true,true,true ];
  let fitAssessment = ["-", "NaF", "PoF", "GdF", "GtF"];
  const fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus];
  // fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]

  const ProcessWorkflowView = (props) => {

    //Drag & Drop state handlers
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [dragAndDrop, setDragAndDrop] = useState( DragNDropState );
    // const [list, setList] = useState( resumes );
    // const [workflowList, setList] = useState( [] );
    const [openStage, setOpenStage] = useState(stateOpenClose);
    const [openStageGroup, setOpenStageGroup] = useState(stateOpenCloseStageGroup);
    const [openCloseMsg, setOpenCloseMsg] = useState("Close");
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [myPreferedList, setMyPreferedList] = useState("[]");
    const [dropTextMsg, setDropTextMsg] = useState("TOP POSITION DROP HERE")

    const [stage1Items, setStage1Items] = useState(0);

    //Filtering and display state handlers
    const [tempWorkflowList, setTempWorkflowList] = useState([]);
    const [workflowList, setWorkflowList] = useState([]);
    const [roleWorkflowList, setRoleWorkflowList] = useState([]);
    const [filteredWorkflowList, setFilteredWorkflowList] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [companyRoleList, setCompanyRoleList] = useState([]);
    const [companyNameSelected, setCompanyNameSelected] = useState("All companies");
    const [companyIdSelected, setCompanyIdSelected] = useState(0);
    const [showRoleList,setShowRoleList] = useState(false);
    const [roleNameSelected, setRoleNameSelected] = useState("All roles");
    const [roleIdSelected, setRoleISelected] = useState(0);

    const customAxios = useCustomAxios()
    const navigate = useNavigate();
    const { ctx, setContext } = ContextManager()
    const urlParameters = useParams();

    const statusSearchList = [
      "Ready for Next Step",
      "Rejected",
      "Reviewed",
      "Shared with Talent Team",
      "Candidate Follow up",
      "Shared with Hiring Lead/Team",
      "Internal Feedback In-Progress",      
      "Initial Call(s) In-Progresss",
      "Interview(s) In-Progress",
      "Offer Extended",
      "Hired"
    ];

    const ProcessUpdate = {
      id: 0,
      status: 0,
      index: -1
    }

    //Event triggered by the user's drag action on any list
    const onDragStart = (event) => {
      const initialIndex = Number(event.currentTarget.dataset.position);
      const id = Number(event.currentTarget.dataset.id);
      const listFrom = event.currentTarget.dataset.list;

      setDragAndDrop({
        ...dragAndDrop,
        id: id,
        listFrom: listFrom,
        draggedFromIndex: initialIndex,
        isDragging: true, 
        originalListOrder: workflowList // store the current state of "list"
      });

      // This is required for Firefox. Without it, drag & drop doesn't work.
      event.dataTransfer.setData("text/html", '');

    }

    //Event triggered when the user is over a drag&drop element
    const onDragOver = (event) => {
      event.preventDefault();

      let onDragOverList = dragAndDrop.originalListOrder;

      // indexes being dragged
      const draggedFromIndex = dragAndDrop.draggedFromIndex; 
      const draggedToIndex = Number(event.currentTarget.dataset.position); 
      const itemDragged = onDragOverList[draggedFromIndex];

      // Check targets to prevent multiple fires
      if (draggedToIndex !== dragAndDrop.draggedToIndex){
        const filteredListToSplit = onDragOverList.filter((item, index) => index !== draggedFromIndex);

        onDragOverList = [
          ...filteredListToSplit.slice(0, draggedToIndex),
          itemDragged,
          ...filteredListToSplit.slice(draggedToIndex)
        ];

        setDragAndDrop({...dragAndDrop,
          updatedListOrder: onDragOverList, 
          draggedToIndex: draggedToIndex
        })
      }
    }

    //Event triggered when a user releases or drops an object into a drag&drop element on any list.
    const onDrop = (event) => {

      const listTo = Number(event.currentTarget.dataset.list);

      //Process resume list
      const updatedlist = [...dragAndDrop.updatedListOrder];

      updatedlist.map((resume, index)=>{        
        if(Number(dragAndDrop.id) === Number(resume.resume_id)){
          resume.resume_process_status = listTo;
        }

        return resume;
      })

      //Re-index the list that is re-ordered within the same stage/status column
      let reorderedCandidateList = [...updatedlist].filter((resume, index) => Number(resume.resume_process_status) === Number(dragAndDrop.listFrom));
      reorderedCandidateList.map((resume, index)=>{ return resume.resume_process_index = index + 1; });

      const itemToSave = [];
      reorderedCandidateList.map(resume=>{
        const resumeToSave = {id: resume.resume_id, status: resume.resume_process_status, index: resume.resume_process_index};
        itemToSave.push(resumeToSave);
      });
      
      if(Number(dragAndDrop.listFrom) === listTo){
        //Aggregate re-indexed list within the same stage/status column to the full set
        let filteredList = [...updatedlist].filter((resume, index) => Number(resume.resume_process_status) !== listTo);
        reorderedCandidateList = reorderedCandidateList.concat(filteredList);
      }else if(Number(dragAndDrop.listFrom) !== listTo){
        //Re-index the list that is re-ordered within a different stage/status column
        let filteredList = [...updatedlist].filter((resume, index) => Number(resume.resume_process_status) === listTo);
        filteredList.map((resume, index)=>{ return resume.resume_process_index = index + 1; });

        filteredList.map(resume=>{
          const resumeToSave = {id: resume.resume_id, status: resume.resume_process_status, index: resume.resume_process_index};
          itemToSave.push(resumeToSave);
        });

        //Aggregate re-indexed list to the full set
        reorderedCandidateList = reorderedCandidateList.concat(filteredList);
        let filteredList_2 = [...updatedlist].filter((resume, index) => (Number(resume.resume_process_status) !== listTo && Number(resume.resume_process_status) !== Number(dragAndDrop.listFrom)));
        reorderedCandidateList = reorderedCandidateList.concat(filteredList_2);
      }

      // dragAndDrop.updatedListOrder = updatedlist;
      // we use the updater function for the "list" hook
      // setList(dragAndDrop.updatedListOrder);
      // setList(updatedlist);
      setWorkflowList(reorderedCandidateList);

      // and reset the state of the DnD
      setDragAndDrop({
        ...dragAndDrop,
        draggedFromIndex: null,
        draggedToIndex: null,
        isDragging: false
      });
      
      //Save all changes into the DB.
      saveCandidateProcessStatus(itemToSave);

    }

    //Event triggered when a drop event is complete.
    const onDragLeave = () => {
      setDragAndDrop({
      ...dragAndDrop,
      draggedToIndex: null
     });
    }
    

    //Method to store/save in the DB the new index and stage/status changes.
    const saveCandidateProcessStatus = async (list)=>{
      //Update object structure
      // [{"id": 0, "status": 0, "index": -1}]

      const postData = list;

      let URL_PATH =  process.env.REACT_APP_RESUME_PROCESS_STATUS_API;
      let URL = URL_PATH + "/" + 0 + "/" + 0;

      await customAxios.post(URL , postData)
      .then(res => {
        // setInitialData(res.data);
        // console.log("message returned ok");
      })
      .catch(error => {
        errorsMessages(error)
        MessageManager(error, "saveCandidateProcessStatus", ctx.Config.Error.getLevels.error)
      })

    };

    useEffect( ()=>{   
      window.scrollTo(0, 0);   
      const companyId = urlParameters.id !== null && urlParameters.id !== undefined ?
      urlParameters.id : 0;

      const roleId =  urlParameters.rid !== null && urlParameters.rid !== undefined ?
      urlParameters.rid : 0;

      setCompanyIdSelected(companyId);
      setRoleISelected(roleId);
      fetchReportData(companyId, roleId);
     
    }, [])

    const setInitialData = (responseData, cid)  => {
      const {company_workflow_view_list, last_updated} = responseData
      setIsRequestInProgress(false);
      setWorkflowList(company_workflow_view_list);
      setTempWorkflowList(company_workflow_view_list);
      setRoleWorkflowList(company_workflow_view_list);
      setFilteredWorkflowList(company_workflow_view_list);
      setShowMessage(false);
      loadMyListData();

      const companyId = urlParameters.id !== null && urlParameters.id !== undefined ?
      urlParameters.id : 0;
      if(Number(companyId) < 1 && Number(cid) < 1){
        fetchCompanyAndRoleData(true, 0);
        setShowRoleList(false);
      }else if(Number(cid) > 0){
        fetchCompanyAndRoleData(false, cid);

        if(company_workflow_view_list.length > 0){
          const tempList = [...company_workflow_view_list].filter(item=> Number(item.company_id) === Number(companyId) );
          setCompanyNameSelected(tempList[0].company_name);
        }
        
      }else{
        fetchCompanyAndRoleData(true, 0);
        setShowRoleList(false);
      }
    }

    //Method to issue a request against the backend API
    // {company_id}/{role_id}/{items_per_page}/{page_number}.
    // {company_id} = 0 retrieves all companies. role_id is ignored.
    // {company_id} != 0 retrieves specific company.
    // {company_id} != 0 + {role_id} != 0 retrieves specific role for specific company.
    // {items_per_page} max = 100
    const fetchReportData = async(cid, rid) => {
      let URL_PATH =  process.env.REACT_APP_COMPANY_VIEW_WORKFLOW_API

      let URL = URL_PATH + "/" + cid + "/" + rid + "/70/1"
      await customAxios.get(URL)
      .then(res => {
          setInitialData(res.data, cid);
      })
      .catch(error => {
          errorsMessages(error)
          MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
      })
  };

  //Method to help populate the company and the role lists
  const populateCompanyRoleList = (responseData, isCompany, cid)=>{    
    const companyId = urlParameters.id !== null && urlParameters.id !== undefined ?
    urlParameters.id : 0;
    const roleId =  urlParameters.rid !== null && urlParameters.rid !== undefined ?
    urlParameters.rid : 0;
    if(isCompany === true){
      const {company_list, last_updated} = responseData;
      setCompanyList(company_list);
      if(Number(companyId) > 0){
        const tempList = [...company_list].filter(item=> Number(item.id) === Number(companyId) );
        setCompanyNameSelected(tempList[0].name);
      }
    }else{
      const {company_role_list, last_updated} = responseData;
      setCompanyRoleList(company_role_list);
      setShowRoleList(true);
      if(Number(roleId) > 0){
        const tempList = [...company_role_list].filter(item=> Number(item.id) === Number(roleId) );
        setRoleNameSelected(tempList[0].name);
      }
    }

    if(Number(cid) > 0 || Number(companyId) > 0 || Number(roleId) > 0){
      setShowRoleList(true);
    }else{
      setShowRoleList(false);
    }
  }

  //Method to issue an HTTP request to get a company or role list
  const fetchCompanyAndRoleData = async(isCompany, cid) => {
    let URL_PATH =  isCompany === true ? 
                    process.env.REACT_APP_COMPANY_LIST_API :
                    process.env.REACT_APP_COMPANY_ROLE_LIST_API + "/" + cid;

    // let URL = URL_PATH + "/" + companyId + "/" + roleId + "/70/1"
    let URL = URL_PATH;
    await customAxios.get(URL)
    .then(res => {
      populateCompanyRoleList(res.data, isCompany, cid);
    })
    .catch(error => {
        errorsMessages(error)
        MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
    })
};


  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let msg = "An error was found. Please check the console for more information"
    displayMessage(error.response !== undefined ? error.response.data.detail : msg)
  }

  const displayMessage = (messageToDisplay) => {
    setIsRequestInProgress(false);
    setShowMessage(true)
    setMessage(messageToDisplay)
    // console.log("**************** Error Found ****************");
    // console.log(messageToDisplay);
  }

  //Method to filter a list or search within a list
  const searchItems = (searchValue) => {
    if (searchValue !== '' && searchValue.length > 2) {

        let filteredData = []
        setIsSearching(true);

        const index = fitAssessment.findIndex((item) => {return item.toLowerCase() === searchValue.toLowerCase()});
        const statusIndex = statusSearchList.findIndex((item)=>{return item.toLocaleLowerCase().includes(searchValue.toLowerCase())});

        if(index > -1){
          const index2 = index - 1;
          filteredData = filteredWorkflowList.filter((item) => {
              return item.resume_fit_assessment === index2;
          });
        }else if(statusIndex > -1){
          const statusIndex2 = statusIndex - 1;
          filteredData = filteredWorkflowList.filter((item) => {
            return item.resume_process_status === statusIndex2;
          });
        }else if(searchValue.indexOf('-') > -1){
          searchValue = searchValue.replaceAll('-', '');
          searchValue =  searchValue.length === 0 || searchValue === '' ? -1 : searchValue;
          filteredData = filteredWorkflowList.filter((item) => {
              return item.resume_fit_score === Number(searchValue);
          });
        }else{
          filteredData = filteredWorkflowList.filter((item) => {
              return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase());
          })
        }

        setWorkflowList(filteredData);
    }
    else{
        setIsSearching(false);
        setWorkflowList(filteredWorkflowList);
    }
  }

  //Method to filter the list by company
  const getCompanyRole = (cid, companyName)=>{    
    const companyId = Number(cid);
    setCompanyNameSelected(companyId < 1 ? "All companies": companyName);    
    setCompanyIdSelected(companyId);
    setCompanyRoleList([]);
    setRoleNameSelected("All roles");
    setRoleISelected(0);

    const urlCompanyId = urlParameters.id !== null && urlParameters.id !== undefined ?
    urlParameters.id : 0;
    
    if(urlCompanyId > 0){
      urlParameters.id = 0;
      navigate("/workflow", {state: {newResume:true}});
      fetchReportData(0, 0);
      fetchCompanyAndRoleData(true, companyId);
    }else{

      if(companyId > 0){
        const filteredData = tempWorkflowList.filter((item) => {
          return item.company_id === companyId;
        });

        fetchCompanyAndRoleData(false, companyId);
        setWorkflowList(filteredData);
        setFilteredWorkflowList(filteredData);
        setRoleWorkflowList(filteredData);
        // setShowRoleList(true);
      }else{
          setWorkflowList(tempWorkflowList);
          setFilteredWorkflowList(tempWorkflowList);
          setShowRoleList(false);
      }
    }
  };

  //Method to filter the list by role
  const getRole = (rid, roleName)=>{
    const roleId = Number(rid);    
    setRoleNameSelected(roleId < 1 ? "All roles" : roleName);
    
    if(roleId > 0){
      const filteredData = roleWorkflowList.filter((item) => {
        return item.role_id === roleId;
      });

      setWorkflowList(filteredData);
      setFilteredWorkflowList(filteredData);
      setShowRoleList(true);
    }else{
      const filteredData = roleWorkflowList;

      setWorkflowList(filteredData);
      setFilteredWorkflowList(filteredData);
    }
  }

    //Function to open an close a group of process stage list
    const setOpenCloseStageGroup = (viewNumber)=> {
      const tempState = [...openStageGroup];
      const index = Number(viewNumber);
      tempState[index] = tempState[index] === false ? true : false;
      setOpenStageGroup(tempState);
    }
    //Function to manage the close/open icon for the open/close stage group action
    const openCloseIconUpGroup = (stage)=>{
      if(openStageGroup[Number(stage)] === true){
        return styles.openCloseIconGroup;
      }else{
        return styles.openCloseIconUpGroup;
      }
    }
    //Function to open/close a process stage view or list
    const setOpenCloseView = (viewNumber)=> {
      const tempState = [...openStage];
      const index = Number(viewNumber);
      tempState[index] = tempState[index] === false ? true : false;
      setOpenStage(tempState);
      const openCloseMessage = tempState[index] === false ? "Close" : "Open";
      setOpenCloseMsg(openCloseMessage);
    }
    //Function to manage the open/close icon for the open/close process stage view action
    const openCloseIconUp = (stage)=>{
      if(openStage[Number(stage)] === true){
        return styles.openCloseIcon;
      }else{
        return styles.openCloseIconUp;
      }
    }
    //Function to open/close the high level process stage container
    const openCloseStageContainer = (stage)=>{
      if(openStage[Number(stage)] === true){
        return styles.sectionContainer;
      }else{
        return styles.closeStage;
      }
    }
    //Function to manage the display style of the label for a process stage view
    const openCloseStageDividerText = (stage)=>{
      if(openStage[Number(stage)] === true){
        return styles.stageDividerTextClosed;
      }else{
        return styles.stageDividerText;
      }
    }

    const isIdOnMyPreferedList = (id)=>{
      // const thePreferedList = loadMyListData();
      const found = myPreferedList.includes(String(id));
      return found;
    }

    const loadMyListData = () => {
      
      let myNewList = [];
      if(myPreferedList[0] !== undefined && myPreferedList[0] !== null && myPreferedList[0] !== ''){
        const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]" ? ctx.User?.myList : 0;
        if(myListInCtx !== 0){
          const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
          parsedList.forEach((item)=> myNewList.push(item));
          setMyPreferedList(myNewList)
        }else{
          setMyPreferedList([]);
        }
      }else{
        myNewList = [...myPreferedList];
      }

      return myNewList;
    }

    const goToReviewDetail = (cid, rid, rsid)=>{
      navigate("/reviewdetail/" + cid + "/" + rid + "/" + rsid +"?#", {state: {newResume:true}});
    }

    return(
<div className={styles.mainWorkflowViewArea}>
        <div className={styles.headerArea} >
                <div className={styles.filterArea} >
                    <div className={styles.filterLabel}></div>
                    <div className={styles.focusAreaSelected}>
                      <div className={styles.companyListLabel} >
                        <span>For: </span> {companyNameSelected}
                        <div className={styles.companyListContainer}>
                          <div onClick={()=> getCompanyRole(0, "All Companies")} >All Companies</div>
                          {
                            companyList.map((company, index)=>
                              <div key={"c" +index} onClick={()=> getCompanyRole(company.id, company.name)} >{company.name}</div>
                            )
                          }
                        </div>
                      </div>
                        {
                          showRoleList ? 
                          <div className={styles.roleListLabel}> 
                            <span>&nbsp;|&nbsp;</span>{roleNameSelected}
                            <div className={styles.roleListContainer}>
                              <div onClick={()=> getRole(0, "All roles")}>All roles</div> 
                              {
                                companyRoleList.map((role, index)=>
                                  <div key={"r" + index} onClick={()=> getRole(role.id, role.name)}>{role.name}</div>  
                                )
                              }
                            </div>
                          </div>
                          :<></>
                        }
                    </div>  
                    <div >
                        <input className={styles.filterInput} icon='search' placeholder='Search | Filter here...' onChange={(e) => searchItems(e.target.value)} />
                    </div>
                </div>
        </div>
      <div className={styles.globalContainer}>
        {/* <div className={styles.stageGroup} > */}
          {/* <div className={styles.openClose} onClick={()=>setOpenCloseStageGroup(1)} >
            <img className={openCloseIconUpGroup(1)} alt="" src="/open-close-icon.svg" />
            <div className={styles.stageDividerText} >Internal</div>
            {openCloseMsg}
          </div> */}
          
          <div className={openCloseStageContainer(1)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(1)} >
                <img className={openCloseIconUp(1)} alt="" src="/open-close-icon.svg" />
                {/* <img className={openCloseIconUp(1)} alt="" /> */}
                <div className={openCloseStageDividerText(1)} >Reviewed</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[1] === true ? styles.stageLabel : styles.stageLabelClosed } >Reviewed</div>
              <div data-position={0} data-list={1} className={openStage[1] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[1] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 1 ?
                    // return(
                      <li key={"li" + index} data-id={resume.resume_id} data-position={index} data-list={1} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(2)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(2)} >
                <img className={openCloseIconUp(2)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(2)} >Talent Team</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[2] === true ? styles.stageLabel : styles.stageLabelClosed} >Shared with Talent Team</div>
              <div data-position={0} data-list={2} className={openStage[2] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[2] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 2 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={2} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(3)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(3)} >
                <img className={openCloseIconUp(3)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(3)} >Follow Up</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[3] === true ? styles.stageLabel : styles.stageLabelClosed} >Candidate Follow Up</div>
              <div data-position={0} data-list={3} className={openStage[3] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[3] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 3 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={3} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          {/* Stage 1 end */}
        {/* </div> */}

        <div className={openCloseStageContainer(4)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(4)} >
                <img className={openCloseIconUp(4)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(4)} >Hiring Team</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[4] === true ? styles.stageLabel : styles.stageLabelClosed} >Shared with Hiring Team</div>
              <div data-position={0} data-list={4} className={openStage[4] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[4] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 4 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={4} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(5)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(5)} >
                <img className={openCloseIconUp(5)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(5)} >Feedback</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[5] === true ? styles.stageLabel : styles.stageLabelClosed} >Feedback In-Progress</div>
              <div data-position={0} data-list={5} className={openStage[5] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[5] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 5 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={5} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(6)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(6)} >
                <img className={openCloseIconUp(6)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(6)} >Phone Interviews</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[6] === true ? styles.stageLabel : styles.stageLabelClosed} >Phone Interviews</div>
              <div data-position={0} data-list={6} className={openStage[6] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[6] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 6 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={6} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(7)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(7)} >
                <img className={openCloseIconUp(7)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(7)} >In-person Interviews</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[7] === true ? styles.stageLabel : styles.stageLabelClosed} >In-person Interviews</div>
              <div data-position={0} data-list={7} className={openStage[7] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[7] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 7 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={7} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(8)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(8)} >
                <img className={openCloseIconUp(8)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(8)} >Offer</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[8] === true ? styles.stageLabel : styles.stageLabelClosed} >Offer</div>
              <div data-position={0} data-list={8} className={openStage[8] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[8] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 8 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={8} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

          <div className={openCloseStageContainer(9)} >
            <div className={styles.headerSection}>
              <div className={styles.openClose} onClick={()=>setOpenCloseView(9)} >
                <img className={openCloseIconUp(9)} alt="" src="/open-close-icon.svg" />
                <div className={openCloseStageDividerText(9)} >Hired</div>
                {/* {openCloseMsg} */}
              </div>
              <div className={openStage[9] === true ? styles.stageLabel : styles.stageLabelClosed} >Hired</div>
              <div data-position={0} data-list={9} className={openStage[9] === true ? styles.dropTopArea : styles.stageLabelClosed} draggable="false" onDragStart={onDragStart}  
              onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} >{dropTextMsg}</div>
            </div>
            <section className={openStage[9] === true ? styles.section : styles.stageLabelClosed}>
              <ul className={styles.ul} >
                {
                  workflowList.map( (resume, index) =>
                    resume.resume_process_status === 9 ?
                    // return(
                      <li key={"li" +index} data-id={resume.resume_id} data-position={index} data-list={9} draggable="true" onDragStart={onDragStart}  onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave} 
                      className={dragAndDrop && dragAndDrop.draggedToIndex=== Number(index) ? styles.dropArea : isIdOnMyPreferedList(resume.resume_id) ? styles.dropbucketStar : styles.dropbucket}
                      > 
                        {/* <span className={styles.span} >{item.id}</span> */}
                        <span key={"spa"+index} className={fitAssessmentStyle[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null ? 0 : resume.resume_fit_assessment + 1]} >{fitAssessment[resume.resume_fit_assessment === undefined || resume.resume_fit_assessment === null  ? 0 : resume.resume_fit_assessment + 1]}</span>
                        <p key={"p"+index} className={styles.paragraph} onClick={()=>goToReviewDetail(resume.company_id, resume.role_id, resume.resume_id)} >{resume.resume_name}</p>
                        <i key={"i"+index} className={styles.item} data-position={index} >{resume.resume_fit_score}&nbsp;</i>
                      </li>
                    // )
                    :
                    <></>
                  )
                }
              </ul>
            </section>
          </div>

      </div>
</div>
    )
  }
  
  export default ProcessWorkflowView;
  
//   ReactDOM.render(
//     <DragToReorderList />,
//     document.getElementById("root")
//   );
  