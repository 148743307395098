import styles from "./HomeStats.module.css";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import { useRef, useState, useEffect } from 'react';

const HomeStats = (props)=> {

    const [userId, setUserId] = useState(0);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [myPreferedList, setMyPreferedList] = useState([]);
    const [newsList, setNewsList] = useState([])
    // const [homeStats, setHomeStats] = useState({})

    const urlParameters = useParams();
    const { ctx, setContext } = ContextManager();
    const customAxios = useCustomAxios();
    const navigate = useNavigate();
    let {state} = useLocation();
    let newsItemCount = 0;

    const homeStatsObject = {
        companies: 0,            
        roles: 0,            
        resumes: 0,            
        processed_resumes: 0,            
        not_processed_resumes: 0,            
        reviewed_resumes: 0,            
        passed_resumes: 0,            
        in_progress_resumes: 0
    };

    const [homeStats, setHomeStats] = useState(homeStatsObject);

    useEffect( () => {
        setUserId(ctx.User.id)

        if(true === true){
            setRequestInProgress(true);
            fetchData();
            fetchNewsData();
        }

        const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]" ? ctx.User?.myList : 0;
        if(myListInCtx !== 0){
            const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
            const myNewList = [];
            parsedList.forEach((item)=> myNewList.push(item));

            setMyPreferedList(myNewList)
        }else{
            setMyPreferedList([]);
        }
    
        return () => {
        }
        }, []
    );

    const loadMyListData = () => {
        const myNewList = [];
        const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]"  ? ctx.User?.myList : 0;
        if(myListInCtx !== 0){
            const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
            parsedList.forEach((item)=> myNewList.push(item));
            setMyPreferedList(myNewList)
        }else{
            setMyPreferedList([]);
        }

        return myNewList;
    }

    const setInitialData = (responseData)  => {
        setRequestInProgress(false)
        setHomeStats(responseData)
    }

    const setNewsData = (responseData)  => {
        const { news_list, update_date } = responseData;
        setNewsList(news_list);
    }

    const fetchData = async() => {
        let URL = process.env.REACT_APP_USER_HOME_STATS_API

        await customAxios.get(URL)
        .then(res => {
            setInitialData(res.data)
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "HomeStats", ctx.Config.Error.getLevels.error)
        })
    }

    const fetchNewsData = async() => {
        let URL = process.env.REACT_APP_NEWS_API

        await customAxios.get(URL)
        .then(res => {
            setNewsData(res.data)
        })
        .catch(error => {
        //   errorsMessages(error) Do not report news errors back to user
          MessageManager(error, "HomeNews", ctx.Config.Error.getLevels.error)
        })
    }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let msg = "An error was found. Please check the console for more information"
    // setMessage(error.response !== undefined ? error.response.data.detail : msg)
    displayMessage(error.response !== undefined ? error.response.data.detail : msg)
  }

  const displayMessage = (messageToDisplay) => {
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const gotoTopView = ()=>{
    navigate("/topcandidates");
  }

  const gotoGeneralView = ()=>{
    navigate("/GeneralView");
  }

  const gotoWorkflow = ()=>{
    navigate("/workflow");
  }

  const gotoReadyNextStepView = ()=>{
    navigate("/readynextstep");
  }

  const gotoMyListView = ()=>{
    navigate("/mylist");
  }

  const gotoNews = ()=>{
    navigate("/news");
  }

    return (
        <div className={styles.gridStatsContainer}>
            <div className={styles.statsContainer} >
                <div className={styles.gridContainer}>
                    <div className={styles.item1} onClick={()=>{gotoTopView()}}>
                        { 
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.top_fit_score}</div>
                        }
                        <div className={styles.countLabel}  > Top List </div>
                    </div>
                    <div className={styles.item2} onClick={()=>{gotoMyListView()}}>
                        { 
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {myPreferedList.length}</div>
                        }
                        <div className={styles.countLabel}  > My List </div>
                    </div>
                    <div className={styles.item3} onClick={()=>{gotoReadyNextStepView()}}>
                        { 
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.at_ready_next_step}</div>
                        }
                        <div className={styles.countLabel}  > Ready For Next Step </div>
                    </div>  
                    <div className={styles.item4} onClick={()=>{gotoWorkflow()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.at_feedback_in_progress}</div>
                        }
                        <div className={styles.countLabel}  > Feedback In-Progress </div>
                    </div>
                    <div className={styles.item5} onClick={()=>{gotoWorkflow()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.at_interview_process}</div>
                        }
                        <div className={styles.countLabel}  > In-Person Interviews </div>
                    </div>
                    <div className={styles.item6} onClick={()=>{gotoWorkflow()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.at_offer_process}</div>
                        }
                        <div className={styles.countLabel}  > Offers </div>
                    </div>
                    <div className={styles.item7} onClick={()=>{gotoWorkflow()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.at_candidate_follow_up}</div>
                        }
                        <div className={styles.countLabel}  > Follow Ups </div>
                    </div>
                    <div className={styles.item8} onClick={()=>{gotoWorkflow()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.at_initial_call}</div>
                        }
                        <div className={styles.countLabel}  > Phone Interviews </div>
                    </div>
                    <div className={styles.item9} onClick={()=>{gotoGeneralView()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.roles}</div>
                        }
                        <div className={styles.countLabel}  > Roles </div>
                    </div>
                    <div className={styles.item10} onClick={()=>{gotoGeneralView()}}>
                        {
                        requestInProgress === true 
                        ?
                        <img className={styles.indicator}  alt="Indicator" title="Indicator" src="/TO_Vertical_Line_Selection_Animation.svg" />
                        :
                        <div className={styles.countItem} > {homeStats.resumes}</div>
                        }
                        <div className={styles.countLabel}  > Candidates </div>
                    </div>
                    <div className={styles.item11} onClick={()=>{gotoNews()}}>

                        <div className={styles.countLabel}  >Latest News & Messages</div>

                        <div className={styles.newsItem} >
                            {
                                newsList.slice(0, 2).map( news =>
                                    <div key={news.id} className={styles.newsContainer} >
                                        <div className={styles.imageArea} >
                                            <img className={styles.image} width="64" title={news.title} src={news.image_url} />
                                        </div>
                                        <div className={styles.infoArea} >
                                            <div className={styles.title}>
                                                {news.title}
                                            </div>
                                            <div className={styles.credits}>
                                            {news.source} | {news.author}
                                            </div>
                                            {/* <div className={styles.description}>
                                                {news.description}
                                            </div> */}
                                            {/* <div className={styles.link}>
                                                <Link to={news.url} className={styles.linkStyle} target="_blank" >Go to publication</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default HomeStats;