import axios from 'axios';
// Change to support proxy
// const BASE_URL = 'http://localhost:8000';
// const BASE_URL = 'http://localhost';
const BASE_URL = '';

export default axios.create({
    baseURL: BASE_URL
});

export const customAxios = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

// export const axiosPrivate = axios.create({
//     baseURL: BASE_URL,
//     headers: { 'Content-Type': 'application/json' },
//     withCredentials: true
// });