
export function MoneyFormat(_number, _sep, currency) {
    _number = typeof _number != "undefined" && _number > 0 ? _number : "0";
    _number = _number.replace(new RegExp("^(\\d{" + (_number.length%3? _number.length%3:0) + "})(\\d{3})", "g"), "$1 $2").replace(/(\d{3})+?/gi, "$1 ").trim();
    if(typeof _sep != "undefined" && _sep != " ") {
        _number = _number.replace(/\s/g, _sep);
    }
    return (CurrencySymbol(currency) + _number);
}

function CurrencySymbol(currency){
    switch(currency) {
        case "USD":
            return "$";
        case "GBP":
            return "£";
        case "EUR":
            return "€";
        default:
            return "$";
    }

}