import styles from "./__HomeBaseTemplate.module.css";
import LeftNavBar from '../components/navbars/SideGlobalNavArea'
import TopGlobalNavArea from "../components/navbars/TopGlobalNavArea";
import ContextManager from "../common/Context/ContextManager";
import JobsWidget from '../components/navbars/JobsWidget'
import { Outlet } from 'react-router-dom';
import { useRef, useState, useEffect} from 'react';

const CompanySetup = () => {

  const [currentWidth, setCurrentWidth] = useState(0);
  const htmlContainerElement = useRef();
  const { ctx, setContext } = ContextManager()

  const [isSideNavBarOpen, setIsSideNavBarOpen] = useState(true);

  const setLeftNavBarOpen = ()=>{
    const newWidth = currentWidth < 550 ? 570 : 549;
    // if(isSideNavBarOpen){
      setCurrentWidth(newWidth);
    // }
  }

  const setNavBarOpenClose = () => {
    const isOpen = isSideNavBarOpen === true ? false : true
    ctx.User.isSideNavBarOpen = isOpen;
    setContext(ctx);
    setIsSideNavBarOpen(isOpen);
    setLeftNavBarOpen();
  }

  let movement_timer = null;
  const SET_EXPERIENCE_RESIZE_TIMEOUT = 100;
  window.addEventListener('resize', ()=>{
    clearInterval(movement_timer);
    movement_timer = setTimeout(captureHtmlContainerSize, SET_EXPERIENCE_RESIZE_TIMEOUT);
  });

  const MIN_SCREEN_LEFT_NAV_BAR_OPEN = 600
  const captureHtmlContainerSize = () =>{
    if(htmlContainerElement.current){
      // setWidth(htmlContainerElement.current.offsetWidth);
      const newWidth = htmlContainerElement.current.offsetWidth
      if(ctx.User.isSideNavBarOpen){
        // setIsSideNavBarOpen(true);
        setCurrentWidth(newWidth);
      }
    }
  }

  return (
    <div className={currentWidth > 550 ? styles.container : styles.templateContainerClosed} onLoad={captureHtmlContainerSize} ref={htmlContainerElement} >
      <div className={styles.navBars} >
        <TopGlobalNavArea closedNavBar={currentWidth} setNavBarOpenOrClosed={setNavBarOpenClose} />
        <div className={styles.LeftNavBarOpener} onClick={()=> setNavBarOpenClose()} ><img alt="Open/Close" src="/accordion_menu.svg" /></div>
        {/* <div className={styles.LeftNavBar} > */}
          <LeftNavBar closedNavBar={currentWidth} isSideNavBarOpen={isSideNavBarOpen} />
        {/* </div> */}
      </div>
      <div className={styles.contentArea}>
        {/* Main Content */}
        <Outlet />
      </div>
    </div>
  );
};

export default CompanySetup;
