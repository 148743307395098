
import { CreditCard, ApplePay, GooglePay, PaymentForm } from "react-square-web-payments-sdk";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { Link } from "react-router-dom";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {GetPlanSelection} from "../../common/Payments/Plans";
import {GetPlanName} from "../../common/Payments/PlanNames";
import { MoneyFormat } from "../../common/Payments/MoneyFormat"
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";

import {useRef, useEffect, useState, Suspense } from "react"

import styles from "./Payment.module.css";
import {ResponseCodes} from "../../common/Payments/ResponseCodes";

const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/
// const NAME_LASTNAME_REGEX = /^[A-Z][a-z-]{3,23}$/;
const NAME_LASTNAME_REGEX = /^[A-Za-z-]{3,70}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;


export default function Payment() {
  const appId = "sq0idp-q23cwPkddIB8s5mMXelf5Q";
  const locationId = "LSPT7QT1PM10Y";

  const navigate = useNavigate();
  const urlParameters = useParams();
  // const state = urlParameters.get("state");
  let {state} = useLocation();
  const customAxios = useCustomAxios();
  const [planToSubscribe, setPlanToSubscribe] = useState(0);
  const [userIdent, setUserIdent] = useState(0);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');
  const [paymentProcessResponseCode, setPaymentProcessResponseCode] = useState(0);
  const [freeProcessed, setFreeProcessed] = useState(false);

  // personal info form -----------------------------------------------------------

  const [userId, setUserId] = useState(0)
  const [userProfile, setUserProfile] = useState({})
  const [companyField, setCompanyField] = useState('')
  const [phoneField, setPhoneField] = useState('')
  const [isVisible, setIsVisible] = useState('none')

  const [nameField, setNameField] = useState("");
  const [nameFocus, setNameFocus] = useState(false);
  const [middleNameField, setMiddleNameField] = useState("");
  const [middleNameFocus, setMiddleNameFocus] = useState(false);
  const [lastNameField, setLastNameField] = useState("");
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validMiddleName, setValidMiddleName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [streetAddressField, setStreetAddressField] = useState("");
  const [aptSuiteField, setAptSuiteField] = useState("");
  const [countryField,setCountryField] = useState("");
  const [cityField, setCityField] = useState("");
  const [stateField, setStateField] = useState("");
  const [postalCodeField, setPostalCodeField] = useState("");
  const [storeInProfile, setStoreInProfile] = useState(false);
  const [storeCard, setStoreCard] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [usePreviousCard, setUsePreviousCard] = useState(false);
  
  const [fieldChange, setFieldChange] = useState(false)
  const [emailChange, setEmailChange] = useState(false)

  const nameFieldRef = useRef();
  const middleNameFieldRef = useRef();
  const lastNameFieldRef = useRef();
  const emailFieldRef = useRef();
  const errorMessageRef = useRef();    
  const validationCodeRef = useRef();
  const emailcardFormRef = useRef(null);

  const[nameFieldNoEmpty, setNameFieldNoEmpty] = useState(false);
  const[lastNameFieldNoEmpty, setLastNameFieldNoEmpty] = useState(false);
  const[emailFieldNoEmpty, setEmailFieldNoEmpty] = useState(false);
  const[countryFieldNoEmpty, setCountryFieldNoEmpty] = useState(false);
  const[streetFieldNoEmpty, setStreetFieldNoEmpty] = useState(false);
  const[aptFieldNoEmpty, setAptFieldNoEmpty] = useState(false);
  const[cityFieldNoEmpty, setCityFieldNoEmpty] = useState(false);
  const[stateFieldNoEmpty, setStateFieldNoEmpty] = useState(false);
  const[postalFieldNoEmpty, setPostalFieldNoEmpty] = useState(false);
  const[postalCardFieldNoEmpty, setPostalCardFieldNoEmpty] = useState(false);
  const[showPayButton, setShowPayButton] = useState(false);

  useEffect(() => {
    setValidName(NAME_LASTNAME_REGEX.test(nameField));
    setFieldChange(true);
    setNameFieldNoEmpty(nameField !== '' ? true : false);
    checkActivePay();
  }, [nameField])

  useEffect(() => {        
    setValidLastName(NAME_LASTNAME_REGEX.test(lastNameField));
    setFieldChange(true);
    setLastNameFieldNoEmpty(lastNameField !== '' ? true : false);
    checkActivePay();
  }, [lastNameField])

  useEffect(() => {        
    setValidMiddleName(NAME_LASTNAME_REGEX.test(middleNameField));
    setFieldChange(true);
    checkActivePay();
  }, [middleNameField])

  useEffect(() => {        
    // No validation for now
    setFieldChange(true);
    checkActivePay();
}, [companyField])

  useEffect(() => {        
      setValidEmail(EMAIL_REGEX.test(emailField));
      setFieldChange(true);
      setEmailChange(true);
      setEmailFieldNoEmpty(emailField !== '' ? true : false);
      checkActivePay();
  }, [emailField])

  useEffect(() => {        
    setCountryFieldNoEmpty(countryField !== '' ? true : false);
    checkActivePay();
}, [countryField])

useEffect(() => {        
  setStreetFieldNoEmpty(streetAddressField !== '' ? true : false);
  checkActivePay();
}, [streetAddressField])

useEffect(() => {        
  setAptFieldNoEmpty(aptSuiteField !== '' ? true : false);
  checkActivePay();
}, [aptSuiteField])


useEffect(() => {        
  setCityFieldNoEmpty(cityField !== '' ? true : false);
  checkActivePay();
}, [cityField])


useEffect(() => {        
  setStateFieldNoEmpty(stateField !== '' ? true : false);
  checkActivePay();
}, [stateField])

useEffect(() => {        
  setPostalFieldNoEmpty(postalCodeField !== '' ? true : false);
  checkActivePay();
}, [postalCodeField])


  const checkActivePay = ()=> {
    if(nameFieldNoEmpty & lastNameFieldNoEmpty & emailFieldNoEmpty &
        countryFieldNoEmpty & streetFieldNoEmpty & aptFieldNoEmpty &
        cityFieldNoEmpty & stateFieldNoEmpty & postalFieldNoEmpty &
        postalCardFieldNoEmpty
      ){
      setShowPayButton(true);
    }else{
      setShowPayButton(false);
    }

  }

  // personal info form -----------------------------------------------------------
  
  // get profile ------------------------------------------------------------------

  const fetchProfileData = async() => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_USER_DETAIL_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setInitialProfileData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "Payment", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  };

  const setInitialProfileData = (responseData)=>{    
    setUserProfile(responseData)
    setNameField(responseData.first_name !== undefined && responseData.first_name !== null ? responseData.first_name : "");
    setMiddleNameField(responseData.middle_name !== undefined && responseData.middle_name !== null ? responseData.middle_name : "");
    setLastNameField(responseData.last_name !== undefined && responseData.last_name !== null ? responseData.last_name : "");
    setCompanyField(responseData.company !== undefined && responseData.company !== null ? responseData.company : "");
    setEmailField(responseData.email_1 !== undefined && responseData.email_1 !== null ? responseData.email_1 : "");
    // setEmail2Field(responseData.email_2 !== undefined && responseData.email_2 !== null ? responseData.email_2 : "");
    setPhoneField(responseData.phone !== undefined && responseData.phone !== null ? responseData.phone : "");
    setCustomerId(responseData.billing_customer_id !== undefined && responseData.billing_customer_id !== null ? responseData.billing_customer_id : "");
  }


  // ^^^^ get profile -------------------------------------------------------------


  const { ctx, setContext } = ContextManager()

  useEffect(() => {
    const planSelected = urlParameters.sp !== null || urlParameters.sp !== undefined ? Number(urlParameters.sp): 0;
    // console.log("Plan: " + planSelected);
    setPlanToSubscribe(planSelected);

    if(Number(planSelected) === 1){
      setIsPaymentComplete(true);
    }else{
      setIsPaymentComplete(false);
    }

    fetchProfileData();

    // const cardInputField = document.getElementById("cardNumber");
    // cardInputField.addEventListener('onChange', handleChange);

    // console.log(">>>>>>>>>>>>>>>>> " + emailcardFormRef?.current.cardNumber.value)
  }, [])

  // const handleChange = (event)=>{
  //   console.log("Event type:" + event.type);
  // };


  // Required in SCA Mandated Regions: Learn more at https://developer.squareup.com/docs/sca-overview
  
  const selectedPlan = {currency: "USD" , plan: planToSubscribe};

  const displayMessage = (messageToDisplay) => {
    // showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  };

  const closeMessage = () =>{
    // if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show);
        setMessage("");
        setSuccessMessage(false);
        setIsRequestInProgress(false);
    // }
  }

  const getErrorMessageForAlert = (errorMessage, previousMessage)=>{
    
    const newLine = previousMessage.toString().length > 0 ? "\n\n" : '';
    
    if(errorMessage.toString().includes("CVV")){
      previousMessage += newLine + "The card issuer declined the request because the CVV value is invalid.";
    }else if(errorMessage.toString().includes("EXPIRATION")){
      previousMessage += newLine + "The card issuer declined the request because the card is expired.";
    }else if(errorMessage.toString().includes("ADDRESS")){
      previousMessage += newLine + "The card issuer declined the request because the postal code is invalid.";
    }else if(errorMessage.toString().includes("GENERIC_DECLINE")){
      previousMessage += newLine + "The card issuer declined the request because the card is invalid.";
    }else{
      previousMessage += newLine + "Error found. The card issuer declined the request.";
    };

    return previousMessage;
  };

  const updatePlanLocally = (newPlan)=>{
    let isSaved = false;

    try{
      newPlan = String(newPlan).replaceAll("'", '"')
      newPlan = (JSON.parse(newPlan));

      if(newPlan !== undefined && newPlan !== null){
          ctx.Resume.plan = newPlan.plan;
          ctx.Resume.companies = Number(newPlan.companies);
          ctx.Resume.roles = Number(newPlan.roles);
          ctx.Resume.uploads = Number(newPlan.uploads);
          ctx.Resume.reviews = Number(newPlan.reviews);
          ctx.Resume.compares = Number(newPlan.compares);
          ctx.Resume.chat = Number(newPlan.chat);
          setContext(ctx);
          // ContextManager.saveContext(ctx);
          localStorage.setItem("ctx", JSON.stringify(ctx));
          isSaved = true;
      }else{
        isSaved = false;
      }
    }catch(error){
      console.log(error);
      // MessageManager(error, "@payment/page | updatePlan", 3);
      isSaved = false;
    }

    return isSaved;

  }

  //Method to process the after payment and plan storage requests.
  const afterSubmitPayment = (result)=>{
    // const result = paymentResult;
    setIsRequestInProgress(false);
    if(result.status === ResponseCodes.PROCESSED){
      const hasBeenLocallySaved = updatePlanLocally(result.plan);
      // setIsRequestInProgress(false);
      setIsPaymentComplete(true);
      setPaymentProcessResponseCode(ResponseCodes.PROCESSED);
    }else if(result.status === ResponseCodes.PROCESSED_NOT_STORED){
      // updatePlan(result.plan);
      // setIsRequestInProgress(false);
      setIsPaymentComplete(true);
      // if()
      setPaymentId(result.message.payment.id);
      setOrderId(result.message.payment.orderId);
      setReceiptNumber(result.message.payment.receiptNumber);
      let savePaymentOrderNumber = "Payment was succesful but the plan couldn't be updated at this time.\nPlease go to your profile and add the following information:" ;
      savePaymentOrderNumber += "\nPayment Id: " + result.message.payment.id + "\nOrder Id: " + result.message.payment.orderId;
      // savePaymentOrderNumber += "\nPayment Number: " + result.message.payment.id + "\nOrder Number: " + result.message.payment.orderId + "\nReceipt Number: " + result.message.payment.receiptNumber
      setPaymentProcessResponseCode(ResponseCodes.PROCESSED_NOT_STORED);
      displayMessage(savePaymentOrderNumber);
    }else if(result.status === ResponseCodes.ERROR){
      let errorMessages = "";
      // setIsRequestInProgress(false);
      // console.log("Erorr found>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      // console.log(result.message)
      setPaymentProcessResponseCode(ResponseCodes.ERROR);
      // JSON.parse(result.message).map((error, i)=>{
      // JSON.parse(result.message).errors.map((error, i)=>{
      result.message.errors.map((error, i)=>{
        errorMessages = getErrorMessageForAlert(error.code, errorMessages);
        // console.log(error.code);
        // MessageManager(error, "@payment/page | afterSubmitPayment", 3);
      });

      displayMessage(errorMessages);
      setShowMessage(true);
    }else{
      let errorMessages = "Payment could not be processed. Check your information and try again.";
      displayMessage(errorMessages);
      setIsRequestInProgress(false);
      setShowMessage(true);
    }
  };

  const submitFreePlan = async ()=>{
    setFreeProcessed(true);
    setIsRequestInProgress(true);
    const result = await submitPayment(getPaymentObjectToPost(null, null));
    // afterSubmitPayment(result);
    if(result.status === ResponseCodes.PROCESSED){
      const hasBeenLocallySaved = updatePlanLocally(result.plan);
      setIsRequestInProgress(false);
      setIsPaymentComplete(true);
      setPaymentProcessResponseCode(ResponseCodes.PROCESSED);

      return congratulationsMessage();
    }
    else if(result.status === ResponseCodes.PROCESSED_NOT_STORED){
      setIsRequestInProgress(false);
      setIsPaymentComplete(true);

      let savePaymentOrderNumber = "Your session may have expired or there was a system issue preventing setting the plan.";
      savePaymentOrderNumber += "\nPlease sign-out/sign-in and try again.";
      displayMessage(savePaymentOrderNumber);
      setPaymentProcessResponseCode(ResponseCodes.PROCESSED_NOT_STORED);
      return notsavedFreeMessage();
    }
    else if(result.status === ResponseCodes.ERROR){
      let errorMessages = "";
      setIsRequestInProgress(false);
      setPaymentProcessResponseCode(ResponseCodes.ERROR);
      // JSON.parse(result.message).map((error, i)=>{
      result.message.errors.map((error, i)=>{
        errorMessages = getErrorMessageForAlert(error.code, errorMessages);
        // console.log(error.code);
        MessageManager(error, "@payment/page | submitFreePlan", 3);
      });

      displayMessage(errorMessages);
      setShowMessage(true);
    }
    else{
      let errorMessages = "Process Failed or Cancelled.";
      displayMessage(errorMessages);
      setIsRequestInProgress(false);
      setShowMessage(true);
    }
  };

  const getPaymentObjectToPost = (token, verified_token)=>{
    const paymentRequestObject = {
      "user_id": ctx.User.id,
      "verified_token": verified_token,
      "transaction_token": token,
      "selected_plan": selectedPlan,
      "extra_fee": 0,
      "is_customer": customerId === '' ? false : true,
      "customer_id": customerId === '' ? null : customerId,
      "first_name": nameField,
      "last_name": lastNameField,
      "email": emailField,
      "store_billing_info": storeInProfile,
      "store_card": storeCard,
      "phone_number": phoneField,
      "address_1": streetAddressField,
      "address_2": aptSuiteField,
      "city": cityField,
      "state": stateField,
      "postal_code": postalCodeField,
      "country": countryField
    };
    return paymentRequestObject;
  }

  const submitPayment = async(planToPost) => {
    let URL = process.env.REACT_APP_USER_PLAN_PAYMENT_API;
    const postData = planToPost;
    const response = await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      // console.log(res.data);
      return res.data
    })
    .catch(error => {
      errorsMessages(error);
      MessageManager(error, "Payment", ctx.Config.Error.getLevels.error);
      return error;
    })

    return response;
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let msg = "An error was found trying to make the payment. Wait a few seconds and try again."
    // setMessage(error.response !== undefined ? error.response.data.detail : msg)
    displayMessage(error.response !== undefined ? error.response.data.detail : msg)
  }

  const congratulationsMessage = ()=>{
    return(
      <div className={styles.pageSuccessMessage}>
        <div>Congratulations! The {GetPlanName(planToSubscribe)} plan is active.</div>
        <div className={styles.pageSuccessSubMessage}>Feel free to go to the <Link style={{textDecoration: "none"}} to={"/home"}>home page</Link> or any other area.</div>
      </div>
    )
  }

  const notsavedMessage = ()=>{
    return(
      <div className={styles.pageSuccessMessage}>
        <div>Payment for the {GetPlanName(planToSubscribe)} plan has been received.</div>
        <div className={styles.pageNoStorageMessage}>Please go to your <Link style={{textDecoration: "none"}} to={"/userprofile"}>profile</Link> and add the following information:</div>
        <div className={styles.pageNoStorageSubMessage}>Payment Id: {paymentId}<br/>Order Id: {orderId}</div>
      </div>
    )
  }

  const notsavedFreeMessage = ()=>{
    return(
      <div className={styles.pageSuccessMessage}>
        <div>The {GetPlanName(planToSubscribe)} plan couldn't been set.</div>
        <div className={styles.pageNoStorageFreeSubMessage}>Your session may have expired or there was a system issue preventing it.</div>
        <div className={styles.pageNoStorageFreeSubMessage}>Please sign out/sign-in and try again.</div>
      </div>
    )
  }


  const raiseVerificationFailed = (msgSouce)=>{
    let msg = "Card information is incorrect. Please correct the billing information or the credit card details."
    setMessage(msgSouce !== undefined && msgSouce !== null && msgSouce !== '' ? msgSouce : msg)
    displayMessage(msg);
  }

  return (
    <Suspense>
    <div className={styles.pageContainer} >
      <div className={styles.pageContainerHeader} >Thanks for choosing<span className={styles.pageContainerHeaderTalent} > Talent</span><span className={styles.pageContainerHeaderOpinion}> Opinion</span></div>
      <div className={styles.pageContainerSubHeader}>You are one step away to find the best candidate</div>
      <div className={styles.planSelectedLabel}>You have selected the {GetPlanName(planToSubscribe)} plan</div>
      <div className={styles.planSelectedBackLabel}>Please <Link style={{textDecoration: "none", color: "#0ea5e9"}} to={"/plans"}>go back</Link> if you would like to select a different plan.</div>
      <div className={styles.cardFormContainer} >
      { isPaymentComplete === false?
        <div className={styles.cardFormArea} >

      {/* User Info Section */}
      <div className={styles.userNameInfoArea}>
<div className={styles.basicInfoArea} >
  <div className={styles.subSectionLabel} >Basic information</div>
        <div className={styles.firstNameArea}>
            {/* <div className={styles.firstNameLabel}>First Name</div> */}
            <label className={nameField && !validName ? styles.invalidFieldLabel : styles.firstNameLabel}  htmlFor="first_name">First Name<span className={styles.neededField} >*</span></label>
            <div className={styles.firstNameInputArea}>
              <input className={styles.fistNameInputField} type="text" id="first_name" name="first_name" placeholder="Enter first name here..."
              ref={nameFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setNameField(e.target.value) } 
              value={nameField}
              required
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="nameMsg"
              onFocus={() => setNameFocus(true)}
              onBlur={() => setNameFocus(false)}
              />
              <input className={styles.userIdInputField} type="number" id="id" name="id" defaultValue={userId} />
            </div>
        </div>
        <div id="nameMsg" className={nameFocus && nameField && !validName ? styles.fieldMsg : styles.screenReaderHidden}>
          Numbers, spaces, and underscores are not allowed.
        </div>
        <div className={styles.lastNameArea}>
            {/* <div className={styles.lastNameLabel}>Last Name</div> */}
            <label className={lastNameField && !validLastName ? styles.invalidFieldLabel : styles.lastNameLabel}  htmlFor="last_name">Last Name<span className={styles.neededField} >*</span></label>
            <div className={styles.lastNameInputArea}>
              <input className={styles.lastNameInputField} type="text" id="Last_name" name="last_name" placeholder="Enter last name here..."
              ref={lastNameFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setLastNameField(e.target.value) } 
              value={lastNameField}
              required
              aria-invalid={validLastName ? "false" : "true"}
              aria-describedby="lastNameMsg"
              onFocus={() => setLastNameFocus(true)}
              onBlur={() => setLastNameFocus(false)}
              />
            </div>
        </div>
        <div id="lastNameMsg" className={lastNameFocus && lastNameField && !validLastName ? styles.fieldMsg : styles.screenReaderHidden}>
                Numbers and underscores are not allowed.
        </div>
      {/* </div> */}

      {/* Contact Info Section */}
      {/* <div className={styles.contactInfoArea}> */}
        <div className={styles.emailArea}>
            <label className={emailField && !validEmail ? styles.invalidFieldLabel : styles.emailLabel}  htmlFor="email_1">Email<span className={styles.neededField} >*</span></label>
            <div className={styles.emailInputFieldArea}>
              <input className={styles.email1InputField} type="text" id="email" name="email" placeholder="Enter primary email here..."
              ref={emailFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setEmailField(e.target.value) } 
              value={emailField}
              // readOnly={true}
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="emailInstructionMsg"
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
              />
            </div>
        </div>
        <div id="emailInstructionMsg" className={emailFocus && emailField && !validEmail ? styles.fieldMsg : styles.screenReaderHidden}>
                Valid emails has the form: yourname@domain.com.
        </div>
        <div className={styles.phoneArea}>
            <div className={styles.phoneLabel}>Phone</div>
            <div className={styles.phoneInputFieldArea}>
              <input className={styles.phoneInputField} type="text" id="phone" name="phone" placeholder="Enter phone number here..." value={phoneField} onChange={(e)=> {setPhoneField(e.target.value);}} />
            </div>
        </div>
</div>
<div className={styles.billingAddressArea} >
        <div className={styles.subSectionLabel} >Billing information</div>
        <div className={styles.companyArea}>
            <div className={styles.companyLabel}>Company</div>
            <div className={styles.companyInputFieldArea}>
              <input className={styles.companyInputField} type="text" id="company" name="company" placeholder="(Optional) Enter company name here..." value={companyField} onChange={(e)=> {setCompanyField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.countryArea}>
            <div className={styles.countryLabel}>Country<span className={styles.neededField} >*</span></div>
            <div className={styles.countryInputFieldArea}>
              {/* <input className={styles.countryInputField} type="text" id="country" name="country" placeholder="Enter country name here..." value={countryField} onChange={(e)=> {setCountryField(e.target.value);}}  /> */}
            
                <select class={styles.countryInputField} autocomplete="country" id="country" name="country" value={countryField} onChange={(e)=> {setCountryField(e.target.value);}}>
                    <option value="">Select a country</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Åland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia (Plurinational State of)</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="CV">Cabo Verde</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="BQ">Caribbean Netherlands</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">Congo, Democratic Republic of the</option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CW">Curaçao</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="CI">Côte d'Ivoire</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="SZ">Eswatini (Swaziland)</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">Heard Island and Mcdonald Islands</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">Korea, North</option>
                    <option value="KR">Korea, South</option>
                    <option value="XK">Kosovo</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">Macedonia North</option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia</option>
                    <option value="MD">Moldova</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar (Burma)</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestine</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn Islands</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthelemy</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="CS">Serbia and Montenegro</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SX">Sint Maarten</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syria</option>
                    <option value="TW">Taiwan</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey (Türkiye)</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UM">U.S. Outlying Islands</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VA">Vatican City Holy See</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Vietnam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.S</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                </select>
                {/* total - 252 */}

            </div>
        </div>
        <div className={styles.streetArea}>
            <div className={styles.streetLabel}>Street address<span className={styles.neededField} >*</span></div>
            <div className={styles.streetInputFieldArea}>
              <input className={styles.streetInputField} type="text" id="streetaddress" name="streetaddress" placeholder="Enter street address here..." value={streetAddressField} onChange={(e)=> {setStreetAddressField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.aptsuiteArea}>
            <div className={styles.aptsuiteLabel}>Apt./Suite<span className={styles.neededField} >*</span></div>
            <div className={styles.aptsuiteInputFieldArea}>
              <input className={styles.aptsuiteInputField} type="text" id="aptsuite" name="aptsuite" placeholder="Enter apt./suite here..." value={aptSuiteField} onChange={(e)=> {setAptSuiteField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.cityArea}>
            <div className={styles.cityLabel}>City<span className={styles.neededField} >*</span></div>
            <div className={styles.cityInputFieldArea}>
              <input className={styles.cityInputField} type="text" id="city" name="city" placeholder="Enter city here..." value={cityField} onChange={(e)=> {setCityField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.stateArea}>
            <div className={styles.stateLabel}>State<span className={styles.neededField} >*</span></div>
            <div className={styles.stateInputFieldArea}>
              <input className={styles.stateInputField} type="text" id="state" name="state" placeholder="Enter state here..." value={stateField} onChange={(e)=> {setStateField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.zipArea}>
            <div className={styles.zipLabel}>Postal Code<span className={styles.neededField} >*</span></div>
            <div className={styles.zipInputFieldArea}>
              <input className={styles.zipInputField} type="text" id="postal_code" name="postal_code" placeholder="Enter postal code here..." value={postalCodeField} onChange={(e)=> {setPostalCodeField(e.target.value);}} />
            </div>
        </div>
</div>
      </div>


          {/* CARD AREA --------------------------------------- */}
          <div className={styles.cardForm} >
            <div className={styles.priceToPayLabel} >{GetPlanName(planToSubscribe)} Plan | Total Amount: <span className={styles.priceSpan} >{MoneyFormat((GetPlanSelection(selectedPlan).amount/100).toString(), ",", "USD")}</span> </div>
            <PaymentForm
              applicationId={appId}
              locationId={locationId}
              createPaymentRequest={() => ({
                countryCode: "US",
                currencyCode: selectedPlan.currency,
                total: {
                  amount: GetPlanSelection(selectedPlan).amount.toString(),
                  label: "Total",
                },
              })}
              cardTokenizeResponseReceived={async (token, verified_token) => {
                if(verified_token.userChallenged !== true && (verified_token.token !== null & verified_token.token !== undefined & verified_token.token !== '')){
                  setIsRequestInProgress(true);
                  const result = await submitPayment(getPaymentObjectToPost(token.token, verified_token.token));
                  afterSubmitPayment(result);
                }else{
                  raiseVerificationFailed();
                }
              }}

              createVerificationDetails={() => ({
                amount: GetPlanSelection(selectedPlan.plan).amount.toString(),
                /* collected from the buyer */
                billingContact: {
                  addressLines: [streetAddressField, aptSuiteField],
                  familyName: nameField,
                  givenName: lastNameField,
                  countryCode: countryField,
                  city: cityField,
                },
                currencyCode: "USD",
                intent: 'CHARGE',
              })}

            >
              <ApplePay /><br/>
              
              <GooglePay /><br/>

              <CreditCard 
                callbacks={{postalCodeChanged: (event) => {
                  setPostalCardFieldNoEmpty(true)
                  checkActivePay();
                } }}

                buttonProps={{
                  css: {
                    visibility : showPayButton ? "visible" : "hidden",
                    backgroundColor: "#14baa2",
                    fontSize: "14px",
                    color: "#ebecf0",
                    "&:hover": {
                      backgroundColor: "#895bf295",
                    },
                  },
                }} 
              />
            </PaymentForm>
          </div>
        </div>

        :
        Number(planToSubscribe) === 1 && freeProcessed === false ?
        <>{ submitFreePlan().then(result => result) }</>
        :
        paymentProcessResponseCode === ResponseCodes.PROCESSED_NOT_STORED && freeProcessed === true ?
        <>{notsavedFreeMessage()}</>
        :
        paymentProcessResponseCode === ResponseCodes.PROCESSED_NOT_STORED ?
        <>{notsavedMessage()}</>
        :
        paymentProcessResponseCode === ResponseCodes.PROCESSED ?
        <>{congratulationsMessage()}</>
        :
        <></>
      }

      </div>
      {
        isRequestInProgress ? (
          <div className={styles.requestInProgressContainer} >
            <div className={styles.requestInProgressArea} >
              <img height='360' width='216' src='/TO_Logo_Bank_Anim.svg' /><br/>
              Payment is in-progress...
            </div>
          </div>
        ): ( 
          <div></div> 
        )
      }

      {
        showMessage ?
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        : <></>
      }

    </div>
    </Suspense>
  );
}
