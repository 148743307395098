import styles from "./_Home.module.css";
import MessageManager from "../common/ErrorManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";

import HomeStats from "../components/home/HomeStats";
import HomeNews from "../components/home/HomeNews.js";

const home = () => {

  return (
    
        <div className={styles.mainHomeArea}>
          <div className={styles.mainContentArea} >
            <div className={styles.welcomeLabelArea} >
              {/* <img className={styles.toLogo} src="/Talent_Logo_Txt_Color_Simple.svg" /> */}
              Welcome to <span className={styles.talentColorLogo}>Talent</span> <span className={styles.opinionColorLogo}>Opinion</span>
            </div>
            <div className={styles.statsArea} >
                <HomeStats />
            </div>
            {/* <div className={styles.newsArea} >
                <div className={styles.newsContainer} >
                  News                
                  <HomeNews />
                </div>
            </div> */}
          </div>
        </div>
  )
};

export default home;
