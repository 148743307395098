import styles from "./CompanyInfoArea.module.css";
import { useState, useEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";

const CompanyInfoArea = (props) => {

  const [aboutCompanyContent, setAboutCompanyContent] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState('none');
  const [companyInfoInterIsVisible, setCompanyInterVisible] = useState('inline');
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIcon);
  const [isNewCompany, setNewCompany] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [featureUsage, setFeatureUsage] = useState({});

  const urlParameters = useParams(props);
  const { ctx, setContext } = ContextManager();
  const customAxios = useCustomAxios();
  const navigate = useNavigate();
  let {state} = useLocation();
  let context = ctx;
  let redirect = false;

  let CompanyInfo = {
    id: companyId,
    name: companyName,      
    about: aboutCompanyContent,
    user_id: userId
  }

  let processing = true

  if(props.createnewcompany !== isNewCompany){
    setNewCompany(props.createnewcompany)
  }

  useEffect( () => {
    fetchFeatureUsageData();
    return () => {
    }
    }, []
  );

  useEffect( () => {
    if(props.saveAllChanges === true){
      if(isRequestInProgress === false){
        props.setSaveAllChanges(false);
        props.setNewStateToSave({name: "companyInfoArea", save: false});
        processPostingData();
      }
    }

    return () => {

    }
    }, [props]        
  );


  // Retrieve data first and populate any state
  useEffect( () => {
      // processing = true
      setUserId(context.User.id)

      if(urlParameters.id > 0){
        MessageManager("---Getting Company Info from URL>", "ComapanyInfoArea", ctx.Config.Error.getLevels.trace);
        fetchData(processing, urlParameters.id);
      }else if(ctx.Company.id > 0){
        MessageManager("---Getting Company Info from ctx>", "ComapanyInfoArea", ctx.Config.Error.getLevels.trace);
        fetchData(processing, ctx.Company.id);
      }else{
        MessageManager("id set to: 0", "ComapanyInfoArea", ctx.Config.Error.getLevels.trace)
        setCompanyId(0)
        ctx.Company.id = 0
        ctx.Company.name = ""
        setContext(ctx)
        setCompanyId(0)
        setCompanyName("")
        setAboutCompanyContent("")
      }

      return () => {
          processing = false
      }
    }, [isNewCompany, state]        
  );

  const setInitialData = (responseData)  => {
    const {id, name, about, user_id} = responseData
    setIsRequestInProgress(false);
    context.Company.id = id
    context.Company.name = name
    setContext(ctx)
    setCompanyId(id)
    setCompanyName(name)
    setAboutCompanyContent(about)
    props.setPageCompanyId(id)
    setSubmitButtonState(false)
    processing = false
    props.setNewStateToSave({name: "companyInfoArea", save: false});

    if(redirect){
      // navigate("/CompanySetup/" + id , {state: {newCo:true}});
    }

  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    
    let msg = "No company information is available."

    if(error?.response?.status === 500){
      msg = error.response.data.detail;
    }

    setIsRequestInProgress(false);
    // setMessage(error.response !== undefined ? error.response.data.detail : msg)
    // displayMessage(error.response !== undefined ? error.response.data.detail !== undefined || error.response.data.detail !== '' ? error.response.data.detail : msg : msg)
    setSuccessMessage(false);
    displayMessage(msg, "error");
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_HOST_DOMAIN_PORT

  // Get data using axios
  const fetchData = async(processing, companyId) => {
    // Change to support proxy
    // let URL = COMAIN_PORT +  process.env.REACT_APP_COMPANY_DETAIL_API
    let URL = process.env.REACT_APP_COMPANY_DETAIL_API
    URL += "/" + companyId
    MessageManager(URL, "CompanyInfoArea", 2)
    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
        if(processing === true){
          setInitialData(res.data)
        }            
    })
    .catch(error => {
      setCompanyId(0) //<--- TODO: Check this assignment if it makes sense for over all UX      
      processing = false
      errorsMessages(error)
      MessageManager(error, "ComapanyInfoArea", ctx.Config.Error.getLevels.error)
    })
  }

  const fetchFeatureUsageData = async() => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_RESUME_FEATURES_USAGE_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setFeatureUsageResponse(res.data)
    })
    .catch(error => {
        errorsMessages(error)
        MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  };

  const setFeatureUsageResponse = (responseData)  => {
    setFeatureUsage(responseData);
  }

  // Post data using the axios functionality
  const postData = async(processing) => {
    redirect = companyId > 0 ? false : true
    let PATH= companyId > 0 ? process.env.REACT_APP_COMPANY_VIEW_UPDATE_API : process.env.REACT_APP_COMPANY_VIEW_NEW_API
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  PATH
    let URL_PATH = PATH
    let URL = URL_PATH
    const postData = CompanyInfo
    // await axios.post(URL, postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.post(URL, postData)
    .then(res => {
      if(processing === true){
        setSuccessMessage(true);
        displayMessage("Company info has been saved.", "message");
        props.showMessage({type: "message", message: "Company info has been saved."});
        setInitialData(res.data)
      }
    })
    .catch(error => {
      processing = false
      errorsMessages(error)
      MessageManager(error, "ComapanyInfoArea", ctx.Config.Error.getLevels.error)
    })
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    processPostingData();
  }

  const processPostingData = () =>{

    fetchFeatureUsageData();

    if(Number(ctx.Resume.companies) !== -1 && Number(featureUsage.companies) >= Number(ctx.Resume.companies)){
      displayMessage("Limit reached. Your plan limit is " + ctx.Resume.companies + " company/companies. You have " + Number(Number(ctx.Resume.companies) - Number(featureUsage.companies))  + " companies left.", "error");
    }else if(checkIfContentIsEmpty(companyName) === true){
      setMessage('');
      setIsRequestInProgress(true);
      processing = true;
      postData(processing);
      //  displayMessage("Testing - companies:" + featureUsage.companies);
    }
  }

  const setSubmitButtonState = (state) => {
    if(state === true){
      setIsVisible('inline');
      props.setNewStateToSave({name: "companyInfoArea", save: true});
    }else{
      setIsVisible('none');
      props.setNewStateToSave({name: "companyInfoArea", save: false});
    }
  }

  const checkIfContentIsEmpty = (contentToCheck) => {
    var state = false
    if(contentToCheck.trim() === ""){
      setMessage('Company Name is required.');
      state = false;
    }else{
      state = true;
    }
    return state;
  }

  const setCompanyNameValue = (companyName) => {
    setCompanyName(companyName);
    setMessage('');
    setSubmitButtonState(checkIfContentIsEmpty(companyName));
  }

  const setAboutCompanyContentValue = (content) => {
    setAboutCompanyContent(content);
    setMessage('');
    setSubmitButtonState(true);
  }

  const setCompanyInterVisibleOnOff = () => {
    let isOn = companyInfoInterIsVisible === 'inline' ?  'none' : 'inline'
    setCompanyInterVisible(isOn)
    
    let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    setOpenCloseIconUp(iconStyle)
    
  }

  const displayMessage = (messageToDisplay, type) => {
    // ## Temporary disabled to enable global message on page
    // setShowMessage(true);
    // setMessage(messageToDisplay);
    if(type === "error"){
      // Remove this if enabled messages in this component
      setShowMessage(true);
      setSuccessMessage(false);
      setMessage(messageToDisplay);
      props.showMessage({type: "error", message: "*** Error found at the Company section. ***"});
    }else{
      props.showMessage({type: "message", message: messageToDisplay});
    }
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  return (
    <div>
      <div style={{display: companyInfoInterIsVisible}}>
        <form >
          <div className={styles.aboutArea}>
            <div className={styles.aboutInputFieldArea}>          
                <textarea value={aboutCompanyContent} onChange={(e) => {setAboutCompanyContentValue(e.target.value);}}               
                className={styles.aboutInputField} 
                id="aboutCompany" name="aboutCompany" 
                rows={4} cols={40} placeholder="Enter company information here, i.e., Brief of what the company does, the business unit you are working on, etc..."/>
            </div>
            <div className={styles.aboutLabelArea}>
              <div className={styles.aboutLabel}>About (Optional)</div>
            </div>
          </div>
          <div className={styles.companyNameArea}>
            <input className={styles.companyNameInputField} type="text" id="companyName" name="companyName" placeholder="Enter company name here..." value={companyName} onChange={(e)=> {setCompanyNameValue(e.target.value);}} />
            <input className={styles.companyIdInputField} type="number" id="id" name="id" defaultValue={companyId} />
          </div>
          <div className={styles.submitButtonArea} style={{display: isVisible}} onClick={handleSubmit}>
            <div >Save</div>
            <div >
              {/* <button className={styles.submitButton} type="submit" onClick={handleSubmit} >Save</button> */}
              <img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
            </div>
          </div>
          {/* <div className={styles.messageArea}>
            {message}
          </div> */}
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        </form>
      </div>
      {/* <img className={openCloseIconUp} onClick={setCompanyInterVisibleOnOff} alt="" src="/open-close-icon.svg" /> */}
    </div>
  );
};

export default CompanyInfoArea;
