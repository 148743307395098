import styles from "./Workflow.module.css";
import ProcessWorkflowView from "../components/workflow/ProcessWorkflowView";

const Workflow = () => {

  return (
    
        <div className={styles.mainWorkflowViewArea}>
            <div className={styles.headerViewArea} >Workflow Process</div>
            {/* <ProcessWorkflowView focusedReport={true} /> */}
            <ProcessWorkflowView />
        </div>
  )
};

export default Workflow;
