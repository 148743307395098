import styles from "./UserFeedback.module.css";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import { useRef, useState, useEffect } from 'react';

const UserFeedback = (props)=> {

    const [feedbackContent, setFeedbackContent] = useState('');
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [submitButtonState, setSubmitButtonState] = useState(false);
    const [feedbackHistory, setFeedbackHistory] = useState([]);
    const [pageTitle, setPageTitle] = useState('');
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [isHistoryVisible, setIsHistoryVisible] = useState(true);

    const urlParameters = useParams();
    const { ctx, setContext } = ContextManager()
    const customAxios = useCustomAxios()
    const navigate = useNavigate();

    const pageTitles = [
        {PageName: "companysetup", Title: "Company & Role Setup"},
        {PageName: "reviewdetail", Title: "Candidate Detail"}, 
        {PageName: "resumecompare", Title: "Compare Candidates"}, 
        {PageName: "resumechat", Title: "Candidate & Role Chat"},
        {PageName: "userprofile", Title: "User Profile"},
        {PageName: "report", Title: "Report"},
        {PageName: "feedback", Title: "General Feedback"},
        {PageName: "", Title: "General Feedback"},
        {PageName: "home", Title: "Home"},
        {PageName: "legal", Title: "Legal"},
        {PageName: "terms", Title: "Terms of Use"},
        {PageName: "faqs", Title: "FAQs"},
        {PageName: "news", Title: "News"},
        {PageName: "workflow", Title: "Workflow"},
        {PageName: "topcandidates", Title: "Top Candidates"},
        {PageName: "mylist", Title: "My List"},
        {PageName: "readynextstep", Title: "Ready Next Step"},
        {PageName: "generalview", Title: "General View"},
        {PageName: "calendar", Title: "Calendar"},
        {PageName: "plans", Title: "Plans"},
        {PageName: "plans/payment", Title: "Payment"},
    ];

    useEffect( () => {

        let pageName = ""
        pageTitles.map( (key) => {
            if(window.location.pathname.toLocaleLowerCase().includes(key.PageName)){
              setPageTitle(key.Title)
              pageName = key.Title
            }
            }
        );

        fetchData(process.env.REACT_APP_USER_FEEDBACK_READ_API, pageName);
    
        return () => {
        }
        }, [] 
    );

    const setFeedbackValue = (feedback) => {
        setFeedbackContent(feedback);

        if(feedback === ""){
          setSubmitButtonState(false);
        }else{
          setSubmitButtonState(true);
        }
    }

    const feedbackMessage = {
        page_name: pageTitle === '' ? 'General' : pageTitle,
        page_url: window.location.pathname.toLocaleLowerCase(),
        feedback: feedbackContent,
        feedback_title: feedbackTitle
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
    
        if(feedbackContent !== ''){
            postData(process.env.REACT_APP_USER_FEEDBACK_STORE_API);
        }
    }

    const setInitialData = (responseData) => {
        const {user_feedback_list, last_updated} = responseData
        
        setFeedbackHistory(user_feedback_list);
        setIsRequestInProgress(false);
        setShowMessage(false);
        // setPageTitle('')
        setFeedbackTitle('');
        setSubmitButtonState(false);
        setFeedbackContent('');

    }

    const fetchData = async(urlPath, pageName) => {
        setIsRequestInProgress(true)
        let URL_PATH = urlPath
        let URL = URL_PATH + "/" + pageName

        await customAxios.get(URL)
        .then(res => {
            setInitialData(res.data)
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "UserFeedback", ctx.Config.Error.getLevels.error)
        }) //Make sure to use proper funcion to capture error
    }

    const postData = async(urlPath) => {
        let URL_PATH = urlPath
        let URL = URL_PATH
        const postData = feedbackMessage

        await customAxios.post(URL , postData)
        .then(res => {
          setInitialData(res.data)
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "UserFeedback", ctx.Config.Error.getLevels.error)
        })
    }

    const errorsMessages = (error) => {

        let errorMsg = "";
    
        if(error?.response?.status === 400){
          errorMsg = "Unexpected error found when sending the feedback. Please try again."
        }else{
            errorMsg = "Unexpected error found. Please try again."
        }

        setIsRequestInProgress(false)
        displayMessage(errorMsg)
    }

    const displayMessage = (messageToDisplay) => {
        setShowMessage(true)
        setMessage(messageToDisplay)
    }

    const closeMessage = () =>{
        if(message){
            const show = showMessage === true ? false : true
            setShowMessage(show)
            setMessage("")
            setSuccessMessage(false)
        }
    }

    const setHistoryViewVisibleOnOff = ()=>{
        setIsHistoryVisible(isHistoryVisible ? false: true);
    }

    return (
        <div className={styles.container} >
            <div className={styles.feedbackPageTitle}>Feedback</div>
            <div >Your honest feedback is really appreciated.</div>
            <div className={styles.formArea}>
                <div className={styles.feedbackBoxArea}>
                    <div className={styles.feedbackTitle}>
                        <div >Title | Theme | Area</div> 
                        <input className={styles.titleInputBox} value={feedbackTitle} placeholder='Enter Title | Theme | Area here...' onChange={(e) => setFeedbackTitle(e.target.value)} />
                    </div>
                    <div className={styles.feedbackInputHeader} >Feedback</div>
                    <div className={styles.feedbackInputBox} >
                        <textarea value={feedbackContent} onChange={(e) => {setFeedbackValue(e.target.value);}}
                                className={styles.inputBox} 
                                id="feedback" name="feedback" 
                                rows={7} cols={108} placeholder="Enter your feedback here..."
                        />
                        <div className={submitButtonState ? styles.submitButtonOn : styles.submitButtonOff}>
                            <div className={styles.svgButton} onClick={handleSubmit}> <img height='48' width='28.8' src='/Submit_Button_Chat.svg' /> </div>
                        </div>
                    </div>
                </div>
                <div className={isHistoryVisible ? styles.feedbackHistoryContainer : styles.feedbackHistoryContainerClosed}>
                    <div  className={styles.feedbackHistoryMainHeaderContainer} >
                        <div className={styles.feedbackHistoryMainHeader} >Feedback History for {pageTitle}</div>
                        <div className={styles.historyViewOpenCloseArrow}>
                            <img className={isHistoryVisible ? styles.openCloseIcon : styles.openCloseIconUp} onClick={setHistoryViewVisibleOnOff} alt="" src="/open-close-icon.svg" />
                        </div>
                    </div>
                    <div className={styles.feedbackHistory}>
                                <div className={styles.feedbackHistoryHeadings}>
                                    <div className={styles.historyListTitleHeader} >Title | Theme | Area</div>
                                    <div className={styles.historyListItemHeader} >Feedback</div>
                                </div>
                        { 
                            feedbackHistory.map( (historyItem, index) =>
                                <div key={index} className={(index % 2 === 0) ? styles.feedbackHistoryList : styles.feedbackHistoryListGray} >
                                    <div className={styles.historyListItemTitle}>{historyItem.feedback_title}</div>
                                    <div className={styles.historyListItem}>{historyItem.feedback}</div>
                                </div>
                            
                            )
                        }
                    </div>
                </div>
            </div>
            { showMessage ? 
                <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
                    <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                        <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                        <div>
                            {message}
                        </div> 
                    </div>
                </div>
                : <></>
            }
            <div className={styles.feedbackPageOptionalAddress}>Optionally, send your feedback to: feedback@talentopinion.com</div>
        </div>
    );
};

export default UserFeedback;
