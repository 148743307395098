import styles from "./SideGlobalNavArea.module.css";
import { Link, useParams, Navigate, useNavigate, useLocation} from "react-router-dom";
import { useState, useEffect } from 'react';
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";

const SideGlobalNavArea1 = (props) => {
  

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();

  const [isNavBarOpen, setIsNavBarOpen] = useState(true);

  const gotToCalendar = () =>{
      navigate("/calendar", {state:{from: "nav"}});
  }


  useEffect(() => {
    if(props.closedNavBar){
      const isClosed = Number(props.closedNavBar) < 550 ? false : true;
      setIsNavBarOpen(isClosed);
    }
  }, [props])


  const gotToWorkflow = () =>{
    // if(urlParameters.id > 0 && urlParameters.rid > 0 && urlParameters.rsid > 0){
    //   navigate("/workflow/" + urlParameters.id + "/" + urlParameters.rid  + "/" + urlParameters.rsid, {state:{from: "nav"}});
    // }else if(ctx.Company.id > 0 && ctx.Role.id > 0 && ctx.Resume.id > 0){
    //   navigate("/workflow/" + ctx.Company.id + "/" + ctx.Role.id + "/" + ctx.Resume.id, {state:{from: "nav"}});
    // }else{
      navigate("/workflow", {state:{from: "nav"}});
    // }
  }

  const gotToChat = () =>{
    if(urlParameters.id > 0 && urlParameters.rid > 0 && urlParameters.rsid > 0){
      navigate("/resumechat/" + urlParameters.id + "/" + urlParameters.rid  + "/" + urlParameters.rsid, {state:{from: "nav"}});
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0 && ctx.Resume.id > 0){
      navigate("/resumechat/" + ctx.Company.id + "/" + ctx.Role.id + "/" + ctx.Resume.id, {state:{from: "nav"}});
    }else{
      navigate("/resumechat", {state:{from: "nav"}});
    }
  }

  const gotToCompanySetup = () =>{
    if(urlParameters.id > 0 && urlParameters.rid > 0){
      navigate("/companysetup/" + urlParameters.id + "/" + urlParameters.rid, {state:{from: "nav"}});
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0){
      navigate("/companysetup/" + ctx.Company.id + "/" + ctx.Role.id, {state:{from: "nav"}});
    }else if(urlParameters.id > 0){
      navigate("/companysetup/" + urlParameters.id, {state:{from: "nav"}});
    }else if(ctx.Company.id > 0){
      navigate("/companysetup/" + ctx.Company.id, {state:{from: "nav"}});
    }else{
      navigate("/companysetup", {state:{from: "nav"}});
    }
  }

  const gotToCompare = () =>{
    if(urlParameters.id > 0 && urlParameters.rid > 0){
      navigate("/resumecompare/" + urlParameters.id + "/" + urlParameters.rid, {state:{from: "nav"}});
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0 && ctx.Resume.id > 0){
      navigate("/resumecompare/" + ctx.Company.id + "/" + ctx.Role.id, {state:{from: "nav"}});
    }else{
      navigate("/resumecompare", {state:{from: "nav"}});
    }
  }

  const gotToReview = () =>{
    if(urlParameters.id > 0 && urlParameters.rid > 0 && urlParameters.rsid > 0){
      navigate("/reviewdetail/" + urlParameters.id + "/" + urlParameters.rid  + "/" + urlParameters.rsid, {state:{from: "nav"}});
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0 && ctx.Resume.id > 0){
      navigate("/reviewdetail/" + ctx.Company.id + "/" + ctx.Role.id + "/" + ctx.Resume.id, {state:{from: "nav"}});
    }else{
      navigate("/reviewdetail", {state:{from: "nav"}});
    }
  }

  const goToHome = () =>{
    navigate("/home", {state:{from: "nav"}});
  }

  const goToFocusedView = () =>{
    navigate("/topcandidates", {state:{from: "nav"}});
  }

  const goToWebsite = () =>{
    window.location.href = "https://talentopinion.com";
  }


  return (
    <div className={isNavBarOpen ? styles.sideGlobalNavArea : styles.sideGlobalNavAreaClosed}>
      <div className={styles.glovalNavLinksArea} >

        {/* <img className={styles.logoIcon_1} alt="" src="/Talent_Logo_Txt_Color_Simple_1.svg" onClick={()=> goToFocusedView()} />
        <img className={styles.logoIcon_2} alt="" src="/Talent_Logo_Txt_Color_Simple_2.svg" /> */}

        <div className={window.location.pathname.toLocaleLowerCase().includes("home") ? styles.homeAreaSelected :styles.homeArea} onClick={()=> goToHome()}> 

            <div className={styles.home}>Home</div>
            <img
              className={styles.homeIcon}
              alt=""
              src="/Home_Icon_64x64_Path.svg"
            />
        </div>

        <div className={window.location.pathname.toLocaleLowerCase().includes("companysetup") ? styles.configurationAreaSelected : styles.configurationArea } onClick={()=> gotToCompanySetup()}>

            <div className={styles.companySetup}>
              <p className={styles.company}>Co. & Role</p>
              <p className={styles.company}>Setup</p>
            </div>
            <img
              className={styles.configurationIcon}
              alt=""
              src="/configuration_icon_64x64_3.svg"
            />

        </div>

        <div className={window.location.pathname.toLocaleLowerCase().includes("reviewdetail") ? styles.reviewAreaSelected : styles.reviewArea} onClick={()=> gotToReview()}>
 
          <div className={styles.review}>Review</div>
          <img
            className={styles.reviewIcon}
            alt=""
            src="/Review_Icon_64x64.svg"
          />
 
        </div>

        <div className={window.location.pathname.toLocaleLowerCase().includes("resumecompare") ? styles.compareAreaSelected : styles.compareArea} onClick={()=> gotToCompare()}>
        
          <div className={styles.chatLabel}>Compare</div>
          <img
            className={styles.compareIcon}
            alt=""
            src="/Compare_Icon_64x64.svg"
          />

        </div>


        <div className={window.location.pathname.toLocaleLowerCase().includes("resumechat") ? styles.chatAreaSelected : styles.chatArea} onClick={()=> gotToChat()}>

          <div className={styles.chatLabel}>Chat</div>
          <img className={styles.chatIcon} alt="" src="/Chat_Icon_64x64.svg" />

        </div>

        <div className={window.location.pathname.toLocaleLowerCase().includes("workflow") ? styles.workflowAreaSelected : styles.workflowArea} onClick={()=> gotToWorkflow()}>

          <div className={styles.workflowLabel}>Workflow</div>
          <img className={styles.workflowIcon} alt="" src="/Workflow_Icon_64x64.svg" />

        </div>

        <div className={window.location.pathname.toLocaleLowerCase().includes("calendar") ? styles.calendarAreaSelected : styles.calendarArea} onClick={()=> gotToCalendar()}>

          <div className={styles.calendarLabel}>Calendar</div>
          <img className={styles.calendarIcon} alt="" src="/Calendar_Icon_white_2.svg" />

        </div>

      </div>
    </div>
  );
};

export default SideGlobalNavArea1;
