import styles from "./HomeNews.module.css";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";


const HomeNews = (props)=> {

    const [userId, setUserId] = useState(0)
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState(false)
    const [requestInProgress, setRequestInProgress] = useState(false)
    const [newsList, setNewsList] = useState([])

    const urlParameters = useParams();
    const { ctx, setContext } = ContextManager()
    const customAxios = useCustomAxios()
    const navigate = useNavigate();
    let {state} = useLocation();

    useEffect( () => {
        setUserId(ctx.User.id)

        if(true === true){
            setRequestInProgress(true)
            fetchData()
        }
    
        return () => {
        }
        }, []
    );


    const setInitialData = (responseData)  => {
        const { news_list, update_date } = responseData;
        setRequestInProgress(false);
        setNewsList(news_list);
    }

    const fetchData = async() => {
        let URL = process.env.REACT_APP_NEWS_API

        await customAxios.get(URL)
        .then(res => {
            setInitialData(res.data)
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "HomeNews", ctx.Config.Error.getLevels.error)
        })
    }

    // Errors captured when sending API requests
    const errorsMessages = (error) => {
        let msg = "An error was found. Please check the console for more information"
        // setMessage(error.response !== undefined ? error.response.data.detail : msg)
        displayMessage(error.response !== undefined ? error.response.data.detail : msg)
    }

    const displayMessage = (messageToDisplay) => {
        setShowMessage(true)
        setMessage(messageToDisplay)
    }

    const closeMessage = () =>{
        if(message){
            const show = showMessage === true ? false : true
            setShowMessage(show)
            setMessage("")
            setSuccessMessage(false)
            // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
            // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
        }
    }

    return (
        <div className={styles.mainNewsContainer}>
            {
                newsList.map( news =>
                    <div key={news.id} className={styles.newsContainer} >
                        <div className={styles.imageArea} >
                            <img className={styles.image} width="64" title={news.title} src={news.image_url} />
                        </div>
                        <div className={styles.infoArea} >
                            <div className={styles.title}>
                                {news.title}
                            </div>
                            <div className={styles.credits}>
                            {news.source} | {news.author}
                            </div>
                            <div className={styles.description}>
                                {news.description}
                            </div>
                            <div className={styles.link}>
                                <Link to={news.url} className={styles.linkStyle} target="_blank" >Go to publication</Link>
                            </div>
                        </div>
                    </div>
                )
            }
            {   showMessage ?
                <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
                    <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
                        <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                        <div>
                            {message}
                        </div> 
                    </div>
                </div>
                :<></>
            }

        </div>
    );

};

export default HomeNews;