
import styles from './Switch.module.css';
import React from 'react';

// const Switch = ({ isOn, toggleChangeHandler, onColor }) => {
const Switch = ({ isSwitchOn, switchToggleHandler, switchOnColor}) => {
  return (
    <div className={styles.switchContainer} >
      <input checked={isSwitchOn} onChange={switchToggleHandler} className={styles.switchCheckbox}
        id={`switchNew`} type="checkbox"
      />
      <label style={{ background: isSwitchOn && switchOnColor }} className={styles.switchLabel}
        htmlFor={`switchNew`} >
        <span className={styles.switchButton} />
      </label>
    </div>
  );
};

export default Switch;