import styles from "./CompanyViewReportList.module.css";
import MessageManager from "../../common/ErrorManager";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useRef, useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import ProcessStatusDisplayNames from "../../common/Status/ProcessStatusDisplayNames"
import UploadResume from "../resume/UploadResume";
import CalendarScheduler from '../calendar/CalendarScheduler';

const CompanyViewReportList = (props) => {

    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [tempReportList, setTempReportList] = useState([]);
    const [queueResumeList, setQueueResumeList] = useState([]);
    const [isItemOnQueue, setIsItemOnQueue] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [processStatus, setProcessStatus] = useState(-1);
    const [companyList, setCompanyList] = useState([]);
    const [companyRoleList, setCompanyRoleList] = useState([]);
    const [companyNameSelected, setCompanyNameSelected] = useState("All companies");
    const [companyIdSelected, setCompanyIdSelected] = useState(0);
    const [showRoleList,setShowRoleList] = useState(false);
    const [roleNameSelected, setRoleNameSelected] = useState("All roles");
    const [roleIdSelected, setRoleISelected] = useState(0);

    const [resumeListToProcess, setResumeListToProcess] = useState([]);
    const [isReviewButtonActive, setIsReviewButtonActive] = useState(false);
    const [isCompareButtonActive, setIsCompareButtonActive] = useState(false);
    const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(false);
    const [featureUsage, setFeatureUsage] = useState({});
    const [fromDeleteAction, setFromDeleteAction] = useState(false);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [tempResumeList, setTempResumeList] = useState([]);
    const [showScheduler, setShowScheduler] = useState(false);
    const [transferEvent, setTransferEvent] = useState({});

    const [width, setWidth] = useState(0);
    const [currentWidth, setCurrentWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [processedResumeColumnList, setProcessedResumeColumnList] = useState([]);
    const [myPreferedList, setMyPreferedList] = useState("[]");
    const [isCheck, setIsCheck] = useState([])

    const urlParameters = useParams();
    const { ctx, setContext } = ContextManager()
    const customAxios = useCustomAxios()
    const navigate = useNavigate();
    let {state} = useLocation();
    const htmlContainerElement = useRef();

    let fitAssessment = ["-", "NaF", "PoF", "GdF", "GtF"];
    // resume.fit_score > 6? fit_assessment[3] : resume.fit_score > 3 ?  resume.fit_score[2] : resume.fit_score > 0 ? resume.fit_score[1] : resume.fit_score[0]
    let fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus, styles.inProgresstatus];
    const reportColumns = { company_name: 0, 
                            role_name: 0, 
                            resume_name: 0, 
                            resume_fit_assessment: 0, 
                            resume_fit_score: 0,
                            resume_process_status: 0
                          };

    const statusSearchList = [
      "Ready for Next Step",
      "Rejected",
      "Reviewed",
      "Shared with Talent Team",
      "Candidate Follow up",
      "Shared with Hiring Lead/Team",
      "Internal Feedback In-Progress",      
      "Initial Call(s) In-Progresss",
      "Interview(s) In-Progress",
      "Offer Extended",
      "Hired"
    ];

    const [sortArrow, setSortArrow] = useState(reportColumns);


    let movement_timer = null;
    const SET_EXPERIENCE_RESIZE_TIMEOUT = 100;
    let initialSize = 800;
    const dateColValueMinSize = 390;
    const companyColValueMinSize = 750;
    const roleColValueMinSize = 550;
    const allColTitlesMinSize = 460;
    const mar = currentWidth < dateColValueMinSize ? 25 : 0;
  
    window.addEventListener('resize', ()=>{
      clearInterval(movement_timer);
      movement_timer = setTimeout(captureHtmlContainerSize, SET_EXPERIENCE_RESIZE_TIMEOUT);
    });
  
    const captureHtmlContainerSize = () =>{
      if(htmlContainerElement.current){
        setWidth(htmlContainerElement.current.offsetWidth);
        setHeight(htmlContainerElement.current.offsetHeight);
        initialSize = (htmlContainerElement.current.offsetWidth / processedResumeColumnList.length) - 10
        setWidth(initialSize)
        setCurrentWidth(htmlContainerElement.current.offsetWidth)
      }
    }

    useEffect( () => {
      // window.scrollTo(0, 0);
      // fetchFeatureUsageData();
      // prepAndFetchData();
      // setSortArrow(reportColumns);
      // All above ^ moved to this following function v
      runInitalDataRetrieval();
        
      return () => {
      }
      }, []
    );

    let refreshTimeoutId = 0;
    let testTimes = 0;
    const refreshTime = 3500 //in ms

    const runInitalDataRetrieval = ()=> {
      window.scrollTo(0, 0);
      // setIsItemOnQueue(false);
      fetchFeatureUsageData();
      prepAndFetchData();
      setSortArrow(reportColumns);
    }

    const prepAndFetchData = ()=>{
      const companyId = urlParameters.id !== null && urlParameters.id !== undefined ?
                        urlParameters.id : 0;

      const roleId =  urlParameters.rid !== null && urlParameters.rid !== undefined ?
                      urlParameters.rid : 0;

      setCompanyIdSelected(companyId);
      setRoleISelected(roleId);

      fetchReportData(companyId, roleId);
    }

    const setInitialData = (responseData, cid)  => {
        const {company_report_view_list, queue_resume_list, last_updated} = responseData
        setIsRequestInProgress(false);
        setIsItemOnQueue(false);
        setReportList(company_report_view_list === undefined || company_report_view_list === null ? [] : company_report_view_list);
        setTempReportList(company_report_view_list === undefined || company_report_view_list === null ? [] : company_report_view_list);
        const queueList = queue_resume_list === undefined || queue_resume_list === null ? [] : queue_resume_list;
        setQueueResumeList(queueList);

        let found = queueList.length > 0 ? true : false;
        if(found){
          refreshTimeoutId = setTimeout(runInitalDataRetrieval, refreshTime);
        }

        setShowMessage(false);

        setIsCheck([]);

        fetchCompanyAndRoleData(true, 0);

        loadMyListData();

        if(cid !== 0){
          setCompanyNameSelected(company_report_view_list[0].company_name);
          fetchCompanyAndRoleData(false, cid);
          // setShowRoleList(true);
        }else{
          setShowRoleList(false);
        }

      }

      const populateCompanyRoleList = (responseData, isCompany, cid)=>{
        if(isCompany === true){
          const {company_list, last_updated} = responseData;
          setCompanyList(company_list);
        }else{
          const {company_role_list, last_updated} = responseData;
          setCompanyRoleList(company_role_list);          
        }

        if(cid !== 0){
          setShowRoleList(true);
        }else{
          setShowRoleList(false);
        }
      }

      const fetchCompanyAndRoleData = async(isCompany, cid) => {
        let URL_PATH =  isCompany === true ? 
                        process.env.REACT_APP_COMPANY_LIST_API :
                        process.env.REACT_APP_COMPANY_ROLE_LIST_API + "/" + cid;
    
        // let URL = URL_PATH + "/" + companyId + "/" + roleId + "/70/1"
        let URL = URL_PATH;
        await customAxios.get(URL)
        .then(res => {
          populateCompanyRoleList(res.data, isCompany, cid);
        })
        .catch(error => {
            errorsMessages(error)
            MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
        })
    };


    // /{company_id}/{role_id}/{items_per_page}/{page_number}.
    // {company_id} = 0 retrieves all companies. role_id is ignored.
    // {company_id} != 0 retrieves specific company.
    // {company_id} != 0 + {role_id} != 0 retrieves specific role for specific company.
    // {items_per_page} max = 100
    const fetchReportData = async(cid, rid) => {
        let URL_PATH =  props?.focusedReport === true ? 
                        process.env.REACT_APP_COMPANY_VIEW_FOCUSED_REPORT_API :
                        props?.nextReport === true ?
                        process.env.REACT_APP_COMPANY_VIEW_READY_NEXT_API:
                        props?.myList === true ?
                        process.env.REACT_APP_COMPANY_VIEW_MYLIST_API:
                        process.env.REACT_APP_COMPANY_VIEW_REPORT_API
        
        // const companyId = cid !== 0 ? cid :
        //                   urlParameters.id !== null && urlParameters.id !== undefined ?
        //                   urlParameters.id : 0;
        
        // const roleId =  rid !== 0 ? rid :
        //                 urlParameters.rid !== null && urlParameters.rid !== undefined ?
        //                 urlParameters.rid : 0;

        let URL = URL_PATH + "/" + cid + "/" + rid + "/70/1"
        await customAxios.get(URL)
        .then(res => {
            setInitialData(res.data, cid);
        })
        .catch(error => {
            errorsMessages(error)
            MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
        })
    };

    const postData = async(urlPath, dataToPost) => {
        let URL = urlPath
        const postData = dataToPost
        await customAxios.post(URL , postData)
        .then(res => {
          // setInitialData(res.data);
          // console.log(res.data);
          updateListAfterStatusUpdate();
        })
        .catch(error => {
          errorsMessages(error)
          MessageManager(error, "AdditionalEvalCrit", ctx.Config.Error.getLevels.error)
        })
      }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let msg = "No candidates were found. Please select a different company and role combination."
    let msg2 = "Candidates were not able to be retrieved at this time. Please try again."
    // setMessage(error.response !== undefined ? error.response.data.detail : msg)
    let msg3 = "General error found. Please try again."
    let message = error.response !== undefined ? error.response.data.detail === null || error.response.data.detail === '' ? msg2 : error.response.data.detail : msg;
    
    if(error.response?.status === 422){
      message = "Request cannot be processed at this time. Try again, if this continues then please send us some feeback with the details.";
    }else if(error.response?.status === 504){
      message = "Request timeout. Please try again. If this continues then please send us some feeback with the details.";
    }

    
    displayMessage(message === '' ? msg3 : message);
  }

  const displayMessage = (messageToDisplay) => {
    setIsRequestInProgress(false);
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const searchItems = (searchValue) => {
    // setSearchInput(searchValue)

    if (searchValue !== '' && searchValue.length > 2) {

        let filteredData = []
        setIsSearching(true);

        const index = fitAssessment.findIndex((item) => {return item.toLowerCase() === searchValue.toLowerCase()});
        const statusIndex = statusSearchList.findIndex((item)=>{return item.toLocaleLowerCase().includes(searchValue.toLowerCase())});

        if(index > -1){
          const index2 = index - 1;
          filteredData = tempReportList.filter((item) => {
              return item.resume_fit_assessment === index2;
          });
        }else if(statusIndex > -1){
          const statusIndex2 = statusIndex - 1;
          filteredData = tempReportList.filter((item) => {
            return item.resume_process_status === statusIndex2;
          });
        }else if(searchValue.indexOf('-') > -1){
          searchValue = searchValue.replaceAll('-', '');
          searchValue =  searchValue.length === 0 || searchValue === '' ? -1 : searchValue;
          filteredData = tempReportList.filter((item) => {
              return item.resume_fit_score === Number(searchValue);
          });
        }else{
          filteredData = tempReportList.filter((item) => {
              return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase());
          })
        }

        setReportList(filteredData);
    }
    else{
        setIsSearching(false);
        setReportList(tempReportList);
    }
  }


  const sortItems = (fieldToSort) => {
    
    let sorteddData = []

    const reportListToSort = isSearching ? reportList : tempReportList;

    if(sortArrow[fieldToSort] === -1 || sortArrow[fieldToSort] === 0){
        sorteddData = [...reportListToSort].sort((itemA, itemB) => {
            setFieldSortArrowDirection(fieldToSort, 1);
            return itemA[fieldToSort] > itemB[fieldToSort] ? 1 : -1 ;
        });
    }else{
        sorteddData = [...reportListToSort].sort((itemA, itemB) => {
            setFieldSortArrowDirection(fieldToSort, -1);
            return itemA[fieldToSort] < itemB[fieldToSort] ? 1 : -1 ;
        });
    }

    setReportList(sorteddData);
  }

  const setFieldSortArrowDirection = (fieldName, direction) =>{
    const newReportColumns = reportColumns;
    newReportColumns[fieldName] = Number(direction);
    setSortArrow(newReportColumns);
  }

  const displayFieldArrow = (fieldName)=>{
    return Number(sortArrow[fieldName]) === 1 ? <img alt="" className={styles.arrowIcon} src="/Arrow_Up.svg" />:<img alt="" className={styles.arrowIcon} src="/Arrow_Down.svg" />
  }

  const goToReviewDetail = (cid, rid, rsid)=>{
    navigate("/reviewdetail/" + cid + "/" + rid + "/" + rsid +"?#", {state: {newResume:true}});
  }

  const goToCompanyDetail = (cid, rid)=>{
    navigate("/companysetup/" + cid + "/" + rid +"?#", {state: {newResume:true}});
  }
  
  const updateListAfterStatusUpdate = ()=>{
    fetchReportData(companyIdSelected, roleIdSelected);
    setSortArrow(reportColumns);
  }

  const selectStatus = (selectedStatus, cid, rid, rsid)=>{
    const url = process.env.REACT_APP_RESUME_PROCESS_STATUS_API  + "/" + cid + "/" + rid;
    setProcessStatus(selectedStatus);

    const dataToPost = {
      id: rsid,
      status: selectedStatus,
    }

    postData(url, [dataToPost]);
  }

  const processStatusMenu2 = (cid, rid, rsid, selectedStatus)=>{
    return(
        <>
            <div className={Number(selectedStatus) === -1 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(-1, cid, rid, rsid)}>Ready for Next Step</div>
            <div className={Number(selectedStatus) === 1 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(1, cid, rid, rsid)}>Reviewed</div>
            <div className={Number(selectedStatus) === 2 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(2, cid, rid, rsid)}>Shared with Talent Team</div>
            <div className={Number(selectedStatus) === 3 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(3, cid, rid, rsid)}>Candidate Follow up</div>
            <div className={Number(selectedStatus) === 4 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(4, cid, rid, rsid)}>Shared with Hiring Lead/Team</div>
            <div className={Number(selectedStatus) === 5 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(5, cid, rid, rsid)}>Internal Feedback In-Progress</div>            
            <div className={Number(selectedStatus) === 6 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(6, cid, rid, rsid)}>Initial Call(s) In-Progresss</div>
            <div className={Number(selectedStatus) === 7 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(7, cid, rid, rsid)}>Interview(s) In-Progress</div>
            <div className={Number(selectedStatus) === 8 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(8, cid, rid, rsid)}>Offer Extended</div>
            <div className={Number(selectedStatus) === 9 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(9, cid, rid, rsid)}>Hired</div>
            <div className={Number(selectedStatus) === 0 ? styles.processStatusMenuSelected : ''} onClick={()=>selectStatus(0, cid, rid, rsid)}>Rejected</div>
        </>
    );
  };

  const processStatusMenu = (cid, rid, rsid, selectedStatus)=>{
    return(
      <select className={styles.processStatusMenu2} id="selectedStatus" name="selectedStatus" value={selectedStatus} onChange={(e)=> {selectStatus(e.target.value, cid, rid, rsid);}}>
        <option value={-1}>Ready for Next Step</option>
        <option value={1}>Reviewed</option>
        <option value={2}>Shared with Talent Team</option>
        <option value={3}>Candidate Follow up</option>
        <option value={4}>Shared with Hiring Lead/Team</option>
        <option value={5}>Internal Feedback In-Progress</option>
        <option value={6}>Initial Call(s) In-Progresss</option>
        <option value={7}>Interview(s) In-Progress</option>
        <option value={8}>Offer Extended</option>
        <option value={9}>Hired</option>
        <option value={0}>Declined</option>
      </select>
    );
  };

  const getCompanyRole = (cid, companyName)=>{
    setCompanyNameSelected(cid === 0 ? "All companies": companyName);
    fetchCompanyAndRoleData(false, cid);
    
    setCompanyIdSelected(cid);

    if(cid === 0){
      props?.focusedReport === true ?
      navigate("/topcandidates", {state: {newResume:true}}):
      props?.nextReport === true ?
      navigate("/readynextstep/", {state: {newResume:true}}):
      props?.myList === true ?
      navigate("/mylist", {state: {newResume:true}}):
      navigate("/generalview", {state: {newResume:true}});
    }else{
      props?.focusedReport === true ?
      navigate("/topcandidates/" + cid, {state: {newResume:true}}):
      props?.nextReport === true ?
      navigate("/readynextstep/" + cid, {state: {newResume:true}}):
      props?.myList === true ?
      navigate("/mylist/" + cid, {state: {newResume:true}}):
      navigate("/generalview/" + cid, {state: {newResume:true}});
    }

    fetchReportData(cid, 0);

  };

  const getRole = (rid, roleName)=>{
    setRoleNameSelected(rid === 0 ? "All roles" : roleName);
    fetchReportData(companyIdSelected, rid);

    if(rid === 0){
      props?.focusedReport === true ?
      navigate("/topcandidates/" + companyIdSelected, {state: {newResume:true}}):
      props?.nextReport === true ?
      navigate("/readynextstep/" + companyIdSelected, {state: {newResume:true}}):
      navigate("/generalview/" + companyIdSelected, {state: {newResume:true}})
    }else{
    props?.focusedReport === true ?
    navigate("/topcandidates/" + companyIdSelected + "/" + rid, {state: {newResume:true}}):
    props?.nextReport === true ?
    navigate("/readynextstep/" + companyIdSelected + "/" + rid, {state: {newResume:true}}):
    navigate("/generalview/" + companyIdSelected + "/" + rid, {state: {newResume:true}})
    }
  }

// Manage candidate resumes-------------------------------

const setFeatureUsageResponse = (responseData)  => {
  setFeatureUsage(responseData);
}

const fetchFeatureUsageData = async() => {
  // Change to support proxy
  // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
  let URL_PATH = process.env.REACT_APP_RESUME_FEATURES_USAGE_API
  let URL = URL_PATH
  await customAxios.get(URL)
  .then(res => {
    setFeatureUsageResponse(res.data)
  })
  .catch(error => {
      errorsMessages(error)
      MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
  }) //Make sure to use proper funcion to capture error
};


const setResumeSelection = (resume_id, company_id, role_id) =>{
  let updatedResumeListToProcess = resumeListToProcess.filter(s => Number(s.id) !==  Number(resume_id))
  
  let newUnCheck = [];

  if(resumeListToProcess.length > updatedResumeListToProcess.length){
    newUnCheck = [...isCheck]
    newUnCheck[resume_id] = false;
  }else{

    const resume_to_analyze = {
      id: Number(resume_id),
      company_id: Number(company_id),
      role_id: Number(role_id),
      user_id: Number(ctx.User.id),
    }

    updatedResumeListToProcess = [...resumeListToProcess, resume_to_analyze];
    newUnCheck = [...isCheck]
    newUnCheck[resume_id] = true;
  }

  setIsCheck(newUnCheck);
  setResumeListToProcess(updatedResumeListToProcess);
  setButtonsSelectionState(updatedResumeListToProcess);
}


const setButtonsSelectionState = (updatedResumeListToProcess) =>{
  setIsReviewButtonActive(updatedResumeListToProcess.length > 0? true: false)
  setIsCompareButtonActive(updatedResumeListToProcess.length > 1? true: false)
  setIsDeleteButtonActive(updatedResumeListToProcess.length > 0? true: false)
}

// const goToReviewDetail = (rsid)=>{
//   if(window.location.pathname.includes("resumechat")){
//     navigate("/resumechat/" + ctx.Company.id + "/" + ctx.Role.id + "/" + rsid +"?#", {state: {newResume:true, newRole:true}});
//   }else{
//     navigate("/reviewdetail/" + ctx.Company.id + "/" + ctx.Role.id + "/" + rsid +"?#", {state: {newResume:true}});
//   }
// }

const analyzeSelectedResumes = (e) => {
  let proceed = false;

  fetchFeatureUsageData();

  if(resumeListToProcess.length === 0){
    displayMessage("No candidate's resumes have been selected to review.")
  }
  else if(Number(ctx.Resume.reviews) === -1){
    proceed = true;
  }
  else if(Number(ctx.Resume.reviews) !== -1){
    if(Number(featureUsage.reviews) >= Number(ctx.Resume.reviews)){
      displayMessage("Limit reached. Your plan only allows " + ctx.Resume.reviews + " analysis.")
    }else if(resumeListToProcess.length > Number(ctx.Resume.reviews) ){
      displayMessage("Analysis selection(s) is above plan Limit or above analysis left. Your plan limit is " + ctx.Resume.reviews + ". You have " + Number(Number(ctx.Resume.reviews) - Number(featureUsage.reviews))  + " analysis left. Remove from list.")
    }else if((resumeListToProcess.length + Number(featureUsage.reviews)) > Number(ctx.Resume.reviews) ){
      displayMessage("Plan limit will be reached. Your plan limit is " + ctx.Resume.reviews + ". You have " + Number(Number(ctx.Resume.reviews) - Number(featureUsage.reviews))  + " analysis left. Remove from list.")
    }else{
      proceed = true;
    }
  }else{
    //Nothing
  }

  if(proceed){
    setIsRequestInProgress(true);
    postDataToResumeProcess(process.env.REACT_APP_RESUME_REVIEWS_MULTIPLE_API)
  }
}

const compareSelectedResumes = (e) => {

  let company_Id = 0;
  let role_id = 0;
  let proceed = false;
  let compareList = [];

  fetchFeatureUsageData();

  if(resumeListToProcess.length === 0){
    displayMessage("No candidates have been selected to compare.")
  }
  else if(resumeListToProcess.length < 2){
    displayMessage("At least 2 candidates need to be selected")
  }
  else if(resumeListToProcess.length > 3){
    displayMessage("Compare upto 3 candidates at the same time. Remove from list." )
  }else if(resumeListToProcess.length <= 3){
    if(Number(ctx.Resume.compares) === -1){
      proceed = true;
    }
    else if(Number(ctx.Resume.compares) !== -1){
      // Send to backend will be allowed and backend will check for limits to allow side-by-side comparison
      // if(Number(featureUsage.compares) >= Number(ctx.Resume.compares)){
      //   displayMessage("Limit reached. Your plan only allows " + ctx.Resume.compares + " compares.")
      // }else if(resumeListToProcess.length > Number(ctx.Resume.compares)){
      //   displayMessage("Limit reached. Your plan only allows to compare " + ctx.Resume.compares + " candidates.")
      // }
      // else{
      proceed = true;
      // }
    }
  }
  {
    let isNotProcessed = false;
    let isDifferentRole = false;
    let candidateRole = 0;
    const localResumeListToProcess = [...resumeListToProcess];
    localResumeListToProcess.map((item, index, array)=>{
      // const itemToCheck = tempResumeList.find((itemTofind)=>{ return itemTofind.id === item;});
      // const itemToCheck = tempResumeList.filter((itemTofind) => { return Number(itemTofind.id) === Number(item);});
      const itemToCheck = tempReportList.filter((itemTofind) => { return Number(itemTofind.resume_id) === Number(item.id);});

      if(itemToCheck !== undefined && itemToCheck !== null){
        if(itemToCheck[0].resume_name === process.env.REACT_APP_INPROGRESS_RESUME || itemToCheck[0].resume_fit_assessment === -1 || itemToCheck[0].resume_fit_score === -1){
          proceed = false;
          isNotProcessed = true;
        }
        candidateRole = candidateRole === 0 ? itemToCheck[0].role_id : candidateRole;
        if(candidateRole !== itemToCheck[0].role_id){
          proceed = false;
          isDifferentRole = true;
        }

        company_Id = itemToCheck[0].company_id;
        role_id = itemToCheck[0].role_id;
        compareList.push(itemToCheck[0].resume_id);
      }
    });

    if(isDifferentRole === true){
      displayMessage("Candidates cannot be compared. All Candidates need to be within the same role and company." );
    }else if(isNotProcessed === true){
      displayMessage("In-Process candidates cannot be compared." );
    }
  }

  if(proceed){
    navigate("/resumecompare/" + company_Id + "/" + role_id, {state: {compare: true, compareIds: compareList}, replace: true});
  }

}

const deleteSelectedResumes = (e) => {
  
  if(resumeListToProcess.length < 1){
    displayMessage("No candidate's resumes have been selected to delete.")
  }
  else{
    setFromDeleteAction(true)
    setShowDeleteMessage(false)
    postDataToResumeProcess(process.env.REACT_APP_RESUME_DELETE_MULTIPLE_API)
  }
}

const postDataToResumeProcess = async(urlPath) => {

  let requestFrom = 0;
  props?.focusedReport === true ? 
  requestFrom = 2 :
  props?.myList === true ?
  requestFrom = 3:
  props?.nextReport === true ?
  requestFrom = 4 :
  requestFrom = 5

  // TODO: FIX THIS COMPANY AND ID
  let URL_PATH = urlPath;
  let URL = "";

  if(urlPath === process.env.REACT_APP_RESUME_DELETE_MULTIPLE_API){
    URL = URL_PATH
  }else{
    URL = URL_PATH;
  }
  
  const ResumesToProcess = {
    resume_list: resumeListToProcess,
    source_id: requestFrom
  }

  const postData = ResumesToProcess;

  await customAxios.post(URL , postData)
  .then(res => {
    setDataResumeProcessed(res.data)
  })
  .catch(error => {
    errorsMessages(error)
    MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
  })
}

const setDataResumeProcessed = (responseData)  => {
  // setIsRequestInProgress(false);
  // setShowMessage(false);

  if(fromDeleteAction === true){
    setShowDeleteMessage(false);
    setIsCheck([]);
    setResumeListToProcess([]);
    setFromDeleteAction(false);
    setButtonsSelectionState([]);
  }

  //Not active at CompanyViewReport
  // Process and check for In-progress analysis
  // InprocessResumesCheck(responseData, 0);
  prepAndFetchData();

}

const askDeleteQuestion = () =>{
  
  if(resumeListToProcess.length > 0){
    setShowDeleteMessage(true)
  }else{
    displayMessage("No candidate's resumes have been selected to delete.")
  }
}

const openModal = () =>{
  if(urlParameters.rid > 0 || ctx.Role.id > 0){
    clearTimeout(refreshTimeoutId);
    setIsRequestInProgress(false);
    setIsModalVisible(true);
    setModalVisibility(true);
  }else{
    displayMessage("No role has been selected to upload candidate's resumes.")
  }
}

// This is called from the UploadResume child
const uploadModalOpenAction = (status) => {
  if(props.uploadModalOpen){
    props.uploadModalOpen(false);
  }
  setIsModalVisible(false);
  setModalVisibility(false);
  setIsRequestInProgress(false);
}

const closeMessage = () =>{
  if(message){
      const show = showMessage === true ? false : true
      setShowMessage(show)
      setMessage("")
      setSuccessMessage(false)
  }
  setShowDeleteMessage(false)
}

const isIdOnMyPreferedList = (id)=>{
  const found = myPreferedList.includes(String(id));
  return found;
}

const loadMyListData = () => {
      
  let myNewList = [];
  if(myPreferedList[0] !== undefined && myPreferedList[0] !== null && myPreferedList[0] !== ''){
    const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]" ? ctx.User?.myList : 0;
    if(myListInCtx !== 0){
      const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
      parsedList.forEach((item)=> myNewList.push(item));
      setMyPreferedList(myNewList)
    }else{
      setMyPreferedList([]);
    }
  }else{
    myNewList = [...myPreferedList];
  }

  return myNewList;
}

  const openScheduler = (arg)=>{
    const guestList = [];
    resumeListToProcess.map(resume =>{
      const guestCandidate = tempReportList.filter((candidateTofind) => { return Number(candidateTofind.resume_id) === Number(resume.id);});
      // filteredData = tempReportList.filter((item) => {
      //   return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase());
      // });
      if(guestCandidate.length > 0){
        const contactInfo = {id: guestCandidate[0].resume_id, name: guestCandidate[0].resume_name, 
          email: guestCandidate[0].resume_email_1, role_id: guestCandidate[0].role_id, 
          resume_id: guestCandidate[0].resume_id, role_name: guestCandidate[0].role_name,
          company_name: guestCandidate[0].company_name };
          
          guestList.push(contactInfo);
      }
    });
    if(resumeListToProcess.length > 0){
    const todayDate = new Date();
    const event = 
      { id:"-1", title: "", start: todayDate, end: todayDate, className: styles.eventClick,
        extendedProps: {
          meeting_id: 0,
          location: '',
          description: '',
          guests: guestList, //[{name: '', email: '', role_id: 0, resume_id: 0}],
          url: ''
        },
      };
    setTransferEvent(event);
    setShowScheduler(true);
    }else{
      displayMessage("No candidates have been selected to create an invite.")
    }
  };

  const closeScheduler = ()=>{
    const show = showScheduler === true ? false : true;
    setShowScheduler(show);
  }

  const checkIfInQueue = (id) =>{
    id = Number(id);
    let isOnQueue = false;
    const found = queueResumeList.find( ql => Number(ql) === id);

    if(found){
      isOnQueue = true;
      // if(isItemOnQueue === false){
      //   setIsItemOnQueue(true);
      //   clearTimeout(refreshTimeoutId);
      //   testTimes = Number(testTimes) + 1;
      //   // if ( Number(testTimes) < Number(process.env.REACT_APP_INPROGRESS_REFRESHS) ){
      //   refreshTimeoutId = setTimeout(runInitalDataRetrieval, refreshTime);
      // }
    };

    return isOnQueue;
  };

  return (    
    <div className={styles.mainCompanyViewArea} onLoad={captureHtmlContainerSize} ref={htmlContainerElement}>
        <div className={styles.headerArea} >
                <div className={styles.filterArea} >
                    <div className={styles.filterLabel}></div>
                    <div className={styles.focusAreaSelected}>
                      <div className={styles.companyListLabel} >
                        <span>For: </span> {companyNameSelected}
                        <div className={styles.companyListContainer}>
                          <div onClick={()=> getCompanyRole(0, "All Companies")} >All Companies</div>
                          {
                            companyList.map((company, index)=>
                              <div key={index} onClick={()=> getCompanyRole(company.id, company.name)} >{company.name}</div>
                            )
                          }
                        </div>
                      </div>
                        {
                          showRoleList ? 
                          <div className={styles.roleListLabel}> 
                            <span>&nbsp;|&nbsp;</span>{roleNameSelected}
                            <div className={styles.roleListContainer}>
                              <div onClick={()=> getRole(0, "All roles")}>All roles</div> 
                              {
                                companyRoleList.map((role, index)=>
                                  <div key={index} onClick={()=> getRole(role.id, role.name)}>{role.name}</div>  
                                )
                              }
                            </div>
                          </div>
                          :<></>
                        }
                    </div>  
                    <div className={styles.filterInputArea}>
                        <input className={styles.filterInput} icon='search' placeholder='Search | Filter here...' onChange={(e) => searchItems(e.target.value)} />
                    </div>
                </div>
                {
                currentWidth > allColTitlesMinSize ?
                <div  className={styles.reportListHeaderArea} > 
                    {currentWidth > companyColValueMinSize ?
                    <div className={styles.companyNameAreaLabel} onClick={()=> sortItems("company_name")} >Company Name {Number(sortArrow.company_name) === 0 ? "" : displayFieldArrow("company_name")}</div>
                    : <></>}
                    { currentWidth > roleColValueMinSize ?
                    <div className={styles.roleNameAreaLabel} onClick={()=> sortItems("role_name")} >Role Name {Number(sortArrow.role_name) === 0 ? "" : displayFieldArrow("role_name")}</div>
                    :<></>
                    }
                    <div className={styles.resumeNameAreaLabel} onClick={()=> sortItems("resume_name")} >Candidate Name {Number(sortArrow.resume_name) === 0 ? "" : displayFieldArrow("resume_name")}</div>
                    <div className={styles.fitAssessmentAreaLabel} onClick={()=> sortItems("resume_fit_assessment")} >Fit Assessment {Number(sortArrow.resume_fit_assessment) === 0 ? "" : displayFieldArrow("resume_fit_assessment")}</div>
                    <div className={styles.fitScoreAreaLabel} onClick={()=> sortItems("resume_fit_score")} >Fit Score {Number(sortArrow.resume_fit_score) === 0 ? "" : displayFieldArrow("resume_fit_score")}</div>
                    <div className={styles.processAreaLabel} onClick={()=> sortItems("resume_process_status")} >Status {Number(sortArrow.resume_process_status) === 0 ? "" : displayFieldArrow("resume_process_status")}</div>
                    <div className={styles.selectAreaLabel} >Select</div>
                </div>
                :<></>
                }
        </div>
        <div className={styles.reportList} >
        {
            reportList?.map( CompanyReportView =>
                <div key={CompanyReportView.resume_id}  className={styles.reportListArea} > 
                    {currentWidth > companyColValueMinSize ?
                    <div className={styles.companyNameArea} onClick={()=> goToCompanyDetail(CompanyReportView.company_id, CompanyReportView.role_id)} >{CompanyReportView.company_name}</div>
                    : <></> 
                    }
                    { 
                    <div className={styles.roleNameArea} onClick={()=> goToCompanyDetail(CompanyReportView.company_id, CompanyReportView.role_id)} >{CompanyReportView.role_name}</div>
                    
                    }
                    <div className={isIdOnMyPreferedList(CompanyReportView.resume_id) ? styles.resumeNameAreaStar : styles.resumeNameArea} onClick={()=> goToReviewDetail(CompanyReportView.company_id, CompanyReportView.role_id, CompanyReportView.resume_id)} >{CompanyReportView.resume_name}</div>
                    <div className={fitAssessmentStyle[checkIfInQueue(CompanyReportView.resume_id) ? 5 : CompanyReportView.resume_fit_assessment === undefined || CompanyReportView.resume_fit_assessment === null ? 0 : CompanyReportView.resume_fit_assessment + 1]} >
                        {
                          CompanyReportView.resume_fit_assessment === undefined || CompanyReportView.resume_fit_assessment === null || CompanyReportView.resume_fit_assessment === -1 || checkIfInQueue(CompanyReportView.resume_id) ?
                          <img className={styles.analysisInProgresListItemImg} alt="" src="/Bi_Color_Progress_ListItem_Bar.svg" /> :
                          fitAssessment[CompanyReportView.resume_fit_assessment === undefined || CompanyReportView.resume_fit_assessment === null  ? 0 : CompanyReportView.resume_fit_assessment + 1]
                        }
                    </div>
                    <div className={styles.fitScoreArea} >
                        {CompanyReportView.resume_fit_score === undefined || CompanyReportView.resume_fit_score === null || CompanyReportView.resume_fit_score === -1 ? "-" : CompanyReportView.resume_fit_score}
                    </div>

                    <div className={styles.processNameArea} >{processStatusMenu(CompanyReportView.company_id, CompanyReportView.role_id, CompanyReportView.resume_id, CompanyReportView.resume_process_status)}
                    </div>

                    <div className={styles.selectStatus}>
                      <input type="checkbox" 
                              id={CompanyReportView.resume_id} 
                              name={CompanyReportView.resume_id}
                              className={styles.checkbox} 
                              value={isCheck[CompanyReportView.resume_id]}
                              onChange={(e)=> {setResumeSelection(CompanyReportView.resume_id, CompanyReportView.company_id, CompanyReportView.role_id);}}
                      />
                    </div>
                </div>
            )
        }{reportList.length < 1 ? 
          props?.focusedReport === true ? 
          <div className={styles.noResumesInfo} >No Great Fit or Good Fit candidates available to display.</div>
          :
          <div className={styles.noResumesInfo} >No candidates available to display.</div>
          :<><p/> </>}
        </div>

        <div className={styles.buttonsAndTitlesArea}>
        <div className={styles.buttonsArea}>
          <div className={isDeleteButtonActive ? styles.deleteButton : styles.deleteButtonInactive} onClick={(e)=> askDeleteQuestion(e)}>
            <div className={styles.deleteIcon}> <img className={styles.hoverColor} alt="Delete" title="Delete" src="/Trash_Logo_button_3.svg" /></div>
            <div className={styles.deleteLabel} >Delete</div>
          </div>
          <div className={isReviewButtonActive ? styles.inviteButton : styles.inviteButtonInactive} onClick={(e)=> openScheduler(e)}>
            <div className={styles.inviteIcon}> <img className={styles.hoverColor} alt="Create an invitation" title="Create an invitation" src="/Calendar_Icon_white_2_green.svg" /></div>
            <div className={styles.inviteLabel}  >Invite</div>
          </div>
          <div className={isCompareButtonActive ? styles.compareButton : styles.compareButtonInactive} onClick={(e)=> compareSelectedResumes(e)}>
            <div className={styles.compareIcon}> <img className={styles.hoverColor} alt="Compare" title="Compare" src="/compare-icon-button.svg" /></div>
            <div className={styles.compareLabel}  >Compare</div>
          </div>
          <div className={isReviewButtonActive ? styles.reviewButton : styles.reviewButtonInactive} onClick={(e)=> analyzeSelectedResumes(e)}>
            <div className={styles.reviewIcon}> <img className={styles.hoverColor} alt="Analyze" title="Analyze" src="/review-icon-button.svg" /></div>
            <div className={styles.reviewLabel}  >Analyze</div>
          </div>
          {
            urlParameters.rid > 0 ?
          <div className={urlParameters.rid > 0 ? styles.uploadButton : styles.uploadButtonInactive} onClick={(e)=> openModal(e)}>
            <div className={styles.uploadIcon}> <img className={styles.hoverColor} alt="Upload" width="48" height="28.8" title="Upload" src="/Upoad_Icon_64x64_Path_Simple.svg" /></div>
            <div className={styles.uploadLabel}  >Upload</div>
          </div>
          :
          <div className={styles.uploadButtonInactive} >
            <div className={styles.uploadIcon}> <img alt="Upload" width="48" height="28.8" title="Upload" src="/Upoad_Icon_64x64_Path_Simple.svg" /></div>
            <div className={styles.uploadLabel}  >Upload</div>
          </div>
          }

        </div>

      </div>
      <div><UploadResume openUploadModal={modalVisibility} uploadModalOpen={uploadModalOpenAction} inContainer={props.inContainer} /></div>
      <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
          <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
              <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
              <div>
                {message}
              </div> 
          </div>
      </div>
      {
        showDeleteMessage ?
        <div className={ showDeleteMessage ? styles.messageContainer : styles.hideModal } >
          <div className= {styles.deleteMessageArea}>
            <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
            Are you sure you would like to delete the selected resume(s)<br/>
            Resumes will be permanently deleted.
            <div className={styles.performDeleteButton} onClick={()=> deleteSelectedResumes()} >Delete</div>
          </div>
        </div>
        :<></>
      }
      { isRequestInProgress ?
          <div className={styles.progressContainer} >
              <div className={styles.progressArea} >
                  <div > <img className={styles.progressLogo}  src="/TO_Page_Progress.gif"  /></div>
                  <div>Processing candidate(s)...</div>
              </div>
          </div>
          :<></>
      }
      {
        showScheduler ?
        <div className={ showScheduler ? styles.messageContainer : styles.hideModal } >
          <CalendarScheduler closeScheduler={closeScheduler} event={transferEvent} />
        </div>
        :<></>
      }
    </div>
  );
};

export default CompanyViewReportList;
