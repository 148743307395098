
import { useRef, useState, useEffect, useContext } from 'react';
import MessageManager from "../ErrorManager";
import ContextManager from "../Context/ContextManager";

const IsNewSessionRequired = () => {
    const { ctx, setContext } = ContextManager()

    // const IsNewSessionRequired = ()=> {

        // To test time differences
        // 2023-11-15T01:10:46.192762
        // const sessionTimeOut = new Date(new Date("2023-12-14T06:16:49.000000").toISOString())        
        // const rightNow = new Date(new Date("2023-12-14T06:17:49.000000").toISOString())

        const rightNow = new Date()
        const sessionTimeOut = new Date(new Date(ctx.Session.sessionTimeOut ? ctx.Session.sessionTimeOut : "2023-11-11T01:45:00.000000").toISOString())
        // Use this to get the always positive value
        // const diffTime = Math.abs(sessionTimeOut - rightNow);
        const diffTime = (rightNow - sessionTimeOut);
        const diffMins = Math.ceil(diffTime / (1000 * 60));
        const isLoginRequired = rightNow >= sessionTimeOut ? true : false;


        // console.log("rightNow | sessionTimeOut: " + rightNow + " | " + sessionTimeOut);
        // console.log("isLoginRequired | diffTime: " + isLoginRequired + " | " + diffMins);
        // console.log("diffMins | diffTime: " + diffMins + " | " + diffTime);

        return isLoginRequired;
    // };
};

export default IsNewSessionRequired;