// import { useParams, useLocation, useNavigate } from "react-router-dom";
import Home from '../pages/_home';
import News from '../pages/News';
import CompanySetup from '../pages/CompanySetup';
import CompanySetup_guide_1 from '../pages/CompanySetup_guide_1';
import CompanySetup_guide_2 from '../pages/CompanySetup_guide_2';
import CompanySetup_guide_3 from '../pages/CompanySetup_guide_3';
import BaseTemplate from '../pages/__BaseTemplate'
import BaseTemplatePrint from '../pages/__BaseTemplatePrint';
import HomeBaseTemplate from '../pages/__HomeBaseTemplate'
import Jobs from '../pages/JobsWidgetPage'
import UploadResume from '../components/resume/UploadResume'
import ResumeReviewDetail from '../pages/ResumeReviewDetail'
import ResumeReviewDetailPrint from '../pages/ResumeReviewDetailPrint';
import ResumeCompare from '../pages/ResumeCompare';
import ResumeChat from '../pages/ResumeChat';
import ResumeChatWithMessage from '../pages/ResumeChatSelectMessage'
import ResumeCompareWithMessage from '../pages/ResumeCompareSelectMessage';
import ResumeReviewWithMessage from '../pages/ResumeReviewSelectMessage'
import Login from '../pages/Login';
import Logout from '../pages/logout';
import Registration from '../pages/Registration';
import VerifyEmail from '../pages/VerifyEmail';
import UserProfile from '../components/profile/UserProfile';
import WrongPathPage from '../pages/WrongPathPage';
import Legal from '../pages/Legal/Legal';
import TermsofUse from '../pages/Legal/TermsofUse';
import FAQs from '../pages/FAQs/FAQs';
import CompanyViewReport from '../pages/CompanyViewReport';
import CompanyViewFocusedReport from '../pages/CompanyViewFocusedReport';
import CompanyViewNextStepReport from '../pages/CompanyViewNextStepReport';
import CompanyViewMyListReport from '../pages/CompanyViewMyListReport';
import GlobalStatsView from '../pages/GlobalStatsView';
import Workflow from '../pages/Workflow';
import PaymentHome from '../pages/Payment/PaymentHome';
import Payment from '../pages/Payment/Payment';
import SubscriptionCancel from "../pages/Payment/SubscriptionCancel"
import CalendarHome from '../pages/Calendar/CalendarHome';
import TalentAvailability from '../pages/Calendar/TalentAvailability';
import EventTemplateDetail from '../pages/Calendar/EventTemplateDetail';
import RequireAuth from '../common/Security/RequireAuth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function Router() {

  const LayoutHome = () => {
    return (
      <HomeBaseTemplate />     
    )
  }
  
  const LayoutHomePrint = () => {
    return (
      <BaseTemplatePrint />     
    )
  }

  const Layout = () => {
    return (
      <BaseTemplate />     
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Tempory test pages _TO BE DELETED */}

        {/* ^^ Tempory test pages _TO BE DELETED  ^^ */}

        {/* Loging and registration pages */}
        <Route path='Signup' element={ <VerifyEmail /> } />
        <Route path='Verify' element={ <VerifyEmail /> } />
        <Route path='Register' element={<Registration />} />
        <Route path='Signin' element={ <Login /> } />
        <Route path='Login' element={ <Login /> } />
        <Route path='Signout' element={ <Logout /> } />
        <Route path='/Calendar/Availability/:token' element={ <TalentAvailability /> } />

        {/* components */}
        {/* Activate the code below by wrapping any route that requires role-based access, once role is active */}
        {/* <Route element={<RequireAuth allowedRoles={[ARRAY_HERE]} />}></Route> */}
        <Route element={<RequireAuth />}>
          <Route path='jobs' element={<Jobs />} />
          <Route path='jobs/:id' element={<Jobs />} />
          <Route path='jobs/:id/:rid' element={<Jobs />} />
          <Route path='upload/:id/:rid' element={<UploadResume />} />
        </Route>

        {/* Homepage layout/template and pages using it */}
        <Route element={<RequireAuth />}>
          <Route element={<LayoutHome />}>
          {/* <Route path='/' element={<HomeRouterBlank />} /> */}
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/news' element={<News />} />
            <Route path='/Legal' element={ <Legal /> } />
            <Route path='/Legal/Terms' element={ <TermsofUse /> } />
            <Route path='/GeneralView' element={ <CompanyViewReport /> } />
            <Route path='/GeneralView/:id' element={ <CompanyViewReport /> } />
            <Route path='/GeneralView/:id/:rid' element={ <CompanyViewReport /> } />
            <Route path='/TopCandidates' element={ <CompanyViewFocusedReport /> } />
            <Route path='/TopCandidates/:id' element={ <CompanyViewFocusedReport /> } />
            <Route path='/TopCandidates/:id/:rid' element={ <CompanyViewFocusedReport /> } />
            <Route path='/ReadyNextStep' element={ <CompanyViewNextStepReport /> } />
            <Route path='/ReadyNextStep/:id' element={ <CompanyViewNextStepReport /> } />
            <Route path='/ReadyNextStep/:id/:rid' element={ <CompanyViewNextStepReport /> } />            
            <Route path='/MyList' element={ <CompanyViewMyListReport /> } />
            <Route path='/MyList/:id' element={ <CompanyViewMyListReport /> } />
            <Route path='/MyList/:id/:rid' element={ <CompanyViewMyListReport /> } />            
            <Route path='/GlobalStatsView' element={ <GlobalStatsView /> } />
            <Route path='/GlobalStatsView/:id' element={ <GlobalStatsView /> } />
            <Route path='/GlobalStatsView/:id/:rid' element={ <GlobalStatsView /> } />
            <Route path='/FAQS' element={ <FAQs /> } />
            <Route path='/workflow' element={ <Workflow /> } />
            <Route path='/workflow/:id' element={ <Workflow /> } />
            <Route path='/workflow/:id/:rid' element={ <Workflow /> } />
            <Route path='/Plans' element={ <PaymentHome /> } />
            <Route path='/Plans/Payment/:sp' element={ <Payment /> } />
            <Route path='/Plans/Cancel' element={ <SubscriptionCancel /> } />
            <Route path='/Calendar' element={ <CalendarHome/> } />
            <Route path="/Calendar/TemplateList" element={ <EventTemplateDetail /> } />
          </Route>
        </Route>

        <Route element={<RequireAuth />}>
          <Route element={<LayoutHomePrint />}>
            <Route path='/resumeprint/:id/:rid/:rsid' element={ <ResumeReviewDetailPrint /> } />
          </Route>
        </Route>

        {/* Main layout/template and application pages */}
        <Route element={ <RequireAuth /> }>
          <Route element={<Layout />}>

            {/* <Route path='Contact' element={<Contact />} /> */}
            <Route path='/UserProfile' element={<UserProfile />} />
            <Route path='/CompanySetup' element={<CompanySetup />} />
            <Route path='/CompanySetup/:id' element={<CompanySetup />} />
            <Route path='/CompanySetup/:id/:rid' element={<CompanySetup />} />

            <Route path='/CompanySetupGuide1' element={<CompanySetup_guide_1 />} />
            <Route path='/CompanySetupGuide1/:id' element={<CompanySetup_guide_1 />} />
            <Route path='/CompanySetupGuide1/:id/:rid' element={<CompanySetup_guide_1 />} />

            <Route path='/CompanySetupGuide2' element={<CompanySetup_guide_2 />} />
            <Route path='/CompanySetupGuide2/:id' element={<CompanySetup_guide_2 />} />
            <Route path='/CompanySetupGuide2/:id/:rid' element={<CompanySetup_guide_2 />} />

            <Route path='/CompanySetupGuide3' element={<CompanySetup_guide_3 />} />
            <Route path='/CompanySetupGuide3/:id' element={<CompanySetup_guide_3 />} />
            <Route path='/CompanySetupGuide3/:id/:rid' element={<CompanySetup_guide_3 />} />

            <Route path='/ReviewDetail' element={<ResumeReviewWithMessage />} />
            <Route path='/ReviewDetail/:id/' element={<ResumeReviewWithMessage />} />
            <Route path='/ReviewDetail/:id/:rid/' element={<ResumeReviewWithMessage />} />
            <Route path='/ReviewDetail/:id/:rid/:rsid' element={<ResumeReviewDetail />} />          
            <Route path='/ResumeCompare' element={<ResumeCompareWithMessage />} />
            <Route path='/ResumeCompare/:id' element={<ResumeCompareWithMessage />} />
            <Route path='/ResumeCompare/:id/:rid' element={<ResumeCompare />} />
            <Route path='/ResumeChat' element={<ResumeChatWithMessage />} />
            <Route path='/ResumeChat/:id/:rid' element={<ResumeChatWithMessage />} />
            <Route path='/ResumeChat/:id/:rid/:rsid' element={<ResumeChat />} />
          </Route>
        </Route>

        {/* If nothing else matches */}
        <Route path='*' element={ <WrongPathPage /> } />
        <Route path='/404' element={ <WrongPathPage /> } />

      </Routes>
    </BrowserRouter>
  );
}

export default Router;