import styles from "./CompanyViewReport.module.css";
import MessageManager from "../common/ErrorManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";
import CompanyViewReportList from "../components/companysetup/CompanyViewReportList";

const CompanyViewReport = () => {

  return (
    
        <div className={styles.mainReportViewArea}>
            <div className={styles.headerViewArea} >General View for Companies | Roles | Candidates</div>
            <CompanyViewReportList />
        </div>
  )
};

export default CompanyViewReport;
