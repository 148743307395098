
import { useContext, useState } from "react";
import Context from "./Context";

function ContextManager(){
    const context = useContext(Context);
    const getContext = () => {
        if(context.User.id === 0 && localStorage.getItem("ctx") != null){ 
            const ctx = JSON.parse(localStorage.getItem("ctx"))
            // Add here any other object created in the ContextContainer
            context.User = ctx.User
            context.Company = ctx.Company
            context.Role = ctx.Role
            context.Resume = ctx.Resume
            context.Session = ctx.Session
            context.Config = ctx.Config
        };
        return context;
    };

    const [ctx, setContext] = useState(getContext())

    const saveContext = () => {
        localStorage.setItem("ctx", JSON.stringify(context))
        setContext(context)
    }

    return {
        setContext: saveContext, ctx
    }
}


export default ContextManager