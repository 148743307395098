// import { ContextManager } from "./common/Context/ContextManager";
// import ContextContainer from "./common/Context/ContextContainer"
import ContextManager from "../../common/Context/ContextManager";
import styles from "./PaymentHome.module.css";
// import { useRouter } from 'next/navigation';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useHistory } from "react"

export default function PaymentHome() {

  // const navigate = useRouter();
  // const navigate = useHistory();
  // const urlParameters = useSearchParams();
  // const state = urlParameters.get("state");
  // const plan = urlParameters.get("plan");

  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();
  // const [ctx, setContext] = useState(ContextContainer);
  // const context = ContextContainer;

  // const getContext = () => {
  //   if(ctx.User.id === 0 && localStorage.getItem("ctx") != null){ 
  //       const ctx_local = JSON.parse(localStorage.getItem("ctx"))
  //       // Add here any other object created in the ContextContainer
  //       context.User = ctx_local.User
  //       context.Company = ctx_local.Company
  //       context.Role = ctx_local.Role
  //       context.Resume = ctx_local.Resume
  //       context.Session = ctx_local.Session
  //       context.Config = ctx_local.Config
  //   };
  //   return context;
  // };

  useEffect(() => {
    // These two may not be needed
    // const newContext = getContext();
    // setContext(newContext);
  }, [])

  // Required in SCA Mandated Regions: Learn more at https://developer.squareup.com/docs/sca-overview
  async function verifyBuyer(payments, token) {
    const verificationDetails = {
      amount: '1.00',
      billingContact: {
        givenName: 'John',
        familyName: 'Doe',
        email: 'john.doe@square.example',
        phone: '3214563987',
        addressLines: ['123 Main Street', 'Apartment 1'],
        city: 'Chicago',
        state: 'IL',
        countryCode: 'US',
      },
      currencyCode: 'USD',
      intent: 'CHARGE',
    };
    // console.log("User: " + verificationDetails);
    // const verificationResults = await payments.verifyBuyer(
    //   token,
    //   verificationDetails,
    // );
    // return verificationResults.token;
    return verificationDetails;
  }
  // createVerificationDetails={verifyBuyer}

  const selectedPlan = {currency: "USD" , plan: 1};
  const paymentUrl = "/plans/payment/";
  const cancelSubscription = "/plans/cancel";

  return (
    <div className={styles.pageContainer} >
      <div className={styles.pageHeaderContainer} >
        <div className={styles.pageContainerHeader} >Unlock the power of<span className={styles.pageContainerHeaderTalent} > Talent</span><span className={styles.pageContainerHeaderOpinion}> Opinion</span></div>
        <div className={styles.pageContainerSubHeader}>Choose a plan to start analyzing candidates and find the best fit, faster than ever</div>
        {ctx.Resume.plan !== 'Free' ? <div className={styles.cancelPlan} onClick={()=>navigate(cancelSubscription)} >Cancel subscription</div> : <></>}
      </div>
      <div className={styles.planContainer} >

        <div className={styles.planArea} >
          {ctx.Resume.plan === 'Free' ? <div className={styles.selectedPlanMessage}>This is your current plan.</div> : <></>}
          <div className={styles.plan} >
            <div className={styles.planHeader} >Free <span className={styles.featureSubHeadline} >- No credit card needed </span></div>
            <div className={styles.planSubHeader} >For talent leaders looking to experience how AI can improve their process.</div>
            <div className={styles.planPriceArea} > 
              <div className={styles.planPrice} >$0 
                {/* <span className={styles.planMonth}>/ month</span>  */}
              </div>
              <div className={styles.planMonth} >/ month</div> 
            </div>
            <div className={styles.planSelectButton} onClick={()=> navigate(paymentUrl + 1) } >Select plan</div>
            <div className={styles.featureHeadline} >3 Analysis & Uploads</div>
            <ul className={styles.featureList}>
              <li>Create up-to 1 company</li>
              <li>Create up-to 1 Job/Role</li>
              <li>Have up-to 3 chats</li>
                <ul className={styles.subFeatureList}>
                    <li>With either resumes or the job/role</li>
                </ul>
              <li>Compare 1 set of up-to 3 resumes</li>
            </ul>
          </div>
        </div>

        <div className={styles.planArea} >
          {ctx.Resume.plan === 'Explorer' ? <div className={styles.selectedPlanMessage}>This is your current plan. <div className={styles.selectedPlan} onClick={()=>navigate(cancelSubscription)} >Cancel subscription</div><div className={styles.selectedPlanMessage2} >before selecting new one.</div></div> : <></>}
          <div className={styles.plan} >
            <div className={styles.planHeader} >Explorer</div>
            <div className={styles.planSubHeader} >For talent leaders looking to explore and narow their candidate's list.<br/>&nbsp;</div>
            <div className={styles.planPriceArea} > 
              <div className={styles.planPrice} >$10 
                {/* <span className={styles.planMonth}>/ month</span>  */}
              </div>
              <div className={styles.planMonth} >/ month</div> 
            </div>
            <div className={styles.planSelectButton} onClick={()=> navigate(paymentUrl+ 2) } >Select plan</div>
            <div className={styles.featureHeadline} >15 Analysis & Uploads</div>
            <ul className={styles.featureList}>
              <li>Create up-to 15 companies</li>
              <li>Create up-to 15 Jobs/Roles</li>
              <li>Have up-to 10 chats</li>
                <ul className={styles.subFeatureList}>
                    <li>With either resumes or jobs/roles</li>
                </ul>
              <li>Compare 5 sets of up-to 3 resumes</li>
            </ul>
          </div>
        </div>
        
        <div className={styles.planArea} >
          {ctx.Resume.plan === 'Standard' ? <div className={styles.selectedPlanMessage}>This is your current plan. <div className={styles.selectedPlan} onClick={()=>navigate(cancelSubscription)} >Cancel subscription</div><div className={styles.selectedPlanMessage2} >before selecting new one.</div></div> : <></>}
          <div className={styles.plan} >
            <div className={styles.planHeader} >Standard</div>
            <div className={styles.planSubHeader} >For talent leaders with a small to medium stream of applicants.<br/>&nbsp;</div>
            <div className={styles.planPriceArea} > 
              <div className={styles.planPrice} >$16 
                {/* <span className={styles.planMonth}>/ month</span>  */}
              </div>
              <div className={styles.planMonth} >/ month</div> 
            </div>
            <div className={styles.planSelectButton} onClick={()=> navigate(paymentUrl + 3) } >Select plan</div>
            <div className={styles.featureHeadline} >200 Analysis & Uploads</div>
            <ul className={styles.featureList}>
              <li>Unlimited companies</li>
              <li>Unlimited Jobs/Roles</li>
              <li>Have up-to 100 chats</li>
                <ul className={styles.subFeatureList}>
                    <li>With either resumes or jobs/roles</li>
                </ul>
              <li>Compare 100 sets of up-to 3 resumes</li>
            </ul>
          </div>
        </div>

        <div className={styles.planArea} >
          {ctx.Resume.plan === 'Pro' ? <div className={styles.selectedPlanMessage}>This is your current plan. <div className={styles.selectedPlan} onClick={()=>navigate(cancelSubscription)} >Cancel subscription</div><div className={styles.selectedPlanMessage2} >before selecting new one.</div></div> : <></>}
          <div className={styles.plan} >
            <div className={styles.planHeader} >Pro</div>
            <div className={styles.planSubHeader} >For talent leaders managing medium to large streams of applicants.<br/>&nbsp;</div>
            <div className={styles.planPriceArea} > 
              <div className={styles.planPrice} >$35 
                {/* <span className={styles.planMonth}>/ month</span>  */}
              </div>
              <div className={styles.planMonth} >/ month</div> 
            </div>
            <div className={styles.planSelectButton} onClick={()=> navigate(paymentUrl + 4) } >Select plan</div>
            <div className={styles.featureHeadline} >500 Analysis & Uploads</div>
            <ul className={styles.featureList}>
              <li>Unlimited companies</li>
              <li>Unlimited Jobs/Roles</li>
              <li>Have up-to 500 chats</li>
                <ul className={styles.subFeatureList}>
                    <li>With either resumes or jobs/roles</li>
                </ul>
              <li>Compare 500 sets of up-to 3 resumes</li>
            </ul>
          </div>
        </div>

        <div className={styles.planArea} >
          {ctx.Resume.plan === 'Premium' ? <div className={styles.selectedPlanMessage}>This is your current plan. <div className={styles.selectedPlan} onClick={()=>navigate(cancelSubscription)} >Cancel subscription</div><div className={styles.selectedPlanMessage2} >before selecting new one.</div></div> : <></>}
          <div className={styles.plan} >
            <div className={styles.planHeader} >Premium</div>
            <div className={styles.planSubHeader} >For talent leaders who need flexibility and are continually looking for the best fit.</div>
            <div className={styles.planPriceArea} > 
              <div className={styles.planPrice} >$65
                {/* <span className={styles.planMonth}>/ month</span>  */}
              </div>
              <div className={styles.planMonth} >/ month</div> 
            </div>
            <div className={styles.planSelectButton} onClick={()=> navigate(paymentUrl + 5) } >Select plan</div>
            <div className={styles.featureHeadline} >1500 Analysis & Uploads</div>
            <ul className={styles.featureList}>
              <li>Unlimited companies</li>
              <li>Unlimited Jobs/Roles</li>
              <li>Have up-to 1500 chats</li>
                <ul className={styles.subFeatureList}>
                    <li>With either resumes or jobs/roles</li>
                </ul>
              <li>Compare 1000 sets of up-to 3 resumes</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}
