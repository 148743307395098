import styles from "./CompanyViewReport.module.css";
import MessageManager from "../common/ErrorManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";
import CompanyViewReportList from "../components/companysetup/CompanyViewReportList";

const CompanyViewFocusedReport = () => {

  return (
    
        <div className={styles.mainReportViewArea}>
            <div className={styles.headerViewArea} >View of Top Candidates</div>
            <CompanyViewReportList focusedReport={true} />
        </div>
  )
};

export default CompanyViewFocusedReport;
