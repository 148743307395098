import styles from "./__BaseTemplatePrint.module.css";
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect} from 'react';

const __BaseTemplatePrint = () => {

  const {state} = useLocation();

  const pageTitles = [
    {PageName: "companysetup", Title: "Company & Role Setup"},
    {PageName: "reviewdetail", Title: "Review | Candidate Detail"}, 
    {PageName: "resumecompare", Title: "Compare Candidates"}, 
    {PageName: "resumechat", Title: "Candidate & Role Chat"},
    {PageName: "userprofile", Title: "User Profile"},
    {PageName: "resumeprint", Title: "Review | Candidate Detail"}
  ];


  const [pageTitle, setPageTitle] = useState('')
  useEffect( () => {

    // window.location.pathname.includes("resumechat")
    pageTitles.map( (key) => {
        if(window.location.pathname.toLocaleLowerCase().includes(key.PageName)){
          setPageTitle(key.Title)
        }
      } 
    );

    return () => {
    }
    }, [state] 
  )

  return (
    <div className={styles.container}>
      {/* <TopGlobalNavArea /> */}
      {/* <LeftNavBar /> */}
      <div className={styles.logo}> <img src='/Talent_Logo_Txt_Color_Simple.svg' /> </div>
      <div className={styles.pageTitle}> {pageTitle} </div>
      <div className={styles.contentArea}>
        <div className={styles.companiesWidgetArea}>
          {/* <JobsWidget /> */}
        </div>
        {/* Main Content */}
        <Outlet />
      </div>
    </div>
  );
};

export default __BaseTemplatePrint;
