import styles from "./FAQs.module.css";
import { useRef, useState, useEffect, useContext } from 'react';

const FAQs = (props) => {

    const faqOpen = [false, false, false, false, false, false, false, false, false, false, false, false]
    const [faqIsOpen, setFaqIsOpen] = useState(faqOpen);

    useEffect( () => {

        return () => {
        }
        }, []
    );

    const openFAQ = (faqId)=>{
        const faqToOpen = [...faqIsOpen];
        faqToOpen[faqId] = faqToOpen[faqId] ? false : true;
        setFaqIsOpen(faqToOpen);
    }

    return(

        <div className={styles.faqsContainer} >
            <div className={styles.faqsPageTitle} >
                Frequently Asked Questions
                <div className={styles.faqsPublishInfo}></div>
            </div>
            <div className={styles.faqsMainSectionContainer} >
                <div className={faqIsOpen[1] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(1)} >
                    <div className={faqIsOpen[1] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why Talent Opinion is different from other services?
                        <img className={faqIsOpen[1] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    Talent Opinion uses a combination of in-house knowledge base, NLP Model, and Large Language Models (LLMs) to perform a true analysis of candidates' resumes and their match against a job description/requirements/posting.
<p />
Talent Opinion generates a non-bias perspective of the possible match like you would hear from a colleague at work. Most services use algorithm matching based on skills and some use different levels of Machine Learning (ML), therefore you will see the matrixed view of their results, where Talent Opinion's results are fluid and more human like.
                    </div>
                </div>
                <div className={faqIsOpen[2] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(2)} >
                    <div className={faqIsOpen[2] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why do I receive a slight different rationale (opinion) if I run a new analysis?
                        <img className={faqIsOpen[2] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    Just like you may receive different perspectives from colleagues at work when you ask them for their opinion of a candidate's resume, then Talent Opinion is the same. On every new analysis* Talent Opinion may include a new perspective of a skill or a group of skills and experience. Nonetheless, the end result may not be that different.
<p />
*Talent Opinion uses a combination of in-house knowledge base, NLP Model, and Large Language Models (LLMs) to perform a true analysis of candidates' resumes.
                    </div>
                </div>
                <div className={faqIsOpen[3] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(3)} >
                    <div className={faqIsOpen[3] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        What analysis' results should I focus on first?
                        <img className={faqIsOpen[3] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                        Talent Opinion recommends that you first focus in all green-tagged Great Fit <span style={{"background-color": "var(--color-lightgreen)"}}>(GtF)</span> assessment results with a Fit Score of 8 or higher. Then, you should review the results with a Fit Score of 7 with a Good Fit <span style={{"background-color": "var(--color-deepskyblue)"}}>(GdF)</span> blue-tagged assessment. 
                        <p/>
                        Nonetheless, it will be up to you and your team to determine any next steps for all Talent Opinion findings.
                    </div>
                </div>
                <div className={faqIsOpen[4] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(4)} >
                    <div className={faqIsOpen[4] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why a lot of the analysis results are in the same assessment level?
                        <img className={faqIsOpen[4] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    There are multiple reasons why you might see a lot of results falling within the same assessment level or score, i.e., Good Fit (GdF) - Score 7, etc., including:
<p/>
1.- The Job Description/Requirements are written in a too general or high level manner.<br/>This will make the analysis of any candidate's resume look either a strong possible match or not a matching at all.
If this is the case, then Talent Opinion recommends that you edit the Job Description/Requirements to be as detailed as needed. You can do this within Talent Opinion without the need to re-import them. You can have a version that truly reflects the job position's requirements within Talent Opinion, and have a version out there in job boards and career pages that may attract a larger pool of potential candidates, if that is the goal.
<p/>
2.- The Job Description has a lot of buzzwords or the candidates' resumes have included as many buzzwords as possible to pass your job boards or ATS system.<br/>
If this is the case, then Talent Opinion recommends that you edit the Job Description/Requirements to reflect the job requirements reality. Within Talent Opinion you have the opportunity to tailor the job description/requirements to ensure that you get an analysis that help you focus only on those candidates that are more promising.
                    </div>
                </div>
                <div className={faqIsOpen[5] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(5)} >
                    <div className={faqIsOpen[5] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why a candidate's resume still says "In-Progress"?
                        <img className={faqIsOpen[5] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                        Talent Opinion has found that in some instances it is hard to read the uploaded candidate's resume document.
<br/>
If this is the case, then Talent Opinion recommends first to go to the candidate's review page, if the analysis could not be performed at uploading time, Talent Opinion detects this and prompts you to perform it.
<br/>
If the "In-Progress" label is still present, or you receive an error message from Talent Opinion, then you may want to delete this candidate's resume and re-upload a new version.
<p/>
Please check the "Why even after many tries I cannot see the analysis result?" FAQ for more information.
                    </div>
                </div>
                <div className={faqIsOpen[6] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(6)} >
                    <div className={faqIsOpen[6] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why even after many tries I cannot see the analysis result?
                        <img className={faqIsOpen[6] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    Talent Opinion has found that in some instances the uploaded candidate's resume document has internal structure issues and cannot be read properly, even if it can be viewed with no issues. PDF documents show this issue more often than Word or other file types.
<p/>
If this is the case, then Talent Opinion recommends to delete the candidate's resume, review the original document file and if necessary use a different file type (i.e. MS-Word). Then, create a new entry by uploading the candidate's resume again.
                    </div>
                </div>
                <div className={faqIsOpen[7] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(7)} >
                    <div className={faqIsOpen[7] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why I cannot delete a Company or a Role?
                        <img className={faqIsOpen[7] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    Talent Opinion is exploring the best user experience in order to avoid unintended company deletions than may include hundreds of resumes. Talent Opinion will enable this again once a good user experience is decided.
<p/>
Alternatively, you can always override any Company or Role by just simply changing the name, and changing the Role Description/Requirements. If you change the Role Description/Requirements then you need to select all candidates under that role and run a new analysis.
<p/>
Let us know if you have examples or ideas of a good user experience, or if you need this as part of your workflow.
                    </div>
                </div>
                <div className={faqIsOpen[8] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(8)} >
                    <div className={faqIsOpen[8] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        I changed/edit the Role Description/Requirements, what do I do now?
                        <img className={faqIsOpen[8] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    If you changed or edited the Role Description/Requirements then you need to select all the candidates you would like to analyze or review with this new change and then click the "Analyze" button/icon from the list. Only those selected will be analyzed.
                    </div>
                </div>
                <div className={faqIsOpen[9] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(9)} >
                    <div className={faqIsOpen[9] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Does Talent Opinion analysis has any bias?
                        <img className={faqIsOpen[9] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    Talent Opinion removes all names, email addresses, telephone numbers, etc., before performing any review, analysis or comparison to avoid any inherent bias in the Artificial Intelligence (AI) models. 
                    <br/>
                    No race, ethnicity, or group is identified, inferred, or used by Talent Opinion for any candidate. 

Nevertheless, users of Talent Opinion's chat capability are reminded the need to abide to the Terms of Use and avoid any misuse when using this capability.
                    </div>
                </div>
                <div className={faqIsOpen[10] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(10)} >
                    <div className={faqIsOpen[10] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        Why the chat says "I may not be able to answer your question right now"?
                        <img className={faqIsOpen[10] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                    Talent Opinion includes some preventive and security AI features to avoid as much as possible rogue and inappropriate questions by users and  rogue and inappropriate answers by our chat capability. This AI feature is configured to be on the more cautious side of things than on the relaxed one.
<p/>
In some instances, the chat capability may interpret your question as one or close to one of those having elements of inappropriateness, even if  your question does not appear to have one.
<p/>
Alternatives include rephrasing your question, using different words, or splitting your question into shorter ones if it is too long to let the chat capability know that it is not inappropriate.
< br />< br />
Talent Opinion is continually improving all capabilities and features, and exploring the best user experience for you. Let us know if you have examples or ideas of a good user experience so we can take notes about it.
                    </div>
                </div>
                <div className={faqIsOpen[11] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(11)} >
                    <div className={faqIsOpen[11] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        What do I need to do before send an invite or mesage through Talent Opinion?
                        <img className={faqIsOpen[11] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                        Go to your profile page and make sure that your company, title (or role), and phone number (optional) information is populated and up to date.
                        <p/>

                        Talent Opinion uses this information within the invites and message templates.
                        
< br />< br />
Talent Opinion is continually improving all capabilities and features, and exploring the best user experience for you. Let us know if you have examples or ideas of a good user experience so we can take notes about it.
                    </div>
                </div>
                <div className={faqIsOpen[12] ? styles.faqsTermSection : styles.faqsTermSectionClosed} onClick={() => openFAQ(12)} >
                    <div className={faqIsOpen[12] ? styles.faqsSectionTitleOpened : styles.faqsSectionTitle} >
                        How can I use my company's logo instead of the Talent Opinion's logo on invites and messages?
                        <img className={faqIsOpen[12] ? styles.openCloseIconUp : styles.openCloseIcon} src="/open-close-icon.svg"  />
                    </div>
                    <div className={styles.faqsSectionContent} >
                        <ul>
                            <li>
                                Go to your profile page and scroll down until you find the section with a light-green text link saying "<span style={{"color": "var(--color-lightseagreen)"}}>Upload your logo</span>". In additon to the light-green text link, you will be able to see a grayed-out "Upload" button on the far right side of the section.
                                <p/>
                            </li>
                            <li>
                                Click the light-green text link to find your preferred logo file within your device. Once you have selected the file, the logo's file name will display right under the link, and the upload button will turn to light-green as the text link.
                                <p/>
                            </li>
                            <li>
                                Press the upload button to upload your logo.
                                <p/>
                            </li>
                            <li>
                                Once your logo has been uploaded, then select "My Logo" option from the "Logo selected" drop-down menu.
                            </li>
                        </ul>
< br />< br />
Talent Opinion is continually improving all capabilities and features, and exploring the best user experience for you. Let us know if you have examples or ideas of a good user experience so we can take notes about it.
                    </div>
                </div>



            </div>
        </div>

    );
}

export default FAQs;