import useCustomAxios from '../../common/hooks/useCustomAxios';
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import {useRef, useEffect, useState, Suspense } from "react"
import 'react-calendar/dist/Calendar.css';
import styles from './CalendarScheduler.module.css'
import Calendar from 'react-calendar';
import InviteTemplateList from "../../common/Calendar/InviteTemplateList";
import GetInviteTemplateName from "../../common/Calendar/GetInviteTemplateName";
import CompanyViewReportListShort from '../companysetup/CompanyViewReportListShort';

// Calendar values
const ValuePiece = Date | null;
const Value = ValuePiece | [ValuePiece, ValuePiece];
const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/


export default function CalendarScheduler(props) {

  const [titleField, setTitleField] = useState("");
  const [nameFocus, setTitleFocus] = useState(false);
  const [startLabelField, setStartLabelField] = useState('');
  const [startDateValue, setStartDateValue] = useState(new Date());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [startTimeField, setStartTimeField] = useState("");
  const [endTimeField, setEndTimeField] = useState("");
  const [endLabelField, setEndLabelField] = useState('');
  const [smallCalendarValueStart, setSmallCalendarValueStart] = useState(new Date());
  const [smallCalendarValueEnd, setSmallCalendarValueEnd] = useState(new Date());
  const [hourList, setHourList] = useState([]);
  const [minuteList, setMinuteList] = useState([]);
  const [startHours, setStartHours] = useState(0);
  const [startMinutes, setStartMinutes] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [endMinutes, setEndMinutes] = useState(0);
  const [partOfStartDay, setPartOfStartDay] = useState('AM');
  const [partOfEndDay, setPartOfEndDay] = useState('AM');
  const [guestList, setGuestList] = useState([]);
  const [guestListFocus, setGuestListFocus] = useState(false);
  const [guestNames, setGuestNames] = useState('');
  const [descriptionInputField, setDescriptionInputField] = useState('');
  const [locationField, setLocationField] = useState('');
  const [meetingId, setMeetingId] = useState(0);
  const [url, setUrl] = useState('');
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [isSendRequestInProgress, setIsSendRequestInProgress] = useState(false);
  const [eventIndex, setEventIndex] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [inviteTemplate, setInviteTemplate] = useState(0);
  const [inviteTemplateName, setInviteTemplateName] = useState('Select a template*');
  const [isInviteTemplateSelected, setIsInviteTemplateSelected] = useState(false);
  const [isCandidateSearchVisible, setIsCandidateSearchVisible] = useState(false);
  const [candidateInvited, setCandidateInvited] = useState(false);
  const [eventTemplateList, setEventTemplateList] = useState([]);
  const [sentToMeOnly, setSentToMeOnly] = useState(false);


  const inputRef = useRef(null);
  const customAxios = useCustomAxios()
  const { ctx, setContext } = ContextManager()

  const getHours = ()=>{
    const hours = [];
    for(let h=1; h<13; h++){
      hours.push(h);
    }
    return hours;
  }

  const getMinutes = ()=>{
    const minutes = [];
    for(let m=0; m<60; m+=5){
      minutes.push(m);
    }
    return minutes;
  }

  useEffect( () => {
    setHourList(getHours);
    setMinuteList(getMinutes);
    setStartTimeField(getTimeFromDate(smallCalendarValueStart));
    setEndTimeField(getTimeFromDate(smallCalendarValueStart));
    
    if(!props.event){
      setStartDateValue(smallCalendarValueStart);
      setEndDateValue(smallCalendarValueStart);
    }
    setStartLabelField(getWeekdayMonthDayName(smallCalendarValueStart.toString(),Intl.DateTimeFormat().resolvedOptions().locale));
    setEndLabelField(getWeekdayMonthDayName(smallCalendarValueStart.toString(), Intl.DateTimeFormat().resolvedOptions().locale));

    fetchEventTemplatesData(0);

    return () => {

    }
  }, []
  );


  useEffect( () => {
    if(props.event){
      setTitleField(props.event.title);
      if(props.event.extendedProps){
        const guestEventList = []
        props.event.extendedProps.guests.map(guest =>{
          guestEventList.push(guest);
        } );

        setLocationField(props.event.extendedProps.location);
        setGuestList(guestEventList);
        setDescriptionInputField(props.event.extendedProps.description);
        setEventIndex(props.event.id);
        setUrl(props.event.extendedProps.url);
        setMeetingId(props.event.extendedProps.meeting_id);
        setInviteTemplate(props.event.extendedProps.template);
        //Replace this code later
        // setInviteTemplateName(GetInviteTemplateName(props.event.extendedProps.template));
        fetchEventTemplatesData(props.event.extendedProps.template);
      };

      const localStartDate = props.event.start;
      const localEndDate = props.event.end ? props.event.end : props.event.start;
      const localStartTime = getTimeFromDate(localStartDate.toString());
      const localEndTime = getTimeFromDate(localEndDate.toString());

      setStartDateValue(localStartDate);
      setEndDateValue(localEndDate);
      setStartLabelField(getWeekdayMonthDayName(localStartDate.toString(), Intl.DateTimeFormat().resolvedOptions().locale));
      setEndLabelField(getWeekdayMonthDayName(localEndDate.toString(), Intl.DateTimeFormat().resolvedOptions().locale));
      setStartTimeField(localStartTime);
      setEndTimeField(localEndTime);

      const startHoursToSet = getHoursFromDate(localStartDate.toString());
      const endHoursToSet = getHoursFromDate(localEndDate.toString());
      setStartHours(startHoursToSet);
      setPartOfStartDay(startHoursToSet > 11 ? "PM" : "AM")
      setStartMinutes(getMinutesFromDate(localStartDate.toString()));
      setEndHours(endHoursToSet);
      setPartOfEndDay(endHoursToSet > 11 ? "PM" : "AM");
      setEndMinutes(getMinutesFromDate(localEndDate.toString()));

      setSmallCalendarValueStart(localStartDate);
      setSmallCalendarValueEnd(localEndDate);

    }

    return () => {
    }
  }, [props]
  );

  useEffect( () => {
      const emailSide = guestNames.split(',');
      setValidEmail(EMAIL_REGEX.test(emailSide[emailSide.length-1].trim()));
    return () => {

    }
  }, [guestNames]
  );

  useEffect( () => {
    // guestList.map((guest=>{
    //   if(guest.resume_id !== undefined && guest.resume_id !== null && guest.resume_id > 0){
    //     setCandidateInvited(true);
    //     break;
    //   }else{
    //     setCandidateInvited(false);
    //   };
    // }));

    const tmpGuestList = [...guestList];
    const tmpGuestList2 = tmpGuestList.filter((e, index)=> e.resume_id !== undefined && e.resume_id !== null);
    if(tmpGuestList2.length > 0){
      setCandidateInvited(true);
    }else{
      setCandidateInvited(false);
    }

    // if(guestList.length < 1){
    //   setCandidateInvited(false);
    // }
  return () => {

    }
  }, [guestList]
  );


  useEffect( () => {
    if(inviteTemplate > 0){
      setIsInviteTemplateSelected(true);
    }else{
      setIsInviteTemplateSelected(false);
    }
    return () => {

    }
  }, [inviteTemplate]
  );

  const setInitialData = (responseData)  => {
    // const {id, company_role_resume_list, last_updated} = responseData
    setIsRequestInProgress(false);
    setIsSendRequestInProgress(false);
  };

  const setUpdateComplete = (responseData, eventForCalendar, closeSchedulerWindow)  => {
    // const {id, company_role_resume_list, last_updated} = responseData
    setIsRequestInProgress(false);
    setIsSendRequestInProgress(false);
    if(responseData){
      if(props.saveEvent){
        props.saveEvent(eventForCalendar, false);

        if(closeSchedulerWindow){
          closeScheduler();
        };
      };
    };
  };

  const postUpdateData = async(url, eventToSend, eventForCalendar, closeSchedulerWindow) => {
    await customAxios.post(url , eventToSend)
    .then(res => {
      setUpdateComplete(res.data, eventForCalendar, closeSchedulerWindow)
    })
    .catch(error => {
      console.log(error);
      errorsMessages(error)
      MessageManager(error, "CalendarScheduler", ctx.Config.Error.getLevels.error)
    })
  };
 
  const setSaveComplete = (responseData, eventForCalendar)  => {
    const {meeting_invite_list, last_updated} = responseData;
    setIsSendRequestInProgress(false);
    setIsRequestInProgress(false);
    if(meeting_invite_list){
      if(props.saveEvent){
        meeting_invite_list.map( (invite)=> {
          eventForCalendar.id = invite.id;
          eventForCalendar.extendedProps.meeting_id = invite.id;
        });
        props.saveEvent(eventForCalendar, true);
      };
      closeScheduler();
    };
  };

  const postSaveData = async(url, eventToSend, eventForCalendar) => {
    await customAxios.post(url , eventToSend)
    .then(res => {
      setSaveComplete(res.data, eventForCalendar)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "CalendarScheduler", ctx.Config.Error.getLevels.error)
    })
  };

  const setDeleteComplete = (responseData, eventForCalendar)  => {
    // const {id, company_role_resume_list, last_updated} = responseData
    setIsRequestInProgress(false);
    setIsSendRequestInProgress(false);
    if(responseData){
      if(props.deleteEvent){
        props.deleteEvent(eventForCalendar);
        closeScheduler();
      };
    };
  };

  const postDeleteData = async(url, eventToSend, eventForCalendar) => {
    await customAxios.post(url , eventToSend)
    .then(res => {
      setDeleteComplete(res.data, eventForCalendar)
    })
    .catch(error => {
      console.log(error);
      errorsMessages(error)
      MessageManager(error, "CalendarScheduler", ctx.Config.Error.getLevels.error)
    })
  };

  const setSendComplete = (responseData, eventForCalendar)  => {
    // const {id, company_role_resume_list, last_updated} = responseData
    setIsRequestInProgress(false);
    setIsSendRequestInProgress(false);
    if(responseData){
      closeScheduler();
    };
  };

  const postData = async(url, eventToSend) => {
    await customAxios.post(url , eventToSend)
    .then(res => {
      setSendComplete(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "CalendarScheduler", ctx.Config.Error.getLevels.error)
    })
  };

  const setEventTemplatesResponse = (responseData, idToSetTemplateName)  => {
    const {event_template_list, last_updated} = responseData;
    // setEventTemplateList(event_template_list);
    sortList(event_template_list);
    if(idToSetTemplateName !== undefined && idToSetTemplateName !== null && idToSetTemplateName > 0){
      setInviteTemplateName(GetInviteTemplateName(idToSetTemplateName, event_template_list));
    };
  }
  
  const fetchEventTemplatesData = async(idToSetTemplateName) => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_USER_CALENDAR_EVENT_TEMPLATES_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setEventTemplatesResponse(res.data, idToSetTemplateName);
    })
    .catch(error => {
        errorsMessages(error)
        MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  };

  const errorsMessages = (error) => {
    // let msg = "An error was found. Please check the console for more information"
    let msg = "Calendar invite cannot be save. Please review it and try again."
    // displayMessage(error.response !== undefined ? error.response.data.detail : msg);
    displayMessage(msg);
  }

  const displayMessage = (messageToDisplay) => {
    setIsRequestInProgress(false);
    setIsSendRequestInProgress(false);
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const transformStartDate = (dateStr)=>{
    const dateValue = new Date(dateStr);

    dateValue.setHours( getHourAs24h(startHours, true), startMinutes);
    setStartDateValue(dateValue);
    setStartLabelField(getWeekdayMonthDayName(dateStr, Intl.DateTimeFormat().resolvedOptions().locale));
    setSmallCalendarValueStart(new Date(dateStr)); //<-- this is fine converting original

  };

  const transformEndDate = (dateStr)=>{
    const dateValue = new Date(dateStr);
    dateValue.setHours( getHourAs24h(endHours, true), endMinutes);
    setEndDateValue(dateValue);
    setEndLabelField(getWeekdayMonthDayName(dateStr, Intl.DateTimeFormat().resolvedOptions().locale));
    setSmallCalendarValueEnd(new Date(dateStr)); //<-- this is fine converting original

  };

  const getTimeFromDate = (dateInfo)=> {
    const newDate = new Date(dateInfo);
    const hourData = newDate.getHours();
    const minutesData = newDate.getMinutes();
    const strHour = hourData < 10 ? "0" + String(hourData): String(hourData);
    const strHMins = minutesData < 10 ? "0" + String(minutesData): String(minutesData);
    const newTime = strHour + ":" + strHMins;

    return newTime;
  }

  const getJoinedDateAndHours = (dateStr, isStart)=> {
    const dateToJoinWHours = new Date(dateStr);
    const newJoinedDate = [
      dateToJoinWHours.getFullYear(), 
      dateToJoinWHours.getMonth() + 1, 
      dateToJoinWHours.getDate()
    ].join('-')
    + 'T' + isStart ? startTimeField : endTimeField;
    // [
    //   isStart ? startHours : endHours, 
    //   isStart ? startMinutes : endMinutes, '00'].join(':');

    return newJoinedDate;
  };

  const getHoursFromDate = (dateInfo)=> {
    const newDate = new Date(dateInfo);
    const hourData = newDate.getHours();
    
    return hourData;
  }

  const getMinutesFromDate = (dateInfo)=> {
    const newDate = new Date(dateInfo);
    const minutesData = newDate.getMinutes();

    return minutesData;
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const getSmallCalendar = (isStart)=> {
    if(isStart){
      return (<><Calendar onChange={transformStartDate} value={smallCalendarValueStart} className={styles.smallCalendarComponentBorder} /></>);
    }else{
      return (<><Calendar onChange={transformEndDate} value={smallCalendarValueEnd} className={styles.smallCalendarComponentBorder}  /></>);
    }
  }

  const setTemplate = (id, name)=>{
    setInviteTemplate(id);
    setInviteTemplateName(name);
  }

  const sortList = (event_template_list)=>{
    const fieldToSort = "category";
    const sorteddData = [...event_template_list].sort((itemA, itemB) => {
        return itemA[fieldToSort] > itemB[fieldToSort] ? 1 : -1 ;
    });

    setEventTemplateList(sorteddData);
  }

  const getInviteTemplateList = ()=>{
    return(
      <div className={styles.templateListContainer} >
      {
        eventTemplateList.map((template)=>
        <div key={template.id} className={inviteTemplate === template.id ? styles.templateItemSelected : styles.templateItem} onClick={()=> setTemplate(template.id,template.name)} >
          <div key={template.id + "2"} className={styles.templateItemLabelArea} >
            <span key={template.id + "3"} className={styles.templateItemLabel} >{template.name}</span><br/>
            <span key={template.id + "4"} className={styles.templateItemDescription} ><i>{template.summary}</i></span>
          </div>
          <div key={template.id + "5"} > <img key={template.id + "5a"} className={styles.templateImage}  src={"/invites/" + template.image_preview_small_file} /> </div>
        </div>
      )}
      </div>
    );
  }

//  should this method be using date & make one for start and one for use.
  function getWeekdayMonthDayName(dateStr, locale)
  {
    var date = new Date(dateStr);
    // options.timeZone = "CDT";
    options.timeZoneName = "short";
    // return date.toLocaleString("en-US", options);
    // return date.toLocaleDateString(locale, { weekday: 'long' });
    return date.toLocaleDateString(locale, { weekday: 'short', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}) + ", " + 
           date.toLocaleDateString(locale, { month: 'short', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}) + " " + 
           date.toLocaleDateString(locale, { day: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
  };

  const selectedHour = (hourSelected, isStart)=>{
    const has24 = getHourAs24h(hourSelected, isStart);
    let hStr = getHourMinuteAsString(has24);
    let mStr = getHourMinuteAsString(isStart ? startMinutes : endMinutes);
    hStr = hStr + ":" + mStr;

    if(isStart){
      setStartHours(has24);
      setStartTimeField(hStr);
      const dateToChangeTime = new Date(startDateValue.getTime());

      dateToChangeTime.setHours(has24, startMinutes);
      setStartDateValue(dateToChangeTime);
    }else{
      setEndHours(has24);
      setEndTimeField(hStr);
      const dateToChangeTime = new Date(endDateValue.getTime());
      dateToChangeTime.setHours(has24, endMinutes);
      setEndDateValue(dateToChangeTime);
    }
  };

  const selectedMinute = (minuteSelected, isStart)=>{

    if(isStart){
      let hStr = getHourMinuteAsString(startHours);
      let mStr = getHourMinuteAsString(minuteSelected);
      mStr = hStr + ":" + mStr;
      setStartMinutes(Number(minuteSelected));
      setStartTimeField(mStr);
      const dateToChangeTime = new Date(startDateValue.getTime());
      dateToChangeTime.setHours(startHours, minuteSelected);
      setStartDateValue(dateToChangeTime);
    }else{
      let hStr = getHourMinuteAsString(endHours);
      let mStr = getHourMinuteAsString(minuteSelected);
      mStr = hStr + ":" + mStr;
      setEndMinutes(Number(minuteSelected));
      setEndTimeField(mStr);
      const dateToChangeTime = new Date(endDateValue.getTime());
      dateToChangeTime.setHours(endHours, minuteSelected);
      setEndDateValue(dateToChangeTime);
    }

  };

  const partOfDayList = (partSelected, isStart)=>{
    console.log(">>>>>>>>>>>>>>>>>>>>>> partSelected; " + partSelected);
    if(isStart){
      let hour_z = Number(startHours);
      if(partSelected === "PM" && hour_z < 12){
        hour_z = hour_z + 12;
      }else if(partSelected === "AM" && hour_z >= 12){
        hour_z = hour_z - 12;
      }
  
      setStartHours(hour_z);

      const dateToChangeTime = new Date(startDateValue.getTime());
      dateToChangeTime.setHours(hour_z, startMinutes);
      setStartDateValue(dateToChangeTime);

      hour_z = getHourMinuteAsString(hour_z);
      let mStr = getHourMinuteAsString(startMinutes);
      const part = hour_z + ":" + mStr;
      setPartOfStartDay(partSelected);
      setStartTimeField(part);

    }else{
      let hour_z = Number(endHours);
      if(partSelected === "PM" && hour_z < 12){
        hour_z = hour_z + 12;
      }else if(partSelected === "AM" && hour_z >= 12){
        hour_z = hour_z - 12;
      }
  
      setEndHours(hour_z);

      const dateToChangeTime = new Date(startDateValue.getTime());
      dateToChangeTime.setHours(hour_z, endMinutes);
      setEndDateValue(dateToChangeTime);

      hour_z = getHourMinuteAsString(hour_z);
      let mStr = getHourMinuteAsString(endMinutes);
      const part = hour_z + ":" + mStr;
      setPartOfEndDay(partSelected);
      setEndTimeField(part);
    }

  };

  const getHourAs24h = (value, isStart)=>{
    let h24h = 0
    const startEndPartSelection = isStart ? partOfStartDay : partOfEndDay;

    if(startEndPartSelection === "PM" && value < 12){
      h24h = value + 12;
    }else if(startEndPartSelection === "AM" && value === 12){
      h24h = 0;
    }else{
      h24h = value;
    }

    return h24h;
  }

  const getHourMinuteAsString = (value)=>{
    let hStr = '';
    if(value < 10){
      hStr = "0" + String(value);
    }else{
      hStr = String(value);
    }

    return hStr;
  }

  const getHourMinuteAsNumber = (value)=>{
    let vNumber = Number(value);
    return vNumber;
  }

  const captureOnChange = (changedTime, isStart)=>{
    const hoursAndMinutes = String(changedTime).split(':');
    let hNum = getHourMinuteAsNumber(hoursAndMinutes[0]);
    let mNum = getHourMinuteAsNumber(hoursAndMinutes[1]);

    if(isStart){
      setStartHours(hNum);
      setStartMinutes(mNum);
      setStartTimeField(changedTime);

      if(hNum > 12){
        setPartOfStartDay("PM");
      }else{
        setPartOfStartDay("AM");
      }

      const dateToChangeTime = new Date(startDateValue.getTime());
      dateToChangeTime.setHours(hNum, mNum);
      setStartDateValue(dateToChangeTime);

    }else{
      setEndHours(hNum);
      setEndMinutes(mNum);
      setEndTimeField(changedTime);

      if(hNum > 12){
        setPartOfEndDay("PM");
      }else{
        setPartOfEndDay("AM");
      }

      const dateToChangeTime = new Date(endDateValue.getTime());
      dateToChangeTime.setHours(hNum, mNum);
      setEndDateValue(dateToChangeTime);

    }
  }

  const addGuests = (guestEvent) => {
    if (guestEvent.key === "Enter" && validEmail){
      const guests = guestEvent.target.value;
      const guestNameEmail = guests.split(',');
      let tmpGuestList = [];
      if(guestList.length > 0){
        tmpGuestList = [...guestList];
      }

      const contactInfo = {name: guestNameEmail.length > 1 ? guestNameEmail[0].trim() : '', email: guestNameEmail.length > 1 ? guestNameEmail[1].trim()  : guestNameEmail[0].trim() };
      tmpGuestList.push(contactInfo);
      setGuestList(tmpGuestList);
      setGuestNames('');
    };
  };


  const addCandidateGuests = (guestCandidate) => {
    if (guestCandidate.length > 0 && guestCandidate[0].id > 0 ){
      let tmpGuestList = [];
      if(guestList.length > 0){
        tmpGuestList = [...guestList];
      }
      // console.log("@CalendarScheduler -  adding: " + guestCandidate.id);
      // const contactInfo = {name: guestCandidate.resume_name, 
      //   email: guestCandidate.email_1, role_id: guestCandidate.role_id, 
      //   resume_id: guestCandidate.resume_id, role_name: guestCandidate.role_name,
      //   company_name: guestCandidate.company_name };
      // tmpGuestList.push(contactInfo);
      guestCandidate.map(candidateInfo =>{
        tmpGuestList.push(candidateInfo);
      });

      setGuestList(tmpGuestList);
      // setGuestNames('');
    };
  };

  const removeGuests = (guestId)=>{
    const tmpGuestList = [...guestList];
    // const tmpGuestList2 = tmpGuestList.filter((e, index)=> index !== guestId);
    const tmpGuestList2 = tmpGuestList.filter((e, index)=> e.email !== guestId);
    setGuestList(tmpGuestList2);
  }

  const closeScheduler = ()=>{
    if(props.closeScheduler){
      props.closeScheduler();
    }
  }

  const saveMeetingInvite = (closeSchedulerWindow)=>{
    setIsSendRequestInProgress(false);
    setIsRequestInProgress(true);
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const meetingStartDate = new Date();
    const event = { 
      id: eventIndex, title: titleField, start: startDateValue, end: endDateValue, className: styles.eventClick,
      extendedProps: {
        meeting_id: meetingId,
        location: locationField,
        description: descriptionInputField,
        guests: guestList,
        url: url,
        template: inviteTemplate,
        time_zone: tz
      }
    };

    const calendarMeetingInvite = {
      id: meetingId,
      user_id: ctx.User.id,
      summary_title: titleField,
      start_date: startDateValue,
      end_date: endDateValue,
      description: descriptionInputField,
      guest_list: guestList,
      location: locationField,
      url: url,
      template: inviteTemplate,
      time_zone: tz
    };
 
    if (meetingId !== undefined && Number(meetingId) > 0){
      postUpdateData(process.env.REACT_APP_USER_CALENDAR_DETAIL_UPDATE_API, calendarMeetingInvite, event, closeSchedulerWindow);
    }else if (meetingId !== undefined && Number(meetingId) < 1){
      postSaveData(process.env.REACT_APP_USER_CALENDAR_DETAIL_CREATE_API, calendarMeetingInvite, event);
    }

    // if(props.saveEvent){
    //   props.saveEvent(event);
    //   closeScheduler();
    // }

  };

  const sendMeetingInvite = ()=>{
    setIsRequestInProgress(false);
    setIsSendRequestInProgress(true);
    saveMeetingInvite(false);
    const sendRequest = {
      meeting_invite_list: [meetingId],
      send_to_me_only: sentToMeOnly
    }
    postData(process.env.REACT_APP_USER_CALENDAR_DETAIL_SEND_API, sendRequest);
  }

  const deleteMeetingInvite = ()=>{
    setIsSendRequestInProgress(false);
    setIsRequestInProgress(true);
    const meetingStartDate = new Date();
    const event = { 
      id: eventIndex, title: titleField, start: startDateValue, end: endDateValue, className: styles.eventClick,
      extendedProps: {
        meeting_id: meetingId,
        location: locationField,
        description: descriptionInputField,
        guests: guestList,
        url: url
      }
    };

    const calendarMeetingInvite = {
      id: meetingId,
      user_id: ctx.User.id,
      summary_title: titleField,
      start_date: startDateValue,
      end_date: endDateValue,
      description: descriptionInputField,
      guest_list: guestList,
      location: locationField,
      url: url
    };

    if (meetingId !== undefined && Number(meetingId) > 0){
      postDeleteData(process.env.REACT_APP_USER_CALENDAR_DETAIL_DELETE_API, calendarMeetingInvite, event);
    }else{
      setDeleteComplete(true, event);
    }
    
    // if(props.deleteEvent){
    //   props.deleteEvent(event);
    //   closeScheduler();
    // }
  };

  const showCandidateSearch = ()=>{
    setIsCandidateSearchVisible(true);
  }

  const hideCandidateSearch = ()=>{
    setIsCandidateSearchVisible(false);
  }

  const sentToMeOnlyValue = ()=>{
    const sendSet = sentToMeOnly === true ? false : true;
    setSentToMeOnly(sendSet);
  }

  return (
    <div className={styles.schedulerContainer} >
      <div className={styles.windowTitleArea} >Event Schedule<div onClick={() => {closeScheduler()}} className={styles.closeSchedulerMessage}>&times;</div></div>
      <div className={styles.schedulerContainerWrapper} >
        <div className={styles.titleArea} >
          <input className={styles.titleInputField} type="text" id="title" name="title" placeholder="Add title here..."
            autoComplete="off" 
            onChange={ (e)=> setTitleField(e.target.value) } 
            value={titleField}
            required
            onFocus={() => setTitleFocus(true)}
            onBlur={() => setTitleFocus(false)}
          />
        </div>
        <div className={styles.allDayArea} >
          <div className={styles.timeIconTrail} ></div>
          <img className={styles.timeIcon} alt='Add time' title='Add time' src="/time_icon.svg" />
          <div className={styles.allDay} >Select the event's timeframe</div>
          <div className={styles.checkboxArea} >
            {/* <input id="allday" name="allday" className={styles.checkboxField} type="checkbox" /> */}
          </div>
        </div>
        <div  className={styles.dateArea} >
          <div className={styles.dateStartArea} >
            <div className={styles.dateStartAreaLabel} >Start:</div>
            <div className={styles.dateFieldDisplay} >
              {startLabelField}
              <div className={styles.smallCalendar} >{getSmallCalendar(true)}</div>
            </div>
            <div className={styles.timeFieldDisplay} >
              {/* 12:00 PM */}
              <input className={styles.timeHolder} aria-label="Time" type="time" onChange={(e)=> captureOnChange(e.target.value, true)} value={startTimeField} />
              <div className={styles.timeAreaContainer} >
                {/* <input className={styles.timeHolder} aria-label="Time2" type="time" /> */}
                <div className={styles.timeArea} >
                    <div className={styles.hourList} >
                      {
                        hourList.map(hour =>{
                          return(<div key={hour} className={hour === startHours || (hour + 12) === startHours || (hour === 12 & startHours === 0) ? styles.timeHoverSelected : styles.timeHover} onClick={()=>selectedHour(hour, true)} >{hour}</div>)
                        })
                      }
                    </div>
                    <div className={styles.minuteList} >
                      {
                        minuteList.map(minute =>{
                          return(<div key={minute} className={minute === startMinutes ? styles.timeHoverSelected : styles.timeHover} onClick={()=>selectedMinute(minute, true)} >{minute === 0 || minute === 5 ? "0" + minute : minute}</div>)
                        })
                      }
                    </div>
                    <div className={styles.partOfDayList} >
                      <div className={partOfStartDay === "AM" ? styles.timeHoverSelected : styles.timeHover} onClick={()=>partOfDayList("AM", true)} >AM</div>
                      <div className={partOfStartDay === "PM" ? styles.timeHoverSelected : styles.timeHover} onClick={()=>partOfDayList("PM", true)} >PM</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dateStartArea} >
            <div className={styles.dateStartAreaLabel} >End:</div>
            <div className={styles.dateFieldDisplay} >
              {endLabelField}
              <div className={styles.smallCalendar} >{getSmallCalendar(false)}</div>
            </div>
            <div className={styles.timeFieldDisplay} >
              {/* 12:00 PM */}
              <input className={styles.timeHolder} aria-label="Time" type="time" onChange={(e)=> captureOnChange(e.target.value, false)} value={endTimeField} />
              <div className={styles.timeAreaContainer} >
                {/* <input className={styles.timeHolder} aria-label="Time2" type="time" /> */}
                <div className={styles.timeArea} >
                    <div className={styles.hourList} >
                      {
                        hourList.map(hour =>{
                          return(<div className={hour === endHours || (hour + 12) === endHours || (hour === 12 & endHours === 0) ? styles.timeHoverSelected : styles.timeHover} onClick={()=>selectedHour(hour, false)} >{hour}</div>)
                        })
                      }
                    </div>
                    <div className={styles.minuteList} >
                      {
                        minuteList.map(minute =>{
                          return(<div className={minute === endMinutes ? styles.timeHoverSelected : styles.timeHover} onClick={()=>selectedMinute(minute, false)} >{minute === 0 || minute === 5 ? "0" + minute : minute}</div>)
                        })
                      }
                    </div>
                    <div className={styles.partOfDayList} >
                      <div className={partOfEndDay === "AM" ? styles.timeHoverSelected : styles.timeHover} onClick={()=>partOfDayList("AM", false)} >AM</div>
                      <div className={partOfEndDay === "PM" ? styles.timeHoverSelected : styles.timeHover} onClick={()=>partOfDayList("PM", false)} >PM</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.guestArea} > 
          <div className={styles.guestInputFieldArea} >
          <div className={styles.peopleIconTrail} ></div>
          <img className={styles.peopleIcon} alt="Search candidates" title='Search candidates' onClick={showCandidateSearch} src="/people_icon_green.svg" />
            <input className={styles.guestInputField} type="text" id="guest" name="guest" placeholder="&#x1F844;Search candidates & type guests emails"
              value = {guestNames}
              autoComplete="on"
              onKeyUp={(e)=> addGuests(e)}
              // onChange={ (e)=> addGuests(e.target.value) }
              onChange={ (e)=> setGuestNames(e.target.value) }
              onFocus={() => setGuestListFocus(true)}
              onBlur={() => setGuestListFocus(false)}
            />
          </div>
          <div className={styles.guestListArea} >
            <ul className={styles.ul} >
              {
                guestList.map( (candidate, index) =>
                  <li key={index} data-id={index} data-position={index} className={styles.guestList} >
                    <span key={index} className={styles.guestName}>{candidate.name !== ''? candidate.name : candidate.email} <i>{candidate.name !== ''? " - " + candidate.email : ''}</i> </span>
                    <span key={"D" + index} id={index} onClick={()=> removeGuests(candidate.email)} className={styles.guestNameRemove} >
                      &times;
                      <span key={"DD" + index} className={styles.guestNameRemoveMsg} >delete</span>
                    </span>
                  </li>
                )
              }
            </ul>
          </div>
          { candidateInvited === false ? <div className={styles.inviteRequired} >One candidate is required* in this event. Click icon to search.</div> : <></>}
        </div>
        <div className={styles.locationArea} >
          <div className={styles.locationInputArea} >
              <div className={styles.locationPinIconTrail} ></div>
              <img className={styles.locationPinIcon} alt="Add location" title='Add location' src="/location_pin_icon.svg" />
              <input className={styles.locationInputField} type="text" id="location" name="location" placeholder="Add location..."
                value = {locationField}
                // autoComplete="on"
                // onKeyUp={(e)=> addGuests(e)}
                onChange={ (e)=> setLocationField(e.target.value) }
                // onChange={ (e)=> setGuestNames(e.target.value) }
                // onFocus={() => setGuestListFocus(true)}
                // onBlur={() => setGuestListFocus(false)}
              />
          </div>
        </div>
        <div className={styles.descriptionArea} >
          <div className={styles.descriptionInputArea} >
              <div className={styles.descriptionIconTrail} ></div>
              <img className={styles.descriptionIcon} alt="Add description" title='Add description' src="/write_icon.svg" />
              <textarea value={descriptionInputField} onChange={(e) => {setDescriptionInputField(e.target.value);}}
                className={styles.descriptionInputField} 
                id="description" name="description"
                rows={6} cols={40}
                placeholder="Add description..."
              />
          </div>
        </div>
      </div>
      <div className={styles.saveArea} >
              <div className={styles.syncArea} >
                <div className={styles.syncToCalendarArea} >
                  <span className={styles.inviteTemplateLabel} >Invite template</span><br />
                  <div className={styles.inviteTemplateNameWrap} > <span className={inviteTemplateName === 'None' || inviteTemplateName === 'Select a template*' ? styles.inviteTemplateNameMissing : styles.inviteTemplateName } > {inviteTemplateName} </span></div>
                  <div className={styles.templateSelection} > {getInviteTemplateList()} </div>
                </div>
                <div className={styles.syncCheckboxArea} >
                  {/* <input type="checkbox" id="" className={styles.checkboxField} /> */}
                  { isCandidateSearchVisible ? <div className={styles.searchCandidate} ><CompanyViewReportListShort addCandidateGuest={addCandidateGuests} closeSearch={hideCandidateSearch} /></div> : <></>}
                </div>
              </div>
              <div className={styles.deleteButtonArea} onClick={deleteMeetingInvite} >
                <img className={styles.hoverColor} alt="Delete" title="Delete" src="/Trash_Logo_button_3.svg" />
                <div className={styles.deleteLabel} >Delete</div>
              </div>


              <div className={titleField ? styles.saveButtonArea: styles.saveButtonAreaInactive} onClick={titleField ? ()=> saveMeetingInvite(true): ()=> {}} >
                <div >Save</div>
                <div >
                  <img className={styles.saveButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
                </div>
              </div>
              
              <div className={isInviteTemplateSelected && candidateInvited && titleField && guestList.length > 0 ? styles.sendButtonArea : styles.sendButtonAreaInactive} onClick={isInviteTemplateSelected && candidateInvited && titleField && guestList.length > 0 ? sendMeetingInvite : ()=>{}} >
                <div className={styles.sendRequirements} >A title, an invite template, and at least one candidate is required*</div>
                <div className={styles.sendLabel} >Send</div>
                <img className={styles.sendButtonIcon} alt="Send" title="Send" src="/Send_Invite_Icon.svg" />
              </div>
              {
                isInviteTemplateSelected && candidateInvited && titleField && guestList.length > 0 ?
              <>
              <div className={styles.inviteSendSymbol} >&#9432;
                <div className={styles.inviteSendSymbolText} >
                  <b>To test invite, send to your email address only.<br/> No candidate or guest will receive an invite.</b>
                </div>
              </div>
              <div className={styles.sendToMeArea} >Send to me only <input id="toMe" name="toMe" className={styles.checkboxField} type="checkbox" onChange={sentToMeOnlyValue}  /></div>
              </>
              :<></>
              }
        </div>

        { isRequestInProgress ?
            <div className={styles.progressContainer} >
                <div className={styles.progressArea} >
                    <div > <img className={styles.progressLogo}  src="/Save_Icon_64x64_v2_Anim.svg"  /></div>
                    <div>Saving in-progress...</div>
                </div>
            </div>
            :<></>
        }

        { isSendRequestInProgress ?
            <div className={styles.progressContainer} >
                <div className={styles.progressArea} >
                    <div > <img className={styles.progressLogo}  src="/Send_Invite_Icon_Anim.svg"  /></div>
                    <div className={styles.progressText} >Sending innvite...</div>
                </div>
            </div>
            :<></>
        }

    </div>
  )
}