
export const GetPlanName = (planToPurchase)=>{
    switch(Number(planToPurchase)){
      case 1: return "Free";
      case 2: return "Explorer";
      case 3: return "Standard";
      case 4: return "Pro";
      case 5: return "Premium";
      case 999991: return "Test";
      default: return "Standard";
    }
  };