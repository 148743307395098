import styles from "./WrongPathPage.module.css"

const WrongPathPage = () =>{ 

    return (
        <div className={styles.mainArea} >
            <div className={styles.notFound}> 
            <div><img height="300px"  src="/not-found-error-opt.svg"  ></img></div>
            <div>Page not found.</div>
            <div>Please review the URL and try again.</div>
            </div>
        </div>
    )
}

export default WrongPathPage