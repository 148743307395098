import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from "@fullcalendar/interaction"
import styles from './CalendarView.module.css'
import useCustomAxios from '../../common/hooks/useCustomAxios';
import ContextManager from "../../common/Context/ContextManager";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import MessageManager from "../../common/ErrorManager";
import { useRef, useState, useEffect  } from 'react';
import CalendarScheduler from './CalendarScheduler'

export default function CalendarView() {
  
  const [preferedView, setPreferedView] = useState("dayGridMonth");
  const [calendarApi, setCalendarApi] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [transferEvent, setTransferEvent] = useState({});
  const [meetingId, setMeetingId] = useState(0);
  const [calendarEvents, setCalendarEvents] = useState([{}]);
  const [eventIndex, setEventIndex] = useState(-1);
  const [inviteTemplate, setInviteTemplate] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const calendarRef = useRef(null);
  const customAxios = useCustomAxios()
  const { ctx, setContext } = ContextManager()
  const navigate = useNavigate();

  useEffect( () => {

    const calendarApiRef = calendarRef.current.getApi();
    setCalendarApi(calendarApiRef);
    calendarApiRef.setOption('height', "100%");
    calendarApiRef.updateSize();
    const calendarViewObject = calendarApiRef.view;
    const start = new Date(calendarViewObject.activeStart).toISOString()
    const end  = new Date(calendarViewObject.activeEnd).toISOString()
    // updateCalendarData(start, end);
    return () => {

    }
  }, []
  );

  useEffect( () => {
    updateCalendarData(startDate, endDate);
    return () => {

    }
  }, [startDate]
  );

  const updateCalendarData = (start, end)=>{
    fetchCalendarData(start, end);
  };

  const setInitialData = (responseData)  => {
    const {meeting_invite_list, last_updated} = responseData
    // setIsRequestInProgress(false);

    const localCalendarApi = calendarRef.current.getApi();
    // const newEvents = [...calendarEvents];
    const newEvents = [];

    if(meeting_invite_list.length > 0){
      meeting_invite_list.map((invite, index)=>{
        const convertedGuestList = invite.guest_list ? invite.guest_list : []; //[{name: '', email: ''}],
        const event = 
        { id:invite.id, title: invite.summary_title, start: invite.start_date, end: invite.end_date, className: styles.eventClick,
          extendedProps: {
            meeting_id: invite.id,
            location: invite.location,
            description: invite.description,
            guests: convertedGuestList,
            url: invite.url,
            template: invite.template,
            time_zone: invite.time_zone
          },
        };

        const existingId = localCalendarApi.getEventById(invite.id);
        if(existingId !== null){
          existingId.remove();
        }
        newEvents.push(event);
        try{
          localCalendarApi.addEvent(event);
        }catch(error){
          console.log(error);
        };
      });
      setCalendarEvents(newEvents);
    };
    setIsRequestInProgress(false);
  };

  const fetchCalendarData = async(start, end) => {
    let URL_PATH = process.env.REACT_APP_USER_CALENDAR_DETAIL_API
    let URL = URL_PATH + "?start=" + start + "&end="+end;
    if(!isRequestInProgress && start !== undefined){
      setIsRequestInProgress(true);
      await customAxios.get(URL)
      .then(res => {
        setInitialData(res.data);
      })
      .catch(error => {
          errorsMessages(error)
          MessageManager(error, "CalendarView", ctx.Config.Error.getLevels.error)
      })
    }
};

const getUpdatedCalendarData = (requestCalendarObject, successCall, errorCall) => {
  
  const start = requestCalendarObject.start.toISOString();
  const end  = requestCalendarObject.end.toISOString();

  // updateCalendarData(start, end);
  setStartDate(start);
  setEndDate(end);

  const event = 
  { id:0, title: 'Initial', start: new Date(2000, 1, 1, 0, 0, 0), end: new Date(1970, 1, 1, 0, 1, 0), className: styles.eventClick,
    extendedProps: {
      meeting_id: 0,
      location: '',
      description: '',
      guests: '',
      url: '',
      template: 0,
      time_zone: ''
    },
  };
  return event;

};

  const errorsMessages = (error) => {
    // let msg = "An error was found. Please check the console for more information"
    let msg = "If calendar invites are not listed, then please use the refresh button."
    // displayMessage(error.response !== undefined ? error.response.data.detail : msg);
    displayMessage(msg);
  }

  const displayMessage = (messageToDisplay) => {
    setIsRequestInProgress(false);
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const handleDateClick = (arg) => {
      // alert(getWeekdayMonthDayName(arg.dateStr, 'US'));
      // calendarApi.changeView(preferedView, arg.dateStr);
  }


  const handleSelect = (arg)=>{
    if(arg.allDay){
      // console.log("@All Day");
      const event = 
        { id:"-1", title: "", start: arg.start, end: arg.end, className: styles.eventClick,
          extendedProps: {
            meeting_id: 0,
            location: '',
            description: '',
            guests: [], //[{name: '', email: '', role_id: 0, resume_id: 0}],
            url: '',
            template: 0,
            time_zone: ''
          },
        };
      setTransferEvent(event);
      setShowScheduler(true);
    }else{
      // console.log("@Selected Day");
      const event = 
        { id: "-1", title: "", start: arg.start, end: arg.end, className: styles.eventClick,
          extendedProps: {
            meeting_id: 0,
            location: '',
            description: '',
            guests: [], //[{name: '', email: '', role_id: 0, resume_id: 0}],
            url: '',
            template: 0,
            time_zone: ''
          },
        };
      
      setTransferEvent(event);
      setShowScheduler(true);
    };
  };

  const eventClickHandler = (arg)=>{
    setTransferEvent(arg.event);
    setShowScheduler(true);
  }

  const seeDayGridMultiMonthView = ()=>{
    setPreferedView("multiMonthYear");
    calendarApi.changeView("multiMonthYear");
  }

  const seeDayGridMonthView = ()=> {
    setPreferedView("dayGridMonth");
    calendarApi.changeView("dayGridMonth");
  }

  const seeDayGridWeekView = ()=> {
    setPreferedView("dayGridWeek");
    calendarApi.changeView("dayGridWeek");
  }

  const seeTimeGridWeekView = ()=> {
    setPreferedView("timeGridWeek");
    calendarApi.changeView("timeGridWeek");
  }

  const seeTimeGridDayView = ()=> {
    setPreferedView("timeGridDay");
    calendarApi.changeView("timeGridDay");
  }

  const closeMessage = () =>{
    const show = showMessage === true ? false : true;
    setShowMessage(show)
    setMessage("")
    setSuccessMessage(false);
  }

  const closeScheduler = ()=>{
    const show = showScheduler === true ? false : true;
    setShowScheduler(show);
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function getWeekdayMonthDayName(dateStr, locale)
  {
    var date = new Date(dateStr);
    options.timeZone = "UTC";
    options.timeZoneName = "short";

    return date.toLocaleDateString(locale, { weekday: 'long'}) + ", " + 
           date.toLocaleDateString(locale, { month: 'long'}) + " " + 
           date.toLocaleDateString(locale, { day: 'numeric'});
  }

  const saveSchedulerEvent = (newEvent, isAPISaved)=>{
    const newEvents = [...calendarEvents];

    if(isAPISaved){
      newEvents.push(newEvent);
      newEvent.className = styles.eventClick;
      calendarApi.addEvent(newEvent);
    }else if(newEvent.id === "-1"){
      newEvent.id = String(newEvents.length + 1);
      newEvents.push(newEvent);
      newEvent.className = styles.eventClick;
      calendarApi.addEvent(newEvent);
    }else{
      newEvents[Number(newEvent.id)] = newEvent;
      const existingEvent = calendarApi.getEventById(newEvent.id);

      existingEvent.remove();
      newEvent.id = newEvent.id;
      newEvent.className = styles.eventClick;
      calendarApi.addEvent(newEvent);
    }

    setCalendarEvents(newEvents);
  };

  const deleteScheduledEvent = (newEvent)=>{
    const existingEvent = calendarApi.getEventById(newEvent.id);
    if(existingEvent !== undefined && existingEvent !== null){
      existingEvent.remove();
    }
  };

  const goToTemplateDetail = (cid, rid, rsid)=>{
    navigate("/calendar/templatelist");
  }

  return (
    <div className={styles.calendarContainer} >
      <div className={styles.templateInviteLabel} onClick={goToTemplateDetail} >See invite template list</div>
      <FullCalendar ref={calendarRef} 
        plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
        initialView= {preferedView} //"custom" //"dayGridMonth" //"dayGridWeek" // "timeGridWeek" // "timeGridDay"
        selectable = {true}
        editable = {true}
        // dragScroll = {true}
        nowIndicator = {true}
        // weekends={false}
        headerToolbar= { {left: 'prev,next today', center: 'title', right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay'}}
        // events={ isRequestInProgress ? getUpdatedCalendarData : calendarEvents }
        events={ getUpdatedCalendarData }
        // events={[{ title: 'event 1', start: '2024-05-07', end: '2024-05-09' },{ title: 'event 2', date: '2024-05-08' }]}
        // events={[{ title: '', start: '1900-01-01T12:00:00.000', end: '1900-01-01T12:01:00.000' }]}
        // dayMaxEventRows = {4}
        dayMaxEvents = {true}
        dateClick={handleDateClick}
        select={handleSelect}
        eventClick={eventClickHandler}
        // eventContent={renderEventContent}
        // eventsSet={()=> alert("Something changed")}
        // eventChange={(e)=> alert(e)}

      />
      {
        showScheduler ?
        <div className={ showScheduler ? styles.messageContainer : styles.hideModal } >
          <CalendarScheduler closeScheduler={closeScheduler} event={transferEvent} saveEvent={saveSchedulerEvent} deleteEvent={deleteScheduledEvent} />
        </div>
        :<></>
      }
      {
        showMessage ?
      <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
          <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
              <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
              <div>
                {message}
              </div> 
          </div>
      </div>
      : <></>
      }
    </div>
  )
}