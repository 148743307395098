import styles from "./EventTemplateDetail.module.css";
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import useCustomAxios from '../../common/hooks/useCustomAxios';
import MessageManager from "../../common/ErrorManager";

import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";


const EventTemplateDetail = () => {

  const [loadComponent, setLoadComponent] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [eventTemplateList, setEventTemplateList] = useState([]);

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();
  let printComponentRef = useRef();

  let processing = true
  let redirect = false
  let showMessageArea = false


  useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });

  // Retrieve data first and populate any state
  useEffect( () => {

    return () => {
        processing = false
    }
    }, [state] 
  )

  useEffect( () => {
    fetchEventTemplatesData();
    return () => {
        processing = false
    }
    }, [] 
  )


  const setEventTemplatesResponse = (responseData)  => {
    const {event_template_list, last_updated} = responseData;
    // setEventTemplateList(event_template_list);
    sortList(event_template_list);
  }
  
  const fetchEventTemplatesData = async() => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_USER_CALENDAR_EVENT_TEMPLATES_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setEventTemplatesResponse(res.data);
    })
    .catch(error => {
        errorsMessages(error)
        MessageManager(error, "CompanyViewReportList", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  };


  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    setIsRequestInProgress(false)
    let noRoleFoundMsg = "Candidate's resume detail could not be found. Please try again, or select a different candidate."
    let errorMsg = "An error was found. Please check the console for more information"
    displayMessage(error.response !== undefined ? noRoleFoundMsg : errorMsg)
  };

  const displayMessage = (messageToDisplay) => {
    showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  };

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        showMessageArea = show
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  const sortList = (event_template_list)=>{
    const fieldToSort = "category";
    const sorteddData = [...event_template_list].sort((itemA, itemB) => {
        return itemA[fieldToSort] > itemB[fieldToSort] ? 1 : -1 ;
    });

    setEventTemplateList(sorteddData);
  }

  const goToCalendar = (cid, rid, rsid)=>{
    navigate("/calendar");
  }


  return (
    <div className={styles.eventTemplateContainer}>
        <div className={styles.containerContentArea}>
            <div className={styles.welcomeLabelArea} >
              {/* <img className={styles.toLogo} src="/Talent_Logo_Txt_Color_Simple.svg" /> */}
              {/* <span className={styles.talentColorLogo}>Talent</span> <span className={styles.opinionColorLogo}>Opinion</span> */}
              Invite Template List
            </div>
            <div className={styles.gotoCalendarLabel} onClick={goToCalendar} >Go back to calendar</div>
            <div className={styles.templateListContainer} >
            {
                eventTemplateList.map((template)=>
                <div className={styles.templateItem} >
                    <div className={styles.templateItemLabelArea} >
                        <span className={styles.templateItemLabel} >{template.name}</span><br/>
                        <span className={styles.templateItemSummary} ><i>{template.summary}</i></span>
                        <p>
                            <span className={styles.templateItemDescriptionLabel} >Description</span><br/>
                            <span className={styles.templateItemDescription} >{template.description}</span>
                        </p>
                        <p>
                            <span className={styles.templateItemDescriptionLabel} >Category</span><br/>
                            <span className={styles.templateItemDescription} >{template.category}</span>
                        </p>
                    </div>
                    <div className={styles.templateItemImageArea} > <img className={styles.templateImage}  src={"/invites/" + template.image_preview_large_file} /> </div>
                </div>
            )}
            </div>
        </div>
        {/* ^ Summary Area ^ */}

        { showMessageArea ? 
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                    {message}
                </div> 
            </div>
        </div>
        : <></>
        }
    </div>
  )
};

export default EventTemplateDetail;
