import styles from "./RoleArea.module.css";
import { useState, useEffect } from 'react';
import axios from "axios";
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";

const RoleArea = (props) => {

  const [requirementsContent, setRequirementsContent] = useState('');
  const [roleName, setRoleName] = useState('')
  const [roleId, setRoleId] = useState(0)
  const [companyId, setCompanyId] = useState(0)
  const [userId, setUserId] = useState(0)
  const [loadComponent, setLoadComponent] = useState(true)
  const [message, setMessage] = useState('')
  const [isVisible, setIsVisible] = useState('none')
  const [companyInfoInterIsVisible, setCompanyInterVisible] = useState('inline')
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIcon)
  const [isNewRole, setNewRole] = useState(false)

  const [successMessage, setSuccessMessage] = useState(false)
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [message, setMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false)
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [featureUsage, setFeatureUsage] = useState({});


  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();
  let firstLoad = true

  const RoleInfo = {
    id: roleId,
    name: roleName,
    requirements: requirementsContent,
    user_id: ctx.User.id,
    company_view_id: ctx.Company.id
  }

  let processing = true
  let redirect = false

  if(props.createnewrole !== isNewRole){
    setNewRole(props.createnewrole)
  }

  useEffect( () => {
    fetchFeatureUsageData();
    return () => {
    }
    }, []
  );

  // Retrieve data first and populate any state
  useEffect( () => {
    // processing = true
    setUserId(ctx.User.id)
    setCompanyId(ctx.Company.id)

    if(urlParameters.id > 0 && urlParameters.rid > 0 && (loadComponent || state?.roleSel  === true || state?.newRole === true)){
      setLoadComponent(false);
      fetchData(urlParameters.id, urlParameters.rid)
    }else if(ctx.Company.id > 0 && ctx.Role.id > 0 && (loadComponent || state?.roleSel  === true || state?.newRole === true)){
      setLoadComponent(false)
      fetchData(ctx.Company.id, ctx.Role.id);
    }else{
      setRoleId(0)
      ctx.Role.id = 0
      ctx.Role.name = ""
      setContext(ctx)
      setRoleId(0)
      setRoleName("")
      setRequirementsContent("")
    }

    return () => {
        processing = false
    }
    }, [isNewRole, state] 
  )

  useEffect( () => {
    if(props.saveAllChanges === true){
      if(isRequestInProgress === false){
        props.setSaveAllChanges(false);
        props.setNewStateToSave({name: "role", save: false});
        processPostingData();
      }
    }

    if(props.newRole === true){
      setRoleId(0);
      ctx.Role.id = 0;
      ctx.Role.name = "";
      setContext(ctx);
      setRoleName("");
      setRequirementsContent("");
    }

    return () => {

    }
    }, [props]
  );


  const setInitialData = (responseData)  => {
    const {id, name, requirements, user_id} = responseData;
    ctx.Role.id = id;
    ctx.Role.name = name;
    setContext(ctx);
    setRoleId(id);
    setRoleName(name);
    setRequirementsContent(requirements);
    props.setPageroleId(id);
    setSubmitButtonState(false);
    processing = false;
    // Let the CompanySetup Page know data has been saved
    props.setNewStateToSave({name: "role", save: false});
    if(redirect){
      navigate("/CompanySetup/" + ctx.Company.id + "/" + id, {state: {newRole:true}});
    }
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    let noRoleFoundMsg = "No role has been selected, created, or found."
    let errorMsg = "No role requirements available, or selected, or an error was found. Please try again."

    let errorMsgCode = noRoleFoundMsg

    if(error.response?.status === 400){
      errorMsgCode = "Role Requirements | Job Posting details are missing.";
    }else if (error.response?.status === 500){
      errorMsgCode = "Role Requirements cannot be created/Updated. | Plan limit might have been reached.";
    }else if (error.response?.status === 512){
      errorMsgCode = errorMsg;
    }else{
      errorMsgCode = noRoleFoundMsg
    };

    setSuccessMessage(false);
    // displayMessage(error.response !== undefined ? noRoleFoundMsg : errorMsg)
    displayMessage(errorMsgCode, "error");
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_HOST_DOMAIN_PORT

  // Get data using the axios functionality
  const fetchData = async(companyId, roleId) => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL = URL_PATH + "/" + companyId + "/" + roleId
    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
      setInitialData(res.data);
    })
    .catch(error => {
      errorsMessages(error);
      MessageManager(error, "RoleArea", ctx.Config.Error.getLevels.error);
    }) //Make sure to use proper funcion to capture error
  }

  const fetchFeatureUsageData = async() => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_RESUME_FEATURES_USAGE_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setFeatureUsageResponse(res.data)
    })
    .catch(error => {
        errorsMessages(error)
        MessageManager(error, "ResumeList", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  };

  const setFeatureUsageResponse = (responseData)  => {
    setFeatureUsage(responseData);
  }

  // Post data using axios
  const postData = async(processing) => {
    redirect = roleId > 0 ? false : true
    let PATH= roleId > 0 ? process.env.REACT_APP_COMPANY_ROLE_UPDATE_API : process.env.REACT_APP_COMPANY_ROLE_NEW_API
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  PATH
    let URL_PATH = PATH
    let URL = URL_PATH
    const postRoleData = RoleInfo
    // await axios.post(URL, postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.post(URL, postRoleData)
    .then(res => {
      if(processing === true){
        setInitialData(res.data);
        setSuccessMessage(true);
        displayMessage("Role has been saved.", "message");
      }
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "RoleArea", ctx.Config.Error.getLevels.error)
    })
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    processPostingData();
  }

  const processPostingData = ()=>{
    
    fetchFeatureUsageData();

    if(Number(ctx.Resume.companies) !== -1 && Number(featureUsage.roles) >= Number(ctx.Resume.roles)){
      displayMessage("Limit reached. Your plan limit is " + ctx.Resume.roles + " role(s). You have " + Number(Number(ctx.Resume.roles) - Number(featureUsage.roles))  + " role(s) left.", "error");
    }else if(checkIfContentIsEmpty(roleName) === true && checkIfContentIsEmpty(requirementsContent) === true){
      if(ctx.Company.id > 0){
        setMessage('')
        processing = true
        postData(processing);
      }else{
        displayMessage('Please select, create, or save company information before updating/saving a new Role.', "error");
      }
    }else{
      displayMessage('Role Name and Role Requirements | Job Posting fields are required.', "error");
    }

    // if(redirect){      
    //   navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id);
    // }
  }

  const setSubmitButtonState = (state) => {
    if(state === true){
      setIsVisible('inline');
      props.setNewStateToSave({name: "role", save: true});
    }else{
      setIsVisible('none');
      props.setNewStateToSave({name: "role", save: false});
    }
  }

  const checkIfContentIsEmpty = (contentToCheck) => {
    var state = false

    if(contentToCheck.trim() === ""){
      displayMessage('Role Name and Role Requirements | Job Posting fields are required.', "error");
      state = false;
    }else{
      state = true;
    }
    return state;
  }

  const setRoleNameValue = (newRoleName) => {
    setRoleName(newRoleName);
    setMessage('');
    const buttonState = checkIfContentIsEmpty(newRoleName);
    setSubmitButtonState(buttonState);
    // if(buttonState){
    //   props.setNewStateToSave({name: "role", save: true});
    // }else{
    //   props.setNewStateToSave({name: "role", save: false});
    // }
  }

  const setRequirementsValue = (content) => {
    setRequirementsContent(content);
    setMessage('');
    const buttonState = checkIfContentIsEmpty(content);
    setSubmitButtonState(buttonState);
    // if(buttonState){
    //   props.setNewStateToSave({name: "role", save: true});
    // }else{
    //   props.setNewStateToSave({name: "role", save: false});
    // }
  }

  const setCompanyInterVisibleOnOff = () => {
    let isOn = companyInfoInterIsVisible === 'inline' ?  'none' : 'inline'
    setCompanyInterVisible(isOn)
    
    let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    setOpenCloseIconUp(iconStyle)    
  }

  const openModal = () =>{
    setModalVisibility(true)
  }

  // This is called from the UploadResume child
  const uploadModalOpen = (status) => {
    props.uploadModalOpen(false)
    setIsModalVisible(false)
    setModalVisibility(false)
  }

  const displayMessage = (messageToDisplay, type) => {
    // ## Temporary disabled to enable global message on page
    // setShowMessage(true)
    // setMessage(messageToDisplay)
    if(type === "error"){
      // Remove this if enabled messages in this component
      setShowMessage(true);
      setSuccessMessage(false);
      setMessage(messageToDisplay);
      props.showMessage({type: "error", message: "*** Error found at the Role section. ***"});
    }else{
      props.showMessage({type: "message", message: messageToDisplay});
    }
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
    }
  }

  return (
    <div className={styles.roleAreaContainer}>
      <div style={{display: companyInfoInterIsVisible}} className={styles.roleAreaContainer}>
        <form >
          <div className={styles.roleArea}>

            <b className={styles.roleNameLabel}>Role Name</b>
            <div className={styles.roleNameFieldArea}>
              <input className={styles.roleNameField} type="text" id="name" name="name" placeholder="Enter role/job position here..." value={roleName} onChange={(e)=> {setRoleNameValue(e.target.value);}} />
              <input className={styles.roleIdInputField} type="number" id="id" name="id" defaultValue={roleId} />
            </div>

            <div className={styles.roleJobPostingArea}>
              <b className={styles.roleJobPostingLabel}>
                Role Requirements | Job Posting
              </b>
              <div className={styles.roleJobPostingField}>
                <div className={styles.roleJobPostingInfoField}>
                  <textarea value={requirementsContent} onChange={(e) => {setRequirementsValue(e.target.value);}}               
                        className={styles.companyXIsLooking} 
                        id="requirements" name="requirements" 
                        rows={28 } cols={108} placeholder="Copy and Paste or type the role/job description here..."/>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.submitButtonArea} style={{display: isVisible}} onClick={handleSubmit}>
            <div >Save</div>
            <div >
              {/* <button className={styles.submitButton} type="submit" onClick={handleSubmit} >Save</button> */}
              <img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
            </div>
          </div>
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        </form>
      </div>
      {/* <img className={openCloseIconUp} onClick={setCompanyInterVisibleOnOff} alt="" src="/open-close-icon.svg" /> */}
    </div>
  );
};

export default RoleArea;
