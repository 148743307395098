import styles from'./App.module.css';
import Router from './routing/Router';
import Context from './common/Context/Context';
import ContextContainer from "./common/Context/ContextContainer";
import MessageManager from "./common/ErrorManager";

function App() {

   return (

    <div className={styles.AppGLobal}>
      
      <Context.Provider value={ContextContainer} >
        <Router />
      </Context.Provider>

    </div> 
  );
}

export default App;
