import styles from "./CalendarHome.module.css";
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";

import CalendarView from "../../components/calendar/CalendarView"

const CalendarHome = () => {

  return (
    
        <div className={styles.mainHomeArea}>
          <div className={styles.mainContentArea} >
            <div className={styles.welcomeLabelArea} >
              {/* <img className={styles.toLogo} src="/Talent_Logo_Txt_Color_Simple.svg" /> */}
              <span className={styles.talentColorLogo}>Talent</span> <span className={styles.opinionColorLogo}>Opinion</span> Calendar
            </div>
            <div className={styles.calendarArea} >
                <CalendarView />
            </div>
          </div>
        </div>
  )
};

export default CalendarHome;
