

export const GetPlanSelection = (selectedPlan)=>{
        
    // const planToBill = new amountMoney;

    switch(Number(selectedPlan.plan)) {
        case 1:
            return { currency: selectedPlan.currency, amount: 0,};
        case 2:
            return { currency: selectedPlan.currency, amount: 1000,};
        case 3:
            return { currency: selectedPlan.currency, amount: 1600,};
        case 4:
            return { currency: selectedPlan.currency, amount: 3500,};
        case 5:
            return { currency: selectedPlan.currency, amount: 6500,};
        case 999991:
            return { currency: selectedPlan.currency, amount: 100,};
        default:
            return { currency: selectedPlan.currency, amount: 1600,};
    }
  };