import { customAxios } from "../../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";

const useCustomAxios = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { ctx, setContext } = ContextManager()

    useEffect(() => {
        // Interceptor for the request
        const requestIntercept = customAxios.interceptors.request.use(
            config => { 
                if (!config.headers['Authorization']) {
                    let access_token = auth?.access_token
                    if(!access_token){
                        access_token = ctx.User.token ;
                        // console.log("Using token from LocalStorage")
                    }
                    config.headers['Authorization'] = `Bearer ${access_token}`;
                    // console.log("auth?.access_token: " + auth?.access_token )
                }
        
                return config;
            }, (error) => Promise.reject(error)
        );
        
        // Interceptor for the response
        const responseIntercept = customAxios.interceptors.response.use(
            response => response, async (error) => { 
                const prevRequest = error?.config;

                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return customAxios(prevRequest);
                }else if (error?.response?.status === 401){
                    // console.log("User logged out. Loging is required.")
                    navigate("/login", {state: {from: window.location.pathname}, replace: true});
                }

                return Promise.reject(error);    
             }
        );

        return () => {
            customAxios.interceptors.request.eject(requestIntercept);
            customAxios.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return customAxios;
}

export default useCustomAxios;