import styles from './Logout.module.css'
import useCustomAxios from '../common/hooks/useCustomAxios';
import { useNavigate} from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";
import MessageManager from "../common/ErrorManager";

const Logout = () => {

    const navigate = useNavigate();
    const { ctx, setContext } = ContextManager()
    const customAxios = useCustomAxios()

    const fetchData = async(URL) => {
        
        ctx.User.id = 0;
        ctx.User.token = "";
        setContext(ctx);
        localStorage.removeItem("ctx");

        await customAxios.get(URL)
        .then(res => {
            navigate("/Signin");
        })
        .catch(error => {
          MessageManager(error, "RoleArea", ctx.Config.Error.getLevels.error)
          navigate("/Signin");
        })
      }

    function timeout(delay) {
        return new Promise( res => setTimeout(console.log("Done2!"), delay) );
    }
    
    setTimeout(() => {
        fetchData("/signout");
      }, "2500");
    

  return (
    <div className={styles.mainArea}>
        <div className={styles.signingOut}> 
            <div><img height="250px"  src="/Signout_Icon_64x64_Path_Anim_Thin.svg"  ></img></div>
            <div ></div>
            <div className={styles.signingOutMessage} >Signing out</div>
            <div > <img className={styles.logo} height={"80px"} src="/Talent_Logo_Txt_Color_Simple.svg"  /> </div>
        </div>
    </div>
  );
};

export default Logout;