import styles from "./UserProfile.module.css";

import { useRef, useState, useEffect } from 'react';
import useCustomAxios from '../../common/hooks/useCustomAxios';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../../common/Context/ContextManager";
import MessageManager from "../../common/ErrorManager";
import FeaturesStats from "../resume/FeaturesStats";


const EMAIL_REGEX = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+[.]+[a-zA-Z0-9]+$/
// const NAME_LASTNAME_REGEX = /^[A-Z][a-z-]{3,23}$/;
const NAME_LASTNAME_REGEX = /^[A-Za-z-]{3,70}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const CODE_REGEX = /^[0-9]{6,6}$/;


const UserProfile = () => {

  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState(false)

  const [userId, setUserId] = useState(0)
  const [roleField, setRoleField] = useState('')
  const [userProfile, setUserProfile] = useState({})
  const [companyField, setCompanyField] = useState('')
  const [phoneField, setPhoneField] = useState('')
  const [isVisible, setIsVisible] = useState('none')

  const [nameField, setNameField] = useState("");
  const [nameFocus, setNameFocus] = useState(false);
  const [middleNameField, setMiddleNameField] = useState("");
  const [middleNameFocus, setMiddleNameFocus] = useState(false);
  const [lastNameField, setLastNameField] = useState("");
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [email2Field, setEmail2Field] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [email2Focus, setEmail2Focus] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validEmail2, setValidEmail2] = useState(false);
  const [passwordField, setPasswordField] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validMiddleName, setValidMiddleName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [matchPasswordField, setMatchPasswordField] = useState('');
  const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);
  const [validMatchPasswordField, setValidMatchPassword] = useState(false);
  const [validationCode, setValidationCode] = useState();
  const [isValidCode, setIsValidCode] = useState(false);
  const [validationCodeFocus, setValidationCodeFocus] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [fieldChange, setFieldChange] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [logoFileName, setLogoFileName] = useState('');
  const [logoToUse, setLogoToUse] = useState(0);
  const [logoStoredToUse, setLogoStoredToUse] = useState(0);
  const [logoUploadProgress, setLogoUploadProgress] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [imageLogo, setImageLogo] = useState("");
  const [imageLogoPreview, setImageLogoPreview] = useState("");

  const nameFieldRef = useRef();
  const middleNameFieldRef = useRef();
  const lastNameFieldRef = useRef();
  const emailFieldRef = useRef();
  const email2FieldRef = useRef();
  const errorMessageRef = useRef();    
  const validationCodeRef = useRef();
  const uploadInputRef = useRef(null);

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const navigate = useNavigate();
  let {state} = useLocation();

  // Retrieve data first and populate any state
  useEffect( () => {
    setUserId(ctx.User.id);
    if(!isRequestInProgress){
      setIsRequestInProgress(true)
      fetchData();
    }
    return () => {
    }
    }, [] 
  );

  useEffect(() => {
    setValidName(NAME_LASTNAME_REGEX.test(nameField));
    setFieldChange(true)
  }, [nameField])

  useEffect(() => {        
    setValidLastName(NAME_LASTNAME_REGEX.test(lastNameField));
    setFieldChange(true)
  }, [lastNameField])

  useEffect(() => {        
    setValidMiddleName(NAME_LASTNAME_REGEX.test(middleNameField));
    setFieldChange(true)
  }, [middleNameField])

  useEffect(() => {        
    // No validation for now
    setFieldChange(true);
}, [companyField, roleField])

  useEffect(() => {        
      setValidEmail(EMAIL_REGEX.test(emailField));
      setFieldChange(true);
      setEmailChange(true);
  }, [emailField])

  useEffect(() => {        
    setValidEmail2(EMAIL_REGEX.test(email2Field));
    setFieldChange(true);
    setEmailChange(true);
  }, [email2Field])

  useEffect(() => {

      setValidPassword(PWD_REGEX.test(passwordField));
      
      setValidMatchPassword(passwordField === matchPasswordField)

      if( passwordField !== "" || matchPasswordField !== ""){
        setPasswordChange(true);
      }else{
        setPasswordChange(false);
      }
  }, [passwordField, matchPasswordField])


  useEffect(() => {

      setIsValidCode(CODE_REGEX.test(validationCode));

  }, [validationCode])

  
  const setInitialData = (responseData)  => {
    setUserProfile(responseData)
    setNameField(responseData.first_name !== undefined && responseData.first_name !== null ? responseData.first_name : "");
    setMiddleNameField(responseData.middle_name !== undefined && responseData.middle_name !== null ? responseData.middle_name : "");
    setLastNameField(responseData.last_name !== undefined && responseData.last_name !== null ? responseData.last_name : "");
    setCompanyField(responseData.company !== undefined && responseData.company !== null ? responseData.company : "");
    setRoleField(responseData.role !== undefined && responseData.role !== null ? responseData.role : "");
    setEmailField(responseData.email_1 !== undefined && responseData.email_1 !== null ? responseData.email_1 : "");
    setEmail2Field(responseData.email_2 !== undefined && responseData.email_2 !== null ? responseData.email_2 : "");
    setPhoneField(responseData.phone !== undefined && responseData.phone !== null ? responseData.phone : "");
    setImageLogo(responseData.logo_image !== undefined && responseData.logo_image !== null ? responseData.logo_image : "/Talent_Logo_Txt_Color_Simple.svg")
    setLogoStoredToUse(responseData.logo_image_used !== undefined && responseData.logo_image_used !== null ? responseData.logo_image_used : 0);
    setLogoToUse(responseData.logo_image_used !== undefined && responseData.logo_image_used !== null ? responseData.logo_image_used : 0);
    // setImageLogoPreview(
    //   responseData.logo_image !== undefined && responseData.logo_image !== null ? responseData.logo_image : "/Talent_Logo_Txt_Color_Simple.svg"
    // );
    setPasswordField("");
    setMatchPasswordField("");
    setIsRequestInProgress(false)
    setFieldChange(false);
  };

  const fetchData = async() => {
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_COMPANY_ROLE_DETAIL_API 
    let URL_PATH = process.env.REACT_APP_USER_DETAIL_API
    let URL = URL_PATH
    await customAxios.get(URL)
    .then(res => {
      setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "UserProfile", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  };


  const postData = async(path, dataToPost, source) => {

    let URL_PATH = path;
    let URL = URL_PATH
    // const postData = "POST PAYLOAD HERE"
    const postData = dataToPost
    await customAxios.post(URL, postData)
    .then(res => {
      // setInitialData(res.data)
      setSuccessMessage(true);
      successPostMessage(res.data, source);
    })
    .catch(error => {
      errorsMessages(error, source)
      MessageManager(error, "UserProfile", ctx.Config.Error.getLevels.error)
    })
  };

  const successPostMessage = (data, source)=>{
    setIsRequestInProgress(false);

    if(source === 1){
      displayMessage("Profile updated.");
      setInitialData(data);
    }else if(source === 2){
      displayMessage("Primary email updated.");
      setInitialData(data);
    }else if(source === 3){
      displayMessage("Secondary email updated.");
      setInitialData(data);
    }else if(source === 4){
      displayMessage("Validation code sent.");
      setValidationCode("");
    }else if(source === 5){
      setPasswordField("");
      setMatchPasswordField("");
      setValidationCode("");
      displayMessage("Password changed.");
    }else{
      displayMessage("Request was successful.");
    };

  }

  // Errors captured when sending API requests
  const errorsMessages = (error, source) => {
    setIsRequestInProgress(false);
    let errorMsg = "An error was found. Please check the console for more information.";
    let errorMsgCode = "";

    if(error.response?.status === 400){
      errorMsgCode = " - Missing or incorrect validation code";
    }else if (error.response?.status === 401){
      errorMsgCode = " - Invalid Code";
    }else{
        errorMsgCode = ""
    };

    if(source === 1){
      errorMsg = "Profile cannot be updated. Please  review and try again.";
    }else if(source === 2){
      errorMsg = "Primary email cannot be updated " + errorMsgCode;
    }else if(source === 3){
      errorMsg = "Secondry email cannot be updated " + errorMsgCode;
    }else if(source === 4){
      errorMsg = "Validation code couldn't be sent. Please try again.";
    }else if(source === 5){
      errorMsg = "Invalid code, please review and try again.";
    }else{
      errorMsg = "An error found. Please try again.";
    };
    
    displayMessage(errorMsg)
  };

  const displayMessage = (messageToDisplay) => {
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        // showMessageArea = show
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
    }
  }

  const setSubmitButtonState = (state) => {
    if(state === true){
      setIsVisible('inline');
    }else{
      setIsVisible('none')
    }
  }


  const handleProfileUpdate = (e) =>{
    e.preventDefault();

    // if( (validName && validLastName && validEmail && validEmail2) && 
    if( (validName && validLastName && validEmail) && 
        (middleNameFieldRef?.current.value === "" && !validMiddleName || middleNameFieldRef?.current.value !== "" && validMiddleName)
      ){
        setIsRequestInProgress(true)
        setFieldChange(false)

        const dataToPost = {
          id: userId, username: emailField, first_name: nameField, middle_name: middleNameField,last_name: lastNameField, 
          company: companyField, role: roleField, email_1: emailField, email_2: email2Field, phone: phoneField, validation_code: validationCode
        };
        setIsRequestInProgress(true)
        postData(process.env.REACT_APP_USER_UPDATE_API, dataToPost, 1);
      };
  }

  const handleCodeRequest = (e) =>{
    e.preventDefault();

    let isEmailChanging = emailChange;
    let passwordChangeLocal = passwordChange;

    console.log("email 1: " + emailField);
    console.log("email 2: " + email2Field);
    console.log("passwordChange: " + passwordChange);

    if((emailField !== "" &&  userProfile.email_1 !== emailField) && (email2Field !== "" && userProfile.email_2 !== email2Field)){
      displayMessage("Only one email address can be changed per request.");
      setEmailChange(false);
    }else if((emailField !== "" && userProfile.email_1 !== emailField) === true || (email2Field !== "" && userProfile.email_2 !== email2Field)=== true && passwordChange === true){
      displayMessage("Only one email address or one password can be changed per request.")
      setPasswordChange(false);
      setEmailChange(false);
      passwordChangeLocal = false;
    }

    let requestCodeWithEmail = emailField;
    // Use the correct key for the request
    if(email2Field !== "" && userProfile.email_2 !== email2Field){
      requestCodeWithEmail = email2Field;
    }

    // if( (validName && validLastName && validEmail && validEmail2) && 
    if((passwordChangeLocal && validMatchPasswordField && validPassword) || (isEmailChanging === true)){
        setIsRequestInProgress(true);
        // setFieldChange(false);
        const dataToPost = {
          email_1: requestCodeWithEmail
        };

        setIsRequestInProgress(true)
        console.log("Sending request from: " + requestCodeWithEmail)
        console.log("Sending request to: " + process.env.REACT_APP_OAUTH_SEND_CODE_API)
        postData(process.env.REACT_APP_OAUTH_SEND_CODE_API, dataToPost, 4);
      };
  };

  const handlePasswordChange = (e) =>{
    e.preventDefault();

    // if( (validName && validLastName && validEmail && validEmail2) && 
    if((passwordChange && validMatchPasswordField && validPassword && isValidCode)){
      setIsRequestInProgress(true);
      // setFieldChange(false);
      const dataToPost = {
          id: userId, username: emailField, password: passwordField, request_validation_code: false, validation_code: validationCode
        };

        setIsRequestInProgress(true)
        postData(process.env.REACT_APP_USER_PASSWORD_UPDATE_API, dataToPost, 5);
      };
  }

  const planOptionSelected = (option) => {
    if(option === "chats"){
      const chats = ctx?.Resume?.chat === -1 ? "Unlimited": Number(ctx?.Resume?.chat);
      return chats;
    }else if(option === "reviews"){
      const reviews = ctx?.Resume?.reviews === -1 ? "Unlimited": Number(ctx?.Resume?.reviews);
      return reviews;
    }else if(option === "uploads"){
      const uploads = ctx?.Resume?.uploads === -1 ? "Unlimited": Number(ctx?.Resume?.uploads);
      return uploads;
    }else if(option === "compares"){
      const compares = ctx?.Resume?.compares === -1 ? "Unlimited": Number(ctx?.Resume?.compares);
      return compares;
    }else if(option === "plan"){
      const plan = ctx?.Resume?.plan === -1 ? "Unlimited": ctx?.Resume?.plan;
      return plan;
    }else if(option === "companies"){
      const companies = ctx?.Resume?.companies === -1 ? "Unlimited": ctx?.Resume?.companies;
      return companies;
    }else if(option === "roles"){
      const roles = ctx?.Resume?.roles === -1 ? "Unlimited": ctx?.Resume?.roles;
      return roles;
    }

    
  }


  const updateLogoSelection = (selection)=>{
    setLogoToUse(selection);

    setIsRequestInProgress(true);
    const url = process.env.REACT_APP_USER_LOGO_USED_UPDATE_API + "/" + selection;
    postLogoData(url, []);
  };

  const logoOptionSelected = (option) => {
    return(
      <select className={styles.logoOptionSelectField} id="logoOptionSelected" name="logoOptionSelected" value={logoToUse} onChange={(e)=> {updateLogoSelection(e.target.value);}}>
        <option value="" disabled>Select a logo to use</option>
        <option value={0}>None</option>
        <option value={1}>Talent Opinion Logo (default)</option>
        <option value={2}>My Logo</option>
      </select>
    )

  }

  const setSelectedLogo = (e)=>{
    e.preventDefault()
    if (!e.target.files) {
        displayMessage("No image have been selected.");
        setIsUploadEnabled(true);
        return;
    }
    setFileList(e.target.files);
    setLogoFileName(e.target.files[0].name);
    setIsUploadEnabled(true);
  }

  const uploadLogo = (e) => {
    e.preventDefault()
    setLogoUploadProgress(true);

    const logoFormData = new FormData();

    try{
      // fileList.forEach(
      //   (file) => {
        if(fileList.length > 0){
          // logoFormData.append(`files`, file, file.name);
          logoFormData.append(`file`, fileList[0], fileList[0].name);

          setIsRequestInProgress(true);
          const url = process.env.REACT_APP_UPLOAD_LOGO_API;
          postLogoData(url, logoFormData);

        }
      //   }
      // );
    }catch(er){
      alert(er);
    }
  };

  const selectLogosToUpload = (e) => {
    //Use the the hidden input element's click event
    e.preventDefault()
    uploadInputRef.current?.click();
  };


  const postLogoData = async(path, dataToPost) => {

    let URL_PATH = path;
    let URL = URL_PATH

    const postData = dataToPost
    await customAxios.post(URL, postData)
    .then(res => {
      // setInitialData(res.data)
      // setSuccessMessage(true);
      successLogoPostMessage(res.data);
    })
    .catch(error => {
      errorsMessages(error, 9)
      MessageManager(error, "UserProfile", ctx.Config.Error.getLevels.error)
    })
  };

  const successLogoPostMessage = (response)=>{
    setLogoUploadProgress(false);
    setIsUploadEnabled(false);
    setIsRequestInProgress(false);
  }

  return (
    <div className={styles.mainUserProfileArea}>
      {/* { fieldChange && (validName && validLastName && validEmail && validEmail2) && (middleNameFieldRef?.current.value === "" && !validMiddleName || middleNameFieldRef?.current.value != "" && validMiddleName) ? */}
      { fieldChange && (validName && validLastName && validEmail) && (middleNameFieldRef?.current.value === "" && !validMiddleName || middleNameFieldRef?.current.value != "" && validMiddleName) ?
      <div className={styles.submitButtonArea} onClick={handleProfileUpdate} >
          <div >Save</div>
          <div >
            <img className={styles.submitButtonIcon} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
          </div>
        </div>
        : <div className={styles.submitEmpty}></div>
      }
      {/* User Info Section */}
      <div className={styles.userNameInfoArea}>
        <div className={styles.firstNameArea}>
            {/* <div className={styles.firstNameLabel}>First Name</div> */}
            <label className={nameField && !validName ? styles.invalidFieldLabel : styles.firstNameLabel}  htmlFor="first_name">First Name</label>
            <div className={styles.firstNameInputArea}>
              <input className={styles.fistNameInputField} type="text" id="first_name" name="first_name" placeholder="Enter first name here..."
              ref={nameFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setNameField(e.target.value) } 
              value={nameField}
              required
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="nameMsg"
              onFocus={() => setNameFocus(true)}
              onBlur={() => setNameFocus(false)}
              />              
              <input className={styles.userIdInputField} type="number" id="id" name="id" defaultValue={userId} />
            </div>
        </div>
        <div id="nameMsg" className={nameFocus && nameField && !validName ? styles.fieldMsg : styles.screenReaderHidden}>
                Valid name is 3 to 70 characters.  Must begin with a letter.
                Numbers, spaces, and underscores are not allowed.
        </div>
        <div className={nameFocus && nameField && !validName ? styles.verticalSpacer: styles.userIdInputField}>&nbsp;</div>
        <div className={styles.middleNameArea}>
            {/* <div className={styles.middleNameLabel}>Middle Name</div> */}
            <label className={middleNameField && !validMiddleName ? styles.invalidFieldLabel : styles.middleNameLabel}  htmlFor="last_name">Middle Name</label>
            <div className={styles.middleNameInputArea}>
              <input className={styles.middleNameInputField} type="text" id="Middle_name" name="last_name" placeholder="(Optional) Enter middle name here..."
              ref={middleNameFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setMiddleNameField(e.target.value) } 
              value={middleNameField}
              aria-invalid={validMiddleName ? "false" : "true"}
              aria-describedby="middleNameMsg"
              onFocus={() => setMiddleNameFocus(true)}
              onBlur={() => setMiddleNameFocus(false)}
              />
            </div>
        </div>
        <div id="middleNameMsg" className={middleNameFocus && middleNameField && !validMiddleName ? styles.fieldMsg : styles.screenReaderHidden}>
                Valid Middle Name is 3 to 70 characters.  Must begin with a letter.
                Numbers and underscores are not allowed.
        </div>
        <div className={styles.lastNameArea}>
            {/* <div className={styles.lastNameLabel}>Last Name</div> */}
            <label className={lastNameField && !validLastName ? styles.invalidFieldLabel : styles.lastNameLabel}  htmlFor="last_name">Last Name</label>
            <div className={styles.lastNameInputArea}>
              <input className={styles.lastNameInputField} type="text" id="Last_name" name="last_name" placeholder="Enter last name here..."
              ref={lastNameFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setLastNameField(e.target.value) } 
              value={lastNameField}
              required
              aria-invalid={validLastName ? "false" : "true"}
              aria-describedby="lastNameMsg"
              onFocus={() => setLastNameFocus(true)}
              onBlur={() => setLastNameFocus(false)}
              />
            </div>
        </div>
        <div id="lastNameMsg" className={lastNameFocus && lastNameField && !validLastName ? styles.fieldShortMsg : styles.screenReaderHidden}>
                Valid Last Name is 3 to 70 characters.  Must begin with a letter.
                Numbers and underscores are not allowed.
        </div>
      </div>

      {/* Contact Info Section */}
      <div className={styles.contactInfoArea}>
        <div className={styles.companyArea}>
            <div className={styles.companyLabel}>Company</div>
            <div className={styles.companyInputFieldArea}>
              <input className={styles.companyInputField} type="text" id="company" name="company" placeholder="Enter company name here..." value={companyField} onChange={(e)=> {setCompanyField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.roleArea}>
            <div className={styles.roleLabel}>Title | Role</div>
            <div className={styles.roleInputFieldArea}>
              <input className={styles.roleInputField} type="text" id="role" name="role" placeholder="Enter role here..." value={roleField} onChange={(e)=> {setRoleField(e.target.value);}} />
            </div>
        </div>
        <div className={styles.emailArea}>
            {/* <div className={styles.emailLabel}>Primary Email</div> */}
            <label className={emailField && !validEmail ? styles.invalidFieldLabel : styles.emailLabel}  htmlFor="email_1">Primary Email</label>
            <div className={styles.emailInputFieldArea}>
              <input className={styles.email1InputField} type="text" id="email1" name="email1" placeholder="Enter primary email here..."
              ref={emailFieldRef}
              autoComplete="on" 
              onChange={ (e)=> setEmailField(e.target.value) } 
              value={emailField}
              readOnly={true}
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="emailInstructionMsg"
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
              />
            </div>
        </div>
        <div id="emailInstructionMsg" className={emailFocus && emailField && !validEmail ? styles.fieldMsg : styles.screenReaderHidden}>
                Valid emails include an initial name or identifier, followed by an '@' symbol, a valid domain, and extension.
                i.e., yourname@domain.com. | To update this email request a validation code below.
        </div>
        <div className={styles.email2Area}>
            {/* <div className={styles.emai2Label}>Secondary Email</div> */}
            <label className={email2Field && !validEmail2 ? styles.invalidFieldLabel : styles.emai2Label}  htmlFor="email_1">Secondary Email</label>
            <div className={styles.emai2LabelSubTitle}>For recovery and security</div>
            <div className={styles.emai2InputFieldArea}>
              <input className={styles.email2InputField} type="text" id="email_2" name="email_2" placeholder="Enter secondary email here..."
              ref={email2FieldRef}
              autoComplete="on" 
              onChange={ (e)=> setEmail2Field(e.target.value) } 
              value={email2Field}
              aria-invalid={validEmail2 ? "false" : "true"}
              aria-describedby="emailInstructionMsg"
              onFocus={() => setEmail2Focus(true)}
              onBlur={() => setEmail2Focus(false)}
              />
            </div>
        </div>
        <div id="emailInstructionMsg" className={email2Focus && email2Field && !validEmail2 ? styles.fieldShortMsg : styles.screenReaderHidden}>
                Valid emails include an initial name or identifier, followed by an '@' symbol, a valid domain, and extension.
                i.e., yourname@domain.com. | Request code below.
        </div>
        <div className={styles.phoneArea}>
            <div className={styles.phoneLabel}>Phone</div>
            <div className={styles.phoneInputFieldArea}>
              <input className={styles.phoneInputField} type="text" id="phone" name="phone" placeholder="Enter phone number here..." value={phoneField} onChange={(e)=> {setPhoneField(e.target.value);}} />
            </div>
        </div>
      </div>

      {/* Password area */}
      <div className={styles.passwordInfoArea}>
        <div className={styles.passwordArea}>
            {/* <div className={styles.passwordLabel}>Password</div> */}
            <label className={passwordField && !validPassword ? styles.invalidFieldLabel : styles.passwordLabel} htmlFor="password">Password</label>
            <div className={styles.passwordInputFieldArea}>
              <input className={styles.passwordInputField} type="password" id="password" name="password" placeholder="Password" 
              onChange={ (e)=> setPasswordField(e.target.value) } 
              value={passwordField}
              autoComplete="off" 
              required
              aria-invalid={validPassword ? "false" : "true"}
              aria-describedby="passwordMsg"
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}              
              />
            </div>
            { passwordChange && validMatchPasswordField && validPassword && isValidCode ?
              <div className={styles.submitButtonArea2}>
                <div >Save pwd</div>
                <div >
                  <img className={styles.submitButtonIcon} onClick={handlePasswordChange} alt="Save" title="Save" src="/Save_Icon_64x64_v2.svg" />
                </div>
              </div>
              : <div className={styles.submitEmpty}></div>
            }
        </div>
        <div id="passwordMsg" className={passwordFocus && passwordField && !validPassword ? styles.fieldMsg : styles.screenReaderHidden}>
                            Valid password is 8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.
                            i.e., <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
            </div>
        <div className={styles.passwordArea}>
            {/* <div className={styles.passwordLabel}>Confirm Password</div> */}
            <label className={matchPasswordField && !validMatchPasswordField ? styles.invalidFieldLabel : styles.passwordLabel} htmlFor="password">Confirm Password</label>
            <div className={styles.passwordInputFieldArea}>
              <input className={styles.passwordInputField} type="password" id="passwordMatch" name="passwordMatch" placeholder="Confirm"              
              onChange={ (e)=> setMatchPasswordField(e.target.value) } 
              value={matchPasswordField}
              autoComplete="off" 
              required
              aria-invalid={validMatchPasswordField ? "false" : "true"}
              aria-describedby="passwordMsg"
              onFocus={() => setMatchPasswordFocus(true)}
              onBlur={() => setMatchPasswordFocus(false)}
              />
            </div>
        </div>
        <div id="passwordMsg" className={matchPasswordFocus && !validMatchPasswordField ? styles.fieldMsg : styles.screenReaderHidden}>
                            Password and Confirm Password must match.
        </div>
        { passwordChange && validMatchPasswordField && validPassword || (email2Field && !validEmail2)=== false || (email2Field && !validEmail2)=== false ?
        <div className={styles.validationArea}>
            {/* <div className={styles.validationLabel}>Validation Code</div> */}
            <label className={validationCode && !isValidCode ? styles.invalidFieldLabel : styles.passwordLabel}  htmlFor="validation_code">Validation code</label>
            <div className={styles.validationInputFieldArea}>
              <input className={styles.validationInputField} type="text" id="code" name="code" placeholder="Code" 
              ref={validationCodeRef}
              autoComplete="off" 
              onChange={ (e)=> setValidationCode(e.target.value) } 
              value={validationCode}
              aria-invalid={isValidCode ? "false" : "true"}
              aria-describedby="matchPassMsg"
              onFocus={() => setValidationCodeFocus(true)}
              onBlur={() => setValidationCodeFocus(false)}
              />
            
            </div>
            { !isValidCode ?
              <div className={styles.validationRequestLabel} onClick={handleCodeRequest}>
                <div>
                  <img className={styles.validationRequestIcon} alt="Code" title="Code" src="/open-close-job-positions3g.svg" />
                </div>
                <div>
                Request<br/>code
                </div>
              </div>
              : <></>
            }
        </div>
        : <></>
        }
        <div id="matchPassMsg" className={validationCodeFocus && validationCode && !isValidCode ? styles.fieldMsg : styles.screenReaderHidden}>
          Validation codes are 6 digits long.
        </div>
      </div>
      <div className={styles.planTypeInfoArea}>
        <div className={styles.planTypeArea}>
          <label className={styles.planLabel} htmlFor="Plan Type">Plan Type:</label>
          <div className={styles.planFieldArea} >
            <div className={styles.planInputField} > {planOptionSelected("plan")} </div>
          </div>
        </div>
        <div className={styles.planTypeOptionsArea}>
          <label className={styles.planLabel} htmlFor="Plan Options">Usage:</label>
          <div className={styles.emai2LabelSubTitle}>Usage resets after new billing period.</div>
          <div className={styles.planOptionsFieldArea}>
            <div className={styles.planOptionsInputField}><FeaturesStats displayAll={true} /></div> 
          </div>
        </div>
      </div>

      <div className={styles.logoInfoArea}>
        <div className={styles.logoSelectionArea}>
          <label className={styles.logoGeneralLabel} htmlFor="Logo Selected">Logo selected:</label>
          <div className={styles.logoFieldArea} >
            <div className={styles.logoSelectionField} > {logoOptionSelected(logoStoredToUse)}</div>
          </div>
        </div>
        <div className={isUploadEnabled ? styles.uploadlogoButton : styles.uploadLogoButtonDisabled} onClick={uploadLogo}>
          <img  alt="Upload" width="48" height="28.8" title="Upload" src="/Upoad_Icon_64x64_Path_Simple.svg" />
          <div className={styles.uploadLabel}  >Upload</div>
        </div>
        <div className={styles.uploadLogoArea}>
          <label className={styles.logoUploadLabel} htmlFor="Logo Options" onClick={selectLogosToUpload} >Upload your logo:</label>
          <div className={styles.uploadLabelSubTitle}>
            <b>{logoFileName}</b>
            <br/><br/>
            <i>Select the logo to use on<br/>messages and calendar invites.</i>
          
          </div>
          
          <div className={styles.uploadLogoFieldArea}>
            <div className={styles.logoPreview} >
            {
              Number(logoToUse) === 0 ? "None selected." :

              Number(logoToUse) === 1 ? <img className={styles.logoPreView} alt="Logo" src={"/Talent_Logo_Txt_Color_Simple.svg"} /> :

              <img className={styles.logoPreView} alt="Logo" src={imageLogo} />
            }
            </div>
            <div className={styles.uploadLogoInputFieldContainer}>
              <input className={styles.uploadLogoInputField} 
                ref={uploadInputRef}
                onChange={(e) => setSelectedLogo(e)}
                style={{ display: 'none' }} 
                type="file" 
                id="logo_image" 
                name="logo_image"
                accept='.jpg,.png,.svg'
              />
            </div>
          </div>
          <div className={styles.maxSizeLabelSubTitle}>Max Width: 300px, Max Height: 84px</div>
        </div>
      </div>

      { showMessage ?
      <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
          <div className={ showMessage ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }>
              <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
              <div>
                {message}
              </div> 
          </div>
      </div>
      : 
      isRequestInProgress ? (
        <div className={styles.requestInProgressContainer } >
          <div className={styles.requestInProgressArea} >
            {/* <div className={ isRequestInProgress ? (successMessage ? styles.messageAreaSuccess : styles.messageArea) : styles.hideModal }> */}
              <img height='150' width='150' src='/Profile_Icon_WhiteCircle_Thicker_Plain_Anim.svg' /><br/>
              Profile in-progress...
            {/* </div> */}
          </div>
        </div>
      )
      :<></>
      
      }
    </div>
  );
};

export default UserProfile;
