import styles from "./ResumeCompare.module.css";
import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import useCustomAxios from '../common/hooks/useCustomAxios';
import MessageManager from "../common/ErrorManager";

import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import ContextManager from "../common/Context/ContextManager";


const ResumeCompare = () => {

  const [loadComponent, setLoadComponent] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState(false)
  // const [candidateInformation, setCandidateInformation] = useState()
  const [needsCompareAgain, setNeedsCompareAgain] = useState(false)
  const [resumeIdsToProcess, setResumeIdsToProcess] = useState([])
  const [processedResumeList, setProcessedResumeList] = useState([])
  const [betterFitRoleSelected, setBetterFitRoleSelected] = useState()
  const [isRequestInProgress, setIsRequestInProgress] = useState(false)
  const [noInfoAvailable, setNoInfoAvailable] = useState(false)
  const [openCloseIconUp, setOpenCloseIconUp] = useState(styles.openCloseIcon)
  const [isRationaleVisible, setRationaleVisible] = useState('inline')
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [onMyList, setOnMyList] = useState([false, false, false]);
  const [myList, setMyList] = useState("[]");

  const urlParameters = useParams();
  const { ctx, setContext } = ContextManager()
  const customAxios = useCustomAxios()
  const htmlContainerElement = useRef();
  const navigate = useNavigate();
  let {state} = useLocation();

  let processing = true
  let redirect = false
  let showMessageArea = false

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const RoleFitSelected = {
    better_fit_id: 0,
    title: "", 
    overall_rationale: ""
  }

  let fitAssessment = ["-", "Not a Fit", "Possible Fit", "God Fit", "Great Fit"]
  // resume.fit_score > 6? fit_assessment[3] : resume.fit_score > 3 ?  resume.fit_score[2] : resume.fit_score > 0 ? resume.fit_score[1] : resume.fit_score[0]
  let fitAssessmentStyle = [styles.nrFitStatus, styles.nafFitStatus, styles.pofFitStatus, styles.gdfFitStatus, styles.gtfFitStatus]

  let movement_timer = null;
  const SET_EXPERIENCE_RESIZE_TIMEOUT = 100;
  let initialSize = 906

  window.addEventListener('resize', ()=>{
    // clearInterval(movement_timer);
    // movement_timer = setTimeout(captureHtmlContainerSize, SET_EXPERIENCE_RESIZE_TIMEOUT);
    captureHtmlContainerSize()
  });

  const captureHtmlContainerSize = () =>{
    if(htmlContainerElement.current && Number(htmlContainerElement.current) != Number(initialSize)){
      let calcSize = 0
      // setWidth(htmlContainerElement.current.offsetWidth - 300);
      // setHeight(htmlContainerElement.current.offsetHeight);
      if(htmlContainerElement.current.offsetWidth < 531){
        calcSize = (htmlContainerElement.current.offsetWidth) - 10
      }else if(htmlContainerElement.current.offsetWidth < 799){
        calcSize = (htmlContainerElement.current.offsetWidth / 2) - 9
      }else{
        calcSize = (htmlContainerElement.current.offsetWidth / processedResumeList.length) - 9
      }

      setWidth(calcSize)
      initialSize = calcSize
      // style={{width: width}}

      MessageManager("Width | Calc (" + htmlContainerElement.current.offsetWidth + " | " + calcSize + ")", "ResumeCompare | setDimensions() --------", ctx.Config.Error.getLevels.trace)
    }
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    captureHtmlContainerSize();
  }, []);

  // Retrieve data first and populate any state
  useEffect( () => {

    let displayErrorMessage = false

    if(urlParameters.id > 0 && urlParameters.rid > 0 && (state && state.compare  === true)){

      setLoadComponent(false)
      if(state.compare === true && state.compareIds.length > 0){
        // fetchData(process.env.REACT_APP_RESUME_DETAIL_API )
        state.compare = false
        setIsRequestInProgress(true)

        let idList = []
        state.compareIds.map( id => idList = [...idList, Number(id)] )
        setResumeIdsToProcess(idList)

        //Check if any candiadate is on prefered list
        const newOnList = [...onMyList];
        const myPreferedList = loadMyListData();
        idList.map((id, index)=>{
          if(myPreferedList.includes(String(id))){
            newOnList[index] = true;
            setOnMyList(newOnList);
          };
        });

        postData(process.env.REACT_APP_RESUME_COMPARE_API, idList)
        setProcessedResumeList([])
      }else{
        displayErrorMessage = true
      }
    }else if(!isRequestInProgress) {
      displayErrorMessage = true
    }

    if(displayErrorMessage === true){
      let errorMessage = "Not enough resumes have been selected. Please select at least 2 resumes to perform a comparison."

      displayMessage(errorMessage)
    }

    return () => {
        processing = false
    }
    }, [state] 
  )

  let tempList = []
  const setInitialData = (responseData)  => {
    const {resume_list, better_fit_id, title, overall_rationale} = responseData
    setIsRequestInProgress(false)
    setShowMessage(false)
    showMessageArea = false
    setNeedsCompareAgain(false)
    setNoInfoAvailable(false)
    

    if(better_fit_id === undefined || better_fit_id === null || better_fit_id === 0){
      //probably an error happened in the processing

      setNeedsCompareAgain(true) 
      // setNoInfoAvailable(true)
    }else{

      tempList = resume_list
      setProcessedResumeList(resume_list)
      let newRoleFit = RoleFitSelected
      newRoleFit.better_fit_id = better_fit_id
      newRoleFit.title = title
      newRoleFit.overall_rationale = overall_rationale
      setBetterFitRoleSelected(newRoleFit)
    }
    processing = false

    if(redirect){
      navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newRole:true}});
    }
  }

  // Errors captured when sending API requests
  const errorsMessages = (error) => {
    setIsRequestInProgress(false)
    let noRoleFoundMsg = "Candidates comparison could not be performed. If this issue continues, then please try with different candidates."
    let errorMsg = "An error was found. Please check the console for more information"
    displayMessage(error.response !== undefined ? noRoleFoundMsg : errorMsg)
  }

  const performCompareAgain = ()=>{
    setNeedsCompareAgain(false)
    showMessageArea = false
    setIsRequestInProgress(true)
    postData(process.env.REACT_APP_RESUME_COMPARE_API, resumeIdsToProcess)
    // fetchData(process.env.REACT_APP_RESUME_COMPARE_API)
  }

  //Set the base domain and port for create/update, and requests urls.
  let COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT

  // Get data using the axios functionality
  const fetchData = async(urlPath) => {
    setIsRequestInProgress(true)
    // let URL_PATH = COMAIN_PORT +  process.env.REACT_APP_RESUME_DETAIL_API
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid + "/" + urlParameters.rsid
    // await axios.get(URL, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }})
    await customAxios.get(URL)
    .then(res => {
        setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeCompare", ctx.Config.Error.getLevels.error)
    }) //Make sure to use proper funcion to capture error
  }

  const postData = async(urlPath, idsToSend) => {
    COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + process.env.REACT_APP_RESUME_PORT
    // COMAIN_PORT = process.env.REACT_APP_HOST_DOMAIN + ":" + 4000
    // Change to support proxy
    // let URL_PATH = COMAIN_PORT +  urlPath
    let URL_PATH = urlPath
    let URL = URL_PATH + "/" + urlParameters.id + "/" + urlParameters.rid
    const postData = idsToSend
    // await axios.post(URL , postData, {
    //   headers: {
    //     'Authorization': 'Bearer ' + process.env.REACT_APP_TO_API_TOKEN
    //   }}
    //   )
    await customAxios.post(URL , postData)
    .then(res => {
      setInitialData(res.data)
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "ResumeCompare", ctx.Config.Error.getLevels.error)
    })
  }

  const displayMessage = (messageToDisplay) => {
    showMessageArea = true
    setShowMessage(true)
    setMessage(messageToDisplay)
  }

  const closeMessage = () =>{
    if(message){
        const show = showMessage === true ? false : true
        showMessageArea = show
        setShowMessage(show)
        setMessage("")
        setSuccessMessage(false)
        // messageStyles ==== styles.hideModal ? styles.messageArea ? styles.hideModal
        // navigate("/CompanySetup/" + ctx.Company.id + "/" + ctx.Role.id, {state: {newResume:true}});
        navigate(-1)
    }
  }

  const setRationaleVisibleOnOff = () => {
    // let isOn = isRationaleVisible === 'inline' ?  'none' : 'inline'
    // setRationaleVisible(isOn)
    
    // let iconStyle = isOn === 'inline' ? styles.openCloseIcon : styles.openCloseIconUp
    // setOpenCloseIconUp(iconStyle)    
  }

  const loadMyListData = () => {
    const myNewList = [];
    const myListInCtx = ctx.User?.myList !== null && ctx.User?.myList !== "[]" ? ctx.User?.myList : 0;
    if(myListInCtx !== 0){
      const parsedList = myListInCtx.replace("[", '').replace("]", '').split(',');
      parsedList.forEach((item)=> myNewList.push(item));
      setMyList(myNewList)
    }else{
      setMyList([]);
    }

    return myNewList;
  }

  const addToRemoveFromMyList = (id, index)=>{
    const newOnList = [...onMyList];
    newOnList[index] = onMyList[index] === true ? false : true;
    setOnMyList(newOnList);
    //send the request to store the change
    const url = process.env.REACT_APP_USER_UPDATE_FOCUSLIST_API;
    const idToUpdate = [id];
    postDataUpdateMyList(url, idToUpdate);
  }

  const updateMyListLocally = (response)=>{
    const {user_focus_list, last_updated} = response;
    ctx.User.myList = JSON.stringify(user_focus_list);
    setContext(ctx);
  }

  const postDataUpdateMyList = async(urlPath, dataToPost) => {
    let URL = urlPath
    const postData = dataToPost
    await customAxios.post(URL , postData)
    .then(res => {
      // setInitialData(res.data);
      updateMyListLocally(res.data);
    })
    .catch(error => {
      errorsMessages(error)
      MessageManager(error, "AdditionalEvalCrit", ctx.Config.Error.getLevels.error)
    })
  }

  return processedResumeList.length > 0  ? ( 
      <div ref={htmlContainerElement} className={styles.container} onLoad={captureHtmlContainerSize} >
        { processedResumeList.map( (candidateInformation, index) => 
        
        <div className={styles.mainCandidateReviewArea}  >
        {/* { processedResumeList.map( candidateInformation =>  */}
          <div key={candidateInformation.id}  className={styles.candidatesContainer} style={{width: width}} >
            <div className={betterFitRoleSelected.better_fit_id === candidateInformation.id ? styles.candidateContainerInfoAreaSelected :styles.candidateContainerInfoAreaNonSelected}>
                
                <div className={styles.candidateLabelArea}>
                  <div className={styles.candidateLabel}> {candidateInformation.name} </div>
                  <div onClick={()=>addToRemoveFromMyList(candidateInformation.id, index)} >
                  {
                    onMyList[index] === true ?
                  <img className={styles.starIconArea} alt="Add to My List" src="/Star_Icon.svg" />:
                  <img className={styles.starIconArea} alt="Add to My List" src="/Star_Empty_Icon.svg" />
                  }
                  </div>
                </div>
                <div className={styles.assessmentArea}>
                  <div className={fitAssessmentStyle[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null ? 0 : candidateInformation.fit_assessment + 1]} >{fitAssessment[candidateInformation.fit_assessment === undefined || candidateInformation.fit_assessment === null  ? 0 : candidateInformation.fit_assessment + 1]}</div>
                  <div className={styles.scoreContent} >Score: {candidateInformation.fit_score} </div>
                </div>              
                
            </div>
          
            <div className={styles.experienceArea} >
              <div className={styles.experienceTitle} >
                <div className={styles.experienceLabel}>
                  Experience Summary
                </div>
                <div className={styles.experienceOpenCloseArrow}>
                  <div><img className={openCloseIconUp} onClick={setRationaleVisibleOnOff} alt="" src="/open-close-icon.svg" /> </div>
                </div>
              </div>
              <div className={styles.experienceContent} style={{display: isRationaleVisible}}>
                {candidateInformation.fit_rationale}
                <p/>
              </div>
            </div>
          </div>
        {/* )} */}
        </div>
        

        )}

        {/* Message error */}
        { showMessageArea ? 
          <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
              <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                  <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                  <div>
                      {message}
                  </div> 
              </div>
          </div>
        : <></>}
        {/* ^ Message error ^ */}

        <div className={styles.overallArea} > 
          <div className={styles.rationaleTitle} >
            <div className={styles.rationaleLabel}>
              Overall | {betterFitRoleSelected.title}
            </div>
            <div className={styles.rationaleCompareAgainButton}>
              {/* <div><img onClick={setRationaleVisibleOnOff} alt="Compare again" title="Compare again" src="/compare-icon-button.svg" /></div> */}
            </div>
          </div>
          <div className={styles.rationaleContent} style={{display: isRationaleVisible}}>
              {betterFitRoleSelected.overall_rationale}
              <p/>  {/* <p/> To create a space at the bottom */}
          </div>
        </div>
      </div>

  ) : needsCompareAgain ? (
        <div className= {styles.noInfoMessageArea}>
            The compare process found some issues. Please try again<br/>
            If this issue continues, then please try with different resumes.
            <div className={styles.performReviewButton} onClick={()=> performCompareAgain()} >Perform a review</div>
        </div>
  ) : isRequestInProgress ? (
        <div className={styles.requestInProgressContainer} >
          <div className={styles.requestInProgressArea} >
            <img height='180' width='108' src='/TO_Page_Progress.gif' /><br/>
            Candidates comparison is in-progress...
          </div>
        </div>
  ) : noInfoAvailable ? (
        <div className= {styles.noInfoMessageArea}>
          No information available, please select a different set of candidates.
        </div>
  ) : showMessage ? (
        <div className={ showMessage ? styles.messageContainer : styles.hideModal } >
            <div className={ showMessage ? successMessage ? styles.messageAreaSuccess : styles.messageArea : styles.hideModal }>
                <div onClick={() => {closeMessage()}} className={styles.closeMessage}>&times;</div>
                <div>
                    {message}
                </div> 
            </div>
        </div>
  ) : ( 
        <div></div> 
  )
};

export default ResumeCompare;
